import React, { useState, useEffect } from 'react'
import { Web3ReactProvider } from '@web3-react/core'
import muiTheme from './Theme/muiTheme'
import SnackbarProvider from 'react-simple-snackbar'
import { ApolloProvider } from 'react-apollo'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { Web3Provider } from '@ethersproject/providers'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from 'redux/store'
import { BrowserRouter as Router } from 'react-router-dom'
import { createClient } from 'helper/graphql/index'
import { DEREX_SUBGRAPH } from 'app-constants'
import AppThemeProvider from './AppThemeProvider'
import Moralis from "moralis";

function getLibrary(provider: any) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}


const defautlSubgraphClient = createClient(DEREX_SUBGRAPH[97])

interface PProps {
  children: React.ReactNode;
}

const Providers: React.FC<PProps> = ({ children }) => {
  const [isMoralisStarted, setIsMoralisStarted] = useState(false)
  useEffect(() => {
    (async () => {
      if (isMoralisStarted === false) {
        await Moralis.start({
          apiKey: process.env.REACT_APP_MORALIS_API_KEY,
        });
        setIsMoralisStarted(true)
      }
    })()
  }, [])

  if (!isMoralisStarted) return null;

  return (
    <Router>
      <SnackbarProvider>
        <ApolloProvider client={defautlSubgraphClient}>
          <MuiThemeProvider theme={muiTheme}>
            <ReduxProvider store={store}>
              <Web3ReactProvider getLibrary={getLibrary}>
                <AppThemeProvider>
                  {children}
                </AppThemeProvider>
              </Web3ReactProvider>
            </ReduxProvider>
          </MuiThemeProvider>
        </ApolloProvider>
      </SnackbarProvider>
    </Router>
  )
}

export default Providers
