import React from 'react'
import ToggleButton from 'Component/core/elements/ToggleButton'
import { AppNumberInput, InputGroup, InputGroupControl, InputGroupText } from 'Component/core/elements/InputGroup/index'
import { ButtonGroup, ButtonGroupButton } from 'Component/core/elements/ButtonGroup/index'
import { RadioButton } from 'Component/core/elements/RadioButton'
import PeriodPicker from 'Component/core/elements/PeriodPicker'
import { BackButton } from 'Component/core/elements/Buttons'
import AppCheckbox from 'Component/core/elements/AppCheckbox'

function ComponentExplorer() {
  return (
    <div className='w-full px-20 py-20'>
      <div>
        <ToggleButton className='white fs-md fw-bold' label={['private', 'public']} />
      </div>
      <div>
        <h2>Input Elements</h2>
        <InputGroup>
          <InputGroupControl />
          <InputGroupText>%</InputGroupText>
        </InputGroup>
        <InputGroup className='mt-10'>
          <InputGroupText>%</InputGroupText>
          <InputGroupText>%</InputGroupText>
          <InputGroupText>%</InputGroupText>
          <InputGroupControl />
          <InputGroupText>111111111111111111111111111111111</InputGroupText>
        </InputGroup>
      </div>
      <div>
        <h2>Button Elements</h2>
        <ButtonGroup>
          <ButtonGroupButton>Standard</ButtonGroupButton>
          <ButtonGroupButton className='primary'>Fast</ButtonGroupButton>
          <ButtonGroupButton>Instant</ButtonGroupButton>
        </ButtonGroup>
      </div>
      <div>
        <RadioButton name='1' type='radio' />
        <RadioButton name='1' type='radio' />
        <RadioButton id='idAdd-No' name='isAdd' className='mr-5' label={<label htmlFor='idAdd-No'>No</label>} />
      </div>
      <div className='mt-10'>
        <h2>Period Picker</h2>
        <PeriodPicker
          label='Day'
          options={[
            {
              label: 'name',
              value: 'name',
            },
            {
              label: 'name',
              value: 'name',
            },
          ]}
          // @ts-ignore
          onClickOption={(e: any, option: any) => {
            console.log({
              e,
              option,
            })
          }}
        />
      </div>
      <AppNumberInput />
      <div className='mt-10'>
        <BackButton label='back' />
      </div>
      <AppCheckbox />
    </div>
  )
}

export default ComponentExplorer
