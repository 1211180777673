import {
  BondReducerParams,
  BondTabs,
  TokenParamsWithBalance,
  VestingForPrincipalParams,
  VestingForProfitParams,
  VestingPeriod,
  InvestmentLimitParams,
  StakingPeriodParams,
  FeeStructureLabelInfo,
  TokenBalance
} from "types"

export enum ActionTypes {
  SET_PARAMETERS            = 'BOND/SET_PARAMETERS',
  SET_TOKEN                 = 'BOND/SET_TOKEN',
  SET_TOKEN_INFO            = 'BOND/SET_TOKEN_INFO',
  SET_TOKEN_RATIO           = 'BOND/SET_TOKEN_RATIO',
  SET_FEE_STRUCTURE         = 'BOND/SET_FEE_STRUCTURE',
  SET_PAIR_TOKEN            = 'BOND/SET_PAIR_TOKEN',
  SET_VESTING_FOR_PRINCIPAL = 'BOND/SET_VESTING_FOR_PRINCIPAL',
  SET_VESTING_FOR_PROFIT    = 'BOND/SET_VESTING_FOR_PROFIT',
  SET_VESTING_PERIOD        = 'BOND/SET_VESTING_PERIOD',
  SET_INVESTMENT_LIMIT      = 'BOND/SET_INVESTMENT_LIMIT',
  SET_STAKING_PERIOD        = 'BOND/SET_STAKING_PERIOD',
  SET_PREPAYMENT_PENALTY    = 'BOND/SET_PREPAYMENT_PENALTY',
  SET_TAB                   = 'BOND/SET_TAB',
  SET_PAIRTOKEN_PRICE_USD   = 'BOND/SET_PAIRTOKEN_PRICE_USD',
  SET_HAS_PRINCIPAL_VESTING    = 'BOND/SET_HAS_PRINCIPAL_VESTING',
  SET_HAS_PROFIT_VESTING    = 'BOND/SET_HAS_PROFIT_VESTING',
  IS_ADDING_PROJECT_INFO    = 'BOND/IS_ADDING_PROJECT_INFO',
  RESET                     = 'BOND/RESET',
}

interface ActionSetParams {
  type: ActionTypes.SET_PARAMETERS
  payload: BondReducerParams
}
interface ActionSetToken {
  type: ActionTypes.SET_TOKEN
  payload: string
}
interface ActionSetTokenInfo {
  type: ActionTypes.SET_TOKEN_INFO
  payload: TokenBalance
}
interface ActionSetTokenRatio {
  type: ActionTypes.SET_TOKEN_RATIO
  payload: number
}

interface ActionSetPairToken {
  type: ActionTypes.SET_PAIR_TOKEN
  payload: TokenParamsWithBalance
}
interface ActionSetPairTokenPriceUSD {
  type: ActionTypes.SET_PAIRTOKEN_PRICE_USD
  payload: string | number
}
interface ActionSetVestingForPrincipal {
  type: ActionTypes.SET_VESTING_FOR_PRINCIPAL
  payload: VestingForPrincipalParams
}
interface ActionSetVestingForProfit {
  type: ActionTypes.SET_VESTING_FOR_PROFIT
  payload: VestingForProfitParams
}
interface ActionSetVestingForPeriod {
  type: ActionTypes.SET_VESTING_PERIOD
  payload: VestingPeriod
}
interface ActionSetInvestmentLimit {
  type: ActionTypes.SET_INVESTMENT_LIMIT
  payload: InvestmentLimitParams
}
interface ActionSetStakingPeriod {
  type: ActionTypes.SET_STAKING_PERIOD
  payload: StakingPeriodParams
}
interface ActionSetPrepaymentPenalty {
  type: ActionTypes.SET_PREPAYMENT_PENALTY
  payload: boolean
}
interface ActionSetTab {
  type: ActionTypes.SET_TAB
  payload: BondTabs
}
interface ActionIsAddingProjectInfo {
  type: ActionTypes.IS_ADDING_PROJECT_INFO
  payload: boolean
}
interface ActionReset {
  type: ActionTypes.RESET
}
interface ActionSetFeeStructure {
  type: ActionTypes.SET_FEE_STRUCTURE
  payload: FeeStructureLabelInfo
}

interface ActionSetHasProfitVesting {
  type: ActionTypes.SET_HAS_PROFIT_VESTING
  payload: boolean
}

interface ActionSetHasPrincipalVesting {
  type: ActionTypes.SET_HAS_PRINCIPAL_VESTING
  payload: boolean
}

export type ActionBond
  = ActionSetParams | ActionSetToken | ActionSetTokenInfo | ActionSetTokenRatio
  | ActionSetPairToken | ActionSetVestingForPrincipal | ActionSetVestingForProfit | ActionSetInvestmentLimit
  | ActionSetVestingForPeriod | ActionSetStakingPeriod | ActionSetPrepaymentPenalty | ActionSetTab
  | ActionIsAddingProjectInfo | ActionReset | ActionSetPairTokenPriceUSD | ActionSetFeeStructure
  | ActionSetHasProfitVesting | ActionSetHasPrincipalVesting
