import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import QueryString from 'query-string'
import { useIsMemeberCookie } from 'hooks'
import { getTelegramChannelName } from 'app-constants'
import { SEARCH_PARAMS } from 'app-constants/search-params'
import { AppRouters } from 'app-constants/app-routers'

const { REACT_APP_AUTH_URL } = process.env

function TelegramCallback(): JSX.Element {
  const history = useHistory()
  const [, setIsChannelMember] = useIsMemeberCookie()

  useEffect(() => {
    const search = history.location.search
    if(!search) return;
    const controller = new AbortController()
    const url = `${REACT_APP_AUTH_URL}${search}`

    axios
      .get(url, {
        signal: controller.signal,
      })
      .then((res) => {
        if (res.data.success) {
          setIsChannelMember('1', { days: 7 });
          const redirectUrl = QueryString.parse(history.location.search).redirectUrl as string;
          if(redirectUrl) {
            history.push(`${redirectUrl}${history.location.search}`)
          } else {
            history.push(`${AppRouters.IBO_BONDS_PAGE}${history.location.search}`)
          }
        } else {
          throw new Error('Somthing Went Wrong!')
        }
      })
      .catch((err) => {
        const message = err.response?.data?.message
        const errorMessage = err.message || err;
        const search = history.location.search;
        if (`${message}`.search(/join to channel/gi) > -1) {
          window.open(`https://t.me/${getTelegramChannelName()}`, '_blank')
          history.push(`${AppRouters.IBO_BONDS_PAGE}${search ? `${search}&` : '?'}${SEARCH_PARAMS.tgError}=${message}`)
        } else if(`${errorMessage}`.search(/canceled/gi) === -1) {
          history.push(`${AppRouters.IBO_BONDS_PAGE}${search ? `${search}&` : '?'}${SEARCH_PARAMS.tgError}=${errorMessage}`)
        } else {
          console.log('ERROR: ', errorMessage)
        }
      })

    return () => {
      controller.abort()
    }
  }, [history])

  return <></>
}

export default TelegramCallback
