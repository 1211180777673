import { BondData } from "types"

export enum ActionTypes {
  IS_LOADING = 'BOND_DETAIL/IS_LOADING',
  FETCH_BOND_DETAIL = 'BOND_DETAIL/FETCH_BOND_DETAIL',
}

interface ActionIsLoading {
  type: ActionTypes.IS_LOADING,
  payload: boolean
}

interface ActionFetchBondDetail {
  type: ActionTypes.FETCH_BOND_DETAIL,
  payload: BondData
}

export type Actions = ActionIsLoading | ActionFetchBondDetail
