import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { BsCheckLg, BsQuestionCircleFill } from 'react-icons/bs'
import { AppNumberInput, InputGroup, InputGroupControl } from 'Component/core/elements/InputGroup'
import { RadioButton } from 'Component/core/elements/RadioButton'
import { Divider } from 'Component/core/elements/Divider'
import { AppButton } from 'Component/core/elements/Buttons'
import AllsetPopup from 'Component/popup/AllsetPopup'

const STable = styled.table`
  width: 100%;
  & > tbody > tr > td {
    padding: 20px 0;
    &:nth-child(3) {
      padding-left: 20px;
    }
    &:nth-child(odd) {
      width: 20%;
      font-weight: bold;
    }
    &:nth-child(even) {
      width: 30%;
    }
  }
`

function BuybackProgram() {
  const [isOpenAllset, setIsOpenAllset] = useState(false)
  const [list, setList] = useState([1,2])

  const onClickAddMore = useCallback(() => {
    setList(p => [...p, 1])
  }, [])

  return (
    <div className='pt-50'>
      <div className='text-primary text-center bg-linear-1 py-40'>
        <h1 className='fs-2xl fw-800 text-content'>
          <BsCheckLg className='mx-20' />
          Congratulation! your bond been deploy sucessfully
        </h1>
      </div>
      <div className='mt-50 text-title'>
        <h3 className='text-primary fw-bold'>Now is the time to Secure sustainable project liquidity</h3>
        <p className='mt-20'>
          During LP vesting periods, investors either seek an early exit strategy or they are on the project tokens. In order to create the best opportunity for sustainable liquidity, projects can
          offer bond buybacks. Through a bon buyback program, project liquidity pools avoid future LP redemption and dumping events.
        </p>
        <div className='mt-40'>
          <div className='d-flex align-items-center mb-30'>
            <div className='fs-xl fw-bold bg-bodybg02 py-5 px-12 text-content'>1</div>
            <p className='m-0 ml-20'>Deposit project tokens into your vault. Tokens can be removed at anytime</p>
          </div>
          <div className='d-flex align-items-center mb-30'>
            <div className='fs-xl fw-bold bg-bodybg02 py-5 px-12'>2</div>
            <p className='m-0 ml-20'>Select the LPs to buyback</p>
          </div>
          <div className='d-flex align-items-center mb-30'>
            <div className='fs-xl fw-bold bg-bodybg02 py-5 px-12'>3</div>
            <p className='m-0 ml-20'>Choose a buyback offer at a percentage premium or discount over the current market price</p>
          </div>
        </div>
      </div>
      <div className='mb-20 text-title'>
        <div>
          {list.map((l, idx) =>
            <div key={idx}>
              <STable>
                <tbody>
                  <tr>
                    <td>
                      <span>Deposit tokens to vault</span>
                      <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                    </td>
                    <td className='position-relative'>
                      <InputGroup className='py-3'>
                        <InputGroupControl />
                      </InputGroup>
                      <button className='fs-sm text-muted position-absolute right-0 -bottom-0'>Balance 10,000,000 SMART</button>
                    </td>
                    <td>
                      <span>Choose a pair</span>
                      <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                    </td>
                    <td>
                      <InputGroup className='py-3'>
                        <InputGroupControl />
                      </InputGroup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Buyback offer</span>
                      <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                    </td>
                    <td className='position-relative'>
                      <AppNumberInput />
                      <p className='text-danger position-absolute mt-5 fs-sm'>You are offering +5% buyback above the market</p>
                    </td>
                    <td>
                      <span className='text-nowrap'>
                        Seller Dumper Shield <br /> protection
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                      </span>
                    </td>
                    <td>
                      <div className='d-flex align-items-center row'>
                        <RadioButton
                          id='seller-dumper-yes'
                          name='seller-dumper'
                          className='d-flex align-items-center py-10 col'
                          label={
                            <label htmlFor='seller-dumper-yes' className='ml-10 pt-5 text-capitalize'>
                              Yes
                            </label>
                          }
                        />
                        <RadioButton
                          id='seller-dumper-no'
                          name='seller-dumper'
                          className='d-flex align-items-center py-10 col'
                          label={
                            <label htmlFor='seller-dumper-no' className='ml-10 pt-5 text-capitalize'>
                              No
                            </label>
                          }
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </STable>
              <Divider className='vertical highlighted-border-bottom mt-20' />
            </div>
          )}
        </div>
        <button className='text-primary fw-bold text-content mt-20' onClick={onClickAddMore}>+Add more buyback offers</button>
        <div className='d-flex justify-content-center py-40'>
          <AppButton className='text-uppercase outline-primary py-15 w-max-700' onClick={() => setIsOpenAllset((p) => !p)}>
            Approve buyback offer
          </AppButton>
        </div>
      </div>
      <AllsetPopup isOpen={isOpenAllset} dismiss={() => setIsOpenAllset(false)} />
    </div>
  )
}

export default BuybackProgram
