import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { TourProvider } from '@reactour/tour'
import ChainChecker from 'Component/ChainChecker'
import AppHeader from 'Component/Headers/AppHeader';
import AppLoadingSpinner from 'Component/AppLoadingSpinner'
import App2ContextProvider from 'App2ContextProvider'
import { ControlButton, DerexTourSteps, IBOTourSteps } from 'Component/tours';
import { Divider } from 'Component/core/elements/Divider';
import Footer from 'Component/Footers/Footer';
// pages
// import IBO_BondDetail from 'Pages/IBO/IBO-Bond-Detail'
import IBOAbout from 'Pages/IBO/IBOAbout'
import Reimbursement from 'Pages/reimbursement'
import LaunchBondPool from 'Pages/IBO/LaunchBondPool'
import RoiBoosterPage from 'Pages/RoiBoosterPage';
import { AppRouters } from 'app-constants/app-routers';
import { RoiBoosterTabs } from 'types';
const DynamicSwapPage = React.lazy(() => import('Pages/DynamicSwap/dynamic-swap'))
const LaunchPools = React.lazy(() => import('Pages/IBO/BondListPage'))

function Router(props: RouteComponentProps): JSX.Element {
  const parentRouter = props.match.path;

  return (
    <div>
      <AppHeader />
      <section id='app-section'>
        <Switch>
          <Route path={parentRouter} exact component={() => <Redirect to={'/swap'} />}></Route>
          <Route path={`${parentRouter}swap`}>
            <React.Suspense fallback={<AppLoadingSpinner />}>
              <ChainChecker>
                <TourProvider
                  steps={DerexTourSteps}
                  // showNavigation={false}
                  showDots={false}
                  showCloseButton={false}
                  showBadge={false}
                  position='left'
                  nextButton={({ setCurrentStep, currentStep, stepsLength, setIsOpen }) => {
                    const handleOnClickNext = () => {
                      const nextStep = currentStep + 1
                      if(stepsLength === nextStep) {
                        setIsOpen(false);
                      } else {
                        setCurrentStep(nextStep);
                      }
                    }
                    return (
                      <ControlButton className='active d-flex align-items-center' onClick={handleOnClickNext}>
                        <Divider className='horizontal h-min-10  px-0 mx-5 highlighted-border-right' />
                        <div>Next</div>
                      </ControlButton>
                    )
                  }}
                  prevButton={({ setCurrentStep, currentStep, setIsOpen }) =>
                    <div className='d-flex align-items-center'>
                      <ControlButton onClick={() => setIsOpen(false)}>I got it, let&apos;s start</ControlButton>
                      <Divider className='horizontal h-min-10  px-0 mx-5 highlighted-border-right' />
                      <ControlButton disabled={currentStep === 0} onClick={() => setCurrentStep(currentStep - 1)}>
                        <div>Previous</div>
                      </ControlButton>
                    </div>
                  }
                  onClickMask={({ currentStep, steps, setCurrentStep, setIsOpen }) => {
                    const nextStep = currentStep + 1;
                    steps?.length && nextStep < steps.length && setCurrentStep(nextStep)
                    steps?.length && nextStep === steps.length && setIsOpen(false)
                  }}
                >
                  <DynamicSwapPage />
                </TourProvider>
              </ChainChecker>
            </React.Suspense>
          </Route>
          <Route path={`${parentRouter}reimbursement`} exact>
            <ChainChecker>
              <App2ContextProvider>
                <Reimbursement />
              </App2ContextProvider>
            </ChainChecker>
          </Route>
          <Route path={`${parentRouter}ibo/launch-pools`} exact>
            <React.Suspense fallback={<AppLoadingSpinner />}>
              <ChainChecker>
                <TourProvider
                  steps={IBOTourSteps}
                  // showNavigation={false}
                  showDots={false}
                  showCloseButton={false}
                  showBadge={false}
                  position='left'
                  nextButton={({ setCurrentStep, currentStep, stepsLength }) =>
                    <ControlButton className='active d-flex align-items-center' disabled={stepsLength === currentStep + 1} onClick={() => setCurrentStep(currentStep + 1)}>
                      <Divider className='horizontal h-min-10  px-0 mx-5 highlighted-border-right' />
                      <div>Next</div>
                    </ControlButton>
                  }
                  prevButton={({ setCurrentStep, currentStep, setIsOpen }) =>
                    <div className='d-flex align-items-center'>
                      <ControlButton onClick={() => setIsOpen(false)}>I got it, let&apos;s start</ControlButton>
                      <Divider className='horizontal h-min-10  px-0 mx-5 highlighted-border-right' />
                      <ControlButton disabled={currentStep === 0} onClick={() => setCurrentStep(currentStep - 1)}>
                        <div>Previous</div>
                      </ControlButton>
                    </div>
                  }
                  onClickMask={({ currentStep, steps, setCurrentStep, setIsOpen }) => {
                    const nextStep = currentStep + 1;
                    steps?.length && nextStep < steps.length && setCurrentStep(nextStep)
                    steps?.length && nextStep === steps.length && setIsOpen(false)
                  }}
                >
                  <LaunchPools />
                </TourProvider>
              </ChainChecker>
            </React.Suspense>
          </Route>
          <Route path={`${parentRouter}ibo/launch-pools/:bondId`} exact component={() => <Redirect to={AppRouters.ComingSoon_Page} />} >
            {/* <ChainChecker>
              <IBO_BondDetail />
            </ChainChecker> */}
          </Route>
          <Route path={`${parentRouter}ibo/about`} exact>
            <ChainChecker>
              <IBOAbout />
            </ChainChecker>
          </Route>
          <Route path={`${parentRouter}ibo/launch-bond-pool`} exact>
            <ChainChecker>
              <LaunchBondPool />
            </ChainChecker>
          </Route>
          <Route path={`${parentRouter}roi-booster`} component={() => <Redirect to={`${parentRouter}roi-booster/${RoiBoosterTabs.BondHolders}`} />} exact />
          <Route path={`${parentRouter}roi-booster/:tabId`} exact>
            <ChainChecker>
              <RoiBoosterPage />
            </ChainChecker>
          </Route>
        </Switch>
        <Footer />
      </section>
    </div>
  )
}

export default Router
