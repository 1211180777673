import React, { useMemo, useState, useCallback } from 'react'
import { TableColumn } from 'react-data-table-component'
import { BsQuestionCircleFill } from 'react-icons/bs'
import { FaSort } from 'react-icons/fa'
import { HiExternalLink } from 'react-icons/hi'

import Gs from 'Theme/globalStyles'
import { PageTitleSection } from 'Component/core/elements/others/styled'
import { MainDesc, MainTitle } from 'Component/core/elements/Text'
import { AppButton } from 'Component/core/elements/Buttons'
import CustomReactTablePagination, { DoubleTableCustomStyles } from 'Component/core/elements/CustomReactDataTableElements'
import SupportingBlockchains from 'Component/Supports/SupportingBlockchains'
import SupportingAllDexs from 'Component/Supports/SupportingAllDexs'
import ExpandableRowComponent from './components/RewardsTable'
import { CliamAllButton } from './components/styled'
import AppLink from 'Component/AppLink'
import useAppDebounceEffect from 'hooks/useAppDebounceEffect'
import LPInsureSubgraph from 'helper/subgraphs/LPInsureSubgraph'
import { useAppChain, useOnScreen } from 'hooks'
import useWallet from 'hooks/useWallet'
import { TokenBalanceSpan } from 'Component/core/elements/others/global-components'
import { RowType } from './local-types'
import CustomScrollbars from 'Component/core/elements/CustomScrollbars'
import AppDataTable from 'Component/AppDataTable'
import { withConnectWalletModal } from 'redux/actions'

const emptyTableData: RowType[] = [{
  id: '',
  pairToken: {
    symbol: ''
  },
  totalRewardAvlToClaim: '',
  defaultExpanded: false
}]

function RenderBondList() {
  const [loadMoreRequired, lastRef] = useOnScreen({ threshold: 0.1 })

  return (
    <CustomScrollbars className='bg-bodybg04 scrollbar-show radius-2' height='150px' width='200px' style={{ position: 'relative' }}
    >
      <div className='p-15'>
        <div>DRX</div>
      </div>
      <div ref={lastRef} />
    </CustomScrollbars>
  )
}
interface ISelfCustodyFarmingPageProps {
  connectWallet: () => void;
}
function SelfCustodyFarmingPage({ connectWallet }: ISelfCustodyFarmingPageProps) {
  const { appChainId } = useAppChain()
  const { active, account } = useWallet()
  const [tableData, setTableData] = useState<RowType[]>([])

  const handleOnClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const bondId = e.currentTarget.dataset.id as string;
    setTableData(p => {
      const idx = p.findIndex(item => item.id === bondId)
      p[idx].defaultExpanded = !p[idx].defaultExpanded;
      return p.slice();
    })
  }, [])

  const columns = useMemo<TableColumn<RowType>[]>(
    () => [
      {
        id: 'no',
        name: '',
        width: '48px',
        minWidth: '48px',
        cell: (row, rowIndex) => {
          return <span>{ +(rowIndex || 0) + 1 }</span>
        },
      },
      {
        id: 'bond',
        name: <>Bond <FaSort /></>,
        cell: (row: RowType) => {
          return <div className='w-full token-name' data-id={row.id} onClick={handleOnClick}>{row.pairToken.symbol}</div>
        },
      },
      {
        id: 'total-reward',
        name: <>Total reward available to claim <FaSort /></>,
        style: {
          color: 'white'
        },
        cell: (row: RowType) => {
          return <TokenBalanceSpan digits={6}>{row.totalRewardAvlToClaim}</TokenBalanceSpan>
        },
      },
      {
        id: 'claim-returns',
        name: 'Claim returns',
        center: true,
        cell: () => {
          return <AppButton className='outline-black hover:outline-primary fs-md text-primary fw-normal py-5 radius-3'>Claim</AppButton>
        },
      },
    ],
    [handleOnClick]
  )

  useAppDebounceEffect(() => {
    (async () => {
      const insureClient = new LPInsureSubgraph(appChainId);
      if(account) {
        const { data } = await insureClient.getInsureBondsByUser(account);
        const rows = data.user.insureBonds.map((insureBond) => {
          const row = {
            id: insureBond.id,
            pairToken: {
              symbol: insureBond.offer.pairToken.symbol
            },
            totalRewardAvlToClaim: 0,
            defaultExpanded: false,
          } as RowType;
          return row;
        })
        setTableData(rows)
      }
    })()
  }, 200, [account])

  return (
    <Gs.Container className='pb-20'>
      <PageTitleSection>
        <MainTitle className='mb-0 text-start'>Self Custody Farming</MainTitle>
        <MainDesc className='text-start pl-0 mt-20'>
          By simply holding your bond, you can earn rewards from multiply assets
          <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
        </MainDesc>
      </PageTitleSection>
      <div className='text-end my-20'>
        <AppLink className='text-primary fw-bold fs-md position-relative pr-15' to='/lp/farming-rewards/reward-bond-holders'>Projects, airdrop your tokens to liquidity providers investors <HiExternalLink className='position-absolute right-0 -top-8' /></AppLink>
      </div>
      <div className='h-min-50vh'>
        <AppDataTable
          requireWalletConnect={!active}
          emptyTableData={emptyTableData}
          onClickConnectWallet={connectWallet}
          columns={columns}
          data={tableData}
          customStyles={DoubleTableCustomStyles}
          pagination
          dense
          paginationComponent={(props) =>
            <div className='d-flex justify-content-between mt-15'>
              <CustomReactTablePagination {...props} />
              <div className='d-flex align-items-center'>
                <CliamAllButton className='outline-black hover:outline-primary text-primary fw-normal'>Claim All</CliamAllButton>
              </div>
            </div>
          }
          expandableRows
          expandableRowsHideExpander
          expandableRowExpanded={row => row.defaultExpanded}
          expandableRowsComponent={ExpandableRowComponent}
        />
      </div>
      <div className='mt-30 pt-10 pb-10 mb-10 relative z-index-10'>
        <SupportingAllDexs />
        <SupportingBlockchains
          onClickTitle={() => {
            console.log('onClickTitle')
          }}
        />
      </div>
    </Gs.Container>
  )
}

export default withConnectWalletModal(SelfCustodyFarmingPage)