import React, { useState, useCallback, useMemo } from 'react'
import { TableColumn } from 'react-data-table-component'
import { BsQuestionCircleFill } from 'react-icons/bs'
import { FaSort } from 'react-icons/fa'

import { AppButton } from 'Component/core/elements/Buttons'
import CustomReactTablePagination, { TableCustomStyles } from 'Component/core/elements/CustomReactDataTableElements'
import TransactionModals from 'Component/popup/transactions/transaction-popups'
import { PageTitleSection } from 'Component/core/elements/others/styled'
import { MainDesc, MainTitle } from 'Component/core/elements/Text'
import Gs from 'Theme/globalStyles'
import SupportingAllDexs from 'Component/Supports/SupportingAllDexs'
import SupportingBlockchains from 'Component/Supports/SupportingBlockchains'
import { AddNewRewardsButton } from './styled'
import AddNewRewardPopup, { RewardsFormDataType } from 'Component/popup/AddNewRewardPopup'
import { simpleAmountInString } from 'helper/math'
import { useAppDispatch } from 'redux/hooks'
import { useAppChain, useProcessingModal, useTransactionModal } from 'hooks'
import { addFarm } from 'redux/reducers/lp-insure/actions'
import useAppSnackbar, { AppWaringOptions } from 'hooks/useAppSnackbar'
import { Dropdown } from 'Component/core/elements/Dropdown'
import SelectInsureOffer, { OfferType } from 'Component/popup/SelectInsureOfferPopup'
import { getClassId } from 'helper/sc-utils/others'
import { getInsureViewId } from 'helper/insure-utils'
import useAppDebounceEffect from 'hooks/useAppDebounceEffect'
import LPInsureSubgraph from 'helper/subgraphs/LPInsureSubgraph'
import useWallet from 'hooks/useWallet'
import { GetInsureOffersWithLPBalanceType, InsureFarm } from 'helper/graphql'
import { TokenBalanceSpan } from 'Component/core/elements/others/global-components'
import AppDataTable from 'Component/AppDataTable'
import { withConnectWalletModal } from 'redux/actions'

type RowType = {
  name: string
  rewardToken: string
  tokenValue: string | number
  supplyBalance: string | number
}

const emptyTableData = [{
  name: '',
  rewardToken: '',
  tokenValue: '',
  supplyBalance: '',
}]

interface IRewardsBondHoldersPageProps {
  connectWallet: () => void; // from withWalletConnect
}

function RewardsBondHoldersPage({ connectWallet }: IRewardsBondHoldersPageProps) {
  const dispatch = useAppDispatch()
  const { active, account } = useWallet()
  const { appChainId } = useAppChain()
  const [openLoadingModal, closeLoadingModal] = useProcessingModal()
  const { open: openTRModal, close: closeTRModal, hash: TRMHash, isOpen: isTRMOpen } = useTransactionModal()
  const [openWaringSnackbar] = useAppSnackbar(AppWaringOptions)

  const [showAddNewRewardsModal, setShowAddNewRewardsModal] = useState(false);
  const [showSelectOffer, setShowSelectOffer] = useState(false);
  const [selectedBond, setSelectedBond] = useState<OfferType | null>(null);
  
  const [myInsureFarms, setMyInsureFarms] = useState<InsureFarm[]>([]);
  const [myInsureOffers, setMyInsureOffers] = useState<GetInsureOffersWithLPBalanceType[]>([]);

  const [tableData, setTableData] = useState<RowType[]>([])

  const columns = useMemo<TableColumn<RowType>[]>(
    () => [
      {
        id: 'bond',
        name: <>Bond <FaSort /></>,
        cell: (row: RowType) => {
          return <div className='w-full token-name'>{row.name}</div>
        },
      },
      {
        id: 'reward-token',
        name: <>Reward Token <FaSort /></>,
        style: {
          color: 'white'
        },
        cell: (row: RowType) => {
          return <span className='token-name'>{row.rewardToken}</span>
        },
      },
      {
        id: 'token-value',
        name: <>Token Value <FaSort /></>,
        style: {
          color: 'white'
        },
        cell: (row: RowType) => {
          return <TokenBalanceSpan prefix='$'>{row.tokenValue}</TokenBalanceSpan>
        },
      },
      {
        id: 'supply-balance',
        name: <>Supply balance <FaSort /></>,
        style: {
          color: 'white'
        },
        cell: (row: RowType) => {
          return <TokenBalanceSpan>{row.supplyBalance}</TokenBalanceSpan>
        },
      },
      {
        id: 'withdraw-balance',
        name: 'Withdraw Balance',
        center: true,
        cell: () => {
          return <AppButton className='outline-black hover:outline-primary fs-md text-primary fw-normal py-4 radius-3'>Withdraw</AppButton>
        },
      },
      {
        id: 'add-new-rewards',
        name: <>Add new rewards <FaSort /></>,
        center: true,
        cell: () => {
          return <AppButton className='no-fill hover:outline-primary fs-md text-primary fw-normal py-4 radius-3'>Add</AppButton>
        },
      },
    ],
    []
  )

  const handleOnSubmitAddReward = useCallback(async(_formData: RewardsFormDataType) => {
    if (selectedBond) {
      const rewardsdPeriod = _formData.rewardsPeriod * _formData.rewardsPeriodOption.value;
      const rewardsAmount = simpleAmountInString(_formData.supplyOfRewards, _formData.rewardsTokenInfo.decimals)
      try {
        openLoadingModal('Adding new reward...')
        const classId = getClassId(selectedBond.token.address)
        const result = await dispatch(addFarm(classId, _formData.rewardsTokenInfo, rewardsAmount, rewardsdPeriod, _formData.hasDumperShield))
        openTRModal(result.transactionHash)
        setShowAddNewRewardsModal(false)
      } catch (error: unknown) {
        // @ts-ignore
        openFailedModal(error.message || error.toString())
      } finally {
        closeLoadingModal()
      }
    } else {
      setShowAddNewRewardsModal(false);
      openWaringSnackbar('Please Select Bond!!!');
    }
  }, [selectedBond])
  const handleOnDismissRewardsModal = useCallback(() => {
    setShowAddNewRewardsModal(false)
  }, [])
  const handleOnSelectOffer = useCallback((_offer: OfferType) => {
    setSelectedBond(_offer)
    setShowSelectOffer(false)
  }, [])
  const handleOnDismissSelectOffer = useCallback(() => {
    setShowSelectOffer(false)
  }, [])
  const handleOnClickSelectBond = useCallback(() => {
    setShowSelectOffer(true)
  }, [])
  const handleOnClickAddReward = useCallback(() => {
    if(!selectedBond) {
      return openWaringSnackbar('First Select offer!');
    }
    setShowAddNewRewardsModal(true)
  }, [selectedBond])

  const fetchUserFarms = useCallback(async() => {
    const insureClient = new LPInsureSubgraph(appChainId)
    if(account) {
      const where = { owner: account.toLowerCase() }
      const { data } = await insureClient.getInsureFarms(where)
      setMyInsureFarms(data.insureFarms)
    } else {
      setMyInsureFarms([])
    }
  }, [appChainId, account])

  const fetchUserInsureOffers = useCallback(async() => {
    const insureClient = new LPInsureSubgraph(appChainId)
    if(account) {
      const where = {
        creator: account.toLowerCase()
      }
      const {data} = await insureClient.getLPBonds(where, 1000)
      setMyInsureOffers(data.insurePools)
    } else {
      setMyInsureOffers([])
    }
  }, [appChainId, account])

  useAppDebounceEffect(() => {
    fetchUserFarms()
  }, 200, [fetchUserFarms])
  useAppDebounceEffect(() => {
    fetchUserInsureOffers()
  }, 200, [fetchUserInsureOffers])

  useAppDebounceEffect(() => {
    const rows: RowType[] = [];
    myInsureOffers.forEach((offer) => {
      const farms = myInsureFarms.filter(farm => farm.classId === getClassId(offer.token.address));
      farms.forEach((farm) => {
        rows.push({
          name: `${offer.token.symbol} <> ${offer.pairToken.symbol}`,
          rewardToken: farm.rewardToken.symbol,
          tokenValue: 1.22,
          supplyBalance: 0,
        })
      })
    })
    setTableData(rows)
  }, 300, [myInsureFarms, myInsureOffers])

  return (
    <Gs.Container className='pb-20'>
      <PageTitleSection>
        <MainTitle className='mb-0 text-center'>Reward bond holders with your token</MainTitle>
        <MainDesc className='pl-0 mt-20 mw-100'>
          Self-custody farming rewards can also help your project to stand out in a crowded market. By offering additional rewards to LPs, the project becomes more attractive compared to other similar projects without such incentives.
          <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
        </MainDesc>
      </PageTitleSection>
      <div>
        <div className='d-flex justify-content-end py-10'>
          <Dropdown
            className='dropdown dark mr-20'
            onClick={handleOnClickSelectBond}
          >
            <button
              type='button'
              className='btn bg-none dropdown-toggle radius-8 d-flex align-items-center'
            >
              <p className='mb-0 py-5 pr-15'>{selectedBond ? `(#${getInsureViewId(selectedBond.id)})${selectedBond.token.symbol}-${selectedBond.pairToken.symbol}` : 'Choose an offer'}</p>
            </button>
          </Dropdown>
          <AddNewRewardsButton className='outline-primary fs-md text-primary hover:text-white fw-normal w-auto radius-3'
            onClick={handleOnClickAddReward}
          >Add New Rewards</AddNewRewardsButton>
        </div>
        <div className='h-min-40vh'>
          <AppDataTable
            requireWalletConnect={!active}
            onClickConnectWallet={connectWallet}
            emptyTableData={emptyTableData}
            columns={columns}
            data={tableData}
            customStyles={TableCustomStyles}
            pagination
            dense
            paginationComponent={CustomReactTablePagination}
          />
        </div>
      </div>
      <AddNewRewardPopup isOpen={showAddNewRewardsModal} dismiss={handleOnDismissRewardsModal} onSubmit={handleOnSubmitAddReward} />
      <SelectInsureOffer isOpen={showSelectOffer} dismiss={handleOnDismissSelectOffer} onSelect={handleOnSelectOffer} />
      <TransactionModals.Success isOpen={isTRMOpen} close={closeTRModal} hash={TRMHash} />

      {/* footer section */}
      <div className='mt-30 pt-10 pb-10 mb-10 relative z-index-10'>
        <SupportingAllDexs />
        <SupportingBlockchains
          onClickTitle={() => {
            console.log('onClickTitle')
          }}
        />
      </div>
    </Gs.Container>
  )
}

export default withConnectWalletModal(RewardsBondHoldersPage)
