import { toBN } from 'web3-utils'
import { isEqAddr } from './others'
import { BOND_ALTERNATIVE_TOKENS } from 'app-constants'
import { BondData, LPInsureData } from 'types'

export const getAlterNativeToken = (token: string) => {
  const keys = Object.keys(BOND_ALTERNATIVE_TOKENS)
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    if (key.toLowerCase() === token.toLowerCase()) {
      return BOND_ALTERNATIVE_TOKENS[key]
    }
  }
  return false
}

export const getTokenRatio = (tokenAddress: string, [token0, token1]: any[], [reserve0, reserve1]: any[]) => {
  let pairToken = ''
  if (isEqAddr(tokenAddress, token0.address)) {
    pairToken = token1.address
  } else if (isEqAddr(tokenAddress, token1.address)) {
    pairToken = token0.address
  } else {
    console.error('InValid tokens!')
    return 0
  }
  if (toBN(tokenAddress).gt(toBN(pairToken))) [reserve0, reserve1] = [reserve1, reserve0] // reserve0 of project token, reserve1 of pair token
  return reserve1 / reserve0
}

export const getRevertedTokenRatio = (token: string, [token0, token1]: any[], [reserve0, reserve1]: any[]) => {
  if (isEqAddr(token, token0.address)) {
    return reserve0 / reserve1
  } else if (isEqAddr(token, token1.address)) {
    return reserve1 / reserve0
  } else {
    console.error('InValid tokens')
    return 0
  }
}

/**
 *
 * @param {Number} startDate milisecs
 * @param {Number} cliffPeriod secs
 *
 */
export function isAllowedRedeemPrincipal(startDate: number, cliffPeriod: number) {
  const now = Date.now()
  cliffPeriod = cliffPeriod * 1e3 // change to milisecs
  const isAllowed = startDate + cliffPeriod < now
  return isAllowed
}

export function adjustBondParams(bond: BondData): BondData {
  const vestingParam = bond.paramerters.vestingParam
  bond.paramerters.vestingParam.prepaymentPenalty = vestingParam.prepaymentPenalty / 1e2

  bond.paramerters.startDate = bond.paramerters.startDate * 1e3
  bond.paramerters.endDate = bond.paramerters.endDate * 1e3
  bond.paramerters.feeParam.stakingAPY = bond.paramerters.feeParam.stakingAPY / 1e4

  bond.paramerters.minInvestment = bond.paramerters.minInvestment / Math.pow(10, +bond.paramerters.pairToken.decimals)
  bond.paramerters.maxInvestment = bond.paramerters.maxInvestment / Math.pow(10, +bond.paramerters.pairToken.decimals)
  bond.info = null
  return bond
}


export function adjustLPInsureParams(insureBond: LPInsureData): LPInsureData {
  insureBond.parameters.startDate = +insureBond.parameters.startDate * 1e3
  insureBond.parameters.endDate = +insureBond.parameters.endDate * 1e3

  insureBond.parameters.minInvestment = +insureBond.parameters.minInvestment / Math.pow(10, +insureBond.pairToken.decimals)
  insureBond.parameters.maxInvestment = +insureBond.parameters.maxInvestment / Math.pow(10, +insureBond.pairToken.decimals)

  insureBond.parameters.vestingParam.prepaymentPenalty = +insureBond.parameters.vestingParam.prepaymentPenalty / 1e2

  insureBond.info = undefined
  return insureBond
}
