import { configureStore, ThunkDispatch } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import { ActionBond } from './reducers/bond/types'
import { ActionBondList } from './reducers/bond-list/types'
import { ActionCoingecko } from './reducers/coingecko/types'
import { ActionGlobals } from './reducers/globals/types'
import { ActionTypeImportedPools } from './reducers/imported-pools/types'
import { ActionLiquidity } from './reducers/liquidity/types'
import { ActionSetting } from './reducers/settings/types'
import { ActionTax } from './reducers/tax/types'
import { ActionTokenList } from './reducers/tokenList/types'
import { VoteAction } from './reducers/votes/types'

export type AppAction
  = ActionBond | ActionBondList | ActionCoingecko | ActionGlobals | ActionTypeImportedPools | ActionLiquidity | ActionSetting | ActionTax
  | ActionTokenList | VoteAction

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunkDispatch = ThunkDispatch<RootState, null, AppAction>;
