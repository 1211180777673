import styled from 'styled-components'
import { InputGroupControl, InputGroupText } from 'Component/core/elements/InputGroup'

export const SUploadLogoInput = styled(InputGroupControl)`
  &::file-selector-button {
    display: none;
  }
  &::-webkit-file-upload-button {
    display: none;
  }
`
export const TokenText = styled(InputGroupText)`
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  text-transform: uppercase;
`

export const TokenErrorMessage = styled.div`
  position: absolute;
  cursor: context-menu;
  bottom: -20px;
  right: 0px;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
