/* eslint-disable */
// @ts-nocheck TODO need to add types
import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { useEffect } from 'react'
import Media from '../../Theme/media-breackpoint'
import addTokenAbi from '../../abi/custom_token_abi.json'
import defaultIcon from '../../Assets/images/defaultIcon.svg'
import loadedIcon from '../../Assets/images/loaded.svg'
import Bkbtn from '../../Assets/images/backBTN.png'
import Web3Utils from 'web3-utils'

import { fetchLink } from '../../helper'
import { useAppChain, useTokenListStorage, useWeb3 } from 'hooks'
import CustomScrollbars from 'Component/core/elements/CustomScrollbars'
import useAppSnackbar from 'hooks/useAppSnackbar'
import TokenIcon from 'Component/TokenIcon'
import { PPClosBTN01 } from 'Component/core/elements/Buttons'
import { FlexDiv, ImgBx, ModalBox } from 'Component/core/elements/others/global-components'
import { MTSearchBX } from 'Component/core/elements/form-elements/Input'
import { TokenListSelector } from 'redux/selectors'
import { useAppSelector } from 'redux/hooks'
import AppLoadingSpinner from 'Component/AppLoadingSpinner'
import { useCustomTokens } from 'hooks/useCustomTokens'
import AppTooltip from 'Component/AppTooltip'

export default function ManageToken({ ...props }) {
  const [openSnackbar] = useAppSnackbar()

  const boxRef = useRef(null)
  const [firstKey, setFirstKey] = useState(Math.random())
  const [secondKey, setSecondKey] = useState(Math.random())
  const [currTab, setCurrTab] = useState(2)
  const [contractValue, setContractValue] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [warning, setWarning] = useState('')
  const [showWarning, setShowWarning] = useState(false)
  const [consent, setConsent] = useState(false)
  const web3 = useWeb3()
  const { appChainId } = useAppChain()
  const { allTokens } = useCustomTokens(appChainId)

  useEffect(() => {
    if (!openModal) setContractValue('')
  }, [openModal])

  const addTokenHandler = async (contract, cb) => {
    try {
      const alreadyIndex = allTokens.findIndex((el) => el.address === contract)
      if (alreadyIndex !== -1) {
        return cb('This token already exists.')
      }
      const isValidAddress = await Web3Utils.isAddress(contract)
      if (!isValidAddress) {
        return cb('Invalid Token Address')
      }
      const contractInstance = new web3.eth.Contract(addTokenAbi, contract)
      const response = await contractInstance.methods.symbol().call()
      if (response) {
        cb('')
        const newToken = {
          address: contract,
          name: response,
          label: response,
          symbol: response,
          image: defaultIcon,
          decimals: 18,
          custom: true,
          addedByMe: true,
          chain: appChainId,
        }

        setOpenModal(false)
        return newToken
      }
    } catch (err) {
      console.log(err)
      const errMessage = 'Seems like your contract address is incorrect. or you are trying to import a token of different Network!!'
      openSnackbar(errMessage)
      return cb(errMessage)
    }
  }

  const onDismiss = () => {
    setCurrTab(1)
    setFirstKey(Math.random())
    setSecondKey(Math.random())
    props.dismiss()
  }

  const resetAndBack = () => {
    if (!warning && !showWarning) {
      props.partialDismiss()
    } else {
      setFirstKey(Math.random())
      setSecondKey(Math.random())
    }
  }

  return (
    <div>
      <PPMainBx ref={boxRef}>
        <PPsBx01 className='v2'>
          <PPstitle01 className='v2'>
            Manage
            <PPBackBTN onClick={resetAndBack} />
            <PPClosBTN01 className='right-0 top-0' onClick={onDismiss} />
          </PPstitle01>

          <MTtabMBX>
            <button
              onClick={() =>
                setTimeout(() => {
                  setCurrTab(2)
                }, 1)
              }
              className={`${currTab === 2 ? 'active' : ''}`}
            >
              Import Token
            </button>
            <button
              onClick={() =>
                setTimeout(() => {
                  setCurrTab(1)
                }, 1)
              }
              className={`${currTab === 1 ? 'active' : ''}`}
            >
              List
            </button>
          </MTtabMBX>
        </PPsBx01>

        {currTab === 1 ? (
          <Tab1 shouldReset={firstKey} showWarning={showWarning} setShowWarning={setShowWarning} />
        ) : (
          <Tab2
            consent={consent}
            warning={warning}
            setWarning={setWarning}
            shouldReset={secondKey}
            setConsent={setConsent}
            addTokenHandler={addTokenHandler}
            contractValue={contractValue}
            setContractValue={setContractValue}
          />
        )}
      </PPMainBx>
      <AppTooltip />
    </div>
  )
}

const Warning = ({ listing, resetLink }) => {
  const [agree, setAgree] = useState(false)
  const { appChainId } = useAppChain()
  const { addItem } = useTokenListStorage(appChainId)

  return (
    <>
      <MTtknMBX>
        <MTtknIcn>
          <TokenIcon src={listing?.data?.logoURI} />
        </MTtknIcn>
        {listing?.data?.name}
        <span>{listing?.data?.tokens?.length || 0} tokens</span>
      </MTtknMBX>
      <WarnBox>
        <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' fill='none' stroke='#FF4343' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
          <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
          <line x1='12' y1='9' x2='12' y2='13'></line>
          <line x1='12' y1='17' x2='12.01' y2='17'></line>
        </svg>
        <h2>Import at your own risk</h2>
        <div>
          By adding this list you are implicitly trusting that the data is correct. Anyone can create a list, including creating fake versions of existing lists and lists that claim to represent
          projects that do not have one.
        </div>
        <div>If you purchase a token from this list, you may not be able to sell it back.</div>
        <label style={{ margin: '20px 0' }}>
          <input style={{ marginRight: '5px' }} type='checkbox' checked={agree} onChange={() => setAgree((a) => !a)} />I understand
        </label>
        <OrangeButton
          type='button'
          style={{ width: '90%' }}
          onClick={() => {
            if (!agree) return
            setTimeout(() => {
              addItem(
                {
                  data: listing.url,
                  enabled: true,
                },
                appChainId
              )
              resetLink()
            }, 1)
          }}
        >
          Import
        </OrangeButton>
      </WarnBox>
    </>
  )
}

const TokenWarning = ({ token, onClickImport }) => {
  const [agree, setAgree] = useState(false)
  return (
    <>
      <MTtknMBX>
        {/* <MTtknIcn><img src={defaultIcon} alt="" /></MTtknIcn> */}
        {token?.name}
      </MTtknMBX>
      <WarnBox>
        <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' fill='none' stroke='#FF4343' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
          <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
          <line x1='12' y1='9' x2='12' y2='13'></line>
          <line x1='12' y1='17' x2='12.01' y2='17'></line>
        </svg>
        <h2>Import at your own risk</h2>
        <div>By adding this token you are implicitly trusting that the data is correct.</div>
        <div>If you purchase this token, you may not be able to sell it back.</div>
        <label style={{ margin: '20px 0' }}>
          <input style={{ marginRight: '5px' }} type='checkbox' checked={agree} onChange={() => setAgree((a) => !a)} />I understand
        </label>
        <OrangeButton
          type='button'
          style={{ width: '90%' }}
          disabled={!agree}
          onClick={onClickImport}
        >
          Import
        </OrangeButton>
      </WarnBox>
    </>
  )
}

const Tab2 = ({ contractValue, setContractValue, addTokenHandler, consent, setConsent, shouldReset, warning, setWarning }) => {
  const [addedToken, setAddedToken] = useState()
  const [addUrl, setAddUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { appChainId } = useAppChain()
  const { removeToken, clearAll, addToken, allTokens } = useCustomTokens(appChainId)
  
  const onClickImport = useCallback(() => {
    addToken(addedToken)
    reset()
  }, [appChainId, addedToken])

  const deleteToken = useCallback((address: string) => {
    removeToken(address, appChainId)
  }, [appChainId])

  useEffect(() => {
    const chain = appChainId === 1 ? 'ether' : 'bsc'
    setAddUrl(`https://${chain}scan.com/address/`)
  }, [appChainId])

  useEffect(() => {
    (async() => {
      if (contractValue) {
        try {
          setIsLoading(true)
          const res =  await addTokenHandler(contractValue, setWarning)
          setAddedToken(res)
        } catch (error) {
          
        } finally {
          setIsLoading(false)
        }
      }
      else setWarning('')
    })()
    // eslint-disable-next-line
  }, [contractValue])

  const reset = () => {
    setContractValue('')
    setConsent(false)
    setWarning('')
    setAddedToken(undefined)
  }
  // eslint-disable-next-line
  useEffect(reset, [shouldReset])

  return (
    <MTTabBX>
      {consent ? (
        <TokenWarning token={addedToken} onClickImport={onClickImport} />
      ) : (
        <>
          <MTSearchBX>
            <input type='text' placeholder='0x0000' value={contractValue} onChange={(e) => setContractValue(e.target.value)} />
          </MTSearchBX>
          {warning ? <Error>{warning}</Error> : <></>}
          <MTTtitle01>
            {allTokens?.length || 0} Custom token
            <div>
              <a onClick={clearAll} href='#A'>
                Clear all
              </a>
            </div>
          </MTTtitle01>
          <div className='w-full d-flex justify-content-center'>{isLoading && <AppLoadingSpinner />}</div>
          {addedToken ? (
            <MTtknMBX>
              <MTtknIcn>
                <TokenIcon src={defaultIcon} />
              </MTtknIcn>
              {addedToken.name}
              <MTtkncheBX>
                <OrangeButton
                  onClick={() =>
                    setTimeout(() => {
                      setConsent(true)
                    }, 1)
                  }
                >
                  Import
                </OrangeButton>
              </MTtkncheBX>
            </MTtknMBX>
          ) : (
            <></>
          )}
          <MTCoinMBX>
            {(allTokens || []).map((t, index) => (
              <PPListtoken02 key={index}>
                <ImgBx>
                  <TokenIcon byAddress={t.address} />
                </ImgBx>
                {t.symbol}
                <PPLLinkBX>
                  {/* eslint-disable-next-line */}
                  <a href='javascript:void(0);' onClick={() => deleteToken(t.address)}>
                    <i className='fas fa-trash-alt'></i>
                  </a>
                  <a target='blank' rel='noopener' href={addUrl + t.address} className='v2'>
                    <i className='fas fa-external-link-alt'></i>
                  </a>
                </PPLLinkBX>
              </PPListtoken02>
            ))}
          </MTCoinMBX>
          <PPsBx02 className='v3' style={{ marginTop: 'auto' }}>
            Tip: Custom tokens are stored locally in your browser
          </PPsBx02>
        </>
      )}
    </MTTabBX>
  )
}

const Tab1 = ({ shouldReset, showWarning, setShowWarning }) => {
  const [isWrong, setIsWrong] = useState(false)
  const [newListing, setNewListing] = useState(null)
  const [linkQuery, setLinkQuery] = useState('')
  const { items, itemData } = useAppSelector(TokenListSelector) // coin list
  const { appChainId } = useAppChain()
  const { setVisible, removeItem } = useTokenListStorage(appChainId)

  useEffect(() => {
    setLinkQuery('')
    setNewListing(null)
    setIsWrong(false)
    setShowWarning(false)
    // eslint-disable-next-line
  }, [shouldReset])

  useEffect(() => {
    if (linkQuery) fetchLink(linkQuery, setNewListing, setIsWrong)
    else {
      setNewListing(null)
      setIsWrong(false)
    }
  }, [linkQuery])

  const checkStatus = (url) => {
    const index = [...items].findIndex((el) => el.data.includes(url) || url.includes(el.data))
    return index !== -1
  }
  const [openSnackbar] = useAppSnackbar()

  return (
    <MTTabBX>
      {showWarning ? (
        <Warning
          resetLink={() => {
            setLinkQuery('')
            setNewListing(false)
            setShowWarning(false)
          }}
          listing={newListing}
        />
      ) : (
        <>
          <MTSearchBX>
            <input value={linkQuery} onChange={(e) => setLinkQuery(e.target.value)} type='text' placeholder='https:// or ipfs:// or ENS name' />
          </MTSearchBX>
          <CustomScrollbars height='420px' width='100%' style={{ position: 'relative' }}>
            {isWrong ? <Error>Seems like the url is broken</Error> : <></>}
            {newListing ? (
              <MTtknMBX style={{ marginBottom: '35px' }}>
                <MTtknIcn>
                  <TokenIcon src={newListing.data.logoURI} />
                </MTtknIcn>
                {newListing.data.name}
                <span>{newListing?.data?.tokens?.length || 0} tokens</span>
                <MTtkncheBX>
                  {checkStatus(newListing?.url) ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <img style={{ height: '15px', width: '15px', color: '#aaa' }} alt='Loaded' src={loadedIcon} />
                      <span style={{ marginLeft: '5px' }}>Loaded</span>
                    </div>
                  ) : (
                    <OrangeButton
                      onClick={() =>
                        setTimeout(() => {
                          setShowWarning(true)
                        }, 1)
                      }
                    >
                      Import
                    </OrangeButton>
                  )}
                </MTtkncheBX>
              </MTtknMBX>
            ) : (
              <></>
            )}
            {items.map((item, idx, arr) => (
              <MTtknMBX key={idx} className={item.enabled ? 'active' : ''}>
                <MTtknIcn>
                  <TokenIcon src={itemData[idx]?.logoURI || item.alterImage} />
                </MTtknIcn>
                {itemData[idx]?.name || item.alterName}
                <span>
                  {itemData[idx]?.tokens?.length || 0} tokens
                  <MTDDMbx>
                    <i className='fas fa-cog'></i>
                    <div className='MTDDinBX'>
                      V2.0.0
                      <a href={'https://tokenlists.org/token-list?url=' + item.tokenListUrl} target='blank' rel='noopener noreferrer'>
                        View list
                      </a>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTimeout(() => {
                            if (item.locked) return openSnackbar('Cannot delete this list, its added by default.')
                            const response = window.prompt('Please type delete to remove the token list.')
                            if (response === 'delete') {
                              removeItem(idx)
                              openSnackbar('Token list deleted successfully.')
                            } else openSnackbar('The token list name you entered is incorrect.')
                          }, 1)
                        }}
                      >
                        Remove list
                      </span>
                    </div>
                  </MTDDMbx>
                </span>
                <MTtkncheBX>
                  <div className='apollo-element apollo-element-active apollo-field-switcher'>
                    <div className='apollo-fieldset'>
                      <label>
                        <input
                          type='checkbox'
                          name='shortcode[active]'
                          value='1'
                          data-depend-id='active'
                          data-atts='active'
                          onChange={(e) => setVisible(idx, e.target.checked)}
                          checked={item.enabled}
                        />
                        <em data-on='on' data-off='off'></em>
                        <span></span>
                      </label>
                    </div>
                  </div>
                </MTtkncheBX>
              </MTtknMBX>
            ))}
          </CustomScrollbars>
        </>
      )}
    </MTTabBX>
  )
}

const MTtabMBX = styled(FlexDiv)`
  width: 100%;
  button {
    width: 50%;
    height: 56px;
    text-align: center;
    display: block;
    font-weight: 700;
    font-size: 16px;
    color: #8e9195;
    border-bottom: 3px solid #8e9195;
    :hover,
    &.active {
      border-color: #8be05a;
      color: #8be05a;
    }
  }
`
const MTTabBX = styled(FlexDiv)`
  width: 100%;
  min-height: 520px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`
const Error = styled(FlexDiv)`
  margin: 15px 15px 15px 25px;
  width: calc(100% - 55px);
  color: red;
`

const MTtknMBX = styled(FlexDiv)`
  margin: 15px 15px 15px 25px;
  width: calc(100% - 55px);
  border-radius: 10px;
  border: 1px solid #545861;
  min-height: 66px;
  justify-content: flex-start;
  padding: 12px 94px 12px 70px;
  position: relative;
  font-size: 16px;
  font-weight: 300;
  &.active {
    border-color: #8be05a;
  }
  span {
    width: 100%;
    display: block;
    font-size: 11px;
  }
  ${Media.xs} {
    margin: 15px;
    width: calc(100% - 30px);
  }
`
const MTtknIcn = styled(FlexDiv)`
  width: 36px;
  height: 36px;
  position: absolute;
  left: 14px;
  top: 14px;
  img {
    max-width: 100%;
    height: auto;
  }
`
const MTDDMbx = styled(FlexDiv)`
  width: 14px;
  height: 14px;
  position: relative;
  color: white;
  display: inline-flex;
  margin-left: 5px;
  margin-top: 2px;
  transition: all 0.5s;
  .MTDDinBX {
    position: absolute;
    left: 5px;
    top: 5px;
    line-height: 20px;
    width: 120px;
    min-height: 92px;
    background-color: #fff;
    border-radius: 10px;
    z-index: 100;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 400;
    justify-content: flex-start;
    color: #000000;
    font-size: 12px;
    -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
    a,
    span {
      color: #8be05a;
      cursor: pointer;
      font-weight: 700 !important;
      :hover {
        text-decoration: underline;
      }
    }
  }
  :hover {
    color: #8be05a;
    .MTDDinBX {
      opacity: 1;
      visibility: visible;
    }
  }
`

const MTTtitle01 = styled(FlexDiv)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: white;
  font-weight: 600;
  padding: 14px 25px;
  a,
  span {
    font-size: 12px;
    cursor: pointer;
    color: #8be05a;
    :hover {
      text-decoration: underline;
    }
  }
`

const MTCoinMBX = styled(FlexDiv)`
  width: 100%;
  padding: 14px 25px;
`
const PPListtoken02 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: #fff;
  padding: 15px 0px;
  & i {
    margin-left: auto;
  }
`

const PPLLinkBX = styled(FlexDiv)`
  margin-left: auto;

  a {
    color: #fff;
    font-size: 14px;
    display: inline-block;
    margin-left: 15px;
    :hover {
      opacity: 0.5;
    }
    &.v2 {
      color: #8be05a;
    }
  }
`

const MTtkncheBX = styled(FlexDiv)`
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  width: 75px;

  .apollo-field-switcher label {
    display: block;
    float: left;
    cursor: pointer;
    position: relative;
    height: 42px;
    width: 75px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  .apollo-field-switcher label input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  input[type='radio'],
  input[type='checkbox'] {
    border: 1px solid #b4b9be;
    background: #fff;
    color: #555;
    clear: none;
    cursor: pointer;
    display: inline-block;
    line-height: 0;
    height: 16px;
    margin: -4px 4px 0 0;
    outline: 0;
    padding: 0 !important;
    text-align: center;
    vertical-align: middle;
    width: 16px;
    min-width: 16px;
    -webkit-appearance: none;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-transition: 0.05s border-color ease-in-out;
    transition: 0.05s border-color ease-in-out;
  }
  .ui-widget input,
  .ui-widget select,
  .ui-widget textarea,
  .ui-widget button {
    font-family: Verdana, Arial, sans-serif;
    font-size: 1em;
  }
  .apollo-field-switcher input[type='radio'],
  .apollo-field-switcher input[type='checkbox'] {
    border: 1px solid #b4b9be;
    background: #fff;
    color: #555;
    clear: none;
    cursor: pointer;
    display: inline-block;
    line-height: 0;
    height: 16px;
    margin: -4px 4px 0 0;
    outline: 0;
    padding: 0 !important;
    text-align: center;
    vertical-align: middle;
    width: 16px;
    min-width: 16px;
    -webkit-appearance: none;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-transition: 0.05s border-color ease-in-out;
    transition: 0.05s border-color ease-in-out;
  }
  .apollo-field-switcher label em {
    position: relative;
    display: block;
    height: 42px;
    width: 75px;
    border-radius: 10px;
    font-size: 14px;
    line-height: 40px;
    font-weight: 500;
    font-style: normal;
    color: #8e9195;
    border: 2px solid #545861;
    -moz-transition: background 0.15s ease-out;
    -o-transition: background 0.15s ease-out;
    -webkit-transition: background 0.15s ease-out;
    transition: background 0.15s ease-out;
  }
  .apollo-field-switcher label em:before {
    content: attr(data-off);
    right: 14px;
  }
  .apollo-field-switcher label em:before,
  .apollo-field-switcher label em:after {
    position: absolute;
    -moz-transition: opacity 0.15s ease-out;
    -o-transition: opacity 0.15s ease-out;
    -webkit-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
  }
  .apollo-field-switcher label em:after {
    content: attr(data-on);
    left: 14px;
    opacity: 0;
  }
  .apollo-field-switcher label em:before,
  .apollo-field-switcher label em:after {
    position: absolute;
    -moz-transition: opacity 0.15s ease-out;
    -o-transition: opacity 0.15s ease-out;
    -webkit-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
  }
  .apollo-field-switcher label span {
    position: absolute;
    top: 13px;
    left: 10px;
    width: 18px;
    height: 18px;
    background-color: #545861;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -moz-transition: left 0.15s ease-out;
    -o-transition: left 0.15s ease-out;
    -webkit-transition: left 0.15s ease-out;
    transition: left 0.15s ease-out;
  }
  .apollo-field-switcher label input:checked ~ em:before {
    opacity: 0;
  }
  .apollo-field-switcher label em:before {
    content: attr(data-off);
    right: 14px;
  }
  .apollo-field-switcher label em:before,
  .apollo-field-switcher label em:after {
    position: absolute;
    z-index: 100;
    -moz-transition: opacity 0.15s ease-out;
    -o-transition: opacity 0.15s ease-out;
    -webkit-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
  }
  .apollo-field-switcher label input:checked ~ em:after {
    opacity: 1;
    color: #fff;
  }
  .apollo-field-switcher label em:after {
    content: attr(data-on);
    left: 14px;
  }
  .apollo-field-switcher label input:checked ~ em {
    border-color: #8be05a;
  }
  .apollo-field-switcher label input:checked ~ span {
    background: #8be05a;
    left: 44px;
  }
  .apollo-field-switcher label em:before,
  .apollo-field-switcher label em:after {
    position: absolute;
    -moz-transition: opacity 0.15s ease-out;
    -o-transition: opacity 0.15s ease-out;
    -webkit-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
  }
  .apollo-field-switcher input[type='checkbox']:checked:before {
    content: '\f147';
    margin: -3px 0 0 -4px;
    color: #1e8cbe;
  }
  .apollo-field-switcher input[type='radio']:checked:before,
  .apollo-field-switcher input[type='checkbox']:checked:before {
    float: left;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    font: 400 21px/1 dashicons;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`

const PPMainBx = styled(ModalBox)`
  max-width: 431px;
  min-width: 431px;
  margin: 0 auto;
  ${Media.lg} {
    min-width: 431px;
  }
  ${Media.md} {
    min-width: auto;
  }
  ${Media.xs} {
    margin: 0px 15px;
    max-width: 100%;
    width: auto;
  }
`

const PPBackBTN = styled(PPClosBTN01)`
  right: auto;
  left: 0px;
  background: url(${Bkbtn}) left top no-repeat;
  :hover {
    opacity: 0.5;
    transform: none;
  }
`

const PPsBx01 = styled(FlexDiv)`
  width: 100%;
  padding: 30px 30px 10px 30px;
  border-bottom: #3c3f46 1px solid;

  &.v2 {
    border-bottom: 0px;
  }
`

const PPsBx02 = styled(FlexDiv)`
  width: 100%;
  padding: 20px 30px 20px 30px;
  border-top: #3c3f46 1px solid;

  &.v2 {
    font-size: 12px;
    border-top: 0px;
    button {
      color: #8be05a;
    }
  }
  &.v3 {
    font-size: 12px;
    font-weight: 300;
    padding: 20px 15px 20px 15px;
  }

  & button {
    border: 0px;
    outline: none;
    background-color: transparent;
    color: #fff;
    :hover {
      opacity: 0.7;
    }
  }
`

// const PPselectTokenBTN = styled(FlexDiv)`
//   justify-content: flex-start;
//   width: 100%;
//   padding: 8px 0;

//   button {
//     display: inline-flex;
//     color: #fff;
//     border-radius: 10px;
//     border: 1px solid #545861;
//     align-items: center;
//     justify-content: flex-start;
//     padding: 4px 10px 4px 6px;
//     margin: 0 8px 8px 0;

//     :hover {
//       background-color: #545861;
//     }

//     &.active {
//       background-color: #1f2127;
//       border-color: #1f2127;
//     }
//   }
// `

const PPstitle01 = styled(FlexDiv)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0 12px 0;
  position: relative;

  &.v2 {
    justify-content: center;
    font-size: 18px;
  }
`
// const CWBTNBX = styled(FlexDiv)`
//   width: 100%;
//   padding: 10px 30px 0px 30px;

//   button {
//     width: 100%;
//     height: 62px;
//     border: 1px solid #545861;
//     border-radius: 10px;
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     font-size: 14px;
//     padding: 10px 14px;
//     color: #ffffff;
//     position: relative;
//     margin: 0 0 15px 0;

//     i {
//       width: 32px;
//       height: 32px;
//       position: absolute;
//       right: 11px;
//       top: 13px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }

//     :hover {
//       background-color: #32353b;
//     }
//   }
// `
const OrangeButton = styled.button`
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #8be05a;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 6px;
`

const WarnBox = styled(FlexDiv)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 28px;

  div {
    color: #ffffffaa;
  }
`
