import { FEE_STRUCTURE, defaultDex, VESTING_PERIOD, VESTING_FOR_PRINCIPAL, REDEMPTION_LEFTOVER, BOND_TYPE, VESTING_FOR_PROFIT, TimeOptions } from 'app-constants'
import { BondReducerState, BondTabs, InvestmentLimitParams, VestingForPrincipal, VestingForPrincipalParams, VestingForProfit, VestingForProfitParams } from 'types'
import { ActionBond, ActionTypes } from './types'

export const initialState: BondReducerState = {
  tab: BondTabs.ProjectInformation,
  // tab states
  // - tab 1
  // step 1
  token: '', // address  //  project token address
  withNewPool: true,
  website: '',
  whitepaper: '',
  logo: {
    preview: '',
    file: null,
  },
  dex: defaultDex,
  // pair: defaultSelectedPairToken,
  startIBODate: new Date().getTime(),
  endIBODate: new Date().getTime(),
  // - tab 2
  bondType: BOND_TYPE.DISCOUNT,
  vestingForPrincipal: {
    type: VESTING_FOR_PRINCIPAL.CLIFF,
    afterOption: TimeOptions[1],
    afterValue: '0', // number in string
    portionValue: '0', // number in string
    everyOption: TimeOptions[1],
    everyValue: '0', // number in string
  },
  vestingForProfit: {
    type: VESTING_FOR_PROFIT.CLIFF,
    afterOption: TimeOptions[1],
    afterValue: '0', // number in string
    portionValue: '0', // number in string
    everyOption: TimeOptions[1],
    everyValue: '0', // number in string
    isStartProfit: true, // start profits redemption after principal
  },
  vestingPeriod: {
    type: VESTING_PERIOD.CLIFF,
    afterValue: '0', // number in string
    afterOption: TimeOptions[1],
    portionValue: '0', // number in string
    everyOption: TimeOptions[1],
    everyValue: '0', // number in string
  },
  prepaymentPenalty: false,
  dynamicPenalty: '0', // number in string
  leftoverBurn: REDEMPTION_LEFTOVER.REFUND,
  // tab 3
  amountOfSupply: '0', // number in string
  raisingTarget: '0', // number in string
  investmentLimit: {
    isSet: false,
    minimum: '0', // number in string
    maximum: '0', // number in string
  },
  feeStructure: FEE_STRUCTURE.UP_WITH_FREE_FEE,
  shieldPeriod: Date.now(), // timestamp in number
  dexWithPool: defaultDex,
  // staking period for users
  stakingPeriod: {
    timeOption: TimeOptions[1],
    value: '0', // number in string
  },
  apyForUsers: '0', // number in string
  isDumperShieldForUsers: false,

  // other states
  isAddingProjectInfo: false,
  pairToken: {
    name: '',
    symbol: '',
    address: '',
    decimals: '',
    balance: '',
  },
  pairTokenPriceUSD: '',
  tokenInfo: {
    name: '',
    symbol: '',
    address: '',
    decimals: '',
    balance: '',
  },
  tokenRatio: 0,
  hasPrincipalVesting: false,
  hasProfitVesting: false,
}

export default function Reducer(state = initialState, action: ActionBond): BondReducerState {
  let newValues: InvestmentLimitParams,
    newVestingPrincipal: VestingForPrincipal,
    newVestingPrincipalParams: VestingForPrincipalParams,
    newVestingProfit: VestingForProfit,
    newVestingProfitParams: VestingForProfitParams,
    hasVesting: boolean;

  switch (action.type) {
    case ActionTypes.SET_PARAMETERS:
      return { ...state, ...action.payload }

    case ActionTypes.SET_TOKEN:
      return { ...state, token: action.payload }

    case ActionTypes.SET_PAIR_TOKEN:
      return { ...state, pairToken: { ...state.pairToken, ...action.payload } }

    case ActionTypes.SET_PAIRTOKEN_PRICE_USD:
      return { ...state, pairTokenPriceUSD: action.payload }

    case ActionTypes.SET_PREPAYMENT_PENALTY:
      return { ...state, prepaymentPenalty: action.payload, dynamicPenalty: '0' }

    case ActionTypes.SET_TOKEN_INFO:
      return { ...state, tokenInfo: action.payload }

    case ActionTypes.SET_TOKEN_RATIO:
      return { ...state, tokenRatio: action.payload }

    case ActionTypes.SET_VESTING_FOR_PRINCIPAL:
      newVestingPrincipalParams = action.payload
      if (typeof newVestingPrincipalParams.type !== 'undefined' && newVestingPrincipalParams.type === VESTING_FOR_PRINCIPAL.CLIFF) {
        newVestingPrincipalParams.everyValue = '0'
        newVestingPrincipalParams.portionValue = '0'
      } else if (typeof newVestingPrincipalParams.type !== 'undefined' && newVestingPrincipalParams.type === VESTING_FOR_PRINCIPAL.GRADED) {
        newVestingPrincipalParams.afterValue = '0'
      }
      newVestingPrincipal = { ...state.vestingForPrincipal, ...newVestingPrincipalParams }

      return { ...state, vestingForPrincipal: newVestingPrincipal }

    case ActionTypes.SET_VESTING_FOR_PROFIT:
      newVestingProfitParams = action.payload
      if (typeof newVestingProfitParams.type !== 'undefined' && newVestingProfitParams.type === VESTING_FOR_PRINCIPAL.CLIFF) {
        newVestingProfitParams.everyValue = '0'
        newVestingProfitParams.portionValue = '0'
      } else if (typeof newVestingProfitParams.type !== 'undefined' && newVestingProfitParams.type === VESTING_FOR_PRINCIPAL.GRADED) {
        newVestingProfitParams.afterValue = '0'
      }
      newVestingProfit = { ...state.vestingForProfit, ...newVestingProfitParams };

      return { ...state, vestingForProfit: newVestingProfit }

    case ActionTypes.SET_VESTING_PERIOD:
      return { ...state, vestingPeriod: { ...state.vestingPeriod, ...action.payload } }

    case ActionTypes.SET_INVESTMENT_LIMIT:
      newValues = action.payload
      if (typeof newValues.isSet !== 'undefined' && newValues.isSet === false) {
        newValues.maximum = '0';
        newValues.minimum = '0';
      }
      return { ...state, investmentLimit: { ...state.investmentLimit, ...newValues } }
    
    case ActionTypes.SET_STAKING_PERIOD:
      return { ...state, stakingPeriod: { ...state.stakingPeriod, ...action.payload } }

    case ActionTypes.SET_TAB:
      return { ...state, tab: action.payload }

    case ActionTypes.SET_FEE_STRUCTURE:
      return { ...state, feeStructure: action.payload }

    case ActionTypes.IS_ADDING_PROJECT_INFO:
      return { ...state, isAddingProjectInfo: action.payload }

    case ActionTypes.SET_HAS_PRINCIPAL_VESTING:
      hasVesting = action.payload;
      if(!hasVesting) {
        newVestingPrincipal = state.vestingForPrincipal
        newVestingPrincipal.afterValue    = '0'
        newVestingPrincipal.everyValue    = '0'
        newVestingPrincipal.portionValue  = '0';

        return { ...state, hasPrincipalVesting: action.payload, vestingForPrincipal: newVestingPrincipal }
      }
      return { ...state, hasPrincipalVesting: action.payload }

    case ActionTypes.SET_HAS_PROFIT_VESTING:
      hasVesting = action.payload;
      if(!hasVesting) {
        newVestingProfit = state.vestingForProfit;
        newVestingProfit.afterValue   = '0'
        newVestingProfit.everyValue   = '0'
        newVestingProfit.portionValue = '0';

        return { ...state, hasProfitVesting: action.payload, vestingForProfit: newVestingProfit }
      }
      return { ...state, hasProfitVesting: action.payload }

    case ActionTypes.RESET:
      return initialState

    default:
      return state
  }
}
