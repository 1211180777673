import dexIcon01 from 'Assets/images/dexIcon-01.png'
import dexIcon02 from 'Assets/images/dexIcon-02.png'
import dexIcon03 from 'Assets/images/dexIcon-03.png'
import dexIcon04 from 'Assets/images/dexIcon-04.png'
import dexIcon05 from 'Assets/images/dexIcon-05.png'
import dexIcon06 from 'Assets/images/dexIcon-06.png'
import dexIcon07 from 'Assets/images/dexIcon-07.png'
import dexIcon08 from 'Assets/images/dexIcon-08.png'
import dexIcon09 from 'Assets/images/dexIcon-09.png'
import dexIcon010 from 'Assets/images/dexIcon-010.png'
import dexIcon011 from 'Assets/images/dexIcon-011.png'
import dexIcon012 from 'Assets/images/dexIcon-012.png'
import dexIcon013 from 'Assets/images/dexIcon-013.png'
import dexIcon014 from 'Assets/images/dexIcon-014.png'
import dexIcon015 from 'Assets/images/dexIcon-015.png'
import dexIcon016 from 'Assets/images/dexIcon-016.png'
import dexIcon017 from 'Assets/images/dexIcon-017.png'
import dexIcon018 from 'Assets/images/dexIcon-018.png'
import dexIcon019 from 'Assets/images/dexIcon-019.png'
import AppLink from 'Component/AppLink'
import { HeadCenterbox, SSIconMBX01 } from './styled'
import HorizontalScroll from 'Component/core/elements/HorizontalScroll'

function SupportingAllDexs({ ...props }) {
  return (
    <SSIconMBX01 {...props}>
      <HeadCenterbox>
        <span className='title'>Supporting all DEXs:</span>
        <div className='support-list'>
          <HorizontalScroll>
            <a href='/reimbursement'>
              <img src={dexIcon01} alt='' />
            </a>
            <AppLink to='#'>
              <img src={dexIcon02} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon03} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon04} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon05} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon06} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon07} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon08} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon09} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon010} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon011} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon012} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon013} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon014} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon015} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon016} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon017} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon018} alt='' />
            </AppLink>
            <AppLink to='#'>
              <img src={dexIcon019} alt='' />
            </AppLink>
          </HorizontalScroll>
        </div>
      </HeadCenterbox>
    </SSIconMBX01>
  )
}

export default SupportingAllDexs
