import { ZERO_ADDRESS } from 'app-constants'
import { isEqAddr } from 'helper'
import { getTokenBalancesWithMorails } from 'helper/tokens-with-balance'
import { RootState } from 'redux/store'
import { AppThunkAction, BaseToken, SupportedNetworks, ThemeConfigType, TokenBalance } from 'types'
import { ActionTypes, ActionGlobals } from './types'

type GlobalsThunkAction<R> = AppThunkAction<R, RootState, null, ActionGlobals>

export const toggleConnectWalletModal = (_value?: boolean): GlobalsThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.toggleConnectWalletModal,
    payload: _value,
  })
}

export const setAvailableTokens = (_value: Array<BaseToken>): GlobalsThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.setAvailableTokens,
    payload: _value,
  })
}

export const setStateFaildT_Modal =
  (isOpen: boolean, message: string, header = ''): GlobalsThunkAction<void> =>
    (dispatch) => {
      dispatch({
        type: ActionTypes.setOpenTransationFailedModal,
        payload: {
          isOpen: isOpen,
          message: message,
          header: header,
        },
      })
    }

type Props = {
  isOpen: boolean
  hash: string
}

export const setTransactionModal = ({ isOpen, hash }: Props): GlobalsThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.setTransactionModal,
    payload: {
      isOpen,
      hash
    },
  })
}
type Props1 = {
  isOpen: boolean
  message: string
}
export const setAppErrorModal = (_value: Props1): GlobalsThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.setAppErrorModal,
    payload: _value,
  })
}

export const setAccount = (_value: string): GlobalsThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_ACCOUNT,
    payload: _value,
  })
}

export const setAppChain = (_chain: SupportedNetworks): GlobalsThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CHAIN,
    payload: _chain,
  })
}

export const setThemeConfig = (_chain: ThemeConfigType): GlobalsThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_APP_THEME,
    payload: _chain,
  })
}

export const loadUserTokensWithBalance
  = (appChainId: SupportedNetworks, tokens: BaseToken[], WToken: string, account: string): GlobalsThunkAction<Promise<void>> => async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.SET_USER_TOKENS_WITH_BALANCE,
        payload: {
          isLoading: true,
          errorMessage: '',
          tokens: []
        },
      })
      const balances = await getTokenBalancesWithMorails(account, appChainId);
      const mainToken = tokens.find((t) => {
        return isEqAddr(t.address, WToken) || isEqAddr(t.address, ZERO_ADDRESS)
      }) as BaseToken
      const mainT: TokenBalance = {
        ...mainToken,
        balance: 0
      };
      const tts = [mainT].concat(balances);

      dispatch({
        type: ActionTypes.SET_USER_TOKENS_WITH_BALANCE,
        payload: {
          isLoading: false,
          errorMessage: '',
          tokens: tts
        },
      })
      // @ts-ignore
    } catch (error: Error) {
      console.log('Error: ', error)
      dispatch({
        type: ActionTypes.SET_USER_TOKENS_WITH_BALANCE,
        payload: {
          isLoading: false,
          errorMessage: error.message || error,
          tokens: []
        },
      })
    }
  }

export const setIsOpenTransactionModal =
  (_isOpen: boolean, message = 'Please wait transaction is processing...'): GlobalsThunkAction<void> => (dispatch) => {
    dispatch({
      type: ActionTypes.SET_IS_OPEN_TRANSACTION_MODAL,
      payload: {
        isOpen: _isOpen,
        message: message,
      },
    })
  }
