import { TimeOptions, VESTING_FOR_PRINCIPAL, VESTING_PERIOD } from 'app-constants'
import tooltip from 'app-constants/tooltip-texts'
import { AppButton, BackButton } from 'Component/core/elements/Buttons'
import { Divider } from 'Component/core/elements/Divider'
import { SForms } from 'Component/core/elements/IBO-SharedComponents'
import { InputGroup, InputGroupText } from 'Component/core/elements/InputGroup'
import PeriodPicker from 'Component/core/elements/PeriodPicker'
import { RadioButton } from 'Component/core/elements/RadioButton'
import React from 'react'
import { BsQuestionCircleFill } from 'react-icons/bs'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { prevStep, setParamsVestingPeriod, setPrepaymentPenalty, setParams, nextStep } from 'redux/reducers/lp-insure/actions'
import { LPInsureSelector } from 'redux/selectors'
import { TimeOption } from 'types'

function LPInsureBondTerms() {
  const dispatch = useAppDispatch()
  const lpInsure = useAppSelector(LPInsureSelector)

  const handleChangeVestingPeriod = (_name: string, _value: any) => {
    dispatch(
      setParamsVestingPeriod({
        [_name]: _value,
      })
    )
  }
  const handleChangeParams = (_name: string, _value: any) => {
    dispatch(
      setParams({
        [_name]: _value,
      })
    )
  }
  const handlePrepaymentPenalty = (enabled: boolean) => {
    dispatch(setPrepaymentPenalty(enabled))
  }
  const handleOnClickback = () => {
    dispatch(prevStep())
  }
  const handleOnClickNextStep = () => {
    dispatch(nextStep())
  }

  return (
    <React.Fragment>
      <div className='py-20'>
        <BackButton onClick={handleOnClickback} />
      </div>
      <SForms>
        <tbody>
          <tr>
            <td>
              <label className='fw-bold'>Vesting period</label>
            </td>
            <td>
              <RadioButton
                id='idAdd-No'
                name='isAdd'
                className='mr-5 d-flex align-items-center'
                label={
                  <label htmlFor='idAdd-No' className='ml-10 pt-5'>
                    Cliff
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                  </label>
                }
                checked={lpInsure.vestingPeriod.type === VESTING_PERIOD.CLIFF}
                value={VESTING_PERIOD.CLIFF}
                onChange={(e: any) => handleChangeVestingPeriod('type', e.target.value)}
              />
            </td>
            <td>
              <RadioButton
                id='idAdd-Yes'
                name='isAdd'
                className='d-flex align-items-center'
                label={
                  <label htmlFor='idAdd-Yes' className='ml-10 pt-5 text-capitalize'>
                    Graded
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                  </label>
                }
                checked={lpInsure.vestingPeriod.type === VESTING_PERIOD.GRADED}
                value={VESTING_PERIOD.GRADED}
                onChange={(e: any) => handleChangeVestingPeriod('type', e.target.value)}
              />
            </td>
            {lpInsure.vestingPeriod.type === VESTING_FOR_PRINCIPAL.CLIFF ? (
              <>
                <td>
                  <label className='fw-bold'>
                    After
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                  </label>
                </td>
                <td>
                  <InputGroup>
                    <NumberFormat
                      displayType='input'
                      value={lpInsure.vestingPeriod.afterValue}
                      onValueChange={({ value }: NumberFormatValues) => handleChangeVestingPeriod('afterValue', value)}
                      isAllowed={({ value }: NumberFormatValues) => {
                        return +value > -1
                      }}
                    />
                    <InputGroupText className='py-7'>
                      <PeriodPicker label={lpInsure.vestingPeriod.afterOption.label} options={TimeOptions} onClickOption={(e: any, option: TimeOption) => handleChangeVestingPeriod('afterOption', option)} />
                    </InputGroupText>
                  </InputGroup>
                </td>
              </>
            ) : (
              <>
                <td>
                  <label className='fw-bold'>
                    Portion
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                  </label>
                </td>
                <td>
                  <InputGroup>
                    <NumberFormat
                      displayType='input'
                      value={lpInsure.vestingPeriod.portionValue}
                      onValueChange={({ value }) => handleChangeVestingPeriod('portionValue', value)}
                      isAllowed={({ value }: NumberFormatValues) => {
                        return +value > -1 && +value < 100
                      }}
                    />
                    <InputGroupText>%</InputGroupText>
                  </InputGroup>
                </td>
              </>
            )}
          </tr>
          <tr hidden={lpInsure.vestingPeriod.type === VESTING_FOR_PRINCIPAL.CLIFF}>
            <td colSpan={3}></td>
            <td>
              <label className='fw-bold'>
                Every
                <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
              </label>
            </td>
            <td>
              <InputGroup>
                <NumberFormat
                  displayType='input'
                  value={lpInsure.vestingPeriod.everyValue}
                  onValueChange={({ value }: NumberFormatValues) => handleChangeVestingPeriod('everyValue', value)}
                  isAllowed={({ value }: NumberFormatValues) => {
                    return +value > -1
                  }}
                />
                <InputGroupText className='py-6'>
                  <PeriodPicker
                    label={lpInsure.vestingPeriod.everyOption.label}
                    options={TimeOptions}
                    onClickOption={(e, option) => handleChangeVestingPeriod('everyOption', option)}
                  />
                </InputGroupText>
              </InputGroup>
            </td>
          </tr>
          <tr>
            <td>
              <label className='fw-bold'>
                Prepayment penalty
                <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('PREPAYMENT_PENALTY')} />
              </label>
            </td>
            <td>
              <RadioButton
                id='Prepayment-Penalty-Yes'
                name='Prepayment-Penalty'
                className='mr-5 d-flex align-items-center'
                label={
                  <label htmlFor='Prepayment-Penalty-Yes' className='ml-10 pt-5'>
                    Yes
                  </label>
                }
                checked={lpInsure.prepaymentPenalty === true}
                onChange={() => handlePrepaymentPenalty(true)}
              />
            </td>
            <td>
              <RadioButton
                id='Prepayment-Penalty-No'
                name='Prepayment-Penalty'
                className='d-flex align-items-center'
                label={
                  <label htmlFor='Prepayment-Penalty-No' className='ml-10 pt-5 text-capitalize'>
                    No
                  </label>
                }
                checked={lpInsure.prepaymentPenalty === false}
                onChange={() => handlePrepaymentPenalty(false)}
              />
            </td>
            <td className={`${!lpInsure.prepaymentPenalty ? 'invisible' : ''}`}>
              <label className='fw-bold'>
                Dynamic penalty
                <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Dynamic_Penalty')} />
              </label>
            </td>
            <td className={`${!lpInsure.prepaymentPenalty ? 'invisible' : ''}`}>
              <InputGroup>
                <NumberFormat
                  displayType='input'
                  value={lpInsure.dynamicPenalty}
                  onValueChange={({ value }: NumberFormatValues) => handleChangeParams('dynamicPenalty', value)}
                  isAllowed={({ value }: NumberFormatValues) => {
                    return +value > -1 && +value < 100
                  }}
                />
                <InputGroupText>%</InputGroupText>
              </InputGroup>
            </td>
          </tr>
        </tbody>
      </SForms>
      <Divider className='vertical mt-20 mb-10 highlighted-border-bottom' />
      <div className='py-50'>
        <div className='d-flex justify-content-center position-relative'>
          <BackButton className='position-absolute left-0 top-22' onClick={handleOnClickback} />
          <AppButton className='text-capitalize outline-primary w-max-500' onClick={handleOnClickNextStep}>
            next step
          </AppButton>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LPInsureBondTerms
