import React from 'react'
import styled from 'styled-components'

const SText = styled.h1`
`

function ComingSoon() {
  return (
    <div className='d-flex align-items-center justify-content-center h-80vh'>
      <SText>Coming Soon</SText>
    </div>
  )
}

export default ComingSoon
