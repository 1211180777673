/* eslint-disable */
// @ts-nocheck TODO need to add types
import React, { useCallback, useMemo, useState } from 'react'
import DataTable, { TableStyles } from 'react-data-table-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import Select, { components } from 'react-select'
import NumberFormat from 'react-number-format'
import reimbursementStakingContract from 'helper/reimbursementStakingContract'
import StakingContract from 'helper/stakingContract'
import constantConfig from 'config/constantConfig'
import notificationConfig from 'config/notificationConfig'
import { usdFormater, numberFormater } from 'helper/index'
import CustomReactTablePagination from 'Component/core/elements/CustomReactDataTableElements'
import Web3Utils from 'web3-utils'
import { useAppChain, useWeb3 } from 'hooks'
import Web3Config from 'config/web3Config'
import useWallet from 'hooks/useWallet'
import { withConnectWalletModal } from 'redux/actions'
import { simpleAmountInString } from 'helper/math'
import 'Assets/app2/style.css'
import Modal from 'Component/Modal'

const CaretDownIcon = () => {
  return <FontAwesomeIcon icon={faCaretDown} />
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  )
}

const claimPopupStyles = {
  option: (base, state) => ({
    ...base,
    backgroundColor: '#2c2f36',
    borderColor: 'transparent',
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    borderColor: 'transparent',
  }),
  control: (base) => ({
    ...base,
    background: '0 0',
    color: 'white',
    border: '2px solid #545861 !important',
    borderRadius: '10px',
    padding: '8px 0',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: '2rem',
  }),
}

const tokenLists = [
  {
    value: 'jntrb',
    label: <p className='staking-token-name text-white'>JNTR/b</p>,
  },
]

// const data = [
//   {
//     id: 0,
//     token: "SMART",
//     fee: "$1695.95",
//     total: "1695.95",
//     reimbursement: "$1695.95",
//     ratio: "1:1",
//     period: "365",
//     staking: "claim",
//     balance: "365",
//     withdrawal: "withdraw",
//   },
//   {
//     id: 1,
//     token: "DEGEN",
//     fee: "$0.990654",
//     total: "0.990654",
//     reimbursement: "$0.990654",
//     ratio: "1:1",
//     period: "365",
//     staking: "claim",
//     balance: "365",
//     withdrawal: "withdraw",
//   },
//   {
//     id: 2,
//     token: "PDO",
//     fee: "$252.46",
//     total: "252.46",
//     reimbursement: "$252.46",
//     ratio: "1:1",
//     period: "365",
//     staking: "claim",
//     balance: "365",
//     withdrawal: "withdraw",
//   },
// ];

const customStyles: TableStyles = {
  table: {
    style: {
      backgroundColor: 'transparent',
    }
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      color: '#8e9196',
      background: 'transparent',
      borderTopWidth: '2px',
      borderBottomWidth: '2px',
      borderColor: '#8e9196',
      height: '50px',
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        color: '#8e9196',
        borderRightWidth: '1px',
        borderColor: '#8e9196',
      },
    },
  },
  cells: {
    style: {
      borderRightWidth: '1px',
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        color: '#8e9196',
        borderRightWidth: '1px',
        borderColor: '#8e9196',
      },
    },
  },
  rows: {
    style: {
      borderRightWidth: '0px',
      height: '50px',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      backgroundColor: 'transparent',
      borderColor: '#8e9196',
      borderBottomColor: '#8e9196 !important',
      color: '#8e9196',
    },
  }
}

function Reimbursement({ balance, balances, unstakingAmounts, getSetting, stakingTableDatas, connectWallet }) {
  const web3 = useWeb3()
  const { library, account, active } = useWallet()
  const { appChainId: chainId } = useAppChain()

  const [openWithdraw, setOpenWithdraw] = useState(false)
  const [openClaim, setOpenClaim] = useState(false)

  const [stakingToken, setstakingToken] = useState('jntrb')
  const [unStakingToken, setunStakingToken] = useState('jntrb')
  const [stakingAmount, setstakingAmount] = useState(null)
  const [stakingId, setstakingId] = useState(null)
  const [availableBalance, setavailableBalance] = useState(null)
  const [approvedAmount, setapprovedAmount] = useState(null)
  const [checkStakingAmount, setcheckStakingAmount] = useState(1)
  const [unstakeAmount, setunstakeAmount] = useState('')

  const web3Config = useMemo(() => {
    if (library) {
      return new Web3Config(library)
    }
    return null
  }, [library])

  const getApprovedAmount = useCallback(async () => {
    if (web3Config) {
      let web3 = web3Config.getWeb3()
      let networkId = web3Config.getNetworkId()
      let stakingInstance = new StakingContract(web3, networkId)
      const approvedAmount = await stakingInstance.getApprovedTokenForStaking(account, constantConfig[networkId].jntrbToken, constantConfig[networkId].reimbursementStakingContract)
      var allowAmount = simpleAmountInString(approvedAmount, 18)
      setapprovedAmount(allowAmount)
    }
  }, [web3Config, account])

  const showClaimPopup = useCallback(
    (id) => {
      if (balance.vault !== null && balance.vault !== undefined) {
        var bal = Web3Utils.fromWei(balance.balance[id])
        bal = bal * 1
        setavailableBalance(bal.toFixed(2) / balances.jntrb)
        setstakingId(id)
        getApprovedAmount()
        setOpenClaim(true)
      } else {
        console.log({
          balance,
          a: balances.jntrb,
          balances,
        })
        notificationConfig.warning(`Your Balance is not enought your balance is ${balance.vault}`)
      }
    },
    [balance, balances, getApprovedAmount]
  )

  const hideClaimPopup = useCallback(() => {
    setOpenClaim(false)
  }, [])

  const showWithdrawPopup = useCallback(
    (id) => {
      setOpenWithdraw(true);
      setstakingId(id)
      setunstakeAmount(unstakingAmounts[id])
      // { console.log("unstakeamount", unstakingAmounts, id) }
    },
    [unstakingAmounts]
  )

  const hideWithdrawPopup = useCallback(() => {
    setOpenWithdraw(false);
  }, [])

  const approveAndStakeNow = useCallback(async () => {
    if (stakingAmount === null || stakingAmount * 1 === 0) {
      setcheckStakingAmount(0)
    } else {
      let reimbursementStakingInstance = new reimbursementStakingContract(web3, chainId)
      let stakingInstance = new StakingContract(web3, chainId)
      setOpenClaim(false)
      stakingInstance.approveJNTRTokenForSwapFactory(
        constantConfig[chainId].jntrbToken, // this token address will change dynamic by select staking.
        constantConfig[chainId].reimbursementStakingContract,
        Web3Utils.toWei(stakingAmount),
        (hash) => {
          notificationConfig.success('Transaction Generated')
        },
        () => {
          notificationConfig.success('Approved Successfully')
          reimbursementStakingInstance.reimbursementStaking(
            balance.vault[stakingId],
            Web3Utils.toWei(stakingAmount),
            (hash) => {
              notificationConfig.success('Transaction generated')
            },
            () => {
              notificationConfig.success('Staking Success')
              getSetting()
              setstakingAmount(0)
            }
          )
        }
      )
    }
  }, [balance.vault, chainId, getSetting, stakingAmount, stakingId, web3])

  const stakeNow = useCallback(async () => {
    if (stakingAmount === null || stakingAmount * 1 === 0) {
      setcheckStakingAmount(0)
    } else {
      let web3 = web3Config.getWeb3()
      let networkId = web3Config.getNetworkId()
      let reimbursementStakingInstance = new reimbursementStakingContract(web3, networkId)
      setOpenClaim(false)
      reimbursementStakingInstance.reimbursementStaking(
        balance.vault[stakingId],
        Web3Utils.toWei(String(stakingAmount)),
        (hash) => {
          notificationConfig.success('Transaction generated')
        },
        () => {
          notificationConfig.success('Staking Success')
          getSetting()
          setstakingAmount(0)
        }
      )
    }
  }, [balance.vault, getSetting, stakingAmount, stakingId, web3Config])

  const withdrawNow = useCallback(() => {
    let web3 = web3Config.getWeb3()
    let networkId = web3Config.getNetworkId()
    let reimbursementStakingInstance = new reimbursementStakingContract(web3, networkId)
    setOpenWithdraw(false)
    reimbursementStakingInstance.reimbursementStakingWithdraw(
      balance.vault[stakingId],
      (hash) => {
        notificationConfig.success('Transaction generated')
      },
      () => {
        notificationConfig.success('Withdraw Success')
        getSetting()
      }
    )
  }, [balance.vault, getSetting, stakingId, web3Config])

  const stakingTokenChange = useCallback(
    async (e) => {
      setstakingToken(e.value)
      getApprovedAmount()
    },
    [getApprovedAmount]
  )

  const unStakingTokenChange = useCallback(async (e) => {
    setunStakingToken(e.value)
  }, [])

  const updateAmount = useCallback(
    (value) => {
      if (value * 1 > availableBalance * 1) {
        setstakingAmount(availableBalance)
        setcheckStakingAmount(1)
      } else {
        setstakingAmount(value)
        setcheckStakingAmount(1)
      }
      getApprovedAmount()
    },
    [availableBalance, getApprovedAmount]
  )

  const walletConnect = () => {
    connectWallet()
    getApprovedAmount()
  }

  const columns = useMemo(
    () => [
      {
        name: 'Token',
        width: '7%',
        selector: (row) => {
          return <p className='token-name reimbursement-table-field'>{row.token}</p>
        },
      },
      // {
      //   name: "Gas & fees spent",
      //   width: "13%",
      //   selector: (row) => {
      //     return <p className="reimbursement-table-field">{row.fee}</p>;
      //   },
      // },
      {
        name: 'Total for reimbursement',
        width: '15%',
        selector: (row) => {
          return <p className='reimbursement-table-field'>{numberFormater().format(row.total)}</p>
        },
      },
      {
        name: 'Reimbursement value',
        width: '15%',
        selector: (row) => {
          return <p className='reimbursement-table-field'>{usdFormater().format(row.reimbursement)}</p>
        },
      },
      {
        name: (
          <div className='d-flex'>
            <p className='ratio-wrap m-0'>Staking ratio</p>
            <i className='fas helpIco fa-question-circle' data-tip='Content Coming Soon'></i>
          </div>
        ),
        width: '12%',
        selector: (row) => {
          return <p className='reimbursement-table-field'>1:{row.ratio}</p>
        },
      },
      {
        name: 'Staking period',
        width: '15%',
        selector: (row) => {
          return <p className='reimbursement-table-field'>{row.period} days</p>
        },
      },
      {
        name: 'Reimbursement',
        center: true,
        width: '12%',
        selector: (row) => {
          return (
            <button onClick={(id) => showClaimPopup(row.id)} className='claim-btn'>
              {row.staking}
            </button>
          )
        },
      },
      {
        name: 'Staking Balance',
        width: '12%',
        selector: (row) => {
          return <p className='reimbursement-table-field'>{numberFormater(3).format(row.balance || 0)}</p>
        },
      },
      {
        name: 'Withdrawal',
        width: '11%',
        center: true,
        selector: (row) => {
          return (
            <button onClick={(id) => showWithdrawPopup(row.id)} className='withdrawal-btn'>
              <FontAwesomeIcon icon={faCircleXmark} />
            </button>
          )
        },
      },
    ],
    [showClaimPopup, showWithdrawPopup]
  )

  return (
    <div className='Flexible-13'>
      <div className='container'>
        <div className='content'>
          <div className='center-mode mt-0 mb-0'>
            {active && account ? (
              stakingTableDatas.length > 0 ? (
                <>
                  <DataTable
                    columns={columns}
                    data={stakingTableDatas}
                    customStyles={customStyles}
                    pagination
                    dense
                    // paginationComponentOptions={paginationComponentOptions}
                    paginationComponent={CustomReactTablePagination}
                  />
                </>
              ) : (
                <div>
                  <div className='d-flex init-table'>
                    {columns.map((column, index) => {
                      return column.name !== 'Withdrawal' ? (
                        <div key={index} className='table-header' style={{ width: column.width }}>
                          {column.name}
                        </div>
                      ) : (
                        <div key={index} className='table-last-header' style={{ width: column.width }}>
                          {column.name}
                        </div>
                      )
                    })}
                  </div>
                  <div className='reimbursement-connect-wallet'>
                    <p className='table-color'>There is no datas</p>
                  </div>
                </div>
              )
            ) : (
              <div>
                <div className='d-flex init-table'>
                  {columns.map((column, index) => {
                    return column.name !== 'Withdrawal' ? (
                      <div key={index} className='table-header' style={{ width: column.width }}>
                        {column.name}
                      </div>
                    ) : (
                      <div key={index} className='table-last-header' style={{ width: column.width }}>
                        {column.name}
                      </div>
                    )
                  })}
                </div>
                <div className='reimbursement-connect-wallet'>
                  <button className='navbar-connect-wallet' onClick={walletConnect}>
                    Connect Wallet
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={openClaim} dismiss={hideClaimPopup}>
        <div className='claimpopup'>
          <div className='content-popup border-2'>
            <div className='form-content'>
              <div className='part0'>
                <div className='input-label'>
                  <p className='label'>Token</p>
                </div>
                <div className='input-group'>
                  <Select
                    id='jntr-select'
                    styles={claimPopupStyles}
                    options={tokenLists}
                    placeholder='Select Token'
                    components={{ DropdownIndicator }}
                    value={tokenLists.find((obj) => obj.value === stakingToken)}
                    onChange={stakingTokenChange}
                  ></Select>
                </div>
              </div>
              <div className='part1'>
                <div className='input-label'>
                  <p className='label'>Amount to deposit</p>
                </div>
                <div className='input-group'>
                  <NumberFormat
                    placeholder='100,000'
                    displayType={'input'}
                    thousandSeparator={true}
                    value={stakingAmount}
                    onValueChange={(values) => {
                      const { value } = values
                      updateAmount(+value)
                    }}
                  />
                </div>
              </div>
              <div>
                {checkStakingAmount === 1 ? (
                  web3Config?.web3 ? (
                    availableBalance !== null && stakingAmount * 1 <= availableBalance * 1 ? (
                      <p className='available-amount '>
                        Total available amount to deposit:{' '}
                        <span className='cursor-pointer' onClick={() => updateAmount(availableBalance)}>
                          {availableBalance}
                        </span>
                      </p>
                    ) : (
                      <p className='invalid-amount'>Invalid amount: Staking amount must be small than your current amount</p>
                    )
                  ) : (
                    <p className='available-amount'>Total available amount to claim: 0</p>
                  )
                ) : (
                  <p className='invalid-amount'>{stakingAmount === null ? "Invalid amount: Staking amount can't be empty." : "Invalid amount: Staking amount can't be 0."}</p>
                )}
              </div>
            </div>
            <div className='part2'>
              {web3Config?.web3 ? (
                stakingAmount * 1000000000000000000 > approvedAmount * 1 ? (
                  <button type='submit' className='stake-btn' onClick={approveAndStakeNow.bind(this)}>
                    Approve &amp; Stake Now
                  </button>
                ) : (
                  <>
                    <button type='submit' className='stake-btn' onClick={stakeNow}>
                      stake now
                    </button>
                  </>
                )
              ) : (
                <button className='wallet-connect-btn' onClick={walletConnect}>
                  Connect Wallet
                </button>
              )}
            </div>

            <div className='closebtn' onClick={hideClaimPopup}>
              <span className='close-icon-x'></span>
            </div>
          </div>
        </div>
      </Modal>
      <Modal isOpen={openWithdraw} dismiss={hideWithdrawPopup}>
        <div className='withdrawpopup'>
          <div className='content-popup border-2'>
            <div className='form-content'>
              <div className='part0'>
                <div className='input-label'>
                  <p className='label'>Token</p>
                </div>
                <div className='input-group'>
                  <Select
                    id='jntr-select'
                    styles={claimPopupStyles}
                    options={tokenLists}
                    placeholder='Select Staking Type'
                    components={{ DropdownIndicator }}
                    value={tokenLists.find((obj) => obj.value === unStakingToken)}
                    onChange={unStakingTokenChange}
                  ></Select>
                </div>
              </div>
              <div className='part1'>
                <div className='input-label'>
                  <p className='label'>Amount to Withdrawal</p>
                </div>
                <div className='input-group'>
                  <p>{Web3Utils.fromWei(String(unstakeAmount || '0'))}</p>
                </div>
              </div>
            </div>
            <div className='part2'>
              <button type='submit' className='withdraw-btn' onClick={withdrawNow}>
                withdraw now
              </button>
            </div>

            <div className='closebtn' onClick={hideWithdrawPopup}>
              <span className='close-icon-x'></span>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default withConnectWalletModal(Reimbursement)
