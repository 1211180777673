import { isAddress } from 'web3-utils'

class SubgraphBaseClass {
    /**
     *
     * @param {String} query
     * @returns
     */
    buildWhereByToken = (query: string) => {
        query = query.trim()
        if (isAddress(query)) {
            return { token_: { id: query } }
        } else {
            return { token_: { symbol_contains_nocase: query } }
        }
    }
}

export default SubgraphBaseClass;
