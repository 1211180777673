import { Pool, PoolInReducer, UserAddedLPPosition } from "types"

export enum ActionTypes {
  SET_LIST =  'IMPORTED_POOLS/SET_LIST',
  RESET =  'IMPORTED_POOLS/RESET',
  CLEAR_ALL =  'IMPORTED_POOLS/CLEAR_ALL',
  SET_LP_ADDRESS =  'IMPORTED_POOLS/SET_LP_ADDRESS',
  SET_POOL =  'IMPORTED_POOLS/SET_POOL',
  SET_USER_ADDED_LIQUIDITY_POSITIONS =  'IMPORTED_POOLS/SET_USER_ADDED_LIQUIDITY_POSITIONS',
  IMPORT_POOL =  'IMPORTED_POOLS/IMPORT_POOL',
  REMOVE_POOL =  'IMPORTED_POOLS/REMOVE_POOL',
  SET_ERRORS =  'IMPORTED_POOLS/SET_ERRORS',
  IS_LOADING_POOL =  'IMPORTED_POOLS/IS_LOADING_POOL',
}

interface ActionSetList {
  type: ActionTypes.SET_LIST
  payload: Array<Pool>
}
interface ActionReset {
  type: ActionTypes.RESET
}
interface ActionClearAll {
  type: ActionTypes.CLEAR_ALL
}
interface ActionSetLPAddress {
  type: ActionTypes.SET_LP_ADDRESS
  payload: string
}
interface ActionPool {
  type: ActionTypes.SET_POOL
  payload: PoolInReducer
}
interface ActionUserAddedLPPositions {
  type: ActionTypes.SET_USER_ADDED_LIQUIDITY_POSITIONS
  payload: Array<UserAddedLPPosition>
}
interface ActionImportPool {
  type: ActionTypes.IMPORT_POOL
}
interface ActionRemovePool {
  type: ActionTypes.REMOVE_POOL
  payload: number
}
interface ActionSetErrors {
  type: ActionTypes.SET_ERRORS
  payload: Array<any>
}
interface ActionIsLoadingPool {
  type: ActionTypes.IS_LOADING_POOL
  payload: boolean
}

export type ActionTypeImportedPools = ActionSetList | ActionReset | ActionClearAll | ActionSetLPAddress | ActionPool | ActionUserAddedLPPositions | ActionImportPool | ActionRemovePool | ActionSetErrors | ActionIsLoadingPool
