import {
    ContractCallContext,
} from 'ethereum-multicall';
import LPInsureProxyABI from 'Assets/Abi/lp-insure-proxy-abi.json';
import { LP_INSURE_PROXY_FACTORY } from 'app-constants/lp-insure';
import { GetMaxAmountReturnType, SupportedNetworks } from 'types';
import { hexToDecimals } from '../math';
import { multicall } from './multicall';

type GetMaxAmount = {
    [key in string]: GetMaxAmountReturnType
}

export async function getMaxAmount(chainId: SupportedNetworks, lpOfferIds: string[]): Promise<GetMaxAmount> {
    const contract = LP_INSURE_PROXY_FACTORY[chainId];

    const contractCallContext: ContractCallContext[] = lpOfferIds.map((offerId) => (
        {
            reference: offerId,
            contractAddress: contract,
            abi: LPInsureProxyABI,
            calls: [{ reference: 'getMaxAmountCall_' + offerId, methodName: 'getMaxAmount', methodParameters: [offerId] }]
        }
    ))
    const values = await multicall(chainId, contractCallContext);
    const result: GetMaxAmount = {};

    for (const offerId in values) {
        const element = values[offerId];
        result[offerId] = {
           maxPayAmount: hexToDecimals(element.callsReturnContext[0]?.returnValues[0]?.hex),
           maxTokenAmount: hexToDecimals(element.callsReturnContext[0]?.returnValues[1]?.hex)
        }
    }
    return result;
}
