import { Component } from 'react'

type StateType = {
  hasError: boolean
  error: null | Error
}

type PropsType = {
  children: any
}

class ErrorBoundary extends Component<PropsType, StateType> {
  state: StateType = {
    hasError: false,
    error: null,
  }
  constructor(props: PropsType) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
    }
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='ErrorBoundary'>
          <div>
            <p>Sorry for the trouble. Something went wrong. please refresh page. we will fix this problem asap!</p>
          </div>
          <p>{this.state.error?.toString()}</p>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
