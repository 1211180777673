import { DropdownMenu } from './Dropdown'

type Option = {
  label: string
  value: any
}

const PeriodPicker = ({ label, options = [], onClickOption }: {
  label: string, options: Option[], onClickOption: (e: any, o: Option) => void
}) => {
  return (
    <div className='btn-group'>
      <button type='button' className='btn dropdown-toggle text-white fs-md' data-bs-toggle='dropdown' aria-expanded='false'>
        {label}
      </button>
      <DropdownMenu className='dropdown-menu bg-greyBorder text-capitalize p-10'>
        {options.map((option, index) => (
          <li key={index} className='dropdown-item' onClick={(e) => onClickOption(e, option)}>
            {option.label}
          </li>
        ))}
      </DropdownMenu>
    </div>
  )
}

export default PeriodPicker
