import styled from 'styled-components'

const TextView = styled.span`
  margin: 0 2px;
  &.number {
    padding: 5px;
    border: 1px solid ${(props) => props.theme.grayBorder3};
    border-radius: 5px;
  }
`

/**
 *
 * @param {String} value  @example '20:20:20:20:20'
 * @returns
 */
function AppTimeView({ value, numclassName = '', textClassName = '', ...props }: {
  value: string,
  numclassName?: string,
  textClassName?: string,
  props?: any
}) {
  const values = value.split('')

  return (
    <div {...props}>
      {values.map((text, index) => (
        <TextView key={`AppTimeView-${index}`} className={`${isNaN(+text) ? `${textClassName} text` : `${numclassName} number`}`}>
          {text}
        </TextView>
      ))}
    </div>
  )
}

export default AppTimeView
