import styled from 'styled-components'

export const PageBannerWrapper = styled.div`
  display: flex;
`

export const PageBannerTitle = styled.h3`
  margin-right: -30px;
  max-width: 250px;
  text-align: center;
  color: ${({ theme }) => theme.colorPrimary};
`

export const PageBannerBox2 = styled.div`
  img {
    max-width: 230px;
  }
`

export const InnovationBox = styled.div`
  text-align: center;
  img {
    max-width: 230px;
  }
`
export const LPInnovationBox = styled.div`
  text-align: center;
`
export const InnovationDescription = styled.p`
  padding-top: 5px;
  font-size: ${({ theme }) => theme.fontSize.md};
`
export const InnovationTitle = styled.h3`
  font-weight: bold;
  margin-top: 25px;
  font-size: ${({ theme }) => theme.fontSize.lg};
`

export const Description = styled.p`
  max-width: 220px;
  text-align: center;
`
