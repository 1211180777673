import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSearchParam } from 'hooks'
import { smartChainQuery } from 'helper'
import useWallet from 'hooks/useWallet'
import { LOCAL_STORAGE_ITEM_NAMES } from 'app-constants'
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'helper/localStorage'
import { SupportedNetworks } from 'types'
import { SEARCH_PARAMS } from 'app-constants/search-params'

// this component helps to add ?chain=1 | 56 | Number to search if it does not exists
interface IChainCheckerProps {
  children: JSX.Element
}
function ChainChecker({ children }: IChainCheckerProps): JSX.Element {
  const history = useHistory()
  const chain = useSearchParam(SEARCH_PARAMS.chainId) || 0
  const { chainId } = useWallet()
  const savedChainId = useMemo<SupportedNetworks | undefined>(() => {
    return loadStateFromLocalStorage(LOCAL_STORAGE_ITEM_NAMES.CONNECTED_CHAIN)
  }, [])

  useEffect(() => {
    if (chainId) {
      saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.CONNECTED_CHAIN, chainId)
    }
  }, [chainId])

  if (chain === 0) {
    let search = history.location.search
    search = smartChainQuery(search, savedChainId || SupportedNetworks.bsctestnetwork) // 1 is mainnet (ethereum)

    history.push({
      pathname: history.location.pathname,
      search: search,
    })
  } else {
    return <>{children}</>
  }
  return <></>
}

export default ChainChecker
