import { TaxSetValuesParams } from "types"

export enum ActionTypes {
  SET_VALUES = 'TAX/SET_VALUES',
  SET_TOKEN = 'TAX/SET_TOKEN',
  RESET = 'TAX/RESET',
}

export interface ActionSetValues {
  type: ActionTypes.SET_VALUES
  payload: TaxSetValuesParams
}

export interface ActionSetToken {
  type: ActionTypes.SET_TOKEN
  payload: string
}

export interface ActionReset {
  type: ActionTypes.RESET
}

export type ActionTax = ActionSetValues | ActionSetToken | ActionReset