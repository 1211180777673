export enum AppRouters {
    ReimbursementPage          = '/reimbursement',

    ComingSoon_Page          = '/coming-soon',

    SwapDetail          = '/swap/detail',
    TransactionSetting  = '/swap/transation-setting',
    VoteSection         = '/swap/votes',
    TaxSection          = '/swap/tax',
    ConnectWallet       = '/swap/connect-wallet',

    Liquidity           = '/swap/liquidity',
    AddOrYourLP         = '/swap/liquidity/add-or-your-lp',
    RemoveLP            = '/swap/liquidity/remove-lp',
    AddLP               = '/swap/liquidity/add-lp',
    ConfirmAndSupply    = '/swap/liquidity/confirm-and-supply',
    SetPoolRegulations  = '/swap/liquidity/pool-regulations',
    YouWillReceive      = '/swap/liquidity/you-will-receive',
    SecureFloor         = '/swap/liquidity/secure-floor',
    ViewPoolRegulations = '/swap/liquidity/view-pool-regulations',

    IBO_BONDS_PAGE      = '/ibo/launch-pools',
    IBO_Add_OFFERS_PAGE = '/ibo/launch-bond-pool',
    IBO_ABOUT_PAGE      = '/ibo/about',
    
    ROI_BOOSTER_PAGE    = '/roi-booster',

    LPInsurePools       = '/lp/pools',
    LPInsureLaunchPools = '/lp/launch-pool',
    LPInsureAboutPage   = '/lp/about',
}
