import { Ballot } from "types"

export enum ActionTypes {
  IS_LOADING = 'VOTES/IS_LOADING',
  IS_LOADED = 'VOTES/IS_LOADED',
  IS_LOAD_MORE_COMPLETED_BALLOTS = 'VOTES/IS_LOAD_MORE_COMPLETED_BALLOTS',
  IS_LOAD_MORE_ACTIVED_BALLOTS = 'VOTES/IS_LOAD_MORE_ACTIVED_BALLOTS',
  LOAD_VOTES = 'VOTES/LOAD_VOTES',
  LOAD_MORE_ACTIVED_VOTES = 'VOTES/LOAD_MORE_ACTIVED_VOTES',
  LOAD_MORE_COMPLETED_VOTES = 'VOTES/LOAD_MORE_COMPLETED_VOTES',
  SELECT_BALLOT = 'VOTES/SELECT_BALLOT',
  RESET = 'VOTES/RESET',
}

export interface ActionIsLoading {
  type: ActionTypes.IS_LOADING
}

export interface ActionIsLoaded {
  type: ActionTypes.IS_LOADED
}

export interface ActionLoadVotes {
  type: ActionTypes.LOAD_VOTES
  payload: {
    activedBallots: Array<Ballot>
    completedBallots: Array<Ballot>
  }
}
export interface ActionLoadMoreActivedBallots {
  type: ActionTypes.IS_LOAD_MORE_ACTIVED_BALLOTS
  payload: boolean
}
export interface ActionLoadMoreActivedVotes {
  type: ActionTypes.LOAD_MORE_ACTIVED_VOTES
  payload: Array<Ballot>
}

export interface ActionIsLoadMoreCompletedBallots {
  type: ActionTypes.IS_LOAD_MORE_COMPLETED_BALLOTS
  payload: boolean
}
export interface ActionLoadMoreCompletedVotes {
  type: ActionTypes.LOAD_MORE_COMPLETED_VOTES
  payload: Array<Ballot>
}

export interface ActionSelectBallot {
  type: ActionTypes.SELECT_BALLOT
  payload: string
}
export interface ActionReset {
  type: ActionTypes.RESET
}

export type VoteAction
  = ActionIsLoading | ActionIsLoaded | ActionLoadVotes | ActionLoadMoreActivedBallots | ActionLoadMoreActivedVotes
  | ActionIsLoadMoreCompletedBallots | ActionLoadMoreCompletedVotes | ActionSelectBallot | ActionReset
