import React, { useCallback, useEffect, useState } from 'react'
import { isAddress } from 'web3-utils'
import { IoMdArrowDropdown } from 'react-icons/io'
import isURL from 'validator/lib/isURL'
import { BondSelector } from 'redux/selectors'

import { nextStep, setPairToken, setParams, setToken } from 'redux/reducers/bond/actions'
import * as S from './styled'
import useAppSnackbar, { AppWaringOptions } from 'hooks/useAppSnackbar'
import { AppButton } from 'Component/core/elements/Buttons'
import { Divider } from 'Component/core/elements/Divider'
import { InputGroup, InputGroupControl, InputGroupText } from 'Component/core/elements/InputGroup'
import { SLogo } from 'Pages/IBO/components/styled'
import TokenIcon from 'Component/TokenIcon'
import AppDatePicker from 'Component/core/elements/DatePicker/DatePicker'
import { isEqAddr } from 'helper'
import { useAppChain, useWeb3 } from 'hooks'
import Web3Helpers from 'helper/Web3Helpers'
import { useMemo } from 'react'
import { NETWORKS, ZERO_ADDRESS } from 'app-constants'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { SPairTokenButton } from 'Component/core/elements/IBO-SharedComponents'
import SelectTokenPopup from 'Component/popup/SelectTokenPopup'
import AppTooltip, { AppToolTipControl } from 'Component/AppTooltip'
import { AppVaildate } from 'types'


enum SelectType {
  none,
  token,
  pairtoken
}

function ProjectInformation(): JSX.Element {
  const { appChainId } = useAppChain()
  const network = useMemo(() => NETWORKS[appChainId], [appChainId])
  const w3 = useWeb3()
  const [openWaringSnackbar] = useAppSnackbar(AppWaringOptions)
  const dispatch = useAppDispatch()
  const bond = useAppSelector(BondSelector)
  const tokenInfo = bond.tokenInfo

  const [tokenSelectType, setTokenSelectType] = useState(SelectType.none)
  const [isPairExists, setIsPairExists] = useState(false)

  // invalid checker fields
  const inValidToken = useMemo<boolean>(() => {
    if (!bond.token || !isAddress(bond.token)) return true
    return false;
  }, [bond.token])
  const inValidWebSite = useMemo<boolean>(() => {
    if (bond.website && !isURL(bond.website)) return true
    return false;
  }, [bond.website])
  const inValidWhitePaper = useMemo<boolean>(() => {
    if (bond.whitepaper && !isURL(bond.whitepaper)) return true
    return false;
  }, [bond.whitepaper])

  const endIBODateValidation = useMemo<AppVaildate>(() => {
    if (Date.now() >= bond.endIBODate)
      return {
        isInvalid: true,
        message: 'IBO end date should be bigger than now!'
      }
    if (bond.startIBODate >= bond.endIBODate)
      return {
        isInvalid: true,
        message: 'IBO end date should be bigger than start date!'
      }
    return {
      isInvalid: false,
      message: ''
    };
  }, [bond.endIBODate])

  const tokenValidation = useMemo<AppVaildate>(() => {
    if (!bond.token) 
      return {
        isInvalid: false,
        message: ''
      };

    if (+bond.tokenInfo.balance <= 0)
      return {
        isInvalid: true,
        message: 'Insufficient token balance!'
      };
    if (+bond.tokenInfo.balance <= 2) 
      return {
        isInvalid: true,
        message: 'Insufficient token balance! Must have more than 2!'
      };

    return {
      isInvalid: false,
      message: ''
    };
  }, [+bond.tokenInfo.balance])

  // values
  const handleDismissSelectToken = useCallback(() => {
    setTokenSelectType(SelectType.none)
  }, [])

  const handleOnSelectToken = useCallback(
    async ({ name, symbol, address, decimals }: {
      name: string, symbol: string, address: string, decimals: string
    }) => {
      try {
        if (tokenSelectType === SelectType.token) {
          await dispatch(setToken(address))
        } else {
          await dispatch(
            setPairToken({
              name,
              symbol,
              address,
              decimals,
            })
          )
        }
        // @ts-ignore
      } catch (error: Error) {
        console.error(error);
        return openWaringSnackbar(error.message)
      } finally {
        setTokenSelectType(SelectType.none)
      }
    },
    [bond.token, tokenSelectType]
  )

  const handleOnChangeToken = useCallback(
    async (e: any) => {
      const token = e.target.value
      if (!isAddress(token)) {
        openWaringSnackbar('Please enter correct address')
      }
      try {
        await dispatch(setToken(token))
      } catch (error: any) {
        console.error('error: ', error)
        openWaringSnackbar(String(error))
      }
    },
    []
  )

  const handleOnClickToken = useCallback(() => {
    setTokenSelectType(SelectType.token)
  }, [])

  const handleOnChangeWhitePaper = useCallback(
    (e: any) => {
      dispatch(
        setParams({
          whitepaper: e.target.value,
        })
      )
    },
    []
  )
  const handleOnChangeWebSite = useCallback(
    (e: any) => {
      dispatch(
        setParams({
          website: e.target.value,
        })
      )
    },
    []
  )

  const handleOnChangeLogo = useCallback(
    (e: any) => {
      dispatch(
        setParams({
          logo: {
            preview: URL.createObjectURL(e.target.files[0]),
            file: e.target.files[0],
          },
        })
      )
    },
    []
  )

  const handleOnChangeIBOStartDate = (_date: Date) => {
    dispatch(
      setParams({
        startIBODate: _date.getTime(),
      })
    )
  }
  const handleOnChangeIBOEndDate = (_date: Date) => {
    AppToolTipControl.hide();
    dispatch(
      setParams({
        endIBODate: _date.getTime(),
      })
    )
  }

  const onClickNextStep = () => {
    // check data validate
    if (!isAddress(bond.token)) return openWaringSnackbar('Add token smart contract address!')
    if (tokenValidation.isInvalid) return openWaringSnackbar(tokenValidation.message)
    if (!isAddress(bond.pairToken.address)) return openWaringSnackbar('PairToken is required!')
    if (+bond.pairToken.balance <= 0) return openWaringSnackbar('Insufficient PairToken balance!')
    if (typeof bond.logo.file !== 'object' || !bond.logo.file) return openWaringSnackbar('Logo file is required!')
    if (inValidWebSite) return openWaringSnackbar('Website should be an url!')
    if (endIBODateValidation.isInvalid) return openWaringSnackbar(endIBODateValidation.message)
    if (isPairExists) return openWaringSnackbar("Pair Already exists! You can't create any bonds with this pair")
    AppToolTipControl.hide();
    dispatch(nextStep())
  }

  useEffect(() => {
    (async () => {
      const w3Helper = new Web3Helpers(w3)
      setIsPairExists(false)
      const pairAddress = await w3Helper.getPair(bond.token, bond.pairToken.address, network.FACTORY)
      if (pairAddress && !isEqAddr(pairAddress, ZERO_ADDRESS)) {
        setIsPairExists(true)
        openWaringSnackbar("Pair Already exists! You can't create any bonds with this pair")
      } else {
        setIsPairExists(false)
      }
    })()
    // disabled for openWaringSnackbar
    // eslint-disable-next-line
  }, [bond.pairToken.address, bond.token, network.FACTORY, w3])


  return (
    <React.Fragment>
      <div className='py-40'>
        <div className='row'>
          <div className='col-6 my-10 d-flex'>
            <div className='position-relative d-flex align-items-center justify-content-between w-full'>
              <label className='text-nowrap fw-bold'>Place your token contract*</label>
              <InputGroup className={`w-full w-max-320 ${(inValidToken || tokenValidation.isInvalid) ? 'danger' : ''}`}>
                <InputGroupControl value={bond.token} onChange={handleOnChangeToken} />
                <S.TokenText onClick={handleOnClickToken}>{tokenInfo.symbol || '[token]'}</S.TokenText>
              </InputGroup>
              <S.TokenErrorMessage
                className='text-danger fs-sm'
                data-type='light'
                data-html='true'
                data-class='data-tooltip'
                data-tip={tokenValidation.message}
              >{tokenValidation.message}</S.TokenErrorMessage>
            </div>
          </div>
          <div className='col-6 my-10' hidden={!bond.withNewPool}>
            <div className='d-flex justify-content-between align-items-center pl-30'>
              <label className='fw-bold'>Select a pair token*</label>
              <SPairTokenButton className={`w-max-320 w-min-320 outline-gray py-7 text-end ${bond.pairToken.address ? '' : 'danger'}`} onClick={() => setTokenSelectType(SelectType.pairtoken)}>
                {bond.pairToken.address ? (
                  <p>
                    <TokenIcon className='md-2' byAddress={bond.pairToken.address} alt='' />
                    <span>{bond.pairToken.symbol}</span>
                  </p>
                ) : (
                  <> &nbsp;</>
                )}
                <IoMdArrowDropdown className='text-muted' />
              </SPairTokenButton>
            </div>
          </div>
        </div>
        <Divider className='vertical mt-15 mb-10 highlighted-border-bottom' />
        <div className='row my-15 align-items-center'>
          <div className='col-6'>
            <div className='d-flex align-items-center justify-content-between'>
              <label className='fw-bold'>Add a logo*</label>
              <div className='w-max-320 d-flex align-items-center'>
                <InputGroup className={`${bond.logo.file ?? 'danger'}`}>
                  <S.SUploadLogoInput type='file' onChange={handleOnChangeLogo} accept='image/*' />
                  <InputGroupText>Browse</InputGroupText>
                </InputGroup>
                {/* <SLogo className='ml-10' src={bond.logo.preview} alt='' /> */}
              </div>
            </div>
          </div>
          <div className='col-3'></div>
          <div className='col-3'>{bond.logo.preview ? <SLogo className='ml-10' src={bond.logo.preview} alt='' /> : <SLogo className='ml-10 placeholder' src='/images/temp-logo.png' alt='' />}</div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <div className='d-flex align-items-center justify-content-between'>
              <label className='fw-bold'>Add your website</label>
              <InputGroup className={`w-max-320 w-min-320 py-2 ${inValidWebSite ? 'danger' : ''}`}>
                <InputGroupControl placeholder='https://' type='url' name='website' value={bond.website} onChange={handleOnChangeWebSite} />
              </InputGroup>
            </div>
          </div>
          <div className='col-6'>
            <div className='d-flex align-items-center justify-content-between pl-30'>
              <label className='fw-bold'>Add link to whitepaper</label>
              <InputGroup className={`w-max-320 w-min-320 py-2 ${inValidWhitePaper ? 'danger' : ''}`}>
                <InputGroupControl name='whitepaper' value={bond.whitepaper} placeholder='https://' type='url' onChange={handleOnChangeWhitePaper} />
              </InputGroup>
            </div>
          </div>
        </div>
        <Divider className='vertical mt-20 mb-10 highlighted-border-bottom' />
        <div className='row py-20 highlighted-border-bottom'>
          <div className='col-6' hidden={!bond.withNewPool}>
            <div className='d-flex justify-content-between align-items-center'>
              <label className='mt-5 fw-bold'>IBO start date*</label>
              <InputGroup className='w-max-320 w-min-320 py-2 pl-15'>
                <AppDatePicker dateFormat='MMMM d, yyyy' selected={bond.startIBODate} onChange={handleOnChangeIBOStartDate} />
              </InputGroup>
            </div>
          </div>
          <div className='col-6' hidden={!bond.withNewPool}>
            <div className='d-flex justify-content-between align-items-center pl-30'>
              <label className='mt-5 fw-bold'>IBO end date*</label>
              <InputGroup
                className={`w-max-320 w-min-320 py-2 pl-15 ${endIBODateValidation.isInvalid ? 'danger' : ''}`}
                data-type='light'
                data-html='true'
                data-class='data-tooltip'
                data-tip={endIBODateValidation.isInvalid ? endIBODateValidation.message : ''}
              >
                <AppDatePicker dateFormat='MMMM d, yyyy' selected={bond.endIBODate} onChange={handleOnChangeIBOEndDate} />
              </InputGroup>
            </div>
          </div>
        </div>
        <Divider className='vertical mt-10 mb-10 highlighted-border-bottom' />
        <div className='d-flex justify-content-center mt-50'>
          <AppButton className='text-capitalize outline-primary w-max-500' onClick={onClickNextStep}>
            next step
          </AppButton>
        </div>
      </div>
      <SelectTokenPopup
        isOpen={tokenSelectType !== SelectType.none}
        dismiss={handleDismissSelectToken}
        onSelectToken={handleOnSelectToken}
        onlyTokensWithBalance
        disabledAddresses={[]}
      />
      <AppTooltip />
    </React.Fragment>
  )
}

export default ProjectInformation
