import React from 'react'
import styled from 'styled-components'

const SCustomCheckBox = styled.div`
  .container {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border-radius: 5px;
    border: 2px solid ${({ theme }) => theme.colorLightGrey};
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: transparent;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: transparent;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 7px;
    top: -7px;
    width: 8px;
    height: 16px;
    border: solid ${({ theme }) => theme.colorPrimary};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &.danger {
    .container .checkmark:after {
      border-color: ${({ theme }) => theme.colorRed};
    }
  }
`

interface IAppCheckboxProps extends React.HTMLAttributes<HTMLInputElement> {
  checked?: boolean
  label?: string | JSX.Element | any
  readOnly?: boolean
}

function AppCheckbox({ label = '', style = {}, className = '', ...props }: IAppCheckboxProps) {
  return (
    <SCustomCheckBox style={style} className={className}>
      <label className='container'>
        {label}
        <input type='checkbox' {...props} />
        <span className='checkmark'></span>
      </label>
    </SCustomCheckBox>
  )
}

export default AppCheckbox
