import { useMemo } from 'react'
import { isEqSymbol, smartSymbol } from 'helper'
import { SimplePriceSelector } from 'redux/selectors'
import { useAppSelector } from 'redux/hooks'

export function usePriceBySymbol(symbol: string): number {

  const simplePrice = useAppSelector(SimplePriceSelector)
  const tokenPriceInUsd = useMemo(() => {
    const tokenValues = Object.values(simplePrice)
    let t = tokenValues.find((t) => isEqSymbol(t.symbol, symbol))
    if(t === undefined) {
      const ts = smartSymbol(symbol)
      t = tokenValues.find((t) => isEqSymbol(t.symbol, ts))
    }
    return +(t?.priceUSD || 0)
  }, [simplePrice, symbol])

  return tokenPriceInUsd
}
