import React, { useEffect } from 'react'

const useAppDebounceEffect = (effect: React.EffectCallback, time = 300, deps?: React.DependencyList) => {
    useEffect(() => {
        let callback: undefined | ReturnType<typeof effect>;
        const timer = setTimeout(() => {
            console.log('calling...')
            callback = effect()
        }, time)
        return () => {
            clearTimeout(timer)
            callback && callback()
        }
    }, deps)
}

export default useAppDebounceEffect
