import { DEREX_SUBGRAPH } from 'app-constants'
import { Ballot, LiquidityUserInfo, SupportedNetworks } from 'types'
import { OrderDirection } from 'types/subgraphs'
import { createClient, GetBallots, GetPair, GetPairsWithLiquidityPositionsByIds, GetUserWithLpPositionsByAccount } from '../graphql/index'

type FetchUserWithLpPositionsRes = {
  users: LiquidityUserInfo[]
}
type Pair = {
  lpAddress: string;
  reserve0: string;
  reserve1: string;
  token0: {
    address: string;
    name: string;
    symbol: string;
    decimals: string;
  }
  token1: {
    address: string;
    name: string;
    symbol: string;
    decimals: string;
  }
}
type FetchPairRes = {
  pair: Pair
}
// DEREX subgraph helper
class BSwapSubgraph {
  SubGraphClient

  constructor(_chainId: SupportedNetworks) {
    this.SubGraphClient = createClient(DEREX_SUBGRAPH[_chainId])
  }

  loadBallots = async (first: number, skip: number, where: undefined | any, orderBy: keyof Ballot | undefined = undefined, order: OrderDirection = 'asc') => {
    return await this.SubGraphClient.query({
      query: GetBallots,
      variables: {
        first,
        skip,
        order,
        orderBy,
        where,
      },
    })
  }

  fetchUserWithLpPositions = async (account: string) => {
    return await this.SubGraphClient.query<FetchUserWithLpPositionsRes>({
      query: GetUserWithLpPositionsByAccount,
      variables: {
        account: account.toLowerCase(),
      },
    })
  }

  fetchPairsLPByIds = async (ids: string[]) => {
    return await this.SubGraphClient.query({
      query: GetPairsWithLiquidityPositionsByIds,
      variables: {
        id_in: ids,
      },
    })
  }
  fetchPair = async(lpAddress: string) =>{
    return await this.SubGraphClient.query<FetchPairRes>({
      query: GetPair,
      variables: {
        lpAddress: lpAddress.toLowerCase(),
      },
    })
  }
}

export default BSwapSubgraph
