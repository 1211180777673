import styled from 'styled-components'

export const CustomTabs2 = styled.div`
  .react-tabs__tab-list {
    margin: 15px 0px 0px;
    padding: 0px;
    list-style-type: none;
    display: flex;
    align-items: center;
    li {
      padding: 15px 0px;
      text-align: center;
      margin: 0px;
      width: 50%;
      border-bottom: 3px solid ${(props) => props.theme.greyBorder};
      &.footer-white {
        border-bottom: 3px solid white;
      }
      cursor: pointer;
      position: relative;
      font-size: 12px;
      font-weight: bold;
      color: ${(props) => props.theme.colorWhite};
      :after {
        display: none;
      }
      &.react-tabs__tab--selected,
      &.active {
        color: ${(props) => props.theme.colorPrimary};
        border-bottom: 3px solid ${(props) => props.theme.colorPrimary};
      }
    }
  }
`

export const SquareTabs = styled.div`
  .react-tabs__tab-list {
    margin: 15px 0px 0px;
    padding: 0px;
    list-style-type: none;
    display: flex;
    align-items: center;
    li {
      padding: 20px 0px;
      text-align: center;
      margin: 0px;
      width: 50%;
      border-top: 4px solid transparent;
      border-bottom: 1px solid ${({ theme }) => theme.greyBorder};
      cursor: pointer;
      position: relative;
      font-size: ${({ theme }) => theme.fontSize['2md']};
      font-weight: bold;
      color: ${({ theme }) => theme.colorLightGrey};
      text-transform: uppercase;

      :after {
        display: none;
      }
      &.react-tabs__tab--selected,
      &.active {
        color: ${({ theme }) => theme.colorPrimary};
        border-radius: 8px 8px 0 0;
        border-top: 4px solid ${({ theme }) => theme.colorPrimary};
        border-left: 1px solid ${({ theme }) => theme.greyBorder};
        border-right: 1px solid ${({ theme }) => theme.greyBorder};

        border-bottom: unset;
      }
    }
  }
`

export const CustomTabs = styled.div`
 .react-tabs__tab-list {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  align-items: center;
  
  li{
    margin: 0px 15px;
    cursor: pointer;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    color: ${(props) => props.theme.colorWhite};
    
    :first-child{
      margin-left: 0px;
    }
    :last-child{
      :after{
        display: none;
      }
    }
    :after{
      content:'';
      height:100%;
      width:1px;
      background-color:${(props) => props.theme.grayBorder2};
      position:absolute;
      top:0;
      right:-15px;
    }
    &.react-tabs__tab--selected {
      color: ${(props) => props.theme.colorPrimary};
    }
    &.react-tabs__tab--selected.active-danger {
      color: ${(props) => props.theme.colorRed};
    }
  }
`
