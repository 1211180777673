import React from 'react'
import { ImSpinner9 } from 'react-icons/im'
import type {  IconBaseProps } from 'react-icons'

export function LoadingSpinner({ ...props }: IconBaseProps): JSX.Element {
  return <ImSpinner9 className='rotate-infinite-1' {...props} />
}


function AppLoadingSpinner({ ...props }: React.HTMLAttributes<HTMLDivElement>): JSX.Element {
  return (
    <div className='p-10 text-center' {...props}>
      <LoadingSpinner />
    </div>
  )
}

export default AppLoadingSpinner
