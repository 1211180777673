import React, { useState, useCallback, useLayoutEffect } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useParams } from 'react-router-dom'
import Gs from 'Theme/globalStyles'
import { PageTitleSection } from 'Component/core/elements/others/styled'
import { MainDesc, MainTitle } from 'Component/core/elements/Text'
import { CustomTabs2 } from 'Component/core/elements/Tabs'

import BondHoldersTabContent from './Tabs/BondHoldersTabContent'
import ProjectTabContent from './Tabs/ProjectTabContent'
import TradersTabContent from './Tabs/TradersTabContent'
import SupportingBlockchains from 'Component/Supports/SupportingBlockchains'
import BondSubgraph, { RewardToken } from 'helper/subgraphs/BondSubgraph'
import { useAppChain } from 'hooks'
import useAppDebounceEffect from 'hooks/useAppDebounceEffect'
import { getTokenPrices } from 'helper/apis/bond-server-api'
import { RewardTokenPrices, RoiPageContext } from './Contexts'

type PageRouterParam = {
  tabId: string
}

function RoiBoosterPageIndex() {
  const { tabId } = useParams<PageRouterParam>()

  const [tab, setTab] = useState(0);
  const { appChainId } = useAppChain();
  const [rewardTokens, setRewardTokens] = useState<RewardToken[]>([]);
  const [rewardTokenPrices, setRewardTokenPrices] = useState<RewardTokenPrices>({});

  const fetchAllRewardTokens = useCallback(async() => {
    let allRewardTokens: RewardToken[] = []
    const subgraph = new BondSubgraph(appChainId);
    const first = 1000;
    let skip = 0;
    const length = 1000;
    let hasNext = false;
    do {
      const res = await subgraph.getRewardTokens(undefined, first, skip);
      allRewardTokens = allRewardTokens.concat(res.data.rewardTokens.slice())
      skip += length;
      hasNext = length === res.data.rewardTokens.length;
    } while (hasNext);
    setRewardTokens(allRewardTokens.slice());
  }, [appChainId])

  useAppDebounceEffect(() => {
    fetchAllRewardTokens()
  }, 300, [fetchAllRewardTokens]);
  
  useAppDebounceEffect(() => {
    (async() =>{
      const tokenIds = rewardTokens.map((t) => t.address.toLocaleLowerCase());
      const _rewardTokens: { [key in string]: RewardToken } = {}
      rewardTokens.forEach((t) => {
        _rewardTokens[t.address.toLocaleLowerCase()] = t;
      })
      if(tokenIds.length === 0) {
        return setRewardTokenPrices({})
      }
      const res = await getTokenPrices({ networkId: appChainId, tokenIds: tokenIds })
      const result: RewardTokenPrices = {};
      res.data.tokenPrices.forEach((token) => {
        const addr = token.address.toLowerCase()
        result[addr] = {
          decimals: _rewardTokens[addr]?.decimals || '18',
          symbol: token.symbol,
          usd: token.usd,
        }
      })
      setRewardTokenPrices(Object.assign({}, result))
    })()
  }, 300, [rewardTokens, appChainId]);

  useLayoutEffect(() => {
    setTab(+tabId)
  }, [])

  const handleOnSelectTab = (index: number) => {
    setTab(index)
  }

  return (
    <Gs.Container className='pb-20'>
      <RoiPageContext.Provider value={{ rewardTokenPrices: rewardTokenPrices }}>
        <PageTitleSection className='d-flex flex-column align-items-center'>
          <MainTitle className='mb-0 fs-6xl'>ROI BOOSTER</MainTitle>
          <MainDesc className='pl-0 mt-5 mb-30 fs-2md'>
            Maximize your profits with incentives rewards and airdrops
          </MainDesc>
        </PageTitleSection>
        <div>
          <CustomTabs2>
            <Tabs selectedIndex={tab} onSelect={handleOnSelectTab}>
              <TabList>
                <Tab className={`fs-md footer-white ${tab === 0 ? 'active' : ''}`}>
                  for Bond Holders
                </Tab>
                <Tab className={`fs-md  footer-white ${tab === 1 ? 'active' : ''}`}>
                  for LP Holders
                </Tab>
                <Tab className={`fs-md footer-white ${tab === 2 ? 'active' : ''}`}>
                  for Projects
                </Tab>
                <Tab className={`fs-md footer-white ${tab === 3 ? 'active' : ''}`}>
                  for Traders
                </Tab>
              </TabList>
              <div className='Bond_Tab_List'>
                <TabPanel><BondHoldersTabContent /></TabPanel>
                <TabPanel></TabPanel>
                <TabPanel><ProjectTabContent /></TabPanel>
                <TabPanel><TradersTabContent /></TabPanel>
              </div>
            </Tabs>
          </CustomTabs2>
        </div>
        <div className='pt-10 pb-10 mb-10 relative z-index-10'>
          <SupportingBlockchains />
        </div>
      </RoiPageContext.Provider>
    </Gs.Container>
  )
}

export default RoiBoosterPageIndex
