import { LOCAL_STORAGE_ITEM_NAMES } from 'app-constants'
import { loadStateFromLocalStorage, removeStateFromLocalStorage, saveStateToLocalStorage } from 'helper/localStorage'
import { CoinGeckoReducerState } from 'types'
import { ActionCoingecko, ActionTypes } from './types'

const initialState: CoinGeckoReducerState = {
  simplePrices: {},
}

function loadValuesFromLocal(): CoinGeckoReducerState {
  const localValues: CoinGeckoReducerState = loadStateFromLocalStorage(LOCAL_STORAGE_ITEM_NAMES.COIN_GECKO);
  if (Object.getOwnPropertyNames(localValues).includes('simplePrices'))
    return localValues;
  removeStateFromLocalStorage(LOCAL_STORAGE_ITEM_NAMES.COIN_GECKO)
  return initialState;
}

export default function coingeckoReducer(state: CoinGeckoReducerState = loadValuesFromLocal() || initialState, action: ActionCoingecko): CoinGeckoReducerState {
  let newState: CoinGeckoReducerState, simplePrices;
  switch (action.type) {
    case ActionTypes.RESET:
      return initialState

    case ActionTypes.INIT_TOKEN_PRICE:
      simplePrices = state.simplePrices;
      newState = { ...state, simplePrices: action.payload }
      saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.COIN_GECKO, newState)
      return newState

    case ActionTypes.SET_TOKEN_PRICE:
      simplePrices = state.simplePrices;
      newState = { ...state, simplePrices: { ...simplePrices, ...action.payload } }
      saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.COIN_GECKO, newState)
      return newState
    default:
      return state
  }
}
