import { EventEmitter } from 'events'
import web3Js from 'web3'
import { ethers } from 'ethers'

import stakingTokenAbi from '../abi/stakingTokenAbi.json'
import tokenAbi from '../abi/tokenAbi.json'
import constantConfig from '../config/constantConfig'
import { Web3Provider } from '@ethersproject/providers'
import { ReceiptCb, SupportedNetworks, TxCb } from 'types'
import { toWei } from 'web3-utils'

class StakingContract extends EventEmitter {
  web3
  networkId
  stakingAddress
  stakingInstance: ethers.Contract

  constructor(web3: Web3Provider, networkId: SupportedNetworks) {
    super()
    this.web3 = web3
    this.networkId = networkId
    this.stakingAddress = constantConfig[networkId].stakingContract
    // @ts-ignore TODO need to check web3.getSigner(0)
    this.stakingInstance = new ethers.Contract(this.stakingAddress, stakingTokenAbi, web3.getSigner(0))
  }

  async getApprovedTokenForStaking(userAddress: string, tokenAddress: string, stakingContractAddress: string): Promise<string> {
    const web3 = this.web3
    // @ts-ignore
    const tokenInstance = new ethers.Contract(tokenAddress, tokenAbi, web3.getSigner(0))
    const balance = await tokenInstance.allowance(userAddress, stakingContractAddress)
    return web3Js.utils.fromWei(balance._hex)
  }

  async approveJNTRTokenForSwapFactory(tokenAddress: string, stakingContractAddress: string, approveAmount: string, txCb: TxCb, receiptCb: ReceiptCb): Promise<void> {
    const web3 = this.web3
    // @ts-ignore web3.getSigner
    const tokenInstance = new ethers.Contract(tokenAddress, tokenAbi, web3.getSigner(0))
    // console.log("approveAmount", approveAmount);
    const highApproval = toWei(approveAmount)
    const payload = await tokenInstance.populateTransaction.approve(stakingContractAddress, highApproval)
    this.sendTransaction(payload.data as string, 0, '150000', tokenAddress, txCb, receiptCb)
  }

  async sendTransaction(payload: string, value: number, gasLimit: string, to: string, txCb: TxCb, receiptCb: ReceiptCb) {
    let gasPrice = '0'
    if (this.networkId === 56 || this.networkId === 97) gasPrice = '20'
    else {
      const response = await fetch('https://ethgasstation.info/json/ethgasAPI.json')
      const json = await response.json()
      gasPrice = (json.fast / 10).toString()
    }

    const tx = {
      to: to,
      data: payload,
      gasPrice: web3Js.utils.toHex(web3Js.utils.toWei(gasPrice, 'gwei')),
      gasLimit: web3Js.utils.toHex(gasLimit),
      value: web3Js.utils.toHex(value),
    }

    this.web3
      .getSigner(0)
      .sendTransaction(tx)
      .then((result) => {
        txCb(result.hash)
        result.wait().then(async (receipt) => {
          receiptCb(receipt)
        })
      })
      .catch((error) => {
        console.log(error);
      })
  }

  async getOptions() {
    // let payload = await this.stakingInstance.getOptions()
  }

  async stakeToken(optionId: string, amount: string, txCb: TxCb, receiptCb: ReceiptCb) {
    const payload = await this.stakingInstance.populateTransaction.createOrder(optionId, amount)
    this.sendTransaction(payload.data as string, 0, '350000', this.stakingAddress, txCb, receiptCb)
  }

  async withdraw(withdrawId: string, txCb: TxCb, receiptCb: ReceiptCb) {
    const payload = await this.stakingInstance.populateTransaction.withdraw(withdrawId)
    this.sendTransaction(payload.data as string, 0, '350000', this.stakingAddress, txCb, receiptCb)
  }

  async withdrawWithoutReward(withdrawId: string, txCb: TxCb, receiptCb: ReceiptCb) {
    const payload = await this.stakingInstance.populateTransaction.withdrawWithoutReward(withdrawId)
    this.sendTransaction(payload.data as string, 0, '350000', this.stakingAddress, txCb, receiptCb)
  }

  async launchPool(token: string, amount: string, stackTokens: string, period: string, rate: string, txCb: TxCb, receiptCb: ReceiptCb) {
    const payload = await this.stakingInstance.populateTransaction.createBonus(token, amount, stackTokens, period, rate)
    this.sendTransaction(payload.data as string, 0, '3000000', this.stakingAddress, txCb, receiptCb)
  }

  handleActions(action: any) {
    switch (action.type) {
      default:
        break
    }
  }
}

export default StakingContract
