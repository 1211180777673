import { LPInsureReducerParams, TokenParamsWithBalance, InvestmentLimitParams, VestingPeriodParams, LPInsureTabs, DexInfo, TokenBalance } from "types"

export enum ActionTypes {
  SET_PARAMETERS = 'LP-INSURE/SET_PARAMETERS',
  SET_TOKEN = 'LP-INSURE/SET_TOKEN',
  SET_DEX = 'LP-INSURE/SET_DEX',
  SET_TOKEN_INFO = 'LP-INSURE/SET_TOKEN_INFO',
  SET_TOKEN_RATIO = 'LP-INSURE/SET_TOKEN_RATIO',
  SET_PAIR_TOKEN = 'LP-INSURE/SET_PAIR_TOKEN',
  SET_VESTING_PERIOD = 'LP-INSURE/SET_VESTING_PERIOD',
  SET_INVESTMENT_LIMIT_ENABLE = 'LP-INSURE/SET_INVESTMENT_LIMIT_ENABLE',
  SET_INVESTMENT_LIMIT = 'LP-INSURE/SET_INVESTMENT_LIMIT',
  SET_PREPAYMENT_PENALTY = 'LP-INSURE/SET_PREPAYMENT_PENALTY',
  SET_TAB = 'LP-INSURE/SET_TAB',
  IS_ADDING_PROJECT_INFO = 'LP-INSURE/IS_ADDING_PROJECT_INFO',
  RESET = 'LP-INSURE/RESET',
}

interface ActionSetParams {
  type: ActionTypes.SET_PARAMETERS
  payload: LPInsureReducerParams
}
interface ActionSetToken {
  type: ActionTypes.SET_TOKEN
  payload: string
}
interface ActionSetTokenInfo {
  type: ActionTypes.SET_TOKEN_INFO
  payload: TokenBalance
}
interface ActionSetTokenRatio {
  type: ActionTypes.SET_TOKEN_RATIO
  payload: number
}

interface ActionSetDex {
  type: ActionTypes.SET_DEX
  payload: DexInfo
}

interface ActionSetPairToken {
  type: ActionTypes.SET_PAIR_TOKEN
  payload: TokenParamsWithBalance
}

interface ActionSetVestingForPeriod {
  type: ActionTypes.SET_VESTING_PERIOD
  payload: VestingPeriodParams
}
interface ActionSetInvestmentLimitState {
  type: ActionTypes.SET_INVESTMENT_LIMIT_ENABLE
  payload: boolean
}
interface ActionSetInvestmentLimit {
  type: ActionTypes.SET_INVESTMENT_LIMIT
  payload: InvestmentLimitParams
}
interface ActionSetPrepaymentPenalty {
  type: ActionTypes.SET_PREPAYMENT_PENALTY
  payload: boolean
}
interface ActionSetTab {
  type: ActionTypes.SET_TAB
  payload: LPInsureTabs
}
interface ActionIsAddingProjectInfo {
  type: ActionTypes.IS_ADDING_PROJECT_INFO
  payload: boolean
}
interface ActionReset {
  type: ActionTypes.RESET
}

export type ActionLPInsure
  = ActionSetParams | ActionSetToken | ActionSetTokenInfo | ActionSetTokenRatio
  | ActionSetPairToken | ActionSetInvestmentLimit
  | ActionSetVestingForPeriod | ActionSetPrepaymentPenalty | ActionSetTab
  | ActionIsAddingProjectInfo | ActionReset | ActionSetDex | ActionSetInvestmentLimitState