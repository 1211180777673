import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import Gs from '../Theme/globalStyles'
import CloseIcon from '../Assets/images/close-icon.png'
import { FlexDiv, ModalBox } from './core/elements/others/global-components'
import Media from 'Theme/media-breackpoint'

interface IModalProps extends React.HTMLAttributes<HTMLDivElement> {
  isOpen: boolean
  dismiss: () => void
  hideBTN?: boolean
}

export default function Modal({ children, isOpen, dismiss, hideBTN = false, className = '', ...props }: IModalProps) {
  const containerRef = useRef(null)
  const onClickContainer = useCallback(
    (e: any) => {
      if (containerRef.current === e.target && typeof dismiss === 'function') {
        // Click outside of content.
        dismiss()
      }
    },
    [containerRef, dismiss]
  )

  return (
    <PopupMainBx className={`z-index-200 modal ${className}`} style={{ display: `${isOpen ? 'block' : 'none'}` }} {...props}>
      {!hideBTN && <SwapPopupClose onClick={dismiss} />}
      <Gs.Container ref={containerRef} onClick={onClickContainer}>
        {children}
      </Gs.Container>
    </PopupMainBx>
  )
}

const PopupMainBx = styled.div`
  width: 100%;
  min-height: 100vh;
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0px;
  top: 0;
  bottom: 0;
  background-color: rgb(22 25 30 / 95%);
  overflow-y: auto;
  overflow-x: hidden;
  &.bg-none {
    background-color: unset;
  }

  ${Gs.Container} {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`

const SwapPopupClose = styled.a`
  position: absolute;
  top: 40px;
  right: 50px;
  cursor: pointer;
  display: block;
  height: 27px;
  width: 27px;
  transition: 0.5s ease all;
  background: url(${CloseIcon}) 50% 50% no-repeat;
  :hover {
    transform: rotate(180deg);
  }
  display: none;
`

export const ModalMainDiv = styled(ModalBox)`
  max-width: 431px;
  margin: 0 auto;
  ${Media.xs} {
    margin: 0px 15px;
    max-width: 100%;
    width: auto;
  }
`

export const ModalTitle = styled(FlexDiv)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0 12px 0;
  position: relative;
`

export const ModalBody = styled(FlexDiv)`
  width: 100%;
  padding: 30px 30px 10px 30px;
`

export const ModalSecondaryTitle = styled(ModalTitle)`
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  &.overFlowHidden {
    overflow: hidden;
  }

  & button {
    border: 0px;
    outline: none;
    background-color: transparent;
    color: #fff;

    :hover {
      opacity: 0.7;
    }
  }
`

export const PPsBx01 = styled(FlexDiv)`
  width: 100%;
  padding: 30px 30px 10px 30px;
`
export const PPstitle01 = styled(FlexDiv)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0 12px 0;
  position: relative;
  color: white;
`
export const PopInput01 = styled.input`
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  line-height: normal;
  background-color: transparent;
  border: 2px solid #545861;
  width: 100%;
  padding: 11px 8px;
  border-radius: 10px;
  margin: 10px 0;
`

export const PPstitle02 = styled(PPstitle01)`
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  // border-bottom: #3c3f46 1px solid;
  &.overFlowHidden {
    overflow: hidden;
  }

  & button {
    border: 0px;
    outline: none;
    background-color: transparent;
    color: #fff;

    :hover {
      opacity: 0.7;
    }
  }
`