import styled from "styled-components";
import { AppButton } from "Component/core/elements/Buttons";

export const CliamAllButton = styled(AppButton)`
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.fontSize.md};
  margin-right: 17px;

  min-width: calc(calc(calc(min(100vw, 1420px) - 20px - 48px) / 3) - 36px)
`
