import React from 'react'
import styled from 'styled-components'
import { ImgBx, TokenBalanceSpan } from 'Component/core/elements/others/global-components'
import { BsPinAngleFill, BsPinAngle } from 'react-icons/bs'
import TokenIcon from 'Component/TokenIcon'
import Media from 'Theme/media-breackpoint'
import fromExponential from 'from-exponential'

interface IPPTokenListItemProps extends React.HTMLAttributes<HTMLButtonElement>{
  symbol: string
  tokenAddress: string
  pined?: boolean
  changeIn24: number
  balance?: number | string
  onClickPined?: React.MouseEventHandler<HTMLOrSVGElement>
  onClickRemovePin?: React.MouseEventHandler<HTMLOrSVGElement>
} 

function PPTokenListItem({ symbol, tokenAddress, balance, pined = false, changeIn24, onClickPined, onClickRemovePin, ...props }: IPPTokenListItemProps) {
  return (
    <TokenListItem {...props}>
      <div className='d-flex align-items-center w-50p'>
        {pined ? <BsPinAngleFill onClick={onClickPined} />: <BsPinAngle onClick={onClickRemovePin} />}
        <ImgBx className='ml-10'>
          <TokenIcon byAddress={tokenAddress} alt='' />
        </ImgBx>
        <TokenLabel>{symbol}</TokenLabel>
      </div>
      <div className='w-full text-end d-flex justify-content-end'>
        <PPListTokenBalanceDiv>{balance && <TokenBalanceSpan title={fromExponential(balance)} digits={2}>{balance}</TokenBalanceSpan>}</PPListTokenBalanceDiv>
      </div>
      <div className='d-flex justify-content-end'>
        <PPList24HrsChanged className={`${changeIn24 > 0 ? 'text-primary' : 'text-danger'}`}>
          {Math.abs(changeIn24)}%
        </PPList24HrsChanged>
      </div>
    </TokenListItem>
  )
}

export default PPTokenListItem

const TokenLabel = styled.span`
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${Media.md} {
    max-width: 70px;
  }
`

const TokenListItem = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  color: #fff;
  padding: 15px 10px 15px 30px;

  :hover {
    background-color: ${(props) => props.theme.selectedTokenBg};
    ${TokenLabel} {
      color: ${(props) => props.theme.colorPrimary};
    }
  }
  &.active {
    background-color: ${(props) => props.theme.selectedTokenBg};
    border-color: #1f2127;
  }
  &:disabled,
  &[disabled] {
    // color: ${(props) => props.theme.colorGrey4};
    color: gray;
    cursor: not-allowed;
  }
`

const PPListTokenBalanceDiv = styled.div`
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${Media.md} {
    max-width: 50px;
  }
`

const PPList24HrsChanged = styled.div`
  min-width: 100px;
  margin-right: 10px;
  text-align: right;
  ${Media.md} {
    min-width: 100px;
  }
  ${Media.sm} {
    min-width: 80px;
  }
`
