import React, { useMemo, useState, useCallback } from 'react'
import { FaSort } from 'react-icons/fa'
import moment from 'moment'
import { BsQuestionCircleFill } from 'react-icons/bs'
import { CgCloseO } from 'react-icons/cg'
import { BiCheck } from 'react-icons/bi'

import PrincipalRedeem from 'Component/popup/PrincipalRedeem'
import { SDataTable, useTableStyles } from 'Component/core/elements/DataTable'
import ProfitRedeem from 'Component/popup/ProfitRedeem'
import { SimplePriceSelector } from 'redux/selectors'
import { TokenBalanceSpan } from 'Component/core/elements/others/global-components'
import { isEqAddr, smartSymbol } from 'helper'
import useAppSnackbar, { AppWaringOptions } from 'hooks/useAppSnackbar'
import { MainTitle } from 'Component/core/elements/Text'
import { useAppChain, useFailedInfoModal, usePriceBySymbol, useProcessingModal } from 'hooks'
import { MainTokenSymbols } from 'app-constants'
import useWallet from 'hooks/useWallet'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import useAppDebounceEffect from 'hooks/useAppDebounceEffect'
import { decimalAmountToExactAmount, simpleAmountInString } from 'helper/math'
import LPInsureSubgraph from 'helper/subgraphs/LPInsureSubgraph'
import { SupportedChains } from 'app-constants/conToken'
import TokenIcon from 'Component/TokenIcon'
import { balanceOf, getRewards, getUserAmounts } from 'helper/sc-utils/insure-sc-utils'
import { harvest, redeemInsure } from 'redux/reducers/lp-insure-list/actions'
import { AppButton } from 'Component/core/elements/Buttons';
import styled from 'styled-components'
import { GlobalDexes } from 'config/app-config'

const SClaimButton = styled(AppButton)`
  padding-top: 2px;
  padding-bottom: 2px;
  max-width: 200px;
`
type TableRowData = {
  no                      : number;
  offerId                 : string | number;
  classId                 : string;
  nonceId                 : string;
  tokenSymbol             : string;

  exchange                : {
    name: string
    icon: string
    site: string
  };
  pairs                   : string;
  LPUSD                   : string | number;
  yourPoolShare           : string | number;
  pairTokenDecimals       : string | number;
  pairTokenBalance        : string | number;
  pairTokenUSD            : string | number;
  pairTokenSymbol         : string;
  protectedTokenBalance   : string | number;
  protectedTokenSymbol    : string;
  protectedTokenUSD       : string | number;
  yourInvestment          : {
    tokenAmount: string | number;
    pairTokenAmount: string | number;
  };
  yourRoi                 : string  | number; // number in 2 decimals
  startDate               : string  | number; // number in 2 decimals
  cliffDays               : string  | number; // number in 2 decimals
  userAmount: {
    principalAmount: string | number
    availableAmount: string | number
  },
  insurance               : {
    amount      : string | number;
    amountUSD   : string | number;
  };
  claimFarming            : JSX.Element | any;
}

interface IRedeemElementProps {
  delayTimeStamp  : string | number;
  amount          : string | number;
  onClickRedeem   : () => void;
}

function RedeemElement ({ onClickRedeem, delayTimeStamp, amount }: IRedeemElementProps) {
  const today = moment(new Date());
  const delayDate = moment(new Date(+delayTimeStamp * 1e3));
  const days = delayDate.diff(today, 'days');
  if(days > 0) {
    return (
      <p className='text-danger text-center mb-0 fw-bold'>{days} lock<br /> days remaining</p>
    )
  } else if(+amount > 0) {
    return (
      <button className='hover:text-danger' onClick={onClickRedeem}>
        <CgCloseO size={25} />
      </button>
    )
  } else {
    return (
      <div className='d-flex align-items-center text-muted'>
        <BiCheck size={25} /> <span>Redeemed</span>
      </div>
    )
  }
}

interface IYourSecureFloorLaunchPoolsProps {
  onClickConnectWallet: () => void
}

function YourSecureFloorLaunchPools({ onClickConnectWallet }: IYourSecureFloorLaunchPoolsProps): JSX.Element {
  const { appChainId } = useAppChain()
  const { account, active } = useWallet()
  const appDispatch = useAppDispatch()
  const [openSnap] = useAppSnackbar(AppWaringOptions)
  const baseTokenPriceInUsd = usePriceBySymbol(MainTokenSymbols[appChainId])
  const simplePrice = useAppSelector(SimplePriceSelector);
  const conntedtedNetwork = useMemo(() => SupportedChains.find(item => item.chainId === appChainId), [appChainId])
  const [openTRLoadingModal, closeTRLoadingModal] = useProcessingModal()
  const [openFailedModal] = useFailedInfoModal()

  const [isOpenPrincipalModal, setIsOpenPrincipalModal] = useState(false)
  const [isOpenProfitModal, setIsOpenProfitModal] = useState(false)
  const [redeemStatus, setRedeemStatus] = useState({
    offerId: '',
    classId: '',
    nonceId: '',
    amount: 0,
    pairTokenDecimals: '18',
    pairTokenSymbol: '',
  })
  const [tableData, setTableData] = useState<TableRowData[]>([])
  // todo
  const [totalInvestment, setTotalInvestment] = useState<string | number>(0)
  // todo
  const [totalProfit, setTotalProfit] = useState<string | number>(0)
  const roi = useMemo(() => {
    if (totalInvestment === 0 || totalProfit === 0) {
      return 0
    }
    return (+totalProfit / +totalInvestment) * 100
  }, [totalInvestment, totalProfit])

  const customStyles = useTableStyles()
  const handleOnClickRedeem = useCallback(async(rowData: TableRowData, type: 'ProfitRedeem' | 'PrincipalRedeem') => {
    const bondBalance = await balanceOf(appChainId, account as string, rowData.classId, rowData.nonceId);
    const amount = decimalAmountToExactAmount(bondBalance, 18);
    if(type === 'PrincipalRedeem') { // redeeem principal
      setIsOpenPrincipalModal(true)
    } else { // redeeem profit
      setIsOpenProfitModal(true)
    }
    setRedeemStatus({
      offerId: rowData.offerId as string,
      classId: rowData.classId as string,
      nonceId: rowData.nonceId as string,
      amount: amount,
      pairTokenDecimals: rowData.pairTokenDecimals as string,
      pairTokenSymbol: rowData.pairTokenSymbol,
    })
  }, [appChainId, account])

  const handleOnSubmitRedeem = useCallback(async(amount: string | number) => {
    try {
      openTRLoadingModal()
      const bnAmount = simpleAmountInString(amount, redeemStatus.pairTokenDecimals)
      await appDispatch(redeemInsure(redeemStatus.classId, redeemStatus.nonceId, bnAmount));
      setIsOpenProfitModal(false)
      setIsOpenPrincipalModal(false)
    } catch (error: unknown) {
      console.log(error);
      // @ts-ignore
      openFailedModal(error?.message || error)
    } finally {
      closeTRLoadingModal()
    }
  }, [redeemStatus, openTRLoadingModal, closeTRLoadingModal])

  const hanldeOnClickClaim = useCallback(async(e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const _classIds: string[] = [e.currentTarget.dataset.classid as string];
      openTRLoadingModal('Claim reward tokens....')
      const rewards = await getRewards(appChainId, _classIds, account as string);
      const hasNoRewards = rewards.every((reward) => {
        return +reward.amount === 0
      });
      if(hasNoRewards) return openSnap('No rewards to claim!')
      await appDispatch(harvest(_classIds))
    } catch (error: unknown) {
      console.log(error)
      // @ts-ignore
      const errorMessage = error.message || error.toString();
      openFailedModal(errorMessage)
    } finally {
      closeTRLoadingModal()
    }
  }, [appChainId, account])

  const columns = useMemo(
    () => [
      {
        name: 'No',
        // width: '7%',
        minWidth: '30px',
        maxWidth: '60px',
        cell: (row: TableRowData) => {
          return <span>{row.no}</span>
        },
      },
      {
        name: (
          <div className='d-flex align-items-center justify-content-between w-full'>
            <span>Blockchain</span>
            <FaSort />
          </div>
        ),
        minWidth: '30px',
        maxWidth: '110px',
        center: true,
        cell: () => {
          return (
            <div className='d-flex align-items-center'>
              <TokenIcon src={conntedtedNetwork?.image} />
              <span className='ml-5'>{conntedtedNetwork?.name}</span>
            </div>
          )
        },
      },
      {
        name: (
          <div className='d-flex align-items-center justify-content-between w-full'>
            <span>Exchange</span>
            <FaSort />
          </div>
        ),
        minWidth: '30px',
        maxWidth: '110px',
        center: true,
        cell: (row: TableRowData) => {
          return (
            <a className='d-flex align-items-center' href={row.exchange.site} target='_blank' rel='noreferrer'>
              <TokenIcon width={25} height={25} src={row.exchange.icon} />
              <span className='pl-2'>{row.exchange.name}</span>
            </a>
          )
        },
      },
      {
        name: (
          <div className='d-flex align-items-center justify-content-between w-full'>
            <span>Pairs</span>
            <FaSort />
          </div>
        ),
        cell: (row: TableRowData) => {
          return (
            <span className='text-nowrap' title={row.offerId as string}>{row.pairs}</span>
          )
        },
      },
      {
        name: (
          <div className='d-flex justify-content-between align-items-center w-full'>
            <span>
              Liquidity pool
            </span>
            <FaSort />
          </div>
        ),
        minWidth: '100px',
        maxWidth: '120px',
        cell: (row: TableRowData) => {
          return <TokenBalanceSpan prefix='$' digits={4}>{row.LPUSD}</TokenBalanceSpan>
        },
      },
      {
        name: (
          <div className='d-flex justify-content-between align-items-center w-full'>
            <span className='text-nowrap'>
              Your pool<br /> share
            </span>
            <FaSort />
          </div>
        ),
        minWidth: '80px',
        maxWidth: '100px',
        cell: (row: TableRowData) => {
          return <TokenBalanceSpan digits={2} suffix='%'>{row.yourPoolShare}</TokenBalanceSpan>
        },
      },
      {
        name: (
          <div className='d-flex justify-content-between align-items-center w-full'>
            <span>
              <span>
                Pair token<br /> balance
              </span>
            </span>

            <FaSort />
          </div>
        ),
        cell: (row: TableRowData) => {
          return (
            <div>
              <TokenBalanceSpan suffix={` ${row.pairTokenSymbol}`}>{row.pairTokenBalance}</TokenBalanceSpan><br />
              <span className='text-muted'>[<TokenBalanceSpan prefix='$'>{row.pairTokenUSD}</TokenBalanceSpan>]</span>
            </div>
          )
        },
      },
      {
        name: (
          <div className='d-flex justify-content-between align-items-center w-full'>
            <span>
              Protected token balance
            </span>
            <FaSort />
          </div>
        ),
        cell: (row: TableRowData) => {
          return (
            <div>
              <TokenBalanceSpan suffix={` ${row.protectedTokenSymbol}`}>{row.protectedTokenBalance}</TokenBalanceSpan><br />
              <span className='text-muted'>[<TokenBalanceSpan prefix='$'>{row.protectedTokenUSD}</TokenBalanceSpan>]</span>
            </div>
          )
        },
      },
      {
        name: (
          <div className='d-flex justify-content-between align-items-center w-full'>
            <span>
              Your investment
              <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
            </span>
            <FaSort />
          </div>
        ),
        cell: (row: TableRowData) => {
          return (
            <div>
              <TokenBalanceSpan suffix={` ${row.tokenSymbol}`}>{row.yourInvestment.tokenAmount}</TokenBalanceSpan><br />
              <span className='text-muted'>[<TokenBalanceSpan suffix={` ${row.pairTokenSymbol}`}>{row.yourInvestment.pairTokenAmount}</TokenBalanceSpan>]</span>
            </div>
          )
        },
      },
      {
        name: (
          <div className='d-flex justify-content-between align-items-center w-full'>
            <span>
              Your ROI
              <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
            </span>
            <FaSort />
          </div>
        ),
        cell: (row: TableRowData) => {
          return <span className='text-primary'>{row.yourRoi}%</span>
        },
      },
      {
        name: (
          <div className='d-flex justify-content-between align-items-center w-full'>
            <span>
              Insurance
              <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
            </span>
            <FaSort />
          </div>
        ),
        cell: (row: TableRowData) => {
          return (
            <div>
              <TokenBalanceSpan suffix={` ${row.pairTokenSymbol}`}>{row.insurance.amount || 0}</TokenBalanceSpan><br />
              <span className='text-muted'>[<TokenBalanceSpan prefix='$'>{row.insurance.amountUSD || 0}</TokenBalanceSpan>]</span>
            </div>
          )
        },
      },
      {
        name: (
          <div className='text-nowrap'>
            <span>
              Redeem principal
            </span>
            <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
          </div>
        ),
        minWidth: '100px',
        maxWidth: '150px',
        center: true,
        cell: (row: TableRowData) => {
          return (
            <RedeemElement 
              amount={row.userAmount.availableAmount}
              delayTimeStamp={+row.startDate + +row.cliffDays}
              onClickRedeem={() => handleOnClickRedeem(row, 'PrincipalRedeem')} 
            />
          )
        },
      },
      {
        name: (
          <div className='text-nowrap'>
            Redeem profits
            <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
          </div>
        ),
        center: true,
        cell: (row: TableRowData) => {
          const profit = +row.userAmount.availableAmount - +row.userAmount.principalAmount
          return (
            <RedeemElement
              amount={profit}
              delayTimeStamp={+row.startDate + +row.cliffDays}
              onClickRedeem={() => handleOnClickRedeem(row, 'ProfitRedeem')} 
            />
          )
        },
      },
      {
        name: (
          <div className='text-nowrap'>
            Claim farming
          </div>
        ),
        center: true,
        cell: (row: TableRowData) => {
          return (
            <SClaimButton
              className='outline-black hover:outline-primary fs-md text-white fw-normal radius-3 text-uppercase'
              data-classid={row.classId}
              onClick={hanldeOnClickClaim}
            >
              Claim
            </SClaimButton>
          )
        },
      },
    ],
    [baseTokenPriceInUsd, conntedtedNetwork, handleOnClickRedeem, hanldeOnClickClaim]
  );

  useAppDebounceEffect(() => {
    (async() => {
      if(account && appChainId) {
        const insureClient = new LPInsureSubgraph(appChainId)
        const { data } = await insureClient.getInsureBondsByUser(account);
        const classIds: string[] = [];
        const nonceIds: string[] = [];
        for (let i = 0; i < data.user.insureBonds.length; i++) {
          const insurebond = data.user.insureBonds[i];
          classIds.push(insurebond.classId);
          nonceIds.push(insurebond.nonceId);
        }
        try {
          const userAmounts = await getUserAmounts(appChainId, classIds, nonceIds, account);
          let totalProfitUSD = 0;
          let totalInvestmentUSD = 0;

          setTableData(data.user.insureBonds.map((insureBond, index) => {
            const avlUserAmt = userAmounts.availableAmount[index] || 0;
            const prpUserAmt = userAmounts.principalAmount[index] || 0;
            const insurePool = insureBond.offer;
            const token0USD = simplePrice[`${insurePool.poolParams.poolPair.token0.address}`.toLowerCase()]?.priceUSD || 0;
            const token1USD = simplePrice[`${insurePool.poolParams.poolPair.token1.address}`.toLowerCase()]?.priceUSD || 0;
            const tokenUSD = simplePrice[`${insurePool.token.address}`.toLowerCase()]?.priceUSD || 0;
            const pairTokenUSD = simplePrice[`${insurePool.pairToken.address}`.toLowerCase()]?.priceUSD || 0;
            let pairTokenBalance = insurePool.poolParams.poolPair.reserve0;
            const lpTotalSupply = insurePool.poolParams.poolPair.totalSupply;
            const lpBalance = insurePool.poolParams.poolPair.liquidityPositions[0]?.liquidityTokenBalance || 0;
            const investmentPosition = insurePool.investmentPositions[0];

            const tokenSymbol = smartSymbol(insurePool.token.symbol);
            const pairSymbol = smartSymbol(insurePool.pairToken.symbol);

            // @ts-ignore
            const protectedTokenBalance = decimalAmountToExactAmount(insurePool.parameters.supplyAmount - insurePool.poolParams.spentTokens, insurePool.token.decimals) || 0;
            const insuranceAmt = decimalAmountToExactAmount(insurePool.totalInsurance, insurePool.pairToken.decimals)
            let pairTokenAmount = 0
            let tokenAmount = 0
      
            if(investmentPosition) {
              pairTokenAmount = decimalAmountToExactAmount(investmentPosition.pairAmount, insurePool.pairToken.decimals)
              tokenAmount = decimalAmountToExactAmount(investmentPosition.tokenAmount, insurePool.token.decimals)
            }
      
            if (isEqAddr(insurePool.poolParams.poolPair.token1.address, insurePool.pairToken.address)) {
              pairTokenBalance = insurePool.poolParams.poolPair.reserve1
            }

            const profit = +avlUserAmt - +prpUserAmt;
            totalProfitUSD += (+pairTokenUSD * decimalAmountToExactAmount(profit, insurePool.pairToken.decimals))
            totalInvestmentUSD += (+pairTokenUSD * tokenAmount);
            const yourRoi = +prpUserAmt > 0 ? (profit / +prpUserAmt) * 100 : 0;

            const dex = Object.values(GlobalDexes).find(dex => isEqAddr(dex.router, insureBond.offer.parameters.router));

            return {
              no: index + 1,
              offerId: insureBond.offer.id,
              classId: insureBond.classId,
              nonceId: insureBond.nonceId,
              exchange: {
                name: dex?.name || 'Unknow',
                icon: dex?.icon,
                site: dex?.site || 'Unknow'
              }, // todo
              pairs: `${tokenSymbol} <> ${pairSymbol}`,
              yourPoolShare: +lpBalance / +lpTotalSupply * 100,
              // @ts-ignore
              LPUSD: token0USD * insurePool.poolParams.poolPair.reserve0 + token1USD * insurePool.poolParams.poolPair.reserve1,
              pairTokenDecimals: insureBond.offer.pairToken.decimals,
              pairTokenBalance: pairTokenBalance,
              // @ts-ignore
              pairTokenUSD: pairTokenBalance * pairTokenUSD,
              tokenSymbol: tokenSymbol,
              pairTokenSymbol: pairSymbol,
              protectedTokenBalance: protectedTokenBalance,
              protectedTokenSymbol: tokenSymbol,
              // @ts-ignore
              protectedTokenUSD: tokenUSD * protectedTokenBalance,
              yourInvestment: {
                pairTokenAmount: pairTokenAmount,
                tokenAmount: tokenAmount,
              },
              yourRoi: yourRoi.toFixed(2),
              startDate: insurePool.parameters.startDate,
              cliffDays: insurePool.parameters.vestingParam.cliffDays,
              userAmount: {
                principalAmount: decimalAmountToExactAmount(prpUserAmt, insurePool.pairToken.decimals),
                availableAmount: decimalAmountToExactAmount(avlUserAmt, insurePool.pairToken.decimals),
              },
              insurance: {
                amount: insuranceAmt,
                // @ts-ignore
                amountUSD: insuranceAmt * pairTokenUSD,
              },
              claimFarming: '123',
            } as TableRowData
          }));
          setTotalInvestment(totalInvestmentUSD)
          setTotalProfit(totalProfitUSD)
        } catch (error) {
          console.log(error)
          setTotalInvestment(0)
          setTotalProfit(0)
          // @ts-ignore
          openSnap(`Can't get the principal and profit info. reason: ${error.message || error}`)
        }
      } else {
        setTableData([])
        setTotalInvestment(0)
        setTotalProfit(0)
      }
    })()
  }, 200, [appChainId, account, simplePrice])

  return (
    <React.Fragment>
      <MainTitle className='fs-2xl text-capitalize'>Your Secure Floor Launchpools</MainTitle>
      <div className='d-flex text-primary justify-content-center my-30'>
        <h2 className='fw-bold highlighted-border-right pr-30'>
          Total investment: $<TokenBalanceSpan digits={2}>{totalInvestment}</TokenBalanceSpan>
        </h2>
        <h2 className='fw-bold highlighted-border-right px-30'>
          Total profits: $<TokenBalanceSpan digits={2}>{totalProfit}</TokenBalanceSpan>
        </h2>
        <h2 className='fw-bold pl-30'>ROI: {roi}%</h2>
      </div>
      <div className='mb-20'>
        {
          active ? (
            // @ts-ignore
            <SDataTable
              columns={columns}
              data={tableData}
              customStyles={customStyles}
              theme='solarized'
            />
          ) : (
            <div className='ConnectWallet d-flex justify-content-center align-items-center w-full'>
              <AppButton className='outline-gray w-max-500' onClick={onClickConnectWallet}>
                Connect Wallet
              </AppButton>
            </div>
          )
        }
      </div>
        <>
          <PrincipalRedeem
            isOpen={isOpenPrincipalModal}
            amount={redeemStatus.amount}
            tokenSymbol={redeemStatus.pairTokenSymbol}
            onSubmit={handleOnSubmitRedeem}
            dismiss={() => setIsOpenPrincipalModal(false)}
          />
          <ProfitRedeem
            isOpen={isOpenProfitModal}
            amount={redeemStatus.amount}
            tokenSymbol={redeemStatus.pairTokenSymbol}
            onSubmit={handleOnSubmitRedeem}
            dismiss={() => setIsOpenProfitModal(false)}
          />
        </>
    </React.Fragment>
  )
}

export default YourSecureFloorLaunchPools
