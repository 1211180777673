import { createTheme } from '@mui/material/styles'
import type { ThemeOptions } from '@mui/material/styles'

const theme: ThemeOptions = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#8be05a',
      dark: '#4e5056',
    },
  },
})

export default theme
