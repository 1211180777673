import React from 'react'
import styled from 'styled-components'

const Tracker = styled.span`
  position: absolute;
  top: 5px;
  width: calc(50% - 5px);
  height: calc(100% - 10px);
  background-color: #545861;
  border-radius: 10px;
  -moz-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  -webkit-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
`

const EMView = styled.em`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  border-radius: 10px;
  font-size: 14px;
  line-height: 40px;
  font-weight: 500;
  font-style: normal;
  border: 2px solid #545861;
  -moz-transition: background 0.15s ease-out;
  -o-transition: background 0.15s ease-out;
  -webkit-transition: background 0.15s ease-out;
  transition: background 0.15s ease-out;
`

const CheckBox = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
`

const MTtkncheBX = styled.label`
  position: relative;
  text-transform: capitalize;
  ${CheckBox}[type='radio'],
  ${EMView}:before {
    content: attr(data-off);
  }
  ${EMView}:before,
  ${EMView}:after {
    transition: opacity 0.15s ease-out;
    margin: 0px 10px;
  }
  ${EMView}:after {
    content: attr(data-on);
  }
  ${CheckBox}:checked ~ em {
    border-color: ${({ theme }) => theme.colorPrimary};
  }
  ${CheckBox} ~ ${Tracker} {
    left: 5px;
  }
  ${CheckBox}:checked ~ ${Tracker} {
    background: ${({ theme }) => theme.colorPrimary};

    right: 5px;
    left: unset;
  }

  &.white {
    color: white;
  }
  &.danger {
    ${CheckBox}:checked ~ em {
      border-color: ${({ theme }) => theme.greyBorder};
    }
    ${CheckBox}:checked ~ ${Tracker} {
      background: ${({ theme }) => theme.colorRed};
    }
  }
`

interface IToggleButtonProps extends React.HTMLAttributes<HTMLInputElement> {
  className?: string
  checked?: boolean
  readOnly?: boolean
  label: string[]
}

function ToggleButton({ className, label: [left, right], ...props }: IToggleButtonProps) {
  return (
    <MTtkncheBX className={className}>
      <CheckBox type='checkbox' name='shortcode[active]' value='1' data-depend-id='active' data-atts='active' {...props} />
      <EMView className='z-index-2' data-on={left || 'on'} data-off={right || 'off'}></EMView>
      <Tracker className='z-index-1'></Tracker>
    </MTtkncheBX>
  )
}

export default ToggleButton
