import Subgraph from './base'
import { BondSubgraphs } from 'app-constants'
import { BondData, SupportedNetworks } from 'types'
import { createClient, GetBondBuyers, GetBonds, GetBondsForFarms, GetFarms, GetRewardTokens } from '../graphql/index'
import { Farm } from 'types/subgraphs/farms'
import { GetBondsForFarm } from 'types/subgraphs/bonds'

type GetFarms = {
  farms: Farm[]
}
export type RewardToken = {
  address: string
  name: string
  symbol: string
  decimals: string
}
type GetBondsRes = {
  bonds: BondData[]
}
type GetRewardTokenRes = {
  rewardTokens: RewardToken[]
}
type GetBondsForFarmsRes = {
  bonds: GetBondsForFarm[]
}
// DEREX subgraph helper
class BondSubgraph  extends Subgraph{
  SubGraphClient

  constructor(_chainId: SupportedNetworks) {
    super();
    this.SubGraphClient = createClient(BondSubgraphs[_chainId])
  }

  getBonds = async (where: undefined | any = undefined, first = 1000, skip = 0, initialPositionUser: string | undefined = undefined ) => {
    return await this.SubGraphClient.query<GetBondsRes>({
      query: GetBonds,
      variables: {
        first,
        skip,
        where: where,
        initialPositionUser,
      },
    })
  }

  getBondsForFarms = async (where: undefined | any = undefined, first = 1000, skip = 0 ) => {
    return await this.SubGraphClient.query<GetBondsForFarmsRes>({
      query: GetBondsForFarms,
      variables: {
        first,
        skip,
        where: where
      },
    })
  }

  getFarms = async (where: undefined | any = undefined, first = 1000, skip = 0 ) => {
    return await this.SubGraphClient.query<GetFarms>({
      query: GetFarms,
      variables: {
        first,
        skip,
        where: where
      },
    })
  }

  getRewardTokens = async (where: undefined | any = undefined, first = 1000, skip = 0 ) => {
    return await this.SubGraphClient.query<GetRewardTokenRes>({
      query: GetRewardTokens,
      variables: {
        first,
        skip,
        where: where
      },
    })
  }

  getBondBuyers = async (where: undefined | any = undefined) => {
    return await this.SubGraphClient.query({
      query: GetBondBuyers,
      variables: {
        where: where,
      },
    })
  }
}

export default BondSubgraph
