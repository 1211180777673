import { useAppSelector } from 'redux/hooks'
import { ImportedPoolsSelector } from 'redux/selectors'
import { Pool } from 'types'

// export const usePools = (appChainId) => {
//   const poolsOnChain = farms[appChainId]
//   if (typeof poolsOnChain === 'undefined') {
//     throw new Error('Cant find Pools.')
//   }
//   const availablePools = poolsOnChain.filter((pool) => pool.token)
//   return availablePools
// }

export const useUserAddedPools = (): Pool[] => {
  const { list } = useAppSelector(ImportedPoolsSelector)
  return list
}
