export enum ActionTypes {
  SetIBO_NeverShow        = 'Tour/SetIBO_NeverShow',
  SetDEREX_NeverShow      = 'Tour/SetDEREX_NeverShow',
  SetLPInsure_NeverShow   = 'Tour/SetLPInsure_NeverShow',
  SetOpenDEREXTour        = 'Tour/SetOpenDEREXTour',
  SetOpenIBOTour          = 'Tour/SetOpenIBOTour',
  SetOpenLPInsureTour     = 'Tour/SetOpenLPInsureTour',
}

export interface ActionIBONeverShow {
  type: ActionTypes.SetIBO_NeverShow
  payload: boolean
}

export interface ActionDEREXNeverShow {
  type: ActionTypes.SetDEREX_NeverShow
  payload: boolean
}

export interface ActionLPInsureNeverShow {
  type: ActionTypes.SetLPInsure_NeverShow
  payload: boolean
}

export interface ActionOpenDEREXTour {
  type: ActionTypes.SetOpenDEREXTour
  payload: boolean
}

export interface ActionOpenIBOTour {
  type: ActionTypes.SetOpenIBOTour
  payload: boolean
}

export interface ActionOpenLPInsureTour {
  type: ActionTypes.SetOpenLPInsureTour
  payload: boolean
}

export type ActionTour = ActionIBONeverShow | ActionDEREXNeverShow 
  | ActionOpenDEREXTour | ActionOpenIBOTour 
  | ActionOpenLPInsureTour | ActionLPInsureNeverShow
