export const saveStateToLocalStorage = (_name: string, _value: string | number | string[] | number[] | any): void => {
  if(typeof _value === 'object' || Array.isArray(_value)) {
    localStorage.setItem(_name, JSON.stringify(_value))
  } else {
    localStorage.setItem(_name, _value)
  }
}
export const loadStateFromLocalStorage = (_name: string): string | any[] | any => {
  const value = localStorage.getItem(_name) || ''
  try {
    return JSON.parse(value)
  } catch (error) {
    return value
  }
}

export const removeStateFromLocalStorage = (key: string): void =>  {
  localStorage.removeItem(key);
}
