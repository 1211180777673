import * as S from './styled'

function PageBanner({ ...props }) {
  return (
    <S.PageBannerWrapper {...props}>
      <div>
        <S.PageBannerTitle className='fs-md fw-bold mt-25'>&quot;Decentralized regulations <br /> can be a game changer&quot;</S.PageBannerTitle>
      </div>
      <S.PageBannerBox2 className='d-flex flex-column align-items-center'>
        <img src='/images/wonderful-1.png' alt='Kevin OLeary aka' />
        <S.Description className='text-muted fs-sm pt-20'>Kevin O&apos;Leary aka Mr. Wonderful Invester - Shark Tank</S.Description>
      </S.PageBannerBox2>
    </S.PageBannerWrapper>
  )
}

export default PageBanner

export function PageBannerInnovation({ ...props }) {
  return (
    <S.PageBannerWrapper {...props}>
      <S.InnovationBox>
        <img src='/images/wonderful-2.png' alt='Kevin OLeary aka' />
        <S.InnovationTitle className='text-primary fs-lg fw-bold mt-25'>&quot;This is innovation!&quot;</S.InnovationTitle>
        <S.InnovationDescription className='text-muted'>
          Kevin O&apos;Leary aka Mr. Wonderful <br/> Invester - <span className='text-nowrap'>Shark Tank</span>
        </S.InnovationDescription>
      </S.InnovationBox>
    </S.PageBannerWrapper>
  )
}


export function LPPageBannerInnovation({ ...props }) {
  return (
    <S.PageBannerWrapper {...props}>
      <S.LPInnovationBox>
        <img src='/images/lp-insure/main-image.png' alt='Kevin OLeary aka' />
      </S.LPInnovationBox>
    </S.PageBannerWrapper>
  )
}
