import { useCallback } from 'react'
import styled from 'styled-components';
import Media from 'Theme/media-breackpoint';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { fetchBonds as fetchBondList, setSearch } from 'redux/reducers/bond-list/actions'
import { useAppChain } from 'hooks';
import { ModalBox } from 'Component/core/elements/others/styled';
import { PPClosBTN01 } from 'Component/core/elements/Buttons';
import CustomScrollbars from 'Component/core/elements/CustomScrollbars'
import Modal, { PPsBx01, PPstitle01, PPstitle02, PopInput01 } from '../Modal'
import { BondListSelector } from 'redux/selectors';
import TokenIcon from 'Component/TokenIcon';
import { LoadingSpinner } from 'Component/AppLoadingSpinner';
import { BondData } from 'types';
import useAppDebounceEffect from 'hooks/useAppDebounceEffect';

interface ISelectBondPopupProps {
  isOpen: boolean;
  dismiss: () => void;
  onSelect: (bond: BondData) => void;
}

function SelectBondPopup({ isOpen, dismiss, onSelect }: ISelectBondPopupProps) {
  const { appChainId } = useAppChain()
  const dispatch = useAppDispatch()
  const fetchBonds = useCallback(() => {
    dispatch(fetchBondList(appChainId))
  }, [appChainId]);
  const { list: bonds, isLoading, search } = useAppSelector(BondListSelector)

  useAppDebounceEffect(() => {
    fetchBonds()
  }, 400, [fetchBonds])

  const handleOnChangeBondSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(e.target.value))
    dispatch(fetchBondList(appChainId))
  }

  const handleOnClickBond = (_bond: BondData) => {
    onSelect(_bond);
  }

  return (
    <Modal isOpen={isOpen} dismiss={dismiss}>
      <PPMainBx>
        <PPsBx01 className='position-relative'>
          <PPClosBTN01 className='position-absolute right-10 top-10' onClick={dismiss} />
          <PPstitle01 className='d-flex justify-content-between align-items-center'>
            <h4>Select Bond</h4>
          </PPstitle01>
          {/* todo update search by text */}
          <PopInput01 placeholder='Search by project name/token address...' value={search} onChange={handleOnChangeBondSearch}/>
        </PPsBx01>
        <PPstitle02 className='pl-30'>
          <span>Bonds</span>
        </PPstitle02>
        <CustomScrollbars
          height='320px'
          width='100%'
          style={{ position: 'relative' }}
          scrollDirection='left'
          trackClass='black'
          renderView={(props: any) => <div {...props} className='view scrollbar-none pl-30' />}
        >
          <div className='d-flex flex-column pb-20 pt-5'>
            {
              isLoading ? (
                <div className='d-flex justify-content-center mt-40'>
                  <LoadingSpinner />
                </div>
              ) : (
                bonds.map((bond) => {
                  return (
                    <SBondCard key={bond.viewId} onClick={() => handleOnClickBond(bond)}>
                      <TokenIcon
                        className='rounded-circle bg-white'
                        src={bond.info?.logo}
                        byAddress={bond.paramerters.token.address}
                        alt={`Logo-${bond.paramerters.token.address}`}
                        style={{ width: 30, height: 30 }}
                      />
                      <span className='pl-10'>{bond.paramerters.token.symbol}-{bond.paramerters.pairToken.symbol}</span>
                      <span className='pl-10 text-muted'>(#{bond.viewId})</span>
                    </SBondCard>
                  )
                })
              )
            }
          </div>
        </CustomScrollbars>
      </PPMainBx>
    </Modal>
  )
}

const PPMainBx = styled(ModalBox)`
  max-width: 450px;
  min-width: 450px;
  margin: 0 auto;
  ${Media.lg} {
    min-width: 400px;
  }
  ${Media.md} {
    min-width: auto;
  }
  ${Media.xs} {
    width: auto;
    margin: 0px 15px;
    max-width: 100%;
  }
`
const SBondCard = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colorPrimary};
  }
`

export default SelectBondPopup
