import gql from 'graphql-tag'

export type GetInsureInvestorsType = {
    lpinvestmentHistories: {
      id: string
      investor: {
        user: string;
      }
      paidValue: string
      timestamp: string
    }[]
}

export const GetInsureInvestors = gql`
  query GetInsureInvestors($where: LPInvestmentHistory_filter) {
    lpinvestmentHistories(where: $where) {
      id
      investor {
        user: id
      }
      paidValue
      timestamp
    }
  }
`

export type UserInsureBond = {
  id: string
  classId: string
  nonceId: string

  offer: {
    id: string
    transactionHash: string
    totalInsurance: string
    creator: {
      account: string
    }
    token: {
      address: string
      symbol: string
      decimals: string
    }
    pairToken: {
      address: string
      symbol: string
      decimals: string
    }
    investmentPositions: {
      pairAmount: string | number
      tokenAmount: string | number
    }[]
    parameters: {
      router: string
      supplyAmount: string | number
      startDate: string
      vestingParam: {
        cliffDays: string
      }
    }
    poolParams: {
      poolPair: {
        lpAddress: string
        reserve0: string
        reserve1: string
        totalSupply: string
        token0: {
          address: string
        }
        token1: {
          address: string
        }
        liquidityPositions: {
          liquidityTokenBalance: string | number
        }[]
      }
    }
  }
}

export type GetUserInsureBondsType = {
  user: {
    account: string
    insureBonds: UserInsureBond[]
  }
}

export const GetUserInsureBonds = gql`
  query GetUserInsureBonds($account: ID!) {
    user(id: $account) {
      account: id
      insureBonds {
        id
        classId
        nonceId

        offer {
          id
          transactionHash
          totalInsurance
          creator {
            account: id
          }
          token {
            address: id
            symbol
            decimals
          }
          pairToken {
            address: id
            symbol
            decimals
          }
          investmentPositions(where: { user: $account }) {
            pairAmount
            tokenAmount
          }
          parameters {
            router
            supplyAmount
            startDate
            vestingParam {
              cliffDays
            }
          }
          poolParams {
            poolPair {
              lpAddress: id
              reserve0
              reserve1
              totalSupply

              token0 {
                address: id
              }
              token1 {
                address: id
              }

              liquidityPositions(where: { user: $account }) {
                liquidityTokenBalance
              }
            }
          }
        }
      }
    }
  }
`
export type InsureFarm = {
  id: string
  classId: string
  farmId: string | number
  rewardToken: {
    address: string
    name: string
    symbol: string
    decimals: string
  }
  toDumperShield: boolean
  rewardPerSecond: string
  period: string | number
  timestamp: string | number
}
export type GetInsureFarms = {
  insureFarms: InsureFarm[]
}
export const GetInsureFarmsQL = gql`
  query GetInsureFarmsQL($where: InsureFarm_filter) {
    insureFarms {
      id
      classId
      farmId
      rewardToken {
        address: id
        name
        symbol
        decimals
      }
      rewardPerSecond
      toDumperShield
      period
      timestamp
    }
  }
`