import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { TourProvider } from '@reactour/tour'
import ChainChecker from 'Component/ChainChecker'
import LPInsureAbout from 'Pages/LP-Insure/LP-InsureAbout'
import LPInsureHeader from 'Component/Headers/LPInsureHeader'
import { Divider } from 'Component/core/elements/Divider';

import { ControlButton, LPInsureTourSteps } from 'Component/tours';
import LPInsureFooter from 'Component/Footers/LPInsureFooter'

// pages
import LPInsurePools from 'Pages/LP-Insure/LP-InsurePools'
import LPInsurePoolDetail from 'Pages/LP-Insure/LP-Insure-Pool-Detail'
import LPInsureLaunchPool from 'Pages/LP-Insure/LP-InsureLaunchPool/LP-InsureLaunchPool'
import FarmingRewardsPage from 'Pages/LP-Insure/FarmingRewards/SelfCustodyFarmingPage'
import RewardsBondHoldersPage from 'Pages/LP-Insure/FarmingRewards/RewardsBondHoldersPage'

function LPInsureRouter(props: RouteComponentProps): JSX.Element {
  const parentRouter = props.match.path;

  return (
    <div>
      <LPInsureHeader />
      <section id='app-section'>
        <Switch>
          <Route path={parentRouter} exact component={() => <Redirect to={'/lp/pools'} />} />
          <Route path={`${parentRouter}/pools`} exact>
            <ChainChecker>
              <TourProvider
                steps={LPInsureTourSteps}
                // showNavigation={false}
                showDots={false}
                showCloseButton={false}
                showBadge={false}
                position='left'
                nextButton={({ setCurrentStep, currentStep, stepsLength, setIsOpen }) => {
                  const handleOnClickNext = () => {
                    const nextStep = currentStep + 1
                    if (stepsLength === nextStep) {
                      setIsOpen(false);
                    } else {
                      setCurrentStep(nextStep);
                    }
                  }
                  return (
                    <ControlButton className='active d-flex align-items-center' onClick={handleOnClickNext}>
                      <Divider className='horizontal h-min-10  px-0 mx-5 highlighted-border-right' />
                      <div>Next</div>
                    </ControlButton>
                  )
                }}
                prevButton={({ setCurrentStep, currentStep, setIsOpen }) =>
                  <div className='d-flex align-items-center'>
                    <ControlButton onClick={() => setIsOpen(false)}>I got it, let&apos;s start</ControlButton>
                    <Divider className='horizontal h-min-10  px-0 mx-5 highlighted-border-right' />
                    <ControlButton disabled={currentStep === 0} onClick={() => setCurrentStep(currentStep - 1)}>
                      <div>Previous</div>
                    </ControlButton>
                  </div>
                }
                onClickMask={({ currentStep, steps, setCurrentStep, setIsOpen }) => {
                  const nextStep = currentStep + 1;
                  steps?.length && nextStep < steps.length && setCurrentStep(nextStep)
                  steps?.length && nextStep === steps.length && setIsOpen(false)
                }}
              >
                <LPInsurePools />
              </TourProvider>
            </ChainChecker>
          </Route>
          <Route path={`${parentRouter}/pools/:poolId`} exact>
            <ChainChecker>
              <LPInsurePoolDetail />
            </ChainChecker>
          </Route>
          <Route path={`${parentRouter}/launch-pool`} exact>
            <ChainChecker>
              <LPInsureLaunchPool />
            </ChainChecker>
          </Route>
          <Route path={`${parentRouter}/about`} exact>
            <ChainChecker>
              <LPInsureAbout />
            </ChainChecker>
          </Route>
          <Route path={`${parentRouter}/farming-rewards/self-custody-farming`} exact>
            <ChainChecker>
              <FarmingRewardsPage />
            </ChainChecker>
          </Route>
          <Route path={`${parentRouter}/farming-rewards/reward-bond-holders`} exact>
            <ChainChecker>
              <RewardsBondHoldersPage />
            </ChainChecker>
          </Route>
        </Switch>
        <LPInsureFooter />
      </section>
    </div>
  )
}

export default LPInsureRouter