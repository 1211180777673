import { Ballot, VotesReducerState } from 'types'
import { ActionTypes, VoteAction } from './types'

export const initialState: VotesReducerState = {
  isLoading: false,
  selectedBallotId: '', // type id-pairAddress
  isLoadMoreActivedBallots: false,
  isLoadMoreCompletedBallots: false,
  activedBallots: [],
  completedBallots: [],
}

export default function Reducer(state = initialState, action: VoteAction): VotesReducerState {
  let activedBallots: Array<Ballot>, completedBallots: Array<Ballot>

  switch (action.type) {
    case ActionTypes.IS_LOADING:
      return { ...state, isLoading: true }

    case ActionTypes.IS_LOADED:
      return { ...state, isLoading: false }

    case ActionTypes.LOAD_VOTES:
      return { ...state, activedBallots: action.payload.activedBallots, completedBallots: action.payload.completedBallots }

    case ActionTypes.IS_LOAD_MORE_ACTIVED_BALLOTS:
      return { ...state, isLoadMoreActivedBallots: action.payload }

    case ActionTypes.LOAD_MORE_ACTIVED_VOTES:
      activedBallots = state.activedBallots
      return { ...state, activedBallots: [...activedBallots, ...action.payload] }

    case ActionTypes.IS_LOAD_MORE_COMPLETED_BALLOTS:
      return { ...state, isLoadMoreCompletedBallots: action.payload }

    case ActionTypes.LOAD_MORE_COMPLETED_VOTES:
      completedBallots = state.completedBallots
      return { ...state, completedBallots: [...completedBallots, ...action.payload] }

    case ActionTypes.SELECT_BALLOT:
      return { ...state, selectedBallotId: action.payload }

    case ActionTypes.RESET:
      return initialState

    default:
      return state
  }
}
