import { EnvType } from 'types'

const { REACT_APP_CHANNEL_ID } = process.env

export const getTelegramChannelName = () => {
  if (REACT_APP_CHANNEL_ID) {
    const name = REACT_APP_CHANNEL_ID
    name.replace(/@/gi, '')
    return name
  } else {
    return null
  }
}

export const getEnvType = (): EnvType => {
  return EnvType.production
}
