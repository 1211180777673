import { BsCircleFill } from 'react-icons/bs'
import { PageTitleSection } from 'Component/core/elements/others/global-components'
import { MainTitle } from 'Component/core/elements/Text'
import Gs from 'Theme/globalStyles'
import { Divider } from 'Component/core/elements/Divider'
import { AppButton } from 'Component/core/elements/Buttons'
import { SBondsContent, SBondsImg, SBondsWrap, SExampleList } from 'Component/core/elements/IBO-SharedComponents'
import AppLink from 'Component/AppLink'
import { AppRouters } from 'app-constants/app-routers'
import SupportingBlockchains from 'Component/Supports/SupportingBlockchains'

function IBOAbout(): JSX.Element {
  return (
    <Gs.Container className='pb-30'>
      <div>
        <div>
          <PageTitleSection>
            <MainTitle className='text-start text-center mb-0'>
              Launch a secure floor launchpool for &nbsp;
              <span className='text-primary'>free</span>
            </MainTitle>
          </PageTitleSection>
        </div>
        <div className='mt-30 lh-base'>
          <p>
            DEREX Exchange has created a Secure Floor solution to ensure no financial loss for those who invest in its liquidity pools. 
          </p>
          <p className='mt-30'>
            Projects wishing to launch a pool on the DEREX Exchange can set a base price for their tokens, which becomes a Secure Floor. This Secure Floor ensures that the token price cannot fall below this base value. When the price hits the floor, a smart contract is triggered, which activates a circuit breaker. This reduces slippage to 0%, avoiding further dumping and protecting the baseline price.
          </p>
          <p className='mt-30'>
            Traditionally, DEXs require LP investors to provide a pair of tokens to the liquidity pool. One token might be commonly valued, like USDT or ETH, and the other could be the project&apos;s token. However, if the project&apos;s token crashes, the investor might lose their USDT or ETH and, in effect, their entire investment. This risk often causes investors to back out when the market or the token&apos;s performance is uncertain. This creates a barrier to entry during certain market conditions and requires LP investors to conduct deep due diligence on each pool, which can be sophisticated, leaving many projects with low liquidity or causing panic withdrawals from the LP.
          </p>
          <p className='mt-30'>
          To encourage investors to add liquidity to new projects in all market conditions and reduce panic withdrawals, DEREX Exchange has designed a new model that guarantees no financial risk for investors. This approach removes the risk of the token value falling, while investors still profit from any increases. So, investors face no risk, even if the project fails.
          </p>
        </div>
        <div className='mt-30 lh-base'>
          <h3 className='text-primary fw-bold'>How it works</h3>
          <p>
            Projects need to launch their Initial Bond Offering (IBO) by setting their pool regulation and adding their project tokens. This process will create a bond that will be used as an intermediate vehicle for the launchpool. When investors want to add liquidity to the pool, they will add only the paired token (like USDT or ETH) to the bond. The bond will then push the project token and the paired tokens to the pool to generate LP tokens. Once the bond receives these LP tokens from the pool, it will hold them until investors order the bond to return them to the pool for cash-out.
          </p>
        </div>
        <div className='mt-30 lh-base'>
          <h3 className='text-primary fw-bold'>Protection</h3>
          <p>
            In order to protect the pool from abuse by LP investors, the smart contract allows LP investors to only withdraw their initial investment and any profits made from the pool. The remaining project tokens are either returned to the project or get burned. This ensures that no investor from the pool receives project tokens, thereby eliminating the risk of token dumping.
          </p>
        </div>
        <div className='mt-30 lh-base'>
          <h3 className='text-primary fw-bold'>New project benefits</h3>
          <p>
            The Secure Floor solution empowers new projects to attract LP investors under any market circumstances. Whether the market is bullish or bearish, whether the project has an outstanding whitepaper or none at all, the Secure Floor removes the necessity for investors to predict the project&apos;s success.
          </p>
        </div>
        <div className='mt-30 lh-base'>
          <h3 className='text-primary fw-bold'>LP Benefits</h3>
          <p>
            LPs have the opportunity to distribute liquidity across all new projects on DEREX Exchange, with the added prospect of becoming early investors in potentially successful projects. This advantage comes without the substantial risk of project failure or fraudulent activity. Furthermore, LPs who join after the Secure Floor is established still enjoy the safety of the same baseline price protection as those who invested early.
          </p>
        </div>
        <div className='mt-30 lh-base'>
          <h3 className='text-primary fw-bold'>Secure Floor plus Circuit Breakers</h3>
          <p>
            LPs have the opportunity to distribute liquidity across all new projects on DEREX Exchange, with the added prospect of becoming early investors in potentially successful projects. This advantage comes without the substantial risk of project failure or fraudulent activity. Furthermore, LPs who join after the Secure Floor is established still enjoy the safety of the same baseline price protection as those who invested early.
          </p>
        </div>
        <div className='mt-30 lh-base'>
          <h3 className='text-primary fw-bold'>Lock-Up Periods</h3>
          <p>
            Projects that launch pools have the ability to set the contract to lock either LP primary funds or only the profits for a specified period before they can be withdrawn. This measure ensures stability in the pool, even during times when a token is booming and LPs&apos; profits are skyrocketing. However, setting these redemption timelines is not a mandatory requirement for projects.
          </p>
        </div>
        <div className='mt-30'>
          <h2 className='text-primary fw-bold'>Example</h2>
          <SExampleList>
            <div>
              <BsCircleFill size={10} />
              <p>Project ABC opens a pool with a ratio of 1 ABC tokens to 0.01 USDT. The ABC / USDT pool forms a Secure Floor of $0.01.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p>The project provides 10,000,000 ABC tokens to launchpool contract while the LP investors provide $100,000 USDT.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p>Once the 10,000,000 ABC token and $100,000 USDT are pushed to the pool, the launchpool contract receives LP tokens worth $200,000.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p>When users utilize the pool and send an extra $100,000 of USDT to buy the ABC token, the amount of tokens in the pool reduces by 10% and the amount of USDT increases by 200%.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p>Assuming LPs own 10% of the pool and those LPs want out, the LPs can trigger a redemption call at any time in the launchpool contract.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p>If called, $20,000 of USDT (equal to 10%) is paid directly to the LP.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p>990,000 ABC tokens (equal to 10%) return to the project or are burned.</p>
            </div>
          </SExampleList>
        </div>
        <div className='mt-20 fs-sm text-muted'>
          <p>NB. If the market crashes and token value drops to the secure floor ration, the 0% slippage triggers restricting the ability to dump and allowing only buy orders.</p>
        </div>
      </div>

      <Divider className='vertical my-50 highlighted-border-bottom' />

      <div className='row'>
        <div className='col-5'>
          <h2 className='fs-xl fw-bold'>How to launch a bond pool offering</h2>
          <div className='d-flex flex-column gap-4 mt-30 lh-base'>
            <div className='d-flex'>
              <div className='bg-bodybg02 px-10 py-3 h-fit fw-bold'>1</div>
              <div className='pl-10 mt-2 h-min-50 fs-md'>Set up your bond terms and discount</div>
            </div>
            <div className='d-flex'>
              <div className='bg-bodybg02 px-10 py-3 h-fit fw-bold'>2</div>
              <div className='pl-10 mt-2 h-min-50 fs-md'>Deposit your project tokens</div>
            </div>
            <div className='d-flex'>
              <div className='bg-bodybg02 px-10 py-3 h-fit fw-bold'>3</div>
              <div className='pl-10 mt-2 h-min-50 fs-md'>Drive your community to the initial bond offering pad to engage with the offering</div>
            </div>
            <div className='d-flex'>
              <div className='bg-bodybg02 px-10 py-3 h-fit fw-bold'>4</div>
              <div className='pl-10 mt-2 h-min-50 fs-md'>The bond uses the fundraising and deposited tokens to add liquidity to the pool</div>
            </div>
            <div className='d-flex'>
              <div className='bg-bodybg02 px-10 py-3 h-fit fw-bold'>5</div>
              <div className='pl-10 mt-2 h-min-50 fs-md'>If a liquidity does not exist, the smart contract creates a new pool based on the criteria set</div>
            </div>
            <div>
              <AppButton as={AppLink} to={AppRouters.IBO_Add_OFFERS_PAGE} className='mt-20 outline-primary text-primary hover:text-colorWhite text-uppercase'>launch your bond pool offering</AppButton>
            </div>
          </div>
        </div>
        <div className='col-2 position-relative'>
          <Divider className='horizontal mt-0 mb-10 highlighted-border-right position-absolute left-50p z-index-5' />
          <div className='d-flex flex-column align-items-center justify-content-center h-full z-index-10 position-relative'>
            <SBondsWrap className='bg-bodybg015 py-5'>
              <SBondsImg src='/images/DEREX-footer.png' alt='' />
              <SBondsContent className='text-primary fw-bold'>Bonds for new pools protected with 100% secure floor</SBondsContent>
            </SBondsWrap>
          </div>
        </div>
        <div className='col-5'>
          <h2 className='fs-xl fw-bold'>How to invest in initial bonds offering</h2>
          <div className='d-flex flex-column gap-4 mt-30 lh-base'>
            <div className='d-flex'>
              <div className='bg-bodybg02 px-10 py-3 h-fit fw-bold'>1</div>
              <div className='pl-10 mt-2 h-min-50 fs-md'>Select a bond to invest in</div>
            </div>
            <div className='d-flex'>
              <div className='bg-bodybg02 px-10 py-3 h-fit fw-bold'>2</div>
              <div className='pl-10 mt-2 h-min-50 fs-md'>Deposit the token amount and type the bond requires</div>
            </div>
            <div className='d-flex'>
              <div className='bg-bodybg02 px-10 py-3 h-fit fw-bold'>3</div>
              <div className='pl-10 mt-2 h-min-50 fs-md'>The bond uses fundraising and deposited tokens to add liquidity to the pool</div>
            </div>
            <div className='d-flex'>
              <div className='bg-bodybg02 px-10 py-3 h-fit fw-bold'>4</div>
              <div className='pl-10 mt-2 h-min-50 fs-md'>Engage with liquidity after the bond duration period expires or sell the bond beforehand using OTC</div>
            </div>
            <div className='d-flex'>
              <div className='bg-bodybg02 px-10 py-3 h-fit fw-bold'>5</div>
              <div className='pl-10 mt-2 h-min-50 fs-md'>Bond holders have voting power for the pool regulation</div>
            </div>
            <div>
              <AppButton as={AppLink} to={AppRouters.IBO_BONDS_PAGE} className='mt-20 outline-primary text-primary hover:text-colorWhite text-uppercase'>Select bond offerings to invest in</AppButton>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-50'>
        <SupportingBlockchains />
      </div>
    </Gs.Container>
  )
}

export default IBOAbout
