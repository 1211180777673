import styled from 'styled-components'

export const PaginationContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
`

export const PaginationActions = styled.div`
   {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
export const PaginationPageLabel = styled.span`
   {
    margin-right: 10px;
    font-size: 13px;
  }
`
