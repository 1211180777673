import React, { useMemo } from 'react'
import styled from 'styled-components'
import Modal, { PPstitle01, PPstitle02 } from '../Modal'
import Media from '../../Theme/media-breackpoint'
import { PPClosBTN01 } from 'Component/core/elements/Buttons'
import { FlexDiv, ModalBox } from 'Component/core/elements/others/global-components'
import AppTooltip from 'Component/AppTooltip'
import { FaildTransactionModalSelector } from 'redux/selectors'
import { useFailedInfoModal } from 'hooks'
import { useAppSelector } from 'redux/hooks'

export default function FailedMessagePopup() {
  const { header, message, isOpen } = useAppSelector(FaildTransactionModalSelector)
  const [, onClose] = useFailedInfoModal()

  const customMessage = useMemo(() => {
    let m = String(message);

    if(m.search(/transaction has been reverted by the EVM/gi) > -1) {
      return 'Transaction has been reverted by the EVM. Please try after 5 mins.'
    } else if(m.search(/transferhelper/gi) > -1) {
      return "There was a problem with the token. Make sure your token is not locked"
    } else if(m.search(/{/gi) > -1) {
      m = `{${m.split('{')[1]}`
      m = JSON.parse(m).message || m;
    }
    return m
  }, [message]);

  return (
    <Modal isOpen={isOpen} dismiss={() => onClose()} className='z-index-2000'>
      <PPMainBx>
        <PPsBx01>
          <PPstitle01>
            {header || 'Transaction failed'}
            {/* @ts-ignore */}
            <PPClosBTN01 className='right-0 top-0' onClick={onClose} />
          </PPstitle01>
          <PPstitle02>
            <span></span>
          </PPstitle02>

          <PPstitle02 className='text-break text-danger'>
            <pre>{customMessage}</pre>
          </PPstitle02>
        </PPsBx01>
      </PPMainBx>

      <AppTooltip />
    </Modal>
  )
}

const PPMainBx = styled(ModalBox)`
  max-width: 431px;
  margin: 0 auto;
  ${Media.xs} {
    margin: 0px 15px;
    max-width: 100%;
    width: auto;
  }
`

const PPsBx01 = styled(FlexDiv)`
  width: 100%;
  padding: 30px 30px 10px 30px;
`
