import fromExponential from 'from-exponential'
import { BigNumber } from "ethers";

// import { BigNumber as BN } from 'ethers'
// import { toBN } from 'web3-utils'
// /**
//  *
//  * @param {number | string | BN} amount
//  * @param {number | BN} decimals
//  * @returns
//  */
// export const simpleToExactAmount = (amount, decimals = 18) => {
//   // Code is largely lifted from the guts of web3 toWei here:
//   // https://github.com/ethjs/ethjs-unit/blob/master/src/index.js
//   let amountString = amount.toString()
//   const decimalsBN = BN.from(decimals)

//   if (decimalsBN.gt(100)) {
//     throw new Error(`Invalid decimals amount`)
//   }

//   const scale = BN.from(10).pow(decimals)
//   const scaleString = scale.toString()

//   // Is it negative?
//   const negative = amountString.substring(0, 1) === '-'
//   if (negative) {
//     amountString = amountString.substring(1)
//   }

//   if (amountString === '.') {
//     throw new Error(`Error converting number ${amountString} to precise unit, invalid value`)
//   }

//   // Split it into a whole and fractional part
//   // eslint-disable-next-line prefer-const
//   let [whole, fraction, ...rest] = amountString.split('.')
//   if (rest.length > 0) {
//     throw new Error(`Error converting number ${amountString} to precise unit, too many decimal points`)
//   }

//   if (!whole) {
//     whole = '0'
//   }
//   if (!fraction) {
//     fraction = '0'
//   }

//   if (fraction.length > scaleString.length - 1) {
//     throw new Error(`Error converting number ${amountString} to precise unit, too many decimal places`)
//   }

//   while (fraction.length < scaleString.length - 1) {
//     fraction += '0'
//   }

//   const wholeBN = BN.from(whole)
//   const fractionBN = BN.from(fraction)
//   let result = wholeBN.mul(scale).add(fractionBN)

//   if (negative) {
//     result = result.mul('-1')
//   }

//   return result
// }

export const simpleAmountInString = (amount: number | string, decimals: string | number = 18): string => {
  const d = +decimals
  if (d > 100) {
    throw new Error(`Invalid decimals amount`)
  }
  let amountString = amount.toString()
  const scale = Math.pow(10, d)
  const scaleString = scale.toString()

  // Is it negative?
  const negative = amountString.substring(0, 1) === '-'
  if (negative) {
    amountString = amountString.substring(1)
  }
  if (amountString === '.') {
    throw new Error(`Error converting number ${amountString} to precise unit, invalid value`)
  }
  // Split it into a whole and fractional part
  // eslint-disable-next-line prefer-const
  let [whole, fraction, ...rest] = amountString.split('.')
  if (rest.length > 0) {
    throw new Error(`Error converting number ${amountString} to precise unit, too many decimal points`)
  }

  if (!whole) {
    whole = '0'
  }
  if (!fraction) {
    fraction = '0'
  }
  if (fraction.length > scaleString.length - 1) {
    throw new Error(`Error converting number ${amountString} to precise unit, too many decimal places`)
  }
  while (fraction.length < scaleString.length - 1) {
    fraction += '0'
  }

  let result = +whole * scale + +fraction
  if (negative) {
    result = result * -1
  }
  return fromExponential(result)
}

export const decimalAmountToExactAmount = (balance: string | number, decimals: string | number = 18): number => {
  if (+decimals > 100) {
    throw new Error(`Invalid decimals amount`)
  }
  return +balance / 10 ** (+decimals)
}

export const removeDecimalsInBigString = (bigNumber: string | number): string => {
  if(typeof bigNumber == 'number') {
    return bigNumber.toFixed(0)
  }
  return bigNumber.split('.')[0]
}

export const slippageValue = (value: number, slipage: number) => {
  value = value - (value / 100 * slipage)
  return value;
}

export const hexToDecimals = (hex:string): string => {
  const hexBalance: string = hex || '0x0';
  const balance = BigNumber.from(hexBalance).toString();
  return balance;
}
