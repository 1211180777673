import { LOCAL_STORAGE_ITEM_NAMES } from 'app-constants'
import { saveStateToLocalStorage, loadStateFromLocalStorage } from 'helper/localStorage'
import { ImportedPoolReducerState, Pool } from 'types'
import { ActionTypeImportedPools, ActionTypes } from './types'

export const initialState: ImportedPoolReducerState = {
  lpAddress: '',
  pool: {
    lpAddress: null,
    lpSymbol: null,
    token0: null,
    token1: null,
  },
  list: loadStateFromLocalStorage(LOCAL_STORAGE_ITEM_NAMES.IMPORTED_POOLS) || [],
  userAddedLiquidityPositions: [],
  errors: [],
  isLoadingPool: false,
}

export default function Reducer(state = initialState, action : ActionTypeImportedPools): ImportedPoolReducerState  {
  let list, newPool
  switch (action.type) {
    case ActionTypes.SET_LIST:
      saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.IMPORTED_POOLS, list)
      return { ...state, list: action.payload }

    case ActionTypes.SET_POOL:
      return { ...state, pool: action.payload }

    case ActionTypes.SET_USER_ADDED_LIQUIDITY_POSITIONS:
      return { ...state, userAddedLiquidityPositions: action.payload }
    case ActionTypes.IMPORT_POOL:
      list = state.list.slice()
      newPool = state.pool as Pool
      list.splice(0, 0, newPool)
      saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.IMPORTED_POOLS, list)
      return { ...state, list: list, pool: { token0: null, token1: null, lpAddress: null, lpSymbol: null }, lpAddress: '' }

    case ActionTypes.REMOVE_POOL:
      list = state.list.slice()
      list.splice(action.payload, 1)
      saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.IMPORTED_POOLS, list)
      return { ...state, list: list }

    case ActionTypes.SET_LP_ADDRESS:
      return { ...state, lpAddress: action.payload }

    case ActionTypes.IS_LOADING_POOL:
      return { ...state, isLoadingPool: action.payload }

    case ActionTypes.SET_ERRORS:
      return { ...state, errors: action.payload }

    case ActionTypes.RESET:
    case ActionTypes.CLEAR_ALL:
      return initialState

    default:
      return state
  }
}
