import React from 'react'
import styled from 'styled-components'
import Modal from 'Component/Modal'
import Media from 'Theme/media-breackpoint'
import { BsCheckCircleFill } from 'react-icons/bs'
import { PPClosBTN01 } from 'Component/core/elements/Buttons'
import { ModalBox } from 'Component/core/elements/others/global-components'

interface IAllsetPopupProps {
  isOpen: boolean
  dismiss: () => void
}

function AllsetPopup({ isOpen, dismiss }: IAllsetPopupProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} dismiss={dismiss}>
      <PPMainBx className='position-relative'>
        <PPClosBTN01 className='position-absolute right-10 top-10' onClick={dismiss} />
        <div className='d-flex flex-column align-items-center'>
          <BsCheckCircleFill className='text-primary' size={60} />
          <h1 className='mt-20 text-primary fw-bold fs-2xl'>You&apos;re All Set! Your Buyback Offer is Active</h1>
          <div className='d-flex text-muted mt-30'>
            <div className='highlighted-border-right pr-20'>Deploy another bond pool</div>
            <div className='pl-20'>Claim fund to your bond from dSPAC</div>
          </div>
        </div>
      </PPMainBx>
    </Modal>
  )
}

const PPMainBx = styled(ModalBox)`
  max-width: 900px;
  min-width: 900px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom:80px;
  ${Media.lg} {
    min-width: 900px;
  }
  ${Media.md} {
    min-width: auto;
  }
  ${Media.xs} {
    width: auto;
    margin: 0px 15px;
    max-width: 100%;
  }
`

export default AllsetPopup
