import { useSnackbar } from 'react-simple-snackbar'
import type { SnackbarOptions } from 'react-simple-snackbar'

const appDefaultOptions: SnackbarOptions = {
  position: 'top-center',
  style: {
    // backgroundColor: 'midnightblue',
    // border: '2px solid lightgreen',
    // color: 'lightblue',
    // fontFamily: 'Menlo, monospace',
    // fontSize: '20px',
    // textAlign: 'center',
  },
  closeStyle: {
    // color: 'lightcoral',
    // fontSize: '16px',
  },
}

export const AppWaringOptions: SnackbarOptions = {
  position: 'top-right',
  style: {
    backgroundColor: '#25282f',
    boxShadow: '0 0 10px 0px red',
  },
  closeStyle: {},
}

function useAppSnackbar(options?: SnackbarOptions): ReturnType<typeof useSnackbar> {
  return useSnackbar(options || appDefaultOptions)
}

export default useAppSnackbar
