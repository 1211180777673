import React from 'react'
import styled from 'styled-components'
import { BsCheckLg } from 'react-icons/bs'
import { GlobalsSelector } from 'redux/selectors'

import Modal, { PPstitle02 } from '../../Modal'
import { PPClosBTN01 } from 'Component/core/elements/Buttons'
import { FlexDiv, ModalBox } from 'Component/core/elements/others/global-components'
import Media from 'Theme/media-breackpoint'
import dexIcon from 'Assets/images/dexIcon-017.png'
import { useAppChain, useProcessingModal } from 'hooks'
import { NETWORKS } from 'app-constants'
import { useAppSelector } from 'redux/hooks'
import AppTooltip from 'Component/AppTooltip'

const SViewA = styled.a`
  color: ${({ theme }) => theme.colorPrimary};
`

const SView1A = styled.a`
  color: white;
`
interface ISuccessProps {
  isOpen: boolean;
  hash: string;
  close: () => void;
}
function Success({ isOpen, hash, close }: ISuccessProps) {
  const { appChainId } = useAppChain()
  const network = NETWORKS[appChainId]
  const ExplorerUrl = network?.ExplorerUrl

  return (
    <Modal isOpen={isOpen} dismiss={close}>
      <PPMainBx>
        <PPsBx01>
          <PPstitle01>
            Transaction Successfull &nbsp;
            <BsCheckLg />
            {/* @ts-ignore */}
            <PPClosBTN01 className='right-0' onClick={close} />
          </PPstitle01>
          <PPstitle02>
            <span></span>
          </PPstitle02>

          <PPstitle02 className='overFlowHidden'>
            <SView1A rel='noreferrer' href={`${ExplorerUrl}/tx/${hash}`} target='_blank'>
              {hash}
            </SView1A>
          </PPstitle02>
        </PPsBx01>

        <PPsBx02>
          <button>
            <SViewA href={`${ExplorerUrl}/tx/${hash}`} target='_blank' rel='noreferrer'>
              View transaction
            </SViewA>
          </button>
        </PPsBx02>
      </PPMainBx>

      <AppTooltip />
    </Modal>
  )
}

function Loading() {
  const {
    processingModal: { isOpen, message },
  } = useAppSelector(GlobalsSelector)
  const [, dismiss] = useProcessingModal()

  return (
    <Modal isOpen={isOpen} dismiss={dismiss} className='z-index-2000'>
      <PPMainBx2>
        <PPsBx03>
          <PPstitle03>
            {message || 'Please wait transaction is processing...'}
            {/* <PPClosBTN01 onClick={props.dismiss} /> */}
          </PPstitle03>
          <PPstitle02>
            <span></span>
          </PPstitle02>
          <ImgBx className='rotate'>
            <img src={dexIcon} alt='' />
          </ImgBx>
        </PPsBx03>
      </PPMainBx2>
      <AppTooltip />
    </Modal>
  )
}

const PPMainBx = styled(ModalBox)`
  max-width: 431px;
  margin: 0 auto;
  ${Media.xs} {
    margin: 0px 15px;
    max-width: 100%;
    width: auto;
  }
`

const PPMainBx2 = styled.div`
  width: 100%;
  max-width: 431px;
  margin: 0 auto;
  background-color: transparent;
  display: block;
  ${Media.xs} {
    margin: 0px 15px;
    max-width: 100%;
    width: auto;
  }
`

const PPsBx01 = styled(FlexDiv)`
  width: 100%;
  padding: 30px 30px 10px 30px;
  border-bottom: #3c3f46 1px solid;
`

const PPsBx02 = styled(FlexDiv)`
  width: 100%;
  padding: 20px 30px 20px 30px;
  border-top: #3c3f46 1px solid;

  & button {
    border: 0px;
    outline: none;
    background-color: transparent;
    color: #fff;

    :hover {
      opacity: 0.7;
    }
  }
`
const PPsBx03 = styled(FlexDiv)`
  width: 100%;
  padding: 30px 30px 10px 30px;
`
const PPstitle01 = styled(FlexDiv)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0 12px 0;
  position: relative;
  color: ${({ theme }) => theme.colorPrimary};
`

const PPstitle03 = styled(FlexDiv)`
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0 12px 0;
  position: relative;
`

const ImgBx = styled(FlexDiv)`
  width: 23px;
  height: 23px;
  text-align: center;
  margin-right: 12px;
  border-radius: 15px;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }
  &.rotate {
    animation: rotation 2s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

const TransactionModal = {
  Success: Success,
  Loading: Loading,
}

export default TransactionModal
