import React, { useCallback, useState, useContext } from 'react'
import { DefaultTheme, ThemeContext } from 'styled-components'
import { AiOutlineCaretDown } from 'react-icons/ai'

import bnbImage from 'Assets/images/icon-3.png'
import ethImage from 'Assets/images/b-icon.png'
import useWallet from 'hooks/useWallet'
import { numberFormater } from 'helper'
import { SUPPORTED_CHAIN_IDS } from 'app-constants'
import AppTooltip from 'Component/AppTooltip'
import { useAppChain } from 'hooks'
import { ViewAddress, WrongNetworkBtn } from 'Component/core/elements/others/global-components'
import { AppContext } from 'Contexts'
import { Dropdown, DropdownMenu } from 'Component/core/elements/Dropdown'
import { withConnectWalletModal } from 'redux/actions'
import AppSidebar from './AppSidebar'
import { Nav } from 'types'
import { RenderLink } from './shared'
import { AppRouters } from 'app-constants/app-routers'
import { SOffcanvas } from 'Component/core/elements/OffCanvas'
import { AppCommunities } from 'config/app-config'
import S from './styled'

const navs: Nav[] = [
  {
    text: 'DYNAMIC DEX',
    to: '/swap',
    externalLink: '',
    reg: /\/swap/gi,
  },
  {
    text: 'STATIC DEX',
    to: '#',
    externalLink: 'https://multiswap.derex.exchange',
  },
  {
    text: 'IBO',
    to: AppRouters.IBO_BONDS_PAGE,
    externalLink: '',
    reg: /\/ibo/gi,
  },
  {
    text: 'BONDS OTC',
    to: '#',
    externalLink: '',
    dataTip: 'Coming Soon',
  },
  {
    text: 'ROI BOOSTER',
    to: AppRouters.ROI_BOOSTER_PAGE,
    reg: /\/roi-booster/gi,
    externalLink: '',
    // dataTip: 'Coming Soon',
  },
  // {
  //   text: 'INDEX FUNDS',
  //   to: '#',
  //   externalLink: '',
  //   dataTip: 'Coming Soon',
  // },
  // {
  //   text: 'dSPAC',
  //   to: '#',
  //   externalLink: '',
  //   dataTip: 'Coming Soon',
  // },
]

function Header({ connectWallet }: any) {
  const { isWrongNetwork } = useContext(AppContext)
  const [isOpen, setIsOpen] = useState(false)
  const { active, account, chainId, balance, disconnectWallet, switchNetwork } = useWallet()
  const { appChainId } = useAppChain()
  const appTheme = useContext<DefaultTheme>(ThemeContext)

  // eslint-disable-next-line
  const onInit = useCallback(() => {
    setIsOpen(false)
  }, [])

  const toggleIsOpen = useCallback((state: boolean | undefined = undefined) => {
    if (typeof state !== 'undefined') {
      setIsOpen(state)
    } else {
      setIsOpen((p) => !p)
    }
  }, [])

  return (
    <>
      <S.Mainheadbox>
        <S.Logo as='button' className='z-index-10 d-flex align-items-center' data-bs-toggle='offcanvas' data-bs-target='#offCanvasHeader' aria-controls='offcanvasScrolling'>
          <img src='/images/DEREX-logo.png' alt='' />
          <AiOutlineCaretDown className='ml-20 mt-5' color='white' size={15} />
        </S.Logo>
        <S.HeadCenterboxV2 className='z-index-5' navAlign='center'>
          {navs.map((nav, index) => (
            <RenderLink key={index} nav={nav} />
          ))}
        </S.HeadCenterboxV2>
        <S.HeadRightbox className='z-index-10'>
          <Dropdown className='dropdown mr-20'>
            <button className='btn bg-none dropdown-toggle' type='button' id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false'>
              More
            </button>
            <DropdownMenu className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
              {navs.map((nav, index) => (
                <li key={index} className='py-5'>
                  <RenderLink className='dropdown-item' key={index} nav={nav} />
                </li>
              ))}
            </DropdownMenu>
          </Dropdown>
          <S.BallanceText className='desktop-div'>{account ? <div style={{ marginRight: '25px' }}>{numberFormater(3).format(+balance)}</div> : <span></span>}</S.BallanceText>
          <div className='img-outer desktop-div'>{active && chainId && SUPPORTED_CHAIN_IDS.includes(chainId) ? <img src={chainId === 1 ? ethImage : bnbImage} alt='Currency' /> : <span></span>}</div>
          {!active ? (
            <S.ConnectWalletButton className='outline-gray px-20 fs-md fw-normal' onClick={connectWallet}>
              Connect Wallet
            </S.ConnectWalletButton>
          ) : !isWrongNetwork ? (
            <S.WalletAddressItem as='div' className='m-0' title={`Disconnect Wallet`} onClick={disconnectWallet}>
              <ViewAddress address={account || ''} />
            </S.WalletAddressItem>
          ) : (
            <WrongNetworkBtn onClick={() => switchNetwork(appChainId)} text='Network' />
          )}
          {/* we will use sidebar in future */}
          {/* <MenuIcon onMouseEnter={() => this.setIsOpen(this.state.isOpen)}> */}
          <S.MenuIcon data-tour='derex-tour-sidebar' data-type='light' data-html='true' data-class='data-tooltip' data-tip={`.`}>
            <S.TopDot className='topdot'></S.TopDot>
            <S.MidDot className='middot'></S.MidDot>
            <S.BottomDot className='bottomdot'></S.BottomDot>
          </S.MenuIcon>
          {/* <Dot></Dot> */}
          <AppSidebar isOpen={isOpen} toggle={toggleIsOpen} onInit={onInit} />
        </S.HeadRightbox>
      </S.Mainheadbox>
      <AppTooltip />
      <SOffcanvas
        id='offCanvasHeader'
        className='offcanvas offcanvas-start bg-primary-1 mt-85'
        data-bs-backdrop='false'
        trackColor={appTheme.bodybg01}
        handleColor={appTheme.bodybg03}
        scrollWidth='10px'
      >
        <S.LPDropDownGroup className='offcanvas-body px-20'>
          {AppCommunities.map((ac, index) => (
            <S.LPDropDownDiv key={index}>
              <a href={ac.link}>
                <img src={ac.image} alt='' style={ac.style} />
                {/* {ac.link === '#' && <span id='coming-soon'>Coming Soon</span>} */}
              </a>
            </S.LPDropDownDiv>
          ))}
        </S.LPDropDownGroup>
      </SOffcanvas>
    </>
  )
}

export default withConnectWalletModal(Header)
