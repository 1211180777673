import Web3 from 'web3'
import { getProposal, getProposals, Rules, TimeOptions, Vars } from 'app-constants'
import { Ballot, BallotTimeOption, BaseToken, ProposalItem, SupportedNetworks, TimeOption } from 'types'
import { decimalAmountToExactAmount } from './math'
import BSwapSubgraph from './subgraphs/BSwapSubgraph'

export const getTimeOption = (_seconds: string | number): TimeOption | null => {
  const nSeconds = +_seconds;
  for (let i = 0; i < TimeOptions.length; i++) {
    const timeOption = TimeOptions[i]
    const r = nSeconds % timeOption.value
    if (r === 0) {
      return timeOption;
    }
  }
  return null;
}

export const getTimeOptionValue = (_seconds: string | number, option: TimeOption): number | string => {
  let v = +_seconds / option.value
  v = v === 0 ? 1 : v;
  return v;
}

export const getTimeOptionAndValue = (_seconds: string | number): [TimeOption | null, string | number | null] => {
  const timeOption = getTimeOption(_seconds);
  let value: string | number = '';
  if(timeOption) {
    value = getTimeOptionValue(_seconds, timeOption)
  }
  return [timeOption, value]
}

/**
 *
 * @param {String} _seconds
 */
export const secondsToLabel = (_seconds: string | number): string => {
  if (+_seconds === 0) return '0 Days'
  const [timeOption, value] = getTimeOptionAndValue(_seconds);
  if(timeOption) {
    return `${value} ${timeOption.label}`
  }
  return '0 Days'
}

export const getBallotValue = (args: string, ruleId: string | number): string | number | null => {
  const web3 = new Web3()
  const rule = Rules[+ruleId]
  const params = web3.eth.abi.decodeParameters(rule.types, args)
  const values = Object.values(params);
  let decimals = 0;
  if (ruleId == 2) {
    const vars = Object.values(Vars);
    const ballotVar = vars.find((v) => v.index == values[0]);
    decimals = ballotVar?.decimals || 0
  }

  return decimalAmountToExactAmount(values[1] || values[0], decimals);
}

export const generateDescription = (ruleId: string | number, args: string, token0: BaseToken, token1: BaseToken): string => {
  const nRuleId = +ruleId
  const rule = Rules[nRuleId]
  const web3 = new Web3()
  const params = web3.eth.abi.decodeParameters(rule.types, args);
  const ruleIndex: string | number | undefined = nRuleId === 2 ? +params[0] : undefined;
  const proposal = getProposal(ruleId, ruleIndex);
  const value = getBallotValue(args, ruleId) as string | number;
  if (!proposal) return '';
  let description: string;
  switch (proposal.index) {
    case 2: // Maximum sell allowance of token0 price per transaction
    case 4: // Maximum sell allowance of TOKEN0 price per order
      description = proposal.getVoteDescrition(token0.symbol, value);
      break;
    case 3: // Maximum sell allowance of token1 price per transaction
    case 5: // Maximum sell allowance of TOKEN1 price per order
      description = proposal.getVoteDescrition(token1.symbol, value);
      break;
    case 6: // Emergency complete halt
      if (+params[0] === 3) {
        description = proposal.getVoteDescrition(token0.symbol, value);
        break;
      }
      description = proposal.getVoteDescrition(token1.symbol, value);
      break;
    case 7: // WMA measured
    case 8: // Minimum baseline fees
    case 9: // Minimum baseline fees
      description = proposal.getVoteDescrition(value)
      break;
    case 10: // Minimum baseline fees
      description = proposal.getVoteDescrition(params[0])
      break;
    default: // pool type
      description = proposal.getVoteDescrition()
      break;
  }
  return description;
}

export const getLastBallot = async (lpAddress: string, ruleId: string | number, chainId: SupportedNetworks, ruleIndex: string | number | undefined = undefined): Promise<Ballot> => {
  const subgraph = new BSwapSubgraph(chainId);
  const res = await subgraph.loadBallots(1, 0, { ruleId: ruleId, ruleIndex, pair: lpAddress.toLowerCase() }, 'timestamp', 'desc')
  const ballots = res.data.ballots as Ballot[]
  if (ballots.length === 0) {
    throw new Error('No Ballots');
  } else {
    return ballots[0];
  }
}

type PValue = string | number | null;
type ProposalReturnType = [PValue, PValue, BallotTimeOption | null]

export const getLastProposalValues = async (lpAddress: string, proposalIndex: string | number, chainId: SupportedNetworks): Promise<ProposalReturnType> => {
  const nPIndex = +proposalIndex;
  const SetVarsRuleId = 2;
  const proposal = getProposals().find((p) => p.index === nPIndex) as ProposalItem;
  if (nPIndex === 6) { // Emergency complete halt
    // need to load maxTxDump0 & maxTxDump1
    const ballot0 = await getLastBallot(lpAddress, SetVarsRuleId, chainId, Vars.maxTxDump0.index);
    const ballot1 = await getLastBallot(lpAddress, SetVarsRuleId, chainId, Vars.maxTxDump1.index);
    const value0 = getBallotValue(ballot0.args, ballot0.ruleId) as string | number;
    const value1 = getBallotValue(ballot1.args, ballot1.ruleId) as string | number;
    return [value0, value1, null]
  }
  const ballot = await getLastBallot(lpAddress, proposal.ruleId, chainId, proposal.poolVar?.index);
  const value = getBallotValue(ballot.args, ballot.ruleId) as string | number;

  if (nPIndex === 7) { // WMA measured
    const timeFrameBallot = ballot;
    const timeFrameValueInSecs = getBallotValue(timeFrameBallot.args, timeFrameBallot.ruleId) as string | number;
    const [timeOption, value] = getTimeOptionAndValue(timeFrameValueInSecs)
    const ballotTimeOption: BallotTimeOption = {
      decimalValue: value as string | number,
      label: timeOption?.label as string,
      value: timeOption?.value as number
    }
    return [null, null, ballotTimeOption]
  }
  if ( nPIndex === 2 || nPIndex === 3 // Maximum sell allowance of token0 & token1 price per transaction
    || nPIndex === 8  // Minimum baseline fees
    || nPIndex === 9 // Coefficient
    || nPIndex === 10 // // Minimum voting power to create a proposal
  ) {
    return [value, null, null]
  }
  if (nPIndex === 4 || nPIndex === 5) { // Maximum sell allowance of token0 & token1 price per order
    // need to load timeframe & it's value
    const timeFrameBallot = await getLastBallot(lpAddress, SetVarsRuleId, chainId, Vars.timeFrame.index);
    const timeFrameValueInSecs = getBallotValue(timeFrameBallot.args, SetVarsRuleId) as string | number;
    const [timeOption, tiemFrameValue] = getTimeOptionAndValue(timeFrameValueInSecs)
    const ballotTimeOption: BallotTimeOption = {
      decimalValue: tiemFrameValue as string | number,
      label: timeOption?.label as string,
      value: timeOption?.value as number
    }
    return [value, null, ballotTimeOption]
  }
  return [null, null, null]
}
