import React, { useCallback, useState, useContext } from 'react'
import { ThemeContext, DefaultTheme } from 'styled-components'

import useWallet from 'hooks/useWallet'
import { numberFormater } from 'helper'
import { NETWORK_ICONS, SUPPORTED_CHAIN_IDS } from 'app-constants'
import AppTooltip from 'Component/AppTooltip'
import { useAppChain } from 'hooks'
import { ViewAddress, WrongNetworkBtn } from 'Component/core/elements/others/global-components'
import { AppContext } from 'Contexts'
import { Dropdown, DropdownMenu } from 'Component/core/elements/Dropdown'
import { withConnectWalletModal } from 'redux/actions'
import S from './styled'
import AppSidebar from './AppSidebar'
import { Nav, SupportedNetworks } from 'types'
import { RenderLink } from './shared'
import { AiOutlineCaretDown } from 'react-icons/ai'
import { Divider } from 'Component/core/elements/Divider'
import { AppButton } from 'Component/core/elements/Buttons'
import ChooseBlockchainPopup from 'Component/popup/ChooseBlockChain'
import { SOffcanvas } from 'Component/core/elements/OffCanvas'
import { AppCommunities } from 'config/app-config'

const navs: Nav[] = [
  {
    text: 'BONDS OFFERING',
    to: '/lp/pools',
    externalLink: '',
  },
  {
    text: 'BONDS OTC',
    to: '#',
    externalLink: '',
    dataTip: 'Coming Soon',
  },
  {
    text: 'FARMING REWARDS',
    to: '/lp/farming-rewards/self-custody-farming',
    externalLink: '',
    // dataTip: 'Coming Soon',
  },
]

function Header({ connectWallet }: any) {
  const { isWrongNetwork } = useContext(AppContext)
  const [isOpen, setIsOpen] = useState(false)
  const { active, account, chainId, balance, disconnectWallet, switchNetwork } = useWallet()
  const { appChainId, switchAppNetwork } = useAppChain()
  const [isOpenBlockchain, setIsOpenBlockchain] = useState(false)

  const appTheme = useContext<DefaultTheme>(ThemeContext)

  // eslint-disable-next-line
  const onInit = useCallback(() => {
    setIsOpen(false)
  }, [])

  const toggleIsOpen = useCallback((state: boolean | undefined = undefined) => {
    if (typeof state !== 'undefined') {
      setIsOpen(state)
    } else {
      setIsOpen(p => !p)
    }
  }, [])
  
  const handleOnSelectBlockchain = useCallback((chain: SupportedNetworks) => {
    chainId !== chain && switchAppNetwork(chain);
    setIsOpenBlockchain(false)
  }, [switchNetwork])

  return (
    <>
      <S.Mainheadbox bg={appTheme.bodybg03}>
        <S.Logo as='button' className='z-index-10 d-flex align-items-center'
          data-bs-toggle='offcanvas'
          data-bs-target='#offCanvasHeader'
          aria-controls="offcanvasScrolling"
        >
          <img src='/images/lp-insure/LP-logo.png' alt='' />
          <AiOutlineCaretDown className='ml-20 mt-5' color='white' size={15} />
        </S.Logo>
        <S.HeadCenterboxV2 className='z-index-5' navAlign='left'>
          {navs.map((nav, index) => (
            <RenderLink key={index} nav={nav} />
          ))}
        </S.HeadCenterboxV2>
        <S.HeadRightbox className='z-index-10'>
          <Dropdown className='dropdown mr-20'>
            <button className='btn bg-none dropdown-toggle' type='button' id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false'>
              More
            </button>
            <DropdownMenu className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
              {navs.map((nav, index) => (
                <li key={index} className='py-5'>
                  <RenderLink className='dropdown-item' key={index} nav={nav} />
                </li>
              ))}
            </DropdownMenu>
          </Dropdown>
          <AppButton className='fs-md no-fill-muted' onClick={() => setIsOpenBlockchain(true)}>
            <span>Blockchain</span>
            <AiOutlineCaretDown className='ml-30' size={15} />
          </AppButton>
          <Divider className='horizontal highlighted-border-right h-min-20 mx-15' />
          <S.BallanceText className='desktop-div text-muted'>{account ? <div style={{ marginRight: '25px' }}>{numberFormater(3).format(+balance)}</div> : <span></span>}</S.BallanceText>
          <div className='img-outer desktop-div' style={{ filter: 'grayscale(1)' }}>
            {
              active && chainId && SUPPORTED_CHAIN_IDS.includes(chainId) 
              ? <img src={NETWORK_ICONS[chainId as SupportedNetworks]} alt='Chain' />
              : <span></span>}
          </div>
          {!active ? (
            <S.ConnectWalletButton className='outline-gray px-20 fs-md fw-normal' onClick={connectWallet}>
              Connect Wallet
            </S.ConnectWalletButton>
          ) : !isWrongNetwork ? (
            <S.WalletAddressItem as='div' className='m-0 text-muted' title={`Disconnect Wallet`} onClick={disconnectWallet}>
              <ViewAddress address={account || ''} />
            </S.WalletAddressItem>
          ) : (
            <WrongNetworkBtn onClick={() => switchNetwork(appChainId)} text='Network' />
          )}
          {/* we will use sidebar in future */}
          {/* <MenuIcon onMouseEnter={() => this.setIsOpen(this.state.isOpen)}> */}
          <S.MenuIcon data-tour="lpInsure-tour-sidebar" data-type='light' data-html='true' data-class='data-tooltip' data-tip={`.`}>
            <S.TopDot className='topdot'></S.TopDot>
            <S.MidDot className='middot'></S.MidDot>
            <S.BottomDot className='bottomdot'></S.BottomDot>
          </S.MenuIcon>
          {/* <Dot></Dot> */}
          <AppSidebar isOpen={isOpen} toggle={toggleIsOpen} onInit={onInit} />
        </S.HeadRightbox>
      </S.Mainheadbox>
      <AppTooltip />
      <ChooseBlockchainPopup isOpen={isOpenBlockchain} dismiss={() => setIsOpenBlockchain(false)} onSelect={handleOnSelectBlockchain} />
      <SOffcanvas
        id='offCanvasHeader'
        className='offcanvas offcanvas-start bg-primary-1 mt-85' 
        data-bs-backdrop="false"
        trackColor={appTheme.bodybg01}
        handleColor={appTheme.bodybg03}
        scrollWidth='10px'
      >
        <S.LPDropDownGroup className='offcanvas-body px-20'>
          {AppCommunities.map((ac, index) => (
            <S.LPDropDownDiv key={index}>
              <a href='#'>
                <img src={ac.image} alt='' />
              </a>
            </S.LPDropDownDiv>
          ))}
        </S.LPDropDownGroup>
      </SOffcanvas>
    </>
  )
}

export default withConnectWalletModal(Header)
