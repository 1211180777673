import { useCallback, useRef, useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import InputRange from 'react-input-range'
import type { Range } from 'react-input-range'
// @ts-ignore
import { TabPanel, Tabs } from 'react-tabs'
import Media from 'Theme/media-breackpoint'
import { BsCheckCircleFill } from 'react-icons/bs'

import { ModalBox, TokenBalanceSpan } from 'Component/core/elements/others/global-components'
import Modal from '../Modal'
import { AppButton, PPClosBTN01 } from 'Component/core/elements/Buttons'
import { RangeInput } from 'Component/core/elements/InputRange'
import { usePriceBySymbol } from 'hooks'

interface IPrincipalRedeemProps {
  isOpen: boolean
  amount: string | number
  tokenSymbol: string
  onSubmit: (amount: string | number) => void
  dismiss: () => void
}

function PrincipalRedeem({ amount, tokenSymbol, isOpen, onSubmit, dismiss }: IPrincipalRedeemProps) {
  const ref = useRef<null | HTMLSpanElement>(null)

  const valueInUsd = usePriceBySymbol(tokenSymbol || '')

  const [value, setValue] = useState(50)
  const [tab, setTab] = useState<number>(0)
  const selectedPrinciple = useMemo(() => {
    return (+amount / 100) * value
  }, [amount, value])

  const handleOnChangeInput = useCallback((v: number | Range) => {
    const value = v.toString()
    setValue(+value)
  }, [])

  const handleOnClickRedeem = () => {
    setTab(1)
  }

  const handleOnClickNo = useCallback(() => {
    dismiss()
  }, [dismiss])

  const onClickRedeem = useCallback(() => {
    onSubmit(selectedPrinciple)
  }, [onSubmit, selectedPrinciple])

  useEffect(() => {
    if (ref.current) {
      ref.current.style.left = `${value}%`
    }
  }, [ref, value])

  useEffect(() => {
    setTab(0)
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} dismiss={dismiss} className='bg-none'>
      <PPMainBx>
        <div className='position-relative'>
          <PPClosBTN01 className='position-absolute top-20 right-20' onClick={dismiss} />
        </div>
        <Tabs
          selectedIndex={tab}
          onSelect={(index: number) => {
            setTab(index)
          }}
        >
          <TabPanel>
            <div className='py-40 px-40'>
              <h1 className='text-center fs-4xl fw-bold text-title-secondary mt-30'>
                How much of your principal do you
                <br /> want to redeem?
              </h1>
              <RangeInput className='danger mt-50 position-relative pt-30'>
                <SInputRangeLabel ref={ref}>{selectedPrinciple.toFixed(4)}</SInputRangeLabel>
                <InputRange maxValue={100} minValue={0} value={value} formatLabel={(value) => `${value}%`} onChange={handleOnChangeInput} />
              </RangeInput>
              <div className='d-flex justify-content-center mt-50'>
                <AppButton className='outline-danger text-white' onClick={handleOnClickRedeem}>
                  Redeem
                </AppButton>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className='py-40 px-40'>
              <h1 className='text-center fs-4xl fw-bold text-title-secondary mt-30'>Are you sure you want to redeem your principal from the pool?</h1>
              <p className='text-center mt-20'>
                Initial principal will be deposit to your wallet, but you will lose the right over of <TokenBalanceSpan className='text-danger fw-bold' suffix={tokenSymbol} digits={6}>{selectedPrinciple}</TokenBalanceSpan> tokens [
                <span className='text-danger fw-bold'>
                  $<TokenBalanceSpan digits={6}>{valueInUsd * selectedPrinciple}</TokenBalanceSpan>
                </span>
                ]
              </p>
              <div className='d-flex justify-content-center mt-50'>
                <AppButton className='outline-gray text-white' onClick={handleOnClickNo}>
                  No I&apos;ll wait
                </AppButton>
                <AppButton className='ml-20 outline-danger text-white' onClick={onClickRedeem}>
                  Redeem my principal
                </AppButton>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <PPMainBx2 className='position-relative'>
              <div className='d-flex flex-column align-items-center py-30'>
                <BsCheckCircleFill className='text-primary' size={60} />
                <h1 className='my-30 text-primary fw-bold fs-2xl'>Principal Redeemed</h1>
                <div className='d-flex text-primary'>
                  <div className='pl-20'>Principal sent to your wallet</div>
                </div>
              </div>
            </PPMainBx2>
          </TabPanel>
        </Tabs>
      </PPMainBx>
    </Modal>
  )
}

const PPMainBx = styled(ModalBox)`
  width: 100%;
  max-width: 450px;
  min-width: 850px;
  margin: 0 auto;

  ${AppButton} {
    max-width: 400px;
  }
`

const PPMainBx2 = styled.div`
  max-width: 900px;
  min-width: 900px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 80px;
  ${Media.lg} {
    min-width: 900px;
  }
  ${Media.md} {
    min-width: auto;
  }
  ${Media.xs} {
    width: auto;
    margin: 0px 15px;
    max-width: 100%;
  }
`

const SInputRangeLabel = styled.span`
  top: -5px;
  position: absolute;
  transition: all 0.35s;
  transform: translateX(-35%);
`

export default PrincipalRedeem
