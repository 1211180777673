import { useCallback, useState } from 'react'
import { PageTitleSection } from 'Component/core/elements/others/global-components'
import { MainTitle } from 'Component/core/elements/Text'
import styled from 'styled-components'
import Modal from 'Component/Modal'
import { PPClosBTN01 } from 'Component/core/elements/Buttons'
import { SupportedChains } from 'app-constants/conToken'
import { AppSupportedChain } from 'types'
import { PopInput, SearchBox, SearchInputWrapper } from 'Component/core/elements/SearchBox'
import { useAppChain } from 'hooks'

const NetworkIcon = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.networkIconBg};
  padding: 5px;
`

const SelectNetworkMain = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  display: block;
  margin: 0 auto;
  padding: 10px 30px;
  border-radius: 10px;
  border: 2px solid #000;
  background-color: ${(props) => props.theme.pageBgColor};
`

interface ISelectNetworkProps {
  onSelectNetwork?: (n: AppSupportedChain) => void
  isOpen: boolean
  onDismiss: (...params: any) => void
}

function SelectNetwork({ onSelectNetwork, isOpen, onDismiss } : ISelectNetworkProps) {
  const [networks, setNetworks] = useState<AppSupportedChain[]>(SupportedChains)
  const { appChainId, switchAppNetwork } = useAppChain()
  const onSearchNetwork = useCallback((e: any) => {
    const search = e.target.value
    const reg = new RegExp(search, 'gi')
    const filteredNetworks = SupportedChains.filter((network) => network.name.search(reg) > -1 || network.symbol.search(reg) > -1)
    setNetworks(filteredNetworks)
  }, [])
  const handleSelectChain = useCallback(
    async (_chain: AppSupportedChain) => {
      try {
        switchAppNetwork(_chain.chainId)
      } catch (error) {
        console.error(error)
      }
      typeof onSelectNetwork === 'function' && onSelectNetwork(_chain)
    },
    [onSelectNetwork, appChainId, switchAppNetwork]
  )

  return (
    <Modal isOpen={isOpen} dismiss={onDismiss} hideBTN={false}>
      <SelectNetworkMain className='position-relative'>
        <PPClosBTN01 className='right-25 top-25' onClick={onDismiss} />
        <PageTitleSection className='d-flex align-items-center justify-content-around py-45'>
          <div className='text-start lh-sm'>
            <MainTitle className='pt-0'>Supporting All EVM chains</MainTitle>
          </div>
        </PageTitleSection>
        <div>
          <SearchBox className='w-full py-30 flex-row flex-nowrap fs-lg'>
            <SearchInputWrapper className='pl-20 w-full d-flex'>
              <div className='text-white d-flex align-items-center text-nowrap'>
                <i className='fas fa-search'></i>
                <span className='pl-10'>Search Blockchain</span>
              </div>
              <PopInput className='w-full pl-10' placeholder='ETH...' onChange={onSearchNetwork} />
            </SearchInputWrapper>
          </SearchBox>
          <div className='row mt-20'>
            {networks.map((network, index) => (
              <div key={index} className='col-6 col-sm-6 col-md-4 col-lg-3 p-0'>
                <div className='bg-bodybg03 m-10 radius-10 p-30 cursor-pointer hover:border-highlight-shadow-primary' onClick={() => handleSelectChain(network)}>
                  <div className='d-flex align-items-center text-capitalize'>
                    <NetworkIcon src={network.image} alt={network.name} />
                    <h3 className='fw-bold mb-0 ml-15'>{network.name}</h3>
                  </div>
                  <div className='d-flex justify-content-evenly mt-20'>
                    <div className='text-center'>
                      <h5 className='text-muted mb-10 fw-bold'>Chain ID</h5>
                      <h3 className='fw-bold'>{network.chainId}</h3>
                    </div>
                    <div className='text-center'>
                      <h5 className='text-muted mb-10 fw-bold'>Currency</h5>
                      <h3 className='text-uppercase fw-bold'>{network.symbol}</h3>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </SelectNetworkMain>
    </Modal>
  )
}

export default SelectNetwork
