import Web3Utils from 'web3-utils'
import { EventEmitter } from 'events'
import { Web3Provider } from '@ethersproject/providers'
// import dispatcher from "../dispatcher";
// import { providers } from "ethers";

// const web3Provider = new WalletConnectProvider({
//     infuraId: "81e7625588484d45a3a3be76abbb2030", // Required
// });

class Web3Config extends EventEmitter {
  web3: Web3Provider
  netWorkId: number
  address: string | null
  balance: string

  constructor(_web3: Web3Provider) {
    super()
    this.web3 = _web3
    this.netWorkId = 0
    this.address = null
    this.balance = ''
    this.checkAccount()
  }

  async checkAccount() {
    const web3 = this.web3
    const address = await web3.listAccounts()
    const netWorkId = await web3.getNetwork()
    this.netWorkId = netWorkId.chainId
    const balance = await web3.getBalance(address[0])
    const balanceFromWei = Web3Utils.hexToNumberString(balance._hex)
    this.balance = Web3Utils.fromWei(balanceFromWei)
    this.address = address[0]
    return address
  }

  getAddress() {
    return this.address
  }

  getNetworkId() {
    return this.netWorkId
  }

  getWeb3() {
    return this.web3
  }

  getBalance() {
    return this.balance
  }

  sendTransction(to: string, data: any, gasPrice: string, gasLimit: string, value: string, callback: (...params: any) => void) {
    const tx = {
      to: to, // Required (for non contract deployments)
      data: data, // Required
      gasPrice: Web3Utils.toHex(gasPrice), // Optional
      gasLimit: Web3Utils.toHex(gasLimit), // Optional
      value: Web3Utils.toHex(value), // Optional
    }

    this.web3
      .getSigner(0)
      .sendTransaction(tx)
      .then((result: any) => {
        callback(result, null)
      })
      .catch((error: any) => {
        // Error returned when rejected
        console.error(error)
      })
  }
}

export default Web3Config
