import { useMemo } from 'react'
import { FaExternalLinkAlt, FaLock, FaMediumM, FaRedditAlien, FaTelegramPlane, FaThumbsUp } from 'react-icons/fa'
import { TfiSharethis } from 'react-icons/tfi'
import { useParams } from 'react-router-dom'
import { IoLogoUsd } from 'react-icons/io'
import { AiOutlineTwitter } from 'react-icons/ai'
import { BsDiscord, BsTwitter } from 'react-icons/bs'
import { GoGraph, GoMarkGithub } from 'react-icons/go'
import Countdown from 'react-countdown'

import SmartImg from 'Component/core/elements/SmartImg'
import { BondInfoItem } from 'Component/core/elements/IBO-SharedComponents'
import { Divider } from 'Component/core/elements/Divider'
import { StatusIcon } from 'Component/core/elements/StatusIcon'
import * as S from './styled'
import { withZero } from 'helper'
import { Completionist, TokenBalanceSpan, ViewAddress } from 'Component/core/elements/others/global-components'
import AppTimeView from 'Component/core/elements/AppTimeView'
import { DetailItem, InvestInput } from 'Component/BondCards/elements'
import TokenSubgraph from 'Component/Charts/TokenSubgraph'
import { AppButton } from 'Component/core/elements/Buttons'
import AppSlider from 'Component/core/elements/Slider'
import { BondDetailBox } from 'Component/BondCards/styled'
import tooltip from 'app-constants/tooltip-texts'
import { secondsToLabel } from 'helper/dynamicHelpers'
import { BlogExpandButton, BlogIcon, BlogItem, BlogItemContent, BlogItemHeader, BlogItemLink, BlogItemTimestamp, BlogItemTitle } from 'Component/core/elements/LP-Insure'
import CustomScrollbars from 'Component/core/elements/CustomScrollbars'

type PageRouterParam = {
    poolId: string
}

function LPInsurePoolDetail() {
    const { poolId } = useParams<PageRouterParam>()
    console.log('poolId: ', poolId)

    const handleOnChangeInvestInput = () => {
        console.log('here')
    }
    const handleOnClickWithdraw = () => {
        console.log('here')
    }
    const handleOnClickMaxButton = () => {
        console.log('here')
    }
    const onClickInvest = () => {
        console.log('here')
    }


    const countdownComponent = useMemo(() => {
        return (
            <Countdown
                date={new Date(2023, 3, 20)}
                intervalDelay={0}
                precision={3}
                renderer={({ days, hours, minutes, seconds, completed, milliseconds }) => {
                    // const milli = Math.round(milliseconds > 10 ? milliseconds / 10 : milliseconds)
                    const milli = milliseconds % 100
                    const _days = withZero(days)
                    const value = `${_days}:${withZero(hours)}:${withZero(minutes)}:${withZero(seconds)}:${withZero(milli)}`
                    return completed ? <Completionist /> : <AppTimeView numclassName='p-5 px-7 fs-md' value={value} />
                }}
            />
        )
    }, [])

    return (
        <S.PageBox>
            <S.DetailStatusDiv className='row'>
                <div className='col-8 d-flex align-items-center'>
                    <StatusIcon className={`active mr-10`} />
                    <span className='pool-id'>Live #1532</span>
                </div>
                <div className='col-4 d-flex justify-content-between pr-30 pl-15'>
                    <div className='d-flex align-items-center'>
                        <S.DetailTitleSpan>Affiliate Program</S.DetailTitleSpan>
                        <Divider className='horizontal mx-10 highlighted-border-right h-80p' />
                        <S.DetailTitleSpan>Telegram Bot</S.DetailTitleSpan>
                    </div>
                    <div className='d-flex align-items-center'>
                        <TfiSharethis className='text-muted mx-20' size={25} />
                        <S.ReviewButton>
                            <FaThumbsUp className='mr-10' />
                            <span>12,345</span>
                        </S.ReviewButton>
                    </div>
                </div>
            </S.DetailStatusDiv>
            <div className='row mt-30'>
                <div className='col highlighted-border-right:h-85p'>
                    <S.ProjectDetailBox>
                        <div className='ProjectDetailBox-Info'>
                            <SmartImg
                                className='rounded-circle bg-white'
                                src={'/images/pdo-med.png'}
                                alt='Logo'
                                style={{ width: 80, height: 80 }}
                                data-bs-toggle='offcanvas'
                                data-bs-target='#offcanvasRight'
                                aria-controls='offcanvasRight'
                            />
                            <div className='ProjectDetailBox-Info-Details'>
                                <S.ProjectSymbol>PDO</S.ProjectSymbol>
                                <S.ProjectName href='#' target='_blank' rel='noreferrer'>
                                    PDO Finance
                                </S.ProjectName>
                            </div>
                        </div>
                        <Divider className='horizontal px-0 highlighted-border-right' />
                        <div className='fs-14 ProjectDetailBox-Detail'>
                            <BondInfoItem icon={<IoLogoUsd />} label='Investment Limits' /*helpText={tooltip('Investment Limits')} */ />
                            <BondInfoItem icon={<FaLock size={13} />} label='Vesting Duration' nobg />
                        </div>
                    </S.ProjectDetailBox>
                </div>
                <div className='col highlighted-border-right:h-82p px-30 fs-sm'>
                    <S.ProjectDetailItem>
                        <span className='title'>Smart contract</span>
                        <span className='detail'>ibc/3ff...b991d6</span>
                    </S.ProjectDetailItem>
                    <S.ProjectDetailItem>
                        <span className='title'>Links</span>
                        <div className='detail'>
                            <span>pdo.fianace</span>
                            <Divider className='horizontal mx-5 highlighted-border-right h-80p' />
                            <span>Whitepaper</span>
                        </div>
                    </S.ProjectDetailItem>
                    <S.ProjectDetailItem>
                        <span className='title'>Explorers</span>
                        <div className='detail'>
                            <span>BSCscan</span>
                        </div>
                    </S.ProjectDetailItem>
                    <S.ProjectDetailItem>
                        <span className='title'>Community</span>
                        <div className='detail'>
                            <FaRedditAlien />
                            <Divider className='horizontal mx-5 highlighted-border-right h-80p' />
                            <AiOutlineTwitter />
                            <Divider className='horizontal mx-5 highlighted-border-right h-80p' />
                            <FaTelegramPlane />
                            <Divider className='horizontal mx-5 highlighted-border-right h-80p' />
                            <BsDiscord />
                            <Divider className='horizontal mx-5 highlighted-border-right h-80p' />
                            <FaMediumM />
                        </div>
                    </S.ProjectDetailItem>
                    <S.ProjectDetailItem>
                        <span className='title'>Source Code</span>
                        <div className='detail'>
                            <GoMarkGithub />
                        </div>
                    </S.ProjectDetailItem>
                </div>
                <div className='col px-30'>
                    <div className='d-flex align-items-center justify-content-between fs-sm'>
                        <h5 className='mb-0 text-muted'>Current bond pool offering ends in</h5>
                        <div className='text-center fw-bold'>{countdownComponent}</div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-20 fs-sm'>
                        <h5 className='mb-0 text-muted'>1 USDT = $1, 1PDO = $0.02</h5>
                        <GoGraph className='text-primary fw-bold' />
                    </div>
                    <InvestInput
                        className='mt-20'
                        tokenAddress={'0x0000000'}
                        valueInUsd={0.22}
                        isInvalid={false}
                        isLoading={false}
                        max={{
                            symbol: 'PDO',
                            value: 1000,
                        }}
                        value={10}
                        hideWithdraw={true}
                        onChangeInvestInput={handleOnChangeInvestInput}
                        onClickWithdraw={handleOnClickWithdraw}
                        onClickMax={handleOnClickMaxButton}
                        onClickInvest={onClickInvest}
                    />
                    <Divider className='vertical mx-5 highlighted-border-bottom' />
                    <div className='mt-10'>
                        <div className='d-flex text-muted fs-sm'>
                            <span className='ml-10'>Received</span>
                            <div className='ml-10'>
                                <p className='mb-0'>
                                    <TokenBalanceSpan>1234567890</TokenBalanceSpan>
                                </p>
                                <div>
                                    <span>Extra&nbsp;</span>
                                    <TokenBalanceSpan>1234567890</TokenBalanceSpan>
                                    <span>&nbsp;|&nbsp;</span>
                                    <span>-2.5% cheaper vs DEX</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <TokenSubgraph
                    token={{
                        address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd', // tBNB address,
                        name: 'BNB',
                        symbol: 'BNB'
                    }}
                />
            </div>
            <p className='text-muted fs-sm'>PDO finance is an open-source sharded multichain protocol that connects and secures a network of specialized blockchains, facilitating cross-chain transfer of any data or asset types, not just tokens, thereby allowing blockchains to be interoperable with each other. Polkadot was designed to provide a foundation for a decentralized internet of blockchains, also known as Web3.</p>
            <AppButton className='no-fill-primary fs-sm w-auto pl-0 fw-light'>Read More</AppButton>
            <div className='mt-20'>
                <AppSlider className='primary-1' value={50} />
                <p className='text-primary text-center fs-sm mt-20'>Remaining available allocation 50%</p>
            </div>
            <Divider className='vertical mt-40 highlighted-border-bottom' />
            <S.LPInsurePoolDetailBox>
                <div className='Bond-Detail-Section highlighted-border-right text-muted fs-sm'>
                    <BondDetailBox>
                        {/* dont remove following line. we will use in future */}
                        {/* <DetailItem label='Bond type' value='50% discount' /> */}
                        {/* Funds distributions is always 100% LP */}
                        <DetailItem label='Funds distributions' value='100% LP' helpText={tooltip('Funds distributions')} />
                        <DetailItem label='Pairs' value={`BNB <> USDT`} />
                        <DetailItem label='Exchange' value='DEREX' />
                        <DetailItem label='Bond face value'
                            value={<TokenBalanceSpan prefix='$' digits={6}>{10}</TokenBalanceSpan>}
                            helpText={tooltip('Bond face value')}
                        />
                        {/* Discount is always -50% */}
                        <DetailItem label='Discount' value='-50%' helpText={tooltip('Discount')} />
                        <DetailItem label='Principal vesting' value={secondsToLabel(10)} />
                        <DetailItem label='Prepayment penalty' value={10} helpText={tooltip('PREPAYMENT_PENALTY')} />
                        <DetailItem label='Profits  vesting period' value={secondsToLabel(10)} />
                        <DetailItem label='Accepting payment' value={'BNB'} />
                        <DetailItem label='Bonds supply available' value={1000000} />
                        <DetailItem label='Investment limit per wallet' value={`5000.000 bonds`} />
                        <DetailItem
                            label='Total Raised'
                            value={
                                <>
                                    <span className='text-primary'>
                                        <TokenBalanceSpan prefix='$'>100</TokenBalanceSpan>
                                    </span>
                                </>
                            }
                        />
                        <DetailItem label='Total Raised (% target)' value={`10%`} />
                        <DetailItem
                            label='Remaining available allocation'
                            value={
                                <TokenBalanceSpan suffix='%' digits={4}>100</TokenBalanceSpan>
                            }
                        />
                    </BondDetailBox>

                    <Divider className='vertical mb-15 mt-10 highlighted-border-bottom' />

                    <DetailItem label='Tokens supply' value={<TokenBalanceSpan>1000</TokenBalanceSpan>} helpText={tooltip('Tokens supply')} />
                    <DetailItem label='FDV' value={<TokenBalanceSpan digits={6} prefix='$'>200</TokenBalanceSpan>} helpText={tooltip('FDV')} />
                    <Divider className='vertical my-15 highlighted-border-bottom' />
                    <DetailItem
                        label='Your investment'
                        value={
                            <span>
                                <TokenBalanceSpan prefix='$'>{100}</TokenBalanceSpan>&nbsp; (<TokenBalanceSpan>{100}</TokenBalanceSpan> USDT&nbsp;)
                            </span>
                        }
                    />
                    <DetailItem label='Your initial LP assets' value='2,000 SMART + 100 USDT' />
                    <DetailItem
                        label='Proof'
                        value={
                            <a href={`https://TODO/tx/${'0x000456789'}`} target='_blank' rel='noreferrer' className='text-secondary'>
                                <ViewAddress address={'0x000456789'} />
                            </a>
                        }
                    />
                    <div className='d-flex justify-content-between mt-25'>
                        <a href='#' className='text-primary' rel='noreferrer' target='_blank'>
                            View Project Info
                            <FaExternalLinkAlt className='ml-5' />
                        </a>
                        <a href='#' className='text-primary' rel='noreferrer' target='_blank'>
                            View project liquidity
                            <FaExternalLinkAlt className='ml-5' />
                        </a>
                    </div>
                </div>
                <div className='Bond-Twitter-Section highlighted-border-right'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <S.SocialCategoryTitle>Tweets</S.SocialCategoryTitle>
                        <BsTwitter className='SocialCategory-Icon' />
                    </div>
                    <div>
                        <CustomScrollbars className='dark-1 scrollbar-show' height='320px' width='100%' style={{ position: 'relative' }}>
                            <div className='pr-40'>
                                {new Array(20).fill(10).map((v, i) => <BlogItem key={i}>
                                    <BlogItemHeader>
                                        <BlogIcon src='/images/pdo-med.png' />
                                        <div className='w-full ml-20'>
                                            <BlogItemTitle>
                                                PDO
                                            </BlogItemTitle>
                                            <BlogItemLink>
                                                @PDO
                                            </BlogItemLink>
                                        </div>
                                        <BlogItemTimestamp>
                                            4h
                                        </BlogItemTimestamp>
                                    </BlogItemHeader>
                                    <BlogItemContent>
                                        Introducing Unbounded, the first open source, freely available and on-chain funded font in the world.
                                    </BlogItemContent>
                                    <BlogExpandButton>Expand</BlogExpandButton>
                                    <Divider className='vertical my-15 highlighted-border-bottom' />
                                </BlogItem>)}
                            </div>
                        </CustomScrollbars>
                    </div>
                    <S.TweetButton>Tweet to @pdofinance</S.TweetButton>
                </div>
                <div className='Bond-Telegram-Section highlighted-border-right'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <S.SocialCategoryTitle>Telegram</S.SocialCategoryTitle>
                        <FaTelegramPlane className='SocialCategory-Icon' />
                    </div>
                    <div>
                        <CustomScrollbars className='dark-1 scrollbar-show' height='320px' width='100%' style={{ position: 'relative' }}>
                            <div className='pr-40'>
                                {
                                    new Array(20).fill(10).map((v, i) => <BlogItem key={i}>
                                        <BlogItemHeader>
                                            <BlogIcon src='/images/pdo-med.png' />
                                            <div className='w-full ml-20'>
                                                <BlogItemTitle>
                                                    PDO
                                                </BlogItemTitle>
                                                <BlogItemLink>
                                                    @PDO
                                                </BlogItemLink>
                                            </div>
                                            <BlogItemTimestamp>
                                                4h
                                            </BlogItemTimestamp>
                                        </BlogItemHeader>
                                        <BlogItemContent>
                                            Interested in building on Polkadot? In 20 minutes now, tune in to the Twitter Spaces on @PolkadotEspanol to learn all about the upcoming Polkadot hackathon in Latin America: application process...
                                        </BlogItemContent>
                                        <Divider className='vertical my-15 highlighted-border-bottom' />
                                    </BlogItem>)
                                }
                            </div>
                        </CustomScrollbars>
                    </div>
                </div>
                <div className='Bond-News-Section'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <S.SocialCategoryTitle>News &amp; Media</S.SocialCategoryTitle>
                        <div>
                            <span className='SocialCategory-Icon fw-bold'>Google&nbsp;</span>
                            <span className='SocialCategory-Icon'>News</span>
                        </div>
                    </div>
                    <div>
                        {
                            new Array(2).fill(10).map((v, i) => <BlogItem key={i}>
                                <BlogItemHeader>
                                    <div className='w-full pr-20'>
                                        <BlogItemContent className='text-primary'>PDO Finance investors should be aware of these updates before pulling out</BlogItemContent>
                                    </div>
                                    <BlogIcon src='/images/pdo-med.png' />
                                </BlogItemHeader>
                                <BlogItemContent className='mt-10'>
                                    Introducing Unbounded, the first open source, freely available and on-chain funded font in the world.
                                </BlogItemContent>
                                <div>
                                    <BlogItemTimestamp>4h ago</BlogItemTimestamp>
                                </div>
                                <Divider className='vertical my-15 highlighted-border-bottom' />
                            </BlogItem>)
                        }
                    </div>
                </div>
            </S.LPInsurePoolDetailBox>
            <Divider className='vertical highlighted-border-bottom' />
        </S.PageBox>
    )
}

export default LPInsurePoolDetail
