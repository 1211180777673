import { useMemo } from 'react'
import useCookie, { cookieOptions } from 'react-use-cookie'
import CookiesName from 'app-constants/cookies'
import defaultIcon from 'Assets/images/defaultIcon.svg'
import { isEqAddr } from 'helper'
import { AvailableTokensSelector, SimplePriceSelector, TokenListSelector } from 'redux/selectors'
import { useAppSelector } from 'redux/hooks'
import { AppToken, ReducerTokenPrice } from 'types'

type UseIsMemeberCookieReturnType = [boolean, (v: string, options: cookieOptions) => void]

export const useIsMemeberCookie = (): UseIsMemeberCookieReturnType => {
  const [value, setValue] = useCookie(CookiesName.isChannelMember, '0')

  return [parseInt(value) > 0, setValue]
}

export const useSimplePrice = (): ReducerTokenPrice[] => {
  // simple price from coingecko. inited on App.js
  const simplePrices = useAppSelector(SimplePriceSelector)
  return useMemo(() => Object.values(simplePrices), [simplePrices])
}

type UseAllTokensReturnType = [AppToken[]]
export const useAllTokens = ({ type = 'all' }: {
  type?: 'all' | 'enabled' | 'disabled'
}): UseAllTokensReturnType => {
  const { items, itemData } = useAppSelector(TokenListSelector) // supported tokens list @type Array

  const allTokens = useMemo(() => {
    return itemData
      ?.filter((item, index) => {
        if (type === 'enabled') {
          return items[index]?.enabled
        } else if (type === 'disabled') {
          return !items[index]?.enabled
        }
        return true
      })
      .map((item) => item.tokens)
      .flat(1)
      .filter((item) => item) // remove underfined
    // disabled for type
    // eslint-disable-next-line
  }, [itemData, items])

  return [allTokens]
}

// returns tokens that are filtered by chain
export const useAvailableTokens = (): [AppToken[], AppToken[]] => {
  const availableTokens = useAppSelector(AvailableTokensSelector) // supported token list
  const { items, itemData } = useAppSelector(TokenListSelector) // supported tokens list @type Array
  const allTokens = useMemo<AppToken[]>(() => {
    const tokens = itemData?.filter((item, index) => items[index]?.enabled)
      .map((item) => item.tokens).flat(1)
      .filter((token) => token) // remove underfined
    // remove duplicated tokens
    const tokens_obj: any = {}
    tokens.forEach((t) => {
      const key = t.address.toLowerCase()
      tokens_obj[key] = t
    })
    return Object.values(tokens_obj)
  }, [itemData, items])

  const mergedTokens: Array<AppToken> = useMemo(() => {
    const availableTokenAddresses = availableTokens.map((item) => item.address)
    // find available tokens
    let filteredTokens = allTokens.filter((item) => {
      return availableTokenAddresses.find((address) => isEqAddr(address, item.address))
    })
    const missedTokens = availableTokens.filter((token0) => {
      return !filteredTokens.find((item) => isEqAddr(item.address, token0.address))
    })
    // filter duplicated tokens
    filteredTokens = filteredTokens.filter((token, index, self) => {
      return self.findIndex((t) => isEqAddr(t.address, token.address)) === index
    })
    const appMissedTokens = missedTokens.map((item) => ({
      name: item.name,
      symbol: item.symbol,
      label: item.symbol,
      decimals: item.decimals,
      address: item.address,
      image: defaultIcon,
    }))
    // add missing tokens
    const mergedTokens: AppToken[] = [...filteredTokens, ...appMissedTokens].slice()

    const appTokens: AppToken[] = mergedTokens
    return appTokens
  }, [availableTokens, allTokens])

  return useMemo(() => [mergedTokens || [], allTokens], [ [mergedTokens, allTokens]])
}
