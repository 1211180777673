import { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import Modal from '../Modal'
import useWallet from 'hooks/useWallet'

import CWIco01 from '../../Assets/images/cwICON-01.png'
import CWIco02 from '../../Assets/images/cwICON-02.png'
import CWIco03 from '../../Assets/images/cwICON-03.png'
import CWIco04 from '../../Assets/images/cwICON-04.png'

import { PPClosBTN01 } from 'Component/core/elements/Buttons'
import { FlexDiv, ModalBox } from 'Component/core/elements/others/global-components'
import { LOCAL_STORAGE_ITEM_NAMES } from 'app-constants'
import { GlobalsSelector } from 'redux/selectors'
import { toggleConnectWalletModal } from 'redux/reducers/globals/globalActions'
import { WalletConnectorTypes, WalletInstallStatus } from 'types'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { WalletOfficialSites } from 'Connectors'
import AppTooltip, { AppToolTipControl } from 'Component/AppTooltip'

function ConnectWallet() {
  const dispatch = useAppDispatch()
  const { showConnectWalletModal: isOpen } = useAppSelector(GlobalsSelector)
  const { active, connectWallet } = useWallet()
  const walletInstallStatus = useMemo<WalletInstallStatus>(() => {
    const _status = {
      [WalletConnectorTypes.BINANCE_CHAIN]: typeof window.BinanceChain !== 'undefined',
      [WalletConnectorTypes.MATH_WALLET]: !!window.ethereum?.isMathWallet,
      [WalletConnectorTypes.METAMASK]: !!window.ethereum?.isMetaMask,
      [WalletConnectorTypes.TRUST_WALLET]: typeof window.trustwallet !== 'undefined',
    }
    return _status
  }, [])

  const dismiss = useCallback(() => {
    dispatch(toggleConnectWalletModal(false))
    AppToolTipControl.hide();
  }, [])

  const handleOnClickConnectWalletButton = useCallback((type: WalletConnectorTypes) => {
    if(!walletInstallStatus[type]) { // need to install
      const officialSite = WalletOfficialSites[type]
      return window.open(officialSite, '_blank')
    }
    connectWallet(type)
  }, [connectWallet])

  useEffect(() => {
    if (active) {
      dismiss()
    }
  }, [active, dismiss])

  useEffect(() => {
    const savedWalletType = localStorage.getItem(LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE)

    if (savedWalletType) {
      connectWallet(parseInt(savedWalletType) as WalletConnectorTypes)
    }
  }, [connectWallet])

  return (
    <Modal isOpen={isOpen} dismiss={dismiss}>
      <PPMainBx>
        <PPsBx01 className='v2'>
          <PPstitle01>
            Connect wallet
            <PPClosBTN01 className='right-0 top-0' onClick={dismiss} />
          </PPstitle01>
        </PPsBx01>

        <CWBTNBX>
          <button onClick={() => handleOnClickConnectWalletButton(WalletConnectorTypes.BINANCE_CHAIN)}>
            {walletInstallStatus[WalletConnectorTypes.BINANCE_CHAIN] ? 'Connect' : 'Install'} Binance Chain Wallet
            <i>
              <img src={CWIco01} alt='' />
            </i>
          </button>

          <button onClick={() => handleOnClickConnectWalletButton(WalletConnectorTypes.METAMASK)}>
            {walletInstallStatus[WalletConnectorTypes.METAMASK] ? 'Connect' : 'Install'} MetaMask
            <i>
              <img src={CWIco02} alt='' />
            </i>
          </button>
          <button onClick={() => handleOnClickConnectWalletButton(WalletConnectorTypes.TRUST_WALLET)}>
            {walletInstallStatus[WalletConnectorTypes.TRUST_WALLET] ? 'Connect' : 'Download'} TrustWallet App
            <i>
              <img src={CWIco03} alt='' />
            </i>
          </button>
          <button onClick={() => handleOnClickConnectWalletButton(WalletConnectorTypes.MATH_WALLET)}>
            {walletInstallStatus[WalletConnectorTypes.MATH_WALLET] ? 'Connect' : 'Get'} MathWallet App
            <i>
              <img src={CWIco04} alt='' />
            </i>
          </button>
        </CWBTNBX>

        <PPsBx02 className='v2'>
          <button>{/*Having trouble finding a token?*/} Learn more about wallets</button>
        </PPsBx02>
      </PPMainBx>

      <AppTooltip />
    </Modal>
  )
}

const PPMainBx = styled(ModalBox)`
  max-width: 431px;
  margin: 0 auto;
`

const PPsBx01 = styled(FlexDiv)`
  width: 100%;
  padding: 30px 30px 10px 30px;
  border-bottom: #3c3f46 1px solid;

  &.v2 {
    border-bottom: 0px;
  }
`

const PPsBx02 = styled(FlexDiv)`
  width: 100%;
  padding: 20px 30px 20px 30px;
  border-top: #3c3f46 1px solid;

  &.v2 {
    font-size: 12px;
    border-top: 0px;
    button {
      color: #8be05a;
    }
  }

  & button {
    border: 0px;
    outline: none;
    background-color: transparent;
    color: #fff;
    :hover {
      opacity: 0.7;
    }
  }
`

const PPstitle01 = styled(FlexDiv)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0 12px 0;
  position: relative;
`

const CWBTNBX = styled(FlexDiv)`
  width: 100%;
  padding: 10px 30px 0px 30px;

  button {
    width: 100%;
    height: 62px;
    border: 1px solid #545861;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    padding: 10px 14px;
    color: #ffffff;
    position: relative;
    margin: 0 0 15px 0;

    i {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 11px;
      top: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :hover {
      background-color: #32353b;
    }
  }
`

export default ConnectWallet
