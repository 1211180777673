import { useLayoutEffect } from 'react'
import { BsCheckLg } from 'react-icons/bs'
// @ts-ignore 
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Gs from 'Theme/globalStyles'
import { withConnectWalletModal } from 'redux/actions'
import { BondTabSelector } from 'redux/selectors'

import useWallet from 'hooks/useWallet'

import { PageTitleSection } from 'Component/core/elements/others/global-components'
import { MainTitle } from 'Component/core/elements/Text'
import { CustomTabs2 } from 'Component/core/elements/Tabs'

import ProjectInformation from 'Pages/IBO/components/Tabs/ProjectInformation/index'
import BondTerms from 'Pages/IBO/components/Tabs/BondTerms'
import FundraisingTerms from 'Pages/IBO/components/Tabs/FundraisingTerms'
import DeployBondPool from 'Pages/IBO/components/Tabs/DeployBondPool/DeployBondPool'
import { AppButton } from 'Component/core/elements/Buttons'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { reset, 
  setStep
} from 'redux/reducers/bond/actions'
import { LaunchPoolContainer } from 'Component/core/elements/IBO-SharedComponents'

function LaunchBondPool({ connectWallet }: {connectWallet: any}) {
  const tab = useAppSelector(BondTabSelector)
  const dispatch = useAppDispatch()
  const { active, account } = useWallet()

  const setTab = (i: number) => {
    dispatch(setStep(i))
  }

  useLayoutEffect(() => {
    dispatch(reset())
  }, [account])

  return (
    <Gs.Container className='pb-20'>
      <PageTitleSection>
        <MainTitle className='text-start mb-0 text-center'>Launch your bond pool</MainTitle>
      </PageTitleSection>
      <LaunchPoolContainer className={`mt-20 ${active ? 'Wallet-Connected' : 'Wallet-Disconnected'}`}>
        <CustomTabs2>
          <Tabs
            selectedIndex={tab}
            onSelect={(index: number) => {
              setTab(index)
            }}
          >
            <TabList>
              <Tab className={`fs-md ${tab > 0 ? 'active' : ''}`}>
                {tab === 0 ? (
                  'Step 1 : Project Information'
                ) : (
                  <>
                    <BsCheckLg className='mr-10' /> Project Information
                  </>
                )}
              </Tab>
              <Tab className={`fs-md ${tab > 1 ? 'active' : ''}`}>
                {tab === 1 ? (
                  'Step 2 : Bond terms'
                ) : tab > 1 ? (
                  <>
                    <BsCheckLg className='mr-10' /> Bond terms
                  </>
                ) : (
                  'Step 2'
                )}
              </Tab>
              <Tab className={`fs-md ${tab > 2 ? 'active' : ''}`}>
                {tab === 2 ? (
                  'Step 3 : Fundraising terms'
                ) : tab > 2 ? (
                  <>
                    <BsCheckLg className='mr-10' /> Fundraising terms
                  </>
                ) : (
                  'Step 3'
                )}
              </Tab>
              <Tab className={`fs-md ${tab > 3 ? 'active' : ''}`}>
                {tab === 3 ? (
                  'Step 4 : Deploy bond pool'
                ) : tab > 3 ? (
                  <>
                    <BsCheckLg className='mr-10' /> Deploy bond pool
                  </>
                ) : (
                  'Step 4'
                )}
              </Tab>
              {/* <Tab className={`fs-md ${tab > 4 ? 'active' : ''}`}>
                {tab === 4 ? (
                  'Step 5 : Buyback program'
                ) : tab > 4 ? (
                  <>
                    <BsCheckLg className='mr-10' /> Buyback program
                  </>
                ) : (
                  'Step 5'
                )}
              </Tab> */}
            </TabList>
            <div className='Bond_Tab_List'>
              <TabPanel>
                <ProjectInformation />
              </TabPanel>
              <TabPanel>
                <BondTerms />
              </TabPanel>
              <TabPanel>
                <FundraisingTerms />
              </TabPanel>
              <TabPanel>
                <DeployBondPool />
              </TabPanel>
              {/* <TabPanel className='pt-50'>
              <BuybackProgram />
            </TabPanel> */}
            </div>
          </Tabs>
        </CustomTabs2>
        <div className='ConnectWallet d-flex justify-content-center w-full'>
          <AppButton className='outline-gray w-max-500' onClick={connectWallet}>
            Connect Wallet
          </AppButton>
        </div>
      </LaunchPoolContainer>
    </Gs.Container>
  )
}

export default withConnectWalletModal(LaunchBondPool)
