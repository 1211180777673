import styled from 'styled-components'
import defaultIcon from 'Assets/images/defaultIcon.svg'

const SmartImgWrap = styled.img``

/**
 * @description replace defaultIcon if src does not exist or get error
 */
interface ISmartImgProps extends React.HTMLAttributes<HTMLImageElement> {
  src?: string
  alt?: string
}

function SmartImg({ src, ...props }: ISmartImgProps) {
  return (
    <SmartImgWrap
      src={src || defaultIcon}
      onError={(e) => {
        // @ts-ignore
        e.target.onerror = null
        // @ts-ignore
        e.target.src = defaultIcon
      }}
      {...props}
    />
  )
}

export default SmartImg
