import { TaxReducerState } from 'types'
import { ActionTax, ActionTypes } from './types'

export const initialState: TaxReducerState = {
  owner: '', // address of token owner
  taxedToken: '', // address of token0 | token1
  pair: {
    address: '',
    decimals: '',
    token0: {
      name: '',
      symbol: '',
      address: '',
      image: '',
    },
    token1: {
      name: '',
      symbol: '',
      address: '',
      image: '',
    },
  },
  sellTax: 0, // 0 ~ 99
  sellTaxReceiver: '',
  buyTax: 0, // 0 ~ 99
  buyTaxReceiver: '',
  showPairTokenPopup: false,
  showTaxFeeStatusPopup: false,
}

export default function Reducer(state = initialState, action: ActionTax): TaxReducerState {
  switch (action.type) {
    case ActionTypes.SET_VALUES:
      return { ...state, ...action.payload }

    case ActionTypes.SET_TOKEN:
      return { ...state, taxedToken: action.payload }

    case ActionTypes.RESET:
      return initialState

    default:
      return state
  }
}
