import { RootState } from 'redux/store'
import { AlterTokenListItem, AppCustomToken, AppThunkAction, AppTokenListData, SupportedNetworks, TokenListItem } from 'types'
import { ActionTypes, ActionTokenList } from './types'

type TokenListAppThunkAction<
  R, // Return type of the thunk function
> = AppThunkAction<R, RootState, null, ActionTokenList>

export const setTokens = (tokens: Array<AppTokenListData>): TokenListAppThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.set,
    payload: tokens,
  })
}

export const init = (): TokenListAppThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.init,
    payload: [],
  })
}

export const add = (_token: AppTokenListData): TokenListAppThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.add,
    payload: _token,
  })
}

export const setItem = (_index: number, tokens: AppTokenListData): TokenListAppThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.setItem,
    payload: {
      index: _index,
      tokens: tokens,
    },
  })
}

export const updateItem = (_index: number, item: AlterTokenListItem): TokenListAppThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.updateItem,
    payload: {
      index: _index,
      item: item,
    },
  })
}

export const setItems = (items: Array<TokenListItem>): TokenListAppThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.setItems,
    payload: items,
  })
}

export const removeItem = (_index: number):TokenListAppThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.remove,
    payload: {
      index: _index,
    },
  })
}

export const addCustomToken = (token: AppCustomToken):TokenListAppThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.ADD_CUSTOM_TOKEN,
    payload: token,
  })
}

export const removeCustomToken = (address: string, chainId: SupportedNetworks):TokenListAppThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.REMOVE_CUSTOM_TOKEN,
    payload: {
      address,
      chainId
    },
  })
}
export const clearAllCustomToken = ():TokenListAppThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_ALL_CUSTOM_TOKEN,
  })
}

// export const setTokens = (_pairs) => (dispatch) => {
//   dispatch({
//     type: ActionTypes.setTokens,
//     payload: _pairs
//   })
// }
