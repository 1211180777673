import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setIsOpenTransactionModal, setStateFaildT_Modal, setTransactionModal } from 'redux/reducers/globals/globalActions'
import { TransactionModalSelector } from 'redux/selectors'

type UseFailedInfoModalReturnType = [(message: string, _header?: string) => void, () => void]

export const useFailedInfoModal = (): UseFailedInfoModalReturnType => {
  const dispatch = useAppDispatch()

  const openModal = useCallback(
    (_message: string, _header = '') => {
      dispatch(setStateFaildT_Modal(true, _message, _header))
    },
    []
  )

  const closeModal = useCallback(() => {
    dispatch(setStateFaildT_Modal(false, ''))
  }, [])

  return [openModal, closeModal]
}

type UseTransactionModalEventHandlers = {
  afterOpen?: () => void;
  afterClose?: () => void;
};

type UseTransactionModalReturnType = {
  isOpen: boolean;
  hash: string;
  open: (hash: string) => void
  close: () => void
}
// use for successfull transaction
export const useTransactionModal = ({ afterClose, afterOpen }: UseTransactionModalEventHandlers = {}): UseTransactionModalReturnType => {
  const dispatch = useAppDispatch()
  const { hash, isOpen } = useAppSelector(TransactionModalSelector)

  const openModal = useCallback(
    (_hash: string) => {
      dispatch(
        setTransactionModal({
          isOpen: true,
          hash: _hash,
        })
      )
      if (typeof afterOpen == 'function') {
        afterOpen()
      }
    },
    [afterOpen]
  )
  const closeModal = useCallback(() => {
    dispatch(
      setTransactionModal({
        isOpen: false,
        hash: '',
      })
    );
    if (typeof afterClose == 'function') {
      afterClose()
    }
  }, [afterClose])

  return { hash, isOpen, open: openModal, close: closeModal }
}

// use for transaction processing
type UseProcessingModalReturnType = [(msg?: string) => void, () => void]
export const useProcessingModal = (): UseProcessingModalReturnType => {
  const dispatch = useAppDispatch()

  const o = useCallback(
    (msg = '') => {
      dispatch(setIsOpenTransactionModal(true, msg))
    },
    []
  )

  const c = useCallback(() => {
    dispatch(setIsOpenTransactionModal(false))
  }, [])

  return [o, c]
}
