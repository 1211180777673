import React from "react";

export type RewardTokenPrices = {
    [key in string]: {
        decimals: string;
        symbol: string;
        usd: string | number;
    }
}

export type RoiContext = {
    rewardTokenPrices: RewardTokenPrices
}

export const RoiPageContext = React.createContext<RoiContext>({ rewardTokenPrices: {} })
