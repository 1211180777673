import { ReducerSimplePrices } from "types"

export enum ActionTypes {
  SET = 'COINGECKO/SET',
  RESET = 'COINGECKO/RESET',
  INIT_TOKEN_PRICE = 'COINGECKO/INIT_TOKEN_PRICE',
  SET_TOKEN_PRICE = 'COINGECKO/SET_TOKEN_PRICE',
}

interface ActionReset {
  type: ActionTypes.RESET
}

interface ActionSetTokenPrices {
  type: ActionTypes.SET_TOKEN_PRICE
  payload: ReducerSimplePrices
}

interface ActionInitTokenPrices {
  type: ActionTypes.INIT_TOKEN_PRICE
  payload: ReducerSimplePrices
}


export type ActionCoingecko = ActionReset | ActionSetTokenPrices | ActionInitTokenPrices
