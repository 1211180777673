import React from 'react'
import styled from 'styled-components'

const RadioButtonWrapper = styled.div`
  & > input {
    background-color: transparent;
    border: 2px solid ${(props) => props.theme.colorGrey};
    min-width: 16px;

    &:checked {
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.colorGrey};
      &[type='radio'] {
        background-image: url('/images/icons8-100--24.png');
        background-size: calc(100% - 2px);
        background-position: center;
      }
    }
  }
  &:has(> input:disabled) {
    color: ${({ theme }) => theme.colorLightGrey} !important;
  }
`

interface IRadioButtonProps extends React.HTMLAttributes<HTMLInputElement>{
  className?: string,
  radioClassName?: string,
  label?: string | any,
  name?: string | any,
  value?: string | any,
  type?: string | any,
  checked?: boolean | any
  disabled?: boolean | any
}

export function RadioButton({ className, label, radioClassName = '', ...props }: IRadioButtonProps) {
  return (
    <RadioButtonWrapper className={`form-check ${className}`}>
      <input className={`form-check-input ${radioClassName}`} type='radio' {...props} />
      {label}
    </RadioButtonWrapper>
  )
}
