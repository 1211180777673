import { useCallback } from 'react'
import { BsCheckLg } from 'react-icons/bs'
// @ts-ignore 
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { AppButton } from 'Component/core/elements/Buttons'
import { PageTitleSection } from 'Component/core/elements/others/styled'
import { MainTitle } from 'Component/core/elements/Text'
import { withConnectWalletModal } from 'redux/actions'
import Gs from 'Theme/globalStyles'
import useWallet from 'hooks/useWallet'
import { LaunchPoolContainer } from 'Component/core/elements/IBO-SharedComponents'
import { CustomTabs2 } from 'Component/core/elements/Tabs'
import SupportingAllDexs from 'Component/Supports/SupportingAllDexs'
import SupportingBlockchains from 'Component/Supports/SupportingBlockchains'

import ProjectInformation from './Tabs/LPInsureProjectInformation'
import LPInsureBondTerms from './Tabs/LPInsureBondTerms'
import LPInsureFundraisingTerms from './Tabs/LPInsureFundraisingTerms'
import LPInsureDeployBondPool from './Tabs/LPInsureDeployBondPool'
import LPInsureBuybackProgram from './Tabs/LPInsureBuybackProgram'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { LPInsureSelector } from 'redux/selectors'
import { setStep } from 'redux/reducers/lp-insure/actions'
import { LPInsureTabs } from 'types'

function LPInsureLaunchPool({ connectWallet }: { connectWallet: any }) {
  const { active } = useWallet()
  const dispatch = useAppDispatch()
  const lpInsureReducerState = useAppSelector(LPInsureSelector)
  const tab = lpInsureReducerState.tab

  const setTab = useCallback(
    (tab: LPInsureTabs) => {
      dispatch(setStep(tab))
    },
    [],
  )
  
  return (
    <Gs.Container className='pb-20'>
      <PageTitleSection>
        <MainTitle className='text-start mb-0 text-center'>Launch your bond pool</MainTitle>
      </PageTitleSection>
      <LaunchPoolContainer className={`mt-20 ${active ? 'Wallet-Connected' : 'Wallet-Disconnected'}`}>
        <CustomTabs2>
          <Tabs
            selectedIndex={tab}
            onSelect={(index: number) => {
              setTab(index)
            }}
          >
            <TabList>
              <Tab className={`fs-md ${tab > 0 ? 'active' : ''}`}>
                {tab === 0 ? (
                  'Step 1 : Project Information'
                ) : (
                  <>
                    <BsCheckLg className='mr-10' /> Project Information
                  </>
                )}
              </Tab>
              <Tab className={`fs-md ${tab > 1 ? 'active' : ''}`}>
                {tab === 1 ? (
                  'Step 2 : Bond terms'
                ) : tab > 1 ? (
                  <>
                    <BsCheckLg className='mr-10' /> Bond terms
                  </>
                ) : (
                  'Step 2'
                )}
              </Tab>
              <Tab className={`fs-md ${tab > 2 ? 'active' : ''}`}>
                {tab === 2 ? (
                  'Step 3 : Fundraising terms'
                ) : tab > 2 ? (
                  <>
                    <BsCheckLg className='mr-10' /> Fundraising terms
                  </>
                ) : (
                  'Step 3'
                )}
              </Tab>
              <Tab className={`fs-md ${tab > 3 ? 'active' : ''}`}>
                {tab === 3 ? (
                  'Step 4 : Deploy bond pool'
                ) : tab > 3 ? (
                  <>
                    <BsCheckLg className='mr-10' /> Deploy bond pool
                  </>
                ) : (
                  'Step 4'
                )}
              </Tab>
              <Tab className={`fs-md ${tab > 4 ? 'active' : ''}`}>
                {tab === 4 ? (
                  'Step 5 : Buyback program'
                ) : tab > 4 ? (
                  <>
                    <BsCheckLg className='mr-10' /> Buyback program
                  </>
                ) : (
                  'Step 5'
                )}
              </Tab>
            </TabList>
            <div className='Bond_Tab_List'>
              <TabPanel>
                <ProjectInformation />
              </TabPanel>
              <TabPanel>
                <LPInsureBondTerms />
              </TabPanel>
              <TabPanel>
                <LPInsureFundraisingTerms />
              </TabPanel>
              <TabPanel>
                <LPInsureDeployBondPool />
              </TabPanel>
              <TabPanel>
                <LPInsureBuybackProgram />
              </TabPanel>
            </div>
          </Tabs>
        </CustomTabs2>
        <div className='ConnectWallet d-flex justify-content-center align-items-center w-full'>
          <AppButton className='outline-gray w-max-500' onClick={connectWallet}>
            Connect Wallet
          </AppButton>
        </div>
      </LaunchPoolContainer>
      <SupportingAllDexs />
      <SupportingBlockchains
        onClickTitle={() => {
          console.log('onClickTitle')
        }}
      />
    </Gs.Container>
  )
}

export default withConnectWalletModal(LPInsureLaunchPool)
