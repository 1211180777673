import { BondData } from "types"

export enum ActionTypes {
  FETCH_BOND_LIST = 'BOND_LIST/FETCH_BOND_LIST',
  VOTE_TO_BOND = 'BOND_LIST/VOTE_TO_BOND',
  SET_SEARCH_VALUE = 'BOND_LIST/SET_SEARCH_VALUE',
  IS_LOADING_BOND_LIST = 'BOND_LIST/IS_LOADING_BOND_LIST',
  SET_INVEST_LENGTH_IN_24HRS = 'BOND_LIST/SET_INVEST_LENGTH_IN_24HRS',
}

interface ActionFetchBondList {
  type: ActionTypes.FETCH_BOND_LIST
  payload: Array<BondData>
}
interface ActionVoteToBond {
  type: ActionTypes.VOTE_TO_BOND
  payload: {
    classId: string
    nonceId: string
  }
}
interface ActionSetSearchValue {
  type: ActionTypes.SET_SEARCH_VALUE
  payload: string
}
interface ActionIsLoadingBondList {
  type: ActionTypes.IS_LOADING_BOND_LIST
  payload: boolean
}
interface ActionSetInvestLengthIn24Hrs {
  type: ActionTypes.SET_INVEST_LENGTH_IN_24HRS
  payload: number
}
export type ActionBondList = ActionFetchBondList | ActionVoteToBond | ActionSetSearchValue | ActionIsLoadingBondList | ActionSetInvestLengthIn24Hrs
