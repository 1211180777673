import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import Modal, { PPsBx01, PPstitle01, PPstitle02, PopInput01 } from '../Modal'
import Media from '../../Theme/media-breackpoint'
import CustomScrollbars from 'Component/core/elements/CustomScrollbars'
import { PPClosBTN01 } from 'Component/core/elements/Buttons'
import { FlexDiv, ModalBox } from 'Component/core/elements/others/global-components'
import { MULTI_SWAP_URL } from 'config/pathConfig'
import { useAppChain } from 'hooks'
import { ApiDexInfo } from 'types'
import AppTooltip from 'Component/AppTooltip'

interface ISelectDexesProps {
  isOpen: boolean
  dismiss: () => void
  onSelect: (dex: ApiDexInfo) => void
}

export default function SelectDexes({ isOpen, dismiss, onSelect }: ISelectDexesProps) {
  const { appChainId } = useAppChain()
  const [dexes, setDexes] = useState<ApiDexInfo[]>([])
  const [filteredDexes, setFilteredDexes] = useState<ApiDexInfo[]>([])
  const [error, setError] = useState('')

  const handleOnChangeSearch = useCallback(
    (e: any) => {
      const search = e.target.value
      const reg = new RegExp(search, 'gi')
      const _filteredDexes = dexes.filter((d) => {
        return reg.test(d.title)
      })
      setFilteredDexes(_filteredDexes)
    },
    [dexes]
  )

  useEffect(() => {
    axios
      .get(`${MULTI_SWAP_URL}/json/dexes.json`)
      .then((res) => {
        let _dexes: ApiDexInfo[] = res.data.dexes.map((d: ApiDexInfo) => {
          d.img = `${MULTI_SWAP_URL}/${d.img}`
          return d
        })
        _dexes = _dexes.filter((d) => d.chainId === appChainId)
        setDexes(_dexes)
        setFilteredDexes(_dexes)
      })
      .catch((err) => {
        console.error(err)
        setError(String(err.message || err))
        setDexes([])
      })
  }, [appChainId])

  return (
    <Modal isOpen={isOpen} dismiss={dismiss}>
      <PPMainBx>
        <PPsBx01 className='position-relative'>
          <PPClosBTN01 className='position-absolute right-10 top-10' onClick={dismiss} />
          <PPstitle01 className='d-flex justify-content-between align-items-center'>
            <h4>Select DEX</h4>
          </PPstitle01>
          <PopInput01 placeholder='Search by name...' onChange={handleOnChangeSearch} />
        </PPsBx01>
        <PPstitle02 className='pl-30'>
          <span>DEX name</span>
        </PPstitle02>
        <CustomScrollbars
          height='320px'
          width='100%'
          style={{ position: 'relative' }}
          scrollDirection='left'
          trackClass='black'
          renderView={(props: any) => <div {...props} className='view scrollbar-none pl-30' />}
        >
          <div className='d-flex flex-column pb-20 pt-5'>
            {error ? (
              <>
                <p className='text-danger'>{error}</p>
              </>
            ) : (
              <>
                {filteredDexes.map((dex) => (
                  <DexCard key={dex.id} as='button' onClick={() => onSelect(dex)}>
                    <img src={`${dex.img}`} alt={dex.title} />
                    <span className='title'>{dex.title}</span>
                  </DexCard>
                ))}
              </>
            )}
          </div>
        </CustomScrollbars>
      </PPMainBx>
      <AppTooltip />
    </Modal>
  )
}

const PPMainBx = styled(ModalBox)`
  max-width: 450px;
  min-width: 450px;
  margin: 0 auto;
  ${Media.lg} {
    min-width: 400px;
  }
  ${Media.md} {
    min-width: auto;
  }
  ${Media.xs} {
    width: auto;
    margin: 0px 15px;
    max-width: 100%;
  }
`

const DexCard = styled(FlexDiv)`
  justify-content: flex-start;
  padding: 5px 0;
  color: ${({ theme }) => theme.colorWhite};
  &:hover {
    color: ${({ theme }) => theme.colorPrimary};
  }
  img {
    padding: 5px;
    width: 35px;
    height: 35px;
  }

  span.title {
    padding-left: 10px;
  }
`
