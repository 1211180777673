import { InjectedConnector as Injected } from '@web3-react/injected-connector'
import { WalletConnectConnector, WalletConnectConnectorArguments } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
// import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { SUPPORTED_CHAIN_IDS, INFRA_PROJECT_ID } from 'app-constants'
import { NetworkUrls, SupportedNetworks, WalletConnectorTypes, WalletOfficialSitesType } from 'types'

export const BinanceWalletNetworkIds: NetworkUrls = {
  [SupportedNetworks.mainnet]: 'eth',
  [SupportedNetworks.bsc]: 'bsc-mainnet',
  [SupportedNetworks.bsctestnetwork]: 'bsc-testnet',
}

// for metamask and math wallet
const injected = new Injected({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})

// coinbasewallet connector
// const CoinbaseWalletConnector = new WalletLinkConnector({
//   url: `https://mainnet.infura.io/v3/${INFRA_PROJECT_ID}`,
//   appName: '',
//   supportedChainIds: SUPPORTED_CHAIN_IDS,
// })

const args : WalletConnectConnectorArguments = {
  rpc: `https://mainnet.infura.io/v3/${INFRA_PROJECT_ID}`,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
}

const WalletConnecter = new WalletConnectConnector(args)

const bscConnector = new BscConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})

export const Connectors = {
  [WalletConnectorTypes.BINANCE_CHAIN]: bscConnector,
  [WalletConnectorTypes.METAMASK]: injected,
  [WalletConnectorTypes.TRUST_WALLET]: WalletConnecter,
  [WalletConnectorTypes.MATH_WALLET]: injected,
}

export const WalletOfficialSites: WalletOfficialSitesType = {
  [WalletConnectorTypes.BINANCE_CHAIN]: 'https://www.binance.com/en/wallet-direct',
  [WalletConnectorTypes.METAMASK]: 'https://metamask.io',
  [WalletConnectorTypes.TRUST_WALLET]: 'https://trustwallet.com',
  [WalletConnectorTypes.MATH_WALLET]: 'https://mathwallet.org',
}