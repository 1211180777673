import React, { useMemo, useState, useCallback, useContext } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { FaFilter, FaSearch, FaSort } from 'react-icons/fa'
import { TableColumn } from 'react-data-table-component'
import { isAddress } from 'web3-utils'

import TransactionModals from 'Component/popup/transactions/transaction-popups'
import AddNewRewardPopup, { RewardsFormDataType } from 'Component/popup/AddNewRewardPopup'
import { PageTitleSection } from 'Component/core/elements/others/styled'
import { MainDesc, MainTitle } from 'Component/core/elements/Text'
import { Dropdown } from 'Component/core/elements/Dropdown'
import { AppButton } from 'Component/core/elements/Buttons'
import CustomReactTablePagination, { DoubleTableCustomStyles } from 'Component/core/elements/CustomReactDataTableElements'
import { PopInput, SearchBox, SearchInputWrapper } from 'Component/core/elements/SearchBox'
import useWallet from 'hooks/useWallet'
import BondHoldersExpandableRowComponent from './components/BondHoldersExpandableRowComponent'
import { withConnectWalletModal } from 'redux/actions'
import SelectBondPopup from 'Component/popup/SelectBondPopup'
import { BondData, PageLoadState } from 'types'
import { useAppDispatch } from 'redux/hooks'
import { addFarm, getRewardsByClassIds, harvest } from 'redux/reducers/bond/actions'
import { decimalAmountToExactAmount, simpleAmountInString } from 'helper/math'
import useAppSnackbar, { AppWaringOptions } from 'hooks/useAppSnackbar'
import { useAppChain, useFailedInfoModal, useProcessingModal, useTransactionModal } from 'hooks'
import BondSubgraph from 'helper/subgraphs/BondSubgraph'
import { TokenBalanceSpan } from 'Component/core/elements/others/global-components'
import { Rewarads } from 'types/smart-contracts/ibo-proxy-contract'
import useAppDebounceEffect from 'hooks/useAppDebounceEffect'
import { GetBondsForFarm } from 'types/subgraphs/bonds'
import { RoiPageContext } from '../Contexts'
import SelectNetwork from 'Component/popup/SelectNetwork'
import AppDataTable from 'Component/AppDataTable'
import AppLoadingSpinner from 'Component/AppLoadingSpinner'
import { getExplorerUrl } from 'helper'

export type BondHolderRowType = {
  no: number
  name: string
  transactionHash: string
  viewId: string
  classId: string
  rewardsToClaim: string | number
  rewards: Rewarads[],
  defaultExpanded: boolean
}

const emptyTableData: BondHolderRowType[] = [{
  no: 0,
  name: '',
  viewId: '',
  classId: '',
  transactionHash: '',
  rewardsToClaim: 0,
  rewards: [],
  defaultExpanded: false,
}]

function BondHoldersTabContent({ connectWallet }: { connectWallet: () => void; }) {
  const { appChainId } = useAppChain()
  const { active, account } = useWallet()
  const roiPageContext = useContext(RoiPageContext)
  const dispatch = useAppDispatch()
  const [openWaringSnackbar] = useAppSnackbar(AppWaringOptions)
  const { open: openTRModal, close: closeTRModal, hash: TRMHash, isOpen: isTRMOpen } = useTransactionModal()
  const [openLoadingModal, closeLoadingModal] = useProcessingModal()
  const [openFailedModal] = useFailedInfoModal()

  const [pageState, setPageState] = useState<PageLoadState>({ error: '', isLoading: true });
  const [showSelectNetwork, setShowSelectNetwork] = useState(false);

  const [showAddNewRewardsModal, setShowAddNewRewardsModal] = useState(false);
  const [showSelectBondPopup, setShowSelectBondPopup] = useState(false);
  const [selectedBond, setSelectedBond] = useState<BondData | null>(null);

  const [search, setSearch] = useState(''); // token symbol

  const [bonds, setBonds] = useState<GetBondsForFarm[]>([]);
  const [rewards, setRewards] = useState<Rewarads[]>([]);

  const [tableData, setTableData] = useState<BondHolderRowType[]>([])

  const hanlleOnRowClicked = (row: BondHolderRowType) => {
    setTableData(p => {
      const index = p.findIndex(data => data.viewId === row.viewId)
      if (index > -1) {
        p[index].defaultExpanded = !p[index].defaultExpanded;
      }
      return p.slice();
    });
  }

  const harvestRewards = async (_classIds: string[]) => {
    try {
      openLoadingModal('Claim reward tokens....')
      await dispatch(harvest(_classIds))
    } catch (error: unknown) {
      console.log(error)
      // @ts-ignore
      const errorMessage = error.message || error.toString();
      openFailedModal(errorMessage)
    } finally {
      closeLoadingModal()
    }
  }

  const columns = useMemo<TableColumn<BondHolderRowType>[]>(
    () => [
      {
        id: 'no',
        name: '',
        width: '48px',
        minWidth: '48px',
        cell: (row) => {
          return <span>{row.no}</span>
        },
      },
      {
        id: 'bond',
        name: <>Bond <FaSort /></>,
        cell: (row) => {
          return <div className='w-full' onClick={() => hanlleOnRowClicked(row)} >
            <a className='token-name' href={`${getExplorerUrl(appChainId)}/tx/${row.transactionHash}`} target='_blank' rel='noreferrer'>{row.name}</a>
          </div>
        },
      },
      {
        id: 'total-reward',
        name: <>Total reward available to claim <FaSort /></>,
        style: {
          color: 'white'
        },
        cell: (row) => {
          return <TokenBalanceSpan prefix='$' digits={8}>{row.rewardsToClaim}</TokenBalanceSpan>
        },
      },
      {
        id: 'claim-returns',
        name: <div className='w-full text-center'>Claim returns</div>,
        center: true,
        cell: (row) => {
          return (
            <AppButton
              className='outline-black hover:outline-primary fs-md text-primary fw-normal py-5 radius-3 text-nowrap'
              title='Claim'
              onClick={() => harvestRewards([row.classId])}
            >Claim</AppButton>
          )
        },
      },
    ],
    [appChainId]
  )

  const walletConnectRequired = useMemo<boolean>(() => {
    return !active;
  }, [active])

  const handleOnDismissRewardsModal = useCallback(() => {
    setShowAddNewRewardsModal(false)
  }, [])
  const handleOnDismissSelectBondModal = useCallback(() => {
    setShowSelectBondPopup(false)
  }, [])
  const handleOnSubmit = useCallback(async (_formData: RewardsFormDataType) => {
    if (selectedBond) {
      const rewardsdPeriod = _formData.rewardsPeriod * _formData.rewardsPeriodOption.value;
      const rewardsAmount = simpleAmountInString(_formData.supplyOfRewards, _formData.rewardsTokenInfo.decimals)
      try {
        openLoadingModal('Adding new reward...')
        const result = await dispatch(addFarm(selectedBond.classId, _formData.rewardsTokenInfo, rewardsAmount, rewardsdPeriod, _formData.hasDumperShield))
        openTRModal(result.transactionHash)
        setShowAddNewRewardsModal(false)
      } catch (error: unknown) {
        // @ts-ignore
        openFailedModal(error.message || error.toString())
      } finally {
        closeLoadingModal()
      }
    } else {
      setShowAddNewRewardsModal(false);
      openWaringSnackbar('Please Select Bond!!!');
    }
  }, [selectedBond])
  const handleOnSelectBond = useCallback((_bond: BondData) => {
    setSelectedBond(_bond)
    setShowSelectBondPopup(false)
  }, [])
  const handleOnClickAddNew = () => {
    setShowAddNewRewardsModal(true)
  }

  const getSearchQuery = useCallback((): any | undefined => {
    let where;
    if (search) {
      if (isAddress(search)) {
        where = {
          "token": search.toLowerCase()
        }
      } else {
        where = {
          "token_": {
            "symbol_contains_nocase": search
          }
        }
      }
    }
    return where;
  }, [search])

  useAppDebounceEffect(() => {
    (async () => {
      let classIds: string[] = []
      try {
        setPageState({ isLoading: true, error: '' })
        const subgraph = new BondSubgraph(appChainId)
        const where = getSearchQuery();
        const result = await subgraph.getBondsForFarms(where)
        const _bonds = result.data.bonds || []
        classIds = _bonds.map((bond) => bond.classId)
        setBonds(_bonds)
        setPageState({ isLoading: false, error: '' })
      } catch (error: unknown) {
        setPageState({ isLoading: false, error: String(error) })
      }
      let rewards: Rewarads[] = []
      if (account) {
        try {
          rewards = await dispatch(getRewardsByClassIds(classIds, account))
          setRewards(rewards)
        } catch (error) {
          console.log(error)
          setRewards([])
        }
      }
    })()
  }, 300, [appChainId, account, getSearchQuery])

  useAppDebounceEffect(() => {
    setTableData(bonds.map((bond, index) => {
      const rewardsPerClassId: Rewarads[]
        = rewards.filter(reward => String(reward.classId) === String(bond.classId))
      // .filter((reward, index, self) => { // remove duplicated rewards
      //   const otherIndex = self.findIndex(_reward => _reward.farmId === reward.farmId)
      //   return otherIndex !== index;
      // })
      let rewardsToClaim = 0;
      for (let i = 0; i < rewardsPerClassId.length; i++) {
        const reward = rewardsPerClassId[i];
        const rewardTokenPrice = roiPageContext.rewardTokenPrices[reward.rewardToken.toLowerCase()];
        const amount = decimalAmountToExactAmount(reward.amount, rewardTokenPrice?.decimals || 18)
        rewardsToClaim += (+(rewardTokenPrice?.usd || '0') * amount)
      }
      return {
        no: index + 1,
        name: `(#${bond.viewId}) ${bond.token.symbol}`,
        transactionHash: bond.transactionHash,
        viewId: bond.viewId,
        classId: bond.classId,
        rewardsToClaim: rewardsToClaim,
        rewards: rewardsPerClassId,
        defaultExpanded: false
      }
    }))
  }, 200, [bonds, rewards, roiPageContext])

  return (
    <div className='mb-30'>
      <PageTitleSection className='mt-10'>
        <MainTitle className='mb-0 text-start ml-0'>Self Custody Farming</MainTitle>
        <MainDesc className='pl-0 mt-5 mb-30 text-start ml-0'>
          By simply holding your bond, you can earn rewards from multiply assets
        </MainDesc>
      </PageTitleSection>
      <div>
        <SearchBox className='w-full py-10 flex-row flex-nowrap fs-lg mt-20'>
          <Dropdown className='dropdown dark mr-20'>
            <button className='btn bg-none dropdown-toggle radius-8 d-flex align-items-center' type='button'
              onClick={() => setShowSelectNetwork(true)}
            >
              <p className='mb-0 py-5 pr-15'>Search By Network</p>
            </button>
          </Dropdown>
          <Dropdown className='dropdown dark mr-20'>
            <button
              type='button'
              className='btn bg-none dropdown-toggle radius-8 d-flex align-items-center'
              onClick={() => setShowSelectBondPopup(true)}
            >
              <p className='mb-0 py-5 pr-15'>{selectedBond === null ? 'Choose a bond' : `#${selectedBond.viewId}(${selectedBond.paramerters.token.symbol}-${selectedBond.paramerters.pairToken.symbol})`}</p>
            </button>
          </Dropdown>
          <SearchInputWrapper className='pl-20 pr-20 d-flex flex-1 align-items-center'>
            <div className='text-white d-flex align-items-center text-nowrap'>
              <FaSearch size={20} />
              <span className='pl-10 fs-2md'>Search</span>
            </div>
            {/* @ts-ignore TODO */}
            <PopInput as={DebounceInput} value={search} onChange={(e) => setSearch(e.target.value)} element='input' debounceTimeout={400} className='pl-10 flex-1 fw-normal' placeholder='' />
            <FaFilter size={15} />
          </SearchInputWrapper>
          {
            walletConnectRequired ? (
              <AppButton className='outline-black fw-bold w-auto px-50' onClick={connectWallet}>Connect Wallet</AppButton>
            ) : (
              <AppButton className='w-auto outline-black hover:outline-primary fs-2md text-primary fw-bold px-25' onClick={handleOnClickAddNew}>ADD REWARDS / AIRDROPS</AppButton>
            )
          }
        </SearchBox>
        <div className='mt-30'>
          {
            pageState.isLoading ? (
              <div className='h-min-10vh w-full d-flex align-items-center justify-content-center'>
                <AppLoadingSpinner />
              </div>
            ) : (
              <AppDataTable
                columns={columns}
                data={tableData}
                emptyTableData={emptyTableData}
                customStyles={DoubleTableCustomStyles}
                pagination
                dense
                onRowClicked={hanlleOnRowClicked}
                expandableRows
                expandableRowsHideExpander
                expandableRowExpanded={row => row.defaultExpanded}
                expandableRowsComponent={BondHoldersExpandableRowComponent}
                requireWalletConnect={walletConnectRequired}
                onClickConnectWallet={connectWallet}
                paginationComponent={(props) => (
                  <div className='d-flex'>
                    <CustomReactTablePagination {...props} />
                  </div>
                )}
              />
            )
          }
        </div>
      </div>
      <SelectNetwork isOpen={showSelectNetwork} onDismiss={() => setShowSelectNetwork(false)} />
      <AddNewRewardPopup isOpen={showAddNewRewardsModal} dismiss={handleOnDismissRewardsModal} onSubmit={handleOnSubmit} />
      <SelectBondPopup isOpen={showSelectBondPopup} dismiss={handleOnDismissSelectBondModal} onSelect={handleOnSelectBond} />
      <TransactionModals.Success isOpen={isTRMOpen} close={closeTRModal} hash={TRMHash} />
    </div>
  )
}

export default withConnectWalletModal(BondHoldersTabContent)
