import tooltip from 'app-constants/tooltip-texts'
import AppTooltip from 'Component/AppTooltip'
import { AppButton, BackButton } from 'Component/core/elements/Buttons'
import { Divider } from 'Component/core/elements/Divider'
import { LaunchPoolInputGroup, SForms1 } from 'Component/core/elements/IBO-SharedComponents'
import { InputGroup, InputGroupText } from 'Component/core/elements/InputGroup'
import { RadioButton } from 'Component/core/elements/RadioButton'
import { smartSymbol } from 'helper'
import useAppSnackbar, { AppWaringOptions } from 'hooks/useAppSnackbar'
import React, { useCallback, useMemo } from 'react'
import { BsQuestionCircleFill } from 'react-icons/bs'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { nextStep, prevStep, setParamsInvestmentLimit, setParams, setInvestmentLimitState } from 'redux/reducers/lp-insure/actions'
import { LPInsureSelector } from 'redux/selectors'
import { AppVaildate, InvestmentLimitParams, LPInsureReducerParams } from 'types'

function LPInsureFundraisingTerms() {
  const dispatch = useAppDispatch()
  const lpInsure = useAppSelector(LPInsureSelector)
  const [openWaringSnackbar] = useAppSnackbar(AppWaringOptions)

  const isInvalidMininum = useMemo<AppVaildate>(() => {
    if(+lpInsure.investmentLimit.minimum > 0 && lpInsure.investmentLimit.maximum <= lpInsure.investmentLimit.minimum) {
      return {
        isInvalid: true,
        message: 'Minimum amount should be smaller than Maximum amount',
      }
    }
    return {
      isInvalid: false,
      message: '',
    }
  }, [lpInsure.investmentLimit.maximum, lpInsure.investmentLimit.minimum])

  const setLPInsureReducerParams = useCallback((key: keyof LPInsureReducerParams, value: any) => {
    dispatch(
      setParams({
        [key]: value
      })
    )
  }, [])
  const handleOnChangeToken = useCallback(({ value }: NumberFormatValues) => {
    setLPInsureReducerParams('amountOfSupply', value)
  }, [])

  const onChangeInvestmentLimitState = (state: boolean) => {
    dispatch(setInvestmentLimitState(state))
  }
  const onChangeInvestmentLimit = (_name: keyof InvestmentLimitParams, _value: any) => {
    dispatch(
      setParamsInvestmentLimit({
        [_name]: _value,
      })
    )
  }
  const handleOnClickNextStep = () => {
    // TODO validate fields
    if(+lpInsure.amountOfSupply < 2) {
      return openWaringSnackbar('Amount of supply should be bigger than 2')
    }
    if(isInvalidMininum.isInvalid) {
      return openWaringSnackbar(isInvalidMininum.message)
    }
    dispatch(nextStep())
  }
  const handleOnClickback = () => {
    dispatch(prevStep())
  }

  return (
    <React.Fragment>
      <div className='py-20'>
        <BackButton onClick={handleOnClickback} />
      </div>
      <div>
        <LaunchPoolInputGroup className='my-40'>
          <div className='my-10 label-section'>
            <label className='text-nowrap fw-bold'>Choose amount of supply
              <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
            </label>
          </div>
          <div className='my-10 input-section position-relative'>
            <InputGroup className={`w-full ${+lpInsure.amountOfSupply > +lpInsure.tokenInfo.balance ? 'danger' : ''}`}>
              <NumberFormat
                displayType='input'
                value={lpInsure.amountOfSupply}
                onValueChange={handleOnChangeToken}
                isAllowed={({ value }) => {
                  return +value <= +lpInsure.tokenInfo.balance
                }}
                data-type='light'
                data-html='true'
                data-class='data-tooltip'
                data-tip={`Balance ${lpInsure.tokenInfo.balance}`}
              />
              <InputGroupText className='text-uppercase'>[{ lpInsure.tokenInfo.symbol || 'token' }]</InputGroupText>
            </InputGroup>
            <div className='position-absolute right-0 fs-sm'>
              <button className='text-muted' onClick={() => setLPInsureReducerParams('amountOfSupply', lpInsure.tokenInfo.balance)}>Balance: ${lpInsure.tokenInfo.balance}</button>
            </div>
          </div>
        </LaunchPoolInputGroup>
        <Divider className='vertical mt-20 mb-10 highlighted-border-bottom' />
        <SForms1 className='my-40'>
          <tbody>
            <tr>
              <td>
                <label className='fw-bold mt-15'>
                  Investment limit per wallet
                  <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Invest_Limit_Per_Wallet')} />
                </label>
              </td>
              <td></td>
              <td colSpan={2} className='w-auto'>
                <div className='d-flex justify-content-between w-full w-max-200'>
                  <RadioButton
                    id='investment-limit-Yes'
                    name='investmentlimit'
                    className='d-flex align-items-center py-10'
                    label={
                      <label htmlFor='investment-limit-Yes' className='ml-10 pt-5 text-capitalize'>
                        Yes
                      </label>
                    }
                    checked={lpInsure.investmentLimit.isSet === true}
                    onChange={() => onChangeInvestmentLimitState(true)}
                  />
                  <RadioButton
                    id='investment-limit-no'
                    name='investmentlimit'
                    className='d-flex align-items-center py-10'
                    label={
                      <label htmlFor='investment-limit-no' className='ml-10 pt-5 text-capitalize'>
                        No
                      </label>
                    }
                    checked={lpInsure.investmentLimit.isSet === false}
                    onChange={() => onChangeInvestmentLimitState(false)}
                  />
                </div>
                <div className={`${lpInsure.investmentLimit.isSet ? 'd-flex' : 'd-none'} mt-20`}>
                  <div className='row w-full'>
                    <div className='col-6 position-relative'>
                      <label className='py-10 fw-bold'>Minimum</label>
                      <InputGroup className={isInvalidMininum.isInvalid ? 'danger' : ''}>
                        <NumberFormat
                          displayType='input'
                          value={lpInsure.investmentLimit.minimum}
                          onValueChange={({ value }: NumberFormatValues) => onChangeInvestmentLimit('minimum', value)} 
                        />
                        <InputGroupText className='px-15'>{smartSymbol(lpInsure.pairToken.symbol)}</InputGroupText>
                      </InputGroup>
                    </div>
                    <div className='col-6'>
                      <label className='py-10 fw-bold'>Maximum</label>
                      <InputGroup>
                        <NumberFormat displayType='input' value={lpInsure.investmentLimit.maximum} onValueChange={({ value }: NumberFormatValues) => onChangeInvestmentLimit('maximum', value)} />
                        <InputGroupText className='px-15'>{smartSymbol(lpInsure.pairToken.symbol)}</InputGroupText>
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </SForms1>
        <Divider className='vertical mt-20 mb-10 highlighted-border-bottom' />
        <div className='d-flex justify-content-center my-80 position-relative'>
          <BackButton className='position-absolute left-0 top-22' onClick={handleOnClickback} />
          <AppButton className='text-capitalize outline-primary w-max-500' onClick={handleOnClickNextStep}>
            next step
          </AppButton>
        </div>
      </div>
      <AppTooltip />
    </React.Fragment>
  )
}

export default LPInsureFundraisingTerms