import styled from "styled-components";
import { FlexDiv } from "Component/core/elements/others/styled";
import Media from "Theme/media-breackpoint";

export const SSIconMBX01 = styled(FlexDiv)`
  width: 100%;
  position: relative;
  min-height: 42px;
`

export const HeadCenterbox = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  padding: 6px;
  overflow: hidden;

  &.supported-chains {
    align-items: flex-start;
  }

  .title {
    margin-right: 10px;
    margin-top: 11px;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colorLightGrey};
    white-space: nowrap;
    ${Media.md} {
      display: block;
      margin-right: 0px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  .support-list {
    display: flex;
    overflow: hidden;
    a {
      display: flex;
      width: 34px;
      min-width: 34px;
      height: 34px;
      min-height: 34px;
      background-color: #16191e;
      border-radius: 25px;
      align-items: center;
      justify-content: center;
      padding: 4px;
      margin: 4px 9px;
      :hover {
        background-color: #000;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
    // ${Media.md} {
    //   justify-content: center;
    // }
  }
`