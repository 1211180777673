import React, { HTMLAttributes } from 'react'
import { BsQuestionCircleFill } from 'react-icons/bs'
import { DebounceInput } from 'react-debounce-input'
import TokenIcon from 'Component/TokenIcon'
import * as S from './styled'
import { TokenBalanceSpan } from 'Component/core/elements/others/global-components'
import { numberFormat } from 'helper'
import { LoadingSpinner } from 'Component/AppLoadingSpinner'

interface IDetailItemProps extends React.DOMAttributes<HTMLDivElement> {
  label: string | any
  value: string | JSX.Element | any
  helpText?: string
}

export function DetailItem({ label, value, helpText,...otherProps }: IDetailItemProps) {
  return (
    <div className='d-flex justify-content-between my-3' {...otherProps}>
      <div className=''>
        {label}
        {helpText && <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={helpText} />}
      </div>
      <div className=''>{value}</div>
    </div>
  )
}

interface IInvestInputProps extends HTMLAttributes<HTMLDivElement> {
  tokenAddress: string
  valueInUsd: number
  max: { symbol: string, value: number },
  isLoading: boolean
  isInvalid: boolean
  investDisabled?: boolean
  hideWithdraw: boolean
  value: string | number
  onClickWithdraw?: React.MouseEventHandler<HTMLButtonElement>
  onClickInvest?: () => void;
  onChangeInvestInput: (value: number | string) => void,
  onClickMax?: React.MouseEventHandler<HTMLButtonElement>
}

export function InvestInput({
  tokenAddress,
  valueInUsd,
  max,
  hideWithdraw,
  isLoading,
  isInvalid,
  investDisabled = false,
  value,
  onClickWithdraw,
  onClickInvest,
  onChangeInvestInput,
  onClickMax,
  ...props
}: IInvestInputProps) {

  const handleInvestmentInput = async(value: string | number) => {
    onChangeInvestInput(value)
  }

  return (
    <div {...props}>
      <S.SInputWrap className={`radius-10 ${isInvalid ? 'invalid' : ''}`}>
        <TokenIcon as={S.TokenImage} byAddress={tokenAddress} className='px-10 rounded-circle' alt='Coin Icon' />
        <DebounceInput
          className='bg-bodybg03 my-2 px-10 text-white radius-0'
          element='input'
          type='number'
          placeholder='0'
          debounceTimeout={400}
          disabled={investDisabled}
          value={String(value)}
          onKeyDown={(e) => {
            if (e.key === 'e' || e.key === 'E') {
              e.stopPropagation()
              e.preventDefault()
            }
          }}
          onChange={(e) => {
            const value = numberFormat(e.target.value)
            handleInvestmentInput(value)
          }}
        />
        <S.InvestButton className={`${investDisabled ? 'disabled' : 'primary'}`} onClick={onClickInvest}
          disabled={investDisabled || isLoading}
        >
          {isLoading ? <div><LoadingSpinner /></div> : 'Invest'}
        </S.InvestButton>
      </S.SInputWrap>
      <div className='mt-10 d-flex align-items-start text-muted h-min-40 pl-2'>
        <S.MaxButton className='text-muted fs-sm fw-bold -mt-2' onClick={onClickMax}>
          Max
        </S.MaxButton>
        <div className='d-flex w-full justify-content-between align-items-start pl-30 fs-sm'>
          <div>
            {max.value > 0 ? (
              <>
                <p className='mb-0'>[<TokenBalanceSpan prefix='$'>{valueInUsd}</TokenBalanceSpan>]</p>
                <p>
                  <TokenBalanceSpan digits={6}>{max.value}</TokenBalanceSpan>&nbsp;
                  {max.symbol}
                </p>
              </>
            ) : (
              <p className='mb-10 mt-2'>No limit</p>
            )}
          </div>

          <button className='text-danger' hidden={hideWithdraw} onClick={onClickWithdraw}>
            Withdraw supply
          </button>
        </div>
      </div>
    </div>
  )
}
