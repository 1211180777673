import FlipNumbers from 'react-flip-numbers'
import type { FlipNumbersProps } from 'react-flip-numbers'
import styled from 'styled-components'
import { FlexDiv } from 'Component/core/elements/others/global-components'

type FlipNumberWrapperProps = {
  border: number
  spacing: number
  numberHeight: number
  numberWidth: number
}

export const FlipNumberWrapper = styled.div<FlipNumberWrapperProps>`
  & > section {
    & > span {
      border: ${(props) => props.border}px solid ${(props) => props.theme.grayBorder3};
      box-sizing: content-box;
      border-radius: 15%;
      margin-left: ${(props) => props.spacing}px;
      margin-right: ${(props) => props.spacing}px;
    }
  }
  &.currency {
    & > section {
      align-items: flex-end !important;

      & > span {
        font-family: var(--dx-ff-secondary) !important;

        &:nth-child(1) {
          display: flex;
          justify-content: center;
          align-items: center;
          border: ${(props) => props.border}px solid ${(props) => props.theme.grayBorder3} !important;
          background-color: ${(props) => props.theme.bodybg014};
          height: ${(props) => props.numberHeight}px;
          width: ${(props) => props.numberWidth}px;
        }
      }
    }
  }
`

export const TotalRaiseWrap = styled(FlexDiv)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > p {
    font-family: var(--dx-ff-secondary);
    font-size: ${({ theme }) => theme.fontSize['2md']};
    font-weight: 800;
    text-shadow: 0 1px ${(props) => props.theme.colorGrey9};
  }
`

type AppFlipNumbersProps = FlipNumbersProps & {
  type?: 'currency' | '',
  numberWidth?: number
  numberHeight?: number
  background: string
  border?: number
  spacing?: number
  className?: string
  fontSize?: string
}

export function AppFlipNumbers(
  { type = 'currency', numberWidth = 55, numberHeight = 60, background, border = 1, spacing = 5, className = '', fontSize = '40px', ...props }: AppFlipNumbersProps
) {
  return (
    <FlipNumberWrapper className={`${type} ${className}`} numberWidth={numberWidth} numberHeight={numberHeight} spacing={spacing} border={border}>
      <FlipNumbers
        // @ts-ignore
        numberStyle={{
          background,
          fontSize: fontSize,
        }}
        // @ts-ignore
        nonNumberStyle={{
          fontSize: fontSize,
          border: 'none',
        }}
        // @ts-ignore
        height={numberHeight}
        // @ts-ignore
        width={numberWidth}
        {...props}
      />
    </FlipNumberWrapper>
  )
}
