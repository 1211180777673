import { useState, useCallback, useMemo, useContext } from 'react'
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from 'recharts'
import { DefaultTheme, ThemeContext } from 'styled-components'
import TokenIcon from '../TokenIcon'
import { BMRight, BMTitle, Box1BottomPart, Box1MiddlePart, Box1Title, Box1TopPart, GraphTypeOption, GraphTypeSelect } from './styled'
import { useAppChain } from 'hooks'
import { AlternativeTokenDataSubgraphs } from 'app-constants'
import { createClient, GetTokenDayDatas } from 'helper/graphql/index'
import moment from 'moment'
import { GraphData, GraphDataType, GraphTypes, TokenDayData } from 'types'
// import DegenApis from 'helper/degen-apis'
import AppLoadingSpinner from 'Component/AppLoadingSpinner'
import { AmountLabel } from 'Component/core/elements/others/global-components'
import useAppDebounceEffect from 'hooks/useAppDebounceEffect'
// import { getCoinBySymbol } from 'helper'

const graphTypes: GraphTypes = {
  days: {
    id: 'days',
    label: 'day',
    days: 3,
    periodLabel: 'day',
    periodValue: 'past 1 day',
    labelFormat: 'MMM DD, YYYY',
  },
  week: {
    id: 'week',
    label: 'week',
    days: 7,
    periodLabel: 'week',
    periodValue: 'past 1 week',
    labelFormat: 'MMM DD',
  },
  month: {
    id: 'month',
    label: 'month',
    days: 31,
    periodLabel: 'month',
    periodValue: 'past 1 month',
    labelFormat: 'MMM DD',
  },
  year: {
    id: 'year',
    label: 'year',
    days: 365,
    periodLabel: 'year',
    periodValue: 'past 1 year',
    labelFormat: 'MMM DD, YYYY',
  },
}

interface ITokenSubgraphProps {
  token: {
    address: string
    name: string
    symbol: string
  }
}

type TokenGraphData = [number, number] // [unix time stamp, price in usd]

function TokenSubgraph({ token }: ITokenSubgraphProps): JSX.Element {
  const { appChainId } = useAppChain()
  const { colorPrimary, colorPrimary04 } = useContext<DefaultTheme>(ThemeContext)

  const [graphData, setGraphData] = useState<GraphData[]>([])
  const [graphBaseData, setGraphBaseData] = useState<TokenGraphData[]>([])
  const [selectedGraphType, setSelectedGraphType] = useState<GraphDataType>(graphTypes.days)
  const [isLoadingTokenInfo, setIsLoadingTokenInfo] = useState<boolean>(true)
  const [changePercent, setChangePercent] = useState('')
  const [changeAmount, setChangeAmount] = useState('')
  const [selectedValue, setSelectedValue] = useState('')

  const onGraphTypeChange = useCallback((e: any) => {
    const keys = Object.keys(graphTypes)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const graphTypeKey = key as keyof GraphTypes
      if (graphTypes[graphTypeKey].id === e.target.value) {
        setSelectedGraphType(graphTypes[graphTypeKey])
        break
      }
    }
  }, [])

  const CustomTooltip = useCallback(({ active, payload }: { active: boolean, payload: any, label: any }) => {
    if (active) {
      setSelectedValue((payload?.[0].payload?.real || 0).toFixed(5))
    }
    let _timeStamp
    if(payload && payload.length > 0) {
      _timeStamp = moment(payload[0].payload.timestamp).format('lll')
    }
    return <div>{_timeStamp}</div>
  }, [])

  const getTokenDataFromSubgraph = useCallback(async (_graphType: GraphDataType): Promise<TokenGraphData[] | null> => {
    const _symbol = token.symbol.toLowerCase()
    const updateData = async (_subgraphUrl: string): Promise<TokenGraphData[] | null> => {
      const subgraphClient = createClient(_subgraphUrl)
      try {
        const res = await subgraphClient.query({
          query: GetTokenDayDatas,
          variables: {
            where: {
              token_: {
                symbol_contains_nocase: _symbol
              },
            },
            first: _graphType.days
          }
        })
        const tokenDayDatas: TokenDayData[] | undefined = res.data?.tokenDayDatas as TokenDayData[]
        if (res.errors) {
          throw new Error(res.errors.join('\n'))
        } else if (!tokenDayDatas || tokenDayDatas.length == 0) {
          throw new Error('Token day data does not exist.')
        } else if (tokenDayDatas && tokenDayDatas.length > 0 && +tokenDayDatas[0].priceUSD === 0 && +tokenDayDatas[1]?.priceUSD === 0) {
          throw new Error('Token day data priceUSD is 0.')
        } else {
          const dayData: TokenGraphData[] = tokenDayDatas.map((t) => [+t.date, +t.priceUSD] as TokenGraphData)
          return dayData;
        }
      } catch (error: any) {
        console.error(error)
      }
      return null
    }

    const subgraphs = AlternativeTokenDataSubgraphs[appChainId]
    for (let i = 0; i < subgraphs.length; i++) {
      const _subgraph = subgraphs[i];
      const data = await updateData(_subgraph)
      if (data) return data
    }
    return null;
  }, [appChainId, token.symbol])

  // const getTokenDataFromDegenApi = useCallback(async (_graphType: GraphDataType): Promise<TokenGraphData[] | null> => {
  //   const coingeckoCoin = getCoinBySymbol(token.symbol)
  //   if(!coingeckoCoin) return null
  //   const res = await DegenApis.getTokenAndPriceByCoinId({ coingeckoCoinId: coingeckoCoin.id });

  //   const updateData = (): TokenGraphData[] | null | undefined => {
  //     const tokenData = res.data.data
  //     if (!tokenData) return null
  //     let _graphData: string  | undefined
  //     if (tokenData) {
  //       switch (_graphType.id) {
  //         case graphTypes.days.id:
  //           _graphData = tokenData.day_graph
  //           break
  //         case graphTypes.week.id:
  //           _graphData = tokenData.week_graph
  //           break
  //         case graphTypes.month.id:
  //           _graphData = tokenData.month_graph
  //           break
  //         case graphTypes.year.id:
  //           _graphData = tokenData.year_graph
  //           break
  //         default:
  //           break;
  //       }
  //     }
  //     if(_graphData) {
  //       const d = JSON.parse(_graphData) as TokenGraphData[]
  //       return d.reverse()
  //     }
  //     return null
  //   }
  //   const data = updateData()
  //   if (data) return data
  //   return null;
  // }, [appChainId, token.symbol])

  const refreshTokenData = useCallback(() => {
    const _graphData: GraphData[] = []
    if (graphBaseData.length > 0) {
      for (let i = 0; i < graphBaseData.length; i++) {
        const dayData = graphBaseData[i]
        const _timeStamp = dayData[0] * 1e3 // dayData[0].toString().length > 9 ? dayData[0] : dayData[0] * 1e3
        const name = moment(_timeStamp).format(selectedGraphType.labelFormat)
        const value = +dayData[1] || (i > 0 ? graphBaseData[i - 1]?.[1] : 0) // || (Math.random() * 1000)

        _graphData.push({
          timestamp: _timeStamp,
          name: name,
          real: value,
          value: value,
        })
      }
      // TODO update the 1
      const endValue = +graphBaseData[0][1] || 1
      const startValue: number = +graphBaseData[graphBaseData.length - 1][1] || 1
      const _changed = endValue - (+startValue)
      const percent = (Math.abs(_changed) / +startValue) * 1e2

      if (_changed >= 0) {
        setChangeAmount('+' + Math.abs(_changed).toFixed(5))
        setChangePercent('+' + percent.toFixed(2))
      } else {
        setChangeAmount('-' + Math.abs(_changed).toFixed(5))
        setChangePercent('-' + percent.toFixed(2))
      }
      setSelectedValue(graphBaseData[0]?.[1].toString() || '0')
      setGraphData(_graphData.reverse())
    } else {
      setChangeAmount('0')
      setChangePercent('0')
      setGraphData([])
    }
  }, [graphBaseData, selectedGraphType.labelFormat])

  useAppDebounceEffect(() => {
    const run = async () => {
      try {
        setIsLoadingTokenInfo(true)
        // let tokenData: TokenGraphData[] | null = await getTokenDataFromDegenApi(selectedGraphType)
        // if(!tokenData) {
        //   tokenData = await getTokenDataFromSubgraph(selectedGraphType)
        // }
        const tokenData: TokenGraphData[] | null = await getTokenDataFromSubgraph(selectedGraphType)
        setGraphBaseData(tokenData || [])
      } catch (error: any) {
        setGraphBaseData([])
        console.error(error)
      } finally {
        setIsLoadingTokenInfo(false)
      }
    }
    run()
  }, 800, [getTokenDataFromSubgraph, selectedGraphType])

  useAppDebounceEffect(() => {
    refreshTokenData()
  }, 400, [refreshTokenData])

  useAppDebounceEffect(() => {
    setSelectedValue('0')
  }, 300, [token.symbol])

  const chartsElts = useMemo(() => {
    return <>
      {token.name !== 'Select Token' ? (
        <Box1BottomPart>
          <span>
            <span className={`${changeAmount.search('-') > -1 ? 'text-danger' : ''}`}>{+changeAmount}</span>
            <span className={changePercent.search('-') < -1 ? 'text-danger' : ''}>({+changePercent}%)</span>
          </span>
          <span className='text-capitalize'>&nbsp;{selectedGraphType.periodLabel}</span>
        </Box1BottomPart>
      ) : null}
      <div>
        <ResponsiveContainer height={200} minWidth={'50px'} minHeight='50px' width={'100%'}>
          <AreaChart data={graphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id='colorValue' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor={colorPrimary04} stopOpacity={1} />
                <stop offset='95%' stopColor={colorPrimary04} stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey='name' />
            {/* @ts-ignore */}
            <Tooltip itemStyle={{ display: 'none' }} contentStyle={{ display: 'none' }} content={<CustomTooltip />} />
            <Area type='monotone' dataKey='value' stroke={colorPrimary} fillOpacity={1} fill='url(#colorValue)' />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  }, [changeAmount, changePercent, selectedGraphType.periodLabel, graphData, colorPrimary, colorPrimary04])

  return (
    <div className='h-full position-relative'>
      <Box1TopPart>
        <div className='img-outer'>
          <TokenIcon byAddress={token.address} alt='' />
        </div>
        <Box1Title>{token.symbol}</Box1Title>
      </Box1TopPart>

      {token.name !== 'Select Token' ? (
        <Box1MiddlePart className='mb-10'>
          <BMTitle className='d-flex align-items-center'>
            <AmountLabel>{selectedValue}</AmountLabel>
          </BMTitle>
          <BMRight>
            <GraphTypeSelect value={selectedGraphType.id} onChange={onGraphTypeChange} className='text-capitalize'>
              {Object.keys(graphTypes).map((key, index) => {
                const graphTypeKey = key as keyof GraphTypes
                return (
                  <GraphTypeOption key={index} value={graphTypes[graphTypeKey].id}>
                    {graphTypes[graphTypeKey].label}
                  </GraphTypeOption>
                )
              })}
            </GraphTypeSelect>
          </BMRight>
        </Box1MiddlePart>
      ) : null}
      {
        isLoadingTokenInfo ? (
          <div className='mt-40'>
            <AppLoadingSpinner />
          </div>
        ) : chartsElts
      }
    </div>
  )
}

export default TokenSubgraph
