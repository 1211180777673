import { LPInsureData } from "types"

export enum ActionTypes {
  FETCH_INSURE_LIST           = 'LP_INSURE_LIST/FETCH_INSURE_LIST',
  SET_ERROR_MESSAGE           = 'LP_INSURE_LIST/SET_ERROR_MESSAGE',
  SET_SEARCH_VALUE            = 'LP_INSURE_LIST/SET_SEARCH_VALUE',
  IS_LOADING_INSURE_LIST      = 'LP_INSURE_LIST/IS_LOADING_INSURE_LIST',
  SET_INVEST_LENGTH_IN_24HRS  = 'LP_INSURE_LIST/SET_INVEST_LENGTH_IN_24HRS',
  SET_VOTE_TO_OFFER           = 'LP_INSURE_LIST/SET_VOTE_TO_OFFER',
}

interface ActionFetchBondList {
  type: ActionTypes.FETCH_INSURE_LIST
  payload: LPInsureData[]
}
interface ActionSetSearchValue {
  type: ActionTypes.SET_SEARCH_VALUE
  payload: string
}
interface ActionIsLoadingBondList {
  type: ActionTypes.IS_LOADING_INSURE_LIST
  payload: boolean
}
interface ActionSetInvestLengthIn24Hrs {  
  type: ActionTypes.SET_INVEST_LENGTH_IN_24HRS
  payload: number
}
interface ActionSetVoteToOffer {  
  type: ActionTypes.SET_VOTE_TO_OFFER
  payload: number // offerID
}
interface ActionSetErrorMessage {  
  type: ActionTypes.SET_ERROR_MESSAGE
  payload: string
}

export type ActionLPInsureList = ActionFetchBondList | ActionSetSearchValue | ActionIsLoadingBondList | ActionSetInvestLengthIn24Hrs
  | ActionSetVoteToOffer | ActionSetErrorMessage
