import styled from 'styled-components'
import { AppScrollbarCSS } from './others/styled'

export const SOffcanvas = styled.div`
  width: auto;

  & .offcanvas-body {
    ${AppScrollbarCSS}
  }
`
