import { TransactionSpeed } from "types"

export enum ActionTypes {
  TOGGLE_MULTIHOPS = 'SETTING/TOGGLE_MULTIHOPS',
  SET_SLIPPAGE = 'SETTING/SET_SLIPPAGE',
  SET_DEADLINE = 'SETTING/SET_DEADLINE',
  SET_SPEED = 'SETTING/SET_SPEED',
}

export interface ActionToggleMultihops {
  type: ActionTypes.TOGGLE_MULTIHOPS
}
export interface ActionSetSlippage {
  type: ActionTypes.SET_SLIPPAGE
  payload: number
}
export interface ActionSetDeadline {
  type: ActionTypes.SET_DEADLINE
  payload: number
}
export interface ActionSetSpeed {
  type: ActionTypes.SET_SPEED
  payload: TransactionSpeed
}

export type ActionSetting = ActionToggleMultihops | ActionSetSlippage | ActionSetDeadline | ActionSetSpeed