import React, { useMemo, useState, useCallback } from 'react'
import { BsQuestionCircleFill } from 'react-icons/bs'
import { TableColumn, TableStyles } from 'react-data-table-component'
import { MainDesc, MainTitle, PageTitleGroup } from 'Component/core/elements/Text'
import tooltip from 'app-constants/tooltip-texts'
import { numberFormater } from 'helper'
import { AppButton } from 'Component/core/elements/Buttons'
import CustomReactTablePagination from 'Component/core/elements/CustomReactDataTableElements'
import AppTooltip from 'Component/AppTooltip'
import useAppDebounceEffect from 'hooks/useAppDebounceEffect'
import { useAppSelector } from 'redux/hooks'
import { GlobalsSelector } from 'redux/selectors'
import { PageLoadState } from 'types'
import { getPendingRewards } from 'helper/multi-calls/pool-multicall-utils'
import { decimalAmountToExactAmount } from 'helper/math'
import { TokenBalanceSpan } from 'Component/core/elements/others/global-components'
import styled from 'styled-components'
import AppDataTable from 'Component/AppDataTable'
import useWallet from 'hooks/useWallet'
import { withConnectWalletModal } from 'redux/actions'
import AppLoadingSpinner from 'Component/AppLoadingSpinner'
import BondSubgraph from 'helper/subgraphs/BondSubgraph'

const ClaimButton = styled(AppButton)`
  padding-top: 2px;
  padding-bottom: 2px;
  max-width: 200px;
`

type TableRowType = {
  name: string
  lpAddress: string
  availableToClaim: number
}
type RewardsType = {
  [key in string]: string;
}

type OwnBond = {
  name: string;
  lpAddress: string;
}

const customStyles: TableStyles = {
  table: {
    style: {
      backgroundColor: 'transparent',
    }
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      color: '#8e9196',
      background: 'transparent',
      borderTopWidth: '2px',
      borderBottomWidth: '2px',
      borderColor: '#8e9196',
      height: '50px',
      fontFamily: 'var(--dx-ff-primary)'
    },
  },
  headCells: {
    style: {
      fontSize: '15px',
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        color: '#8e9196',
        borderRightWidth: '1px',
        borderColor: '#8e9196',
      },
    },
  },
  cells: {
    style: {
      background: 'transparent',
      borderRightWidth: '1px',

      '& div:first-child': {
        overflow: 'inherit !important'
      },
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderColor: '#8e9196',
      },
    },
  },
  rows: {
    style: {
      borderRightWidth: '0px',
      height: '50px',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderColor: '#8e9196',
      borderBottomColor: '#8e9196 !important',
      color: '#8e9196',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: '#272a31'
      }
    },
  }
}

const emptyTableData: TableRowType[] = [
  {
    name: '',
    lpAddress: '',
    availableToClaim: 0
  }
]

function ProjectTabContent({ connectWallet }: { connectWallet: () => void }) {
  const { active } = useWallet()
  const { appChainId, account } = useAppSelector(GlobalsSelector)
  const [pageState, setPageState] = useState<PageLoadState>({ error: '', isLoading: true });

  const [tableData, setTableData] = useState<TableRowType[]>([])
  const [ownBonds, setOwnBonds] = useState<OwnBond[]>([])
  const [rewards, setRewards] = useState<RewardsType>({})

  const totalRewards: number = useMemo(() => {
    const values = Object.values(rewards).map((v) => +v);
    const sum = values.length > 0 ? values.reduce((accumulator, currectValue) => accumulator + currectValue) : 0;
    return decimalAmountToExactAmount(sum, 18)
  }, [rewards])

  useAppDebounceEffect(() => {
    (async () => {
      try {
        setPageState({ isLoading: true, error: '' })
        const subgraph = new BondSubgraph(appChainId);
        const where = {
          poolParam_: {
            projectWallet: `${account}`.toLowerCase()
          }
        }
        const res = await subgraph.getBonds(where);
        if (res.data.bonds.length > 0) {
          setOwnBonds(res.data.bonds.map((item) => ({
            name: `${item.paramerters.token.symbol} <> ${item.paramerters.pairToken.symbol}`,
            lpAddress: item.poolParam.poolPair.lpAddress.toLowerCase(),
          })));
        } else {
          setOwnBonds([])
        }
        setPageState({ isLoading: false, error: '' })
      } catch (error) {
        setPageState({ isLoading: false, error: String(error) })
      }
    })()
  }, 300, [appChainId, account])
  useAppDebounceEffect(() => {
    (async () => {
      const pendingRewards = await getPendingRewards(appChainId, ownBonds.map((item) => item.lpAddress), account);
      setRewards(pendingRewards)
    })()
  }, 300, [appChainId, account, ownBonds])

  useAppDebounceEffect(() => {
    setTableData(ownBonds.map((item) => {
      const rewardValue = rewards[item.lpAddress];
      return {
        name: item.name,
        lpAddress: item.lpAddress,
        availableToClaim: decimalAmountToExactAmount(rewardValue || '0', 18) || 0, // 18 is standard decimals of derex pool
      }
    }))
  }, 100, [ownBonds, rewards])

  const handleOnClickClaim = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const lpAddress: string = e.currentTarget.dataset.lpaddress as string;
      const claim: string = e.currentTarget.dataset.claim as string;
      console.log('lpAddress: ', lpAddress)
      console.log('claim: ', claim)
    },
    [],
  )


  const columns = useMemo<TableColumn<TableRowType>[]>(
    () => [
      {
        name: 'Pools',
        cell: (row) => {
          return <a href='#' className='token-name'>{row.name}</a>
        }
      },
      {
        name: '$DRX available to claim',
        style: {
          color: 'white'
        },
        cell: (row) => {
          return <p>{numberFormater(4).format(+row.availableToClaim)}</p>
        }
      },
      {
        name: 'Claim',
        center: true,
        maxWidth: '200px',
        cell: (row) => {
          return (
            <ClaimButton
              className='outline-black hover:outline-primary fs-md text-primary fw-normal radius-3'
              data-lpaddress={row.lpAddress}
              data-claim={row.availableToClaim}
              onClick={handleOnClickClaim}
            >Claim</ClaimButton>
          )
        },
      },
    ],
    [handleOnClickClaim]
  )

  return (
    <div className='mb-30'>
      <PageTitleGroup className='flex-column'>
        <MainDesc className='w-max-auto p-0 mx-0 text-justify'>
          <MainTitle as='div' className='text-start ml-0'>Airdrop super bonus</MainTitle>
          DEREX did not conduct any presale or early investment offers, and the team does not hold any tokens.
          Instead, the project rewards users and projects utilizing DEREX and IBO by giving away $DRX tokens as a bonus.
          These tokens not only have no selling pressure from existing holders, but also have a correlated buying presuure model that uses 80% of generated fees to buy back and burn $DRX tokens
        </MainDesc>
      </PageTitleGroup>
      <div className='mb-20'>
        <p className='text-danger text-title'>$DRX tokens are subject to Dumper Shield restriction
          <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Coming Soon')} />
        </p>
        {
          pageState.isLoading ? (
            <div className='h-min-15vh w-full d-flex align-items-center justify-content-center'>
              <AppLoadingSpinner />
            </div>
          ) : (
            <AppDataTable
              emptyTableData={emptyTableData}
              requireWalletConnect={!active}
              columns={columns}
              data={tableData}
              customStyles={customStyles}
              pagination
              dense
              paginationComponent={(props) =>
                <div className='d-flex justify-content-between mt-15'>
                  <CustomReactTablePagination {...props} />
                  <div className='d-flex align-items-center mr-18'>
                    <div className='px-10 mr-20'>
                      <span className='text-muted fs-md'>Total $DRX to claim: <TokenBalanceSpan digits={8}>{totalRewards}</TokenBalanceSpan></span>
                    </div>
                    <ClaimButton className='w-auto outline-black hover:outline-primary fs-md text-primary fw-normal px-49 radius-3'>Claim All</ClaimButton>
                  </div>
                </div>
              }
              onClickConnectWallet={connectWallet}
            />
          )
        }
      </div>
      <AppTooltip />
    </div>
  )
}

export default withConnectWalletModal(ProjectTabContent)
