import { AppOtherConfigByNetwork, BondAlterNativeTokens, BondType, DexInfo, FeeStructure, NetworkUrls, RedemptionOption, RedemptionOptions, SmartContractConfig, SupportedNetworks, VestingOption, VestingOptions } from 'types'
import { ADDRESS_ONE } from './addresses'
import { BSwaprouter2ContractAddress } from './dynamic'
import tooltip from './tooltip-texts'

export const BOND_PROXY_CONTRACTS: SmartContractConfig = {
  [SupportedNetworks.mainnet]: 'TODO',
  [SupportedNetworks.bsc]: '0x4b40d94d3b9ae8c8716a34340dd9e68d3d9a717a',
  [SupportedNetworks.bsctestnetwork]: '0x2ef5468ba256d0ef529ed6eb71b6f0add5a61b7c',
}

export const BOND_MAIN_CONTRACTS: SmartContractConfig = {
  [SupportedNetworks.mainnet]: 'TODO',
  [SupportedNetworks.bsc]: '0x2911d8404319d8727995c33c713cbf3bcecef23b',
  [SupportedNetworks.bsctestnetwork]: '0xab403cdbecf7f91f88fb425728657cce767a1e09',
}

export const BOND_ALTERNATIVE_TOKENS: BondAlterNativeTokens = {
  BNB: ADDRESS_ONE,
  '0xae13d989dac2f0debff460ac112a837c89baa7cd': ADDRESS_ONE, // on TBSC network
  '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c': ADDRESS_ONE, // on BSC network
}

export const BondSubgraphs: NetworkUrls = {
  [SupportedNetworks.mainnet]: 'TODO',
  [SupportedNetworks.bsc]: 'https://api.thegraph.com/subgraphs/name/fhrrydeveloper/derex',
  [SupportedNetworks.bsctestnetwork]: 'https://api.thegraph.com/subgraphs/name/fhrrydeveloper/ibo-tbsc',
}

export const MainTokenSymbols: AppOtherConfigByNetwork = {
  [SupportedNetworks.mainnet]: 'ETH',
  [SupportedNetworks.bsc]: 'BNB',
  [SupportedNetworks.bsctestnetwork]: 'BNB',
}

export const defaultDex: DexInfo = {
  title: 'DEREX Exchange',
  router: BSwaprouter2ContractAddress[97],
  img: 'https://multiswap.derex.exchange//images/dex-icons/dynamic-swap.png',
  theGraphUri: '',
}

export const BOND_TYPE: BondType = {
  DISCOUNT: {
    label: '50% discount',  
    value: 'DISCOUNT',
  },
  IMPERMANENT: {
    label: 'Impermanent loss protection',
    value: 'IMPERMANENT',
  },
}

export const FEE_STRUCTURE: FeeStructure = {
  UP_WITH_FREE_FEE: {
    label: 'Up to 100%%% with 0% fee',
    tooltipText: tooltip('Bond_Pool_UP_TO_100BNB'),
    value: 0,
    fee: 0,
  },
  ABOVE_WITH_TOKEN_FEE_INTO_PDO_AND_Dumper: {
    label: 'Above 100%%% with 10% tokens fees enroll into the PDO and Dumper Shield',
    tooltipText: tooltip('Bond_Pool_5P_TOKEN_FEES_ENROLL'),
    value: 2,
    fee: 10,
  },
}

export const VESTING_FOR_PRINCIPAL: VestingOption = {
  CLIFF: VestingOptions.Cliff,
  GRADED: VestingOptions.Graded,
}
export const VESTING_PERIOD: VestingOption = {
  CLIFF: VestingOptions.Cliff,
  GRADED: VestingOptions.Graded,
}
export const VESTING_FOR_PROFIT: VestingOption = {
  CLIFF: VestingOptions.Cliff,
  GRADED: VestingOptions.Graded,
}
export const REDEMPTION_LEFTOVER: RedemptionOption = {
  BURN: RedemptionOptions.Burn,
  REFUND: RedemptionOptions.Refund,
}
