import { combineReducers } from 'redux'

import globalsReducer from './globals/globalSlice'
import tokenListReducer from './tokenList/tokenSlice'
import settingsReducer from './settings/settingsSlice'
import liquidityReducer from './liquidity/liquiditySlice'
import bondReducer from './bond/slice'
import bondListReducer from './bond-list/slice'
import bondDetailReducer from './bond-detail/slice'
import lpInsureReducer from './lp-insure/slice'
import lpInsureListReducer from './lp-insure-list/slice'
import taxReducer from './tax/slice'
import coingeckoReducer from './coingecko/coingeckoSlice'
import votesReducer from './votes/slice'
import poolsReducer from './imported-pools/slice'
import tourReducer from './tour/Slice'

const rootReducer = combineReducers({
  globals: globalsReducer,
  votes: votesReducer,
  tokenList: tokenListReducer,
  settings: settingsReducer,
  liquidity: liquidityReducer,
  importedPools: poolsReducer,
  bond: bondReducer,
  bondList: bondListReducer,
  bondDetail: bondDetailReducer,
  tax: taxReducer,
  coingecko: coingeckoReducer,
  lpInsure: lpInsureReducer,
  lpInsureList: lpInsureListReducer,
  tour: tourReducer,
})

export default rootReducer
