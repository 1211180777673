import Web3 from 'web3'
import type { AbiItem } from 'web3-utils'
import Web3Utils, { toBN } from 'web3-utils'
import { BaseToken, ConvertableTokenSymbolConfig, SupportedNetworks } from 'types'
// import BSwapfactoryAbi from 'Assets/Abi/BSwapFactoryAbi.json'
import BSwapPairAbi from 'Assets/Abi/BSwapPairAbi.json'
import {
  CONVERTABLE_TOKEN_SYMBOLS,
  DMC_POOL_ADDRESS_BSC,
  DMC_TOKEN_ADDRESS,
  MAIN_ZERO_ADDRESS,
  WBNB,
  ZERO_ADDRESS,
  NETWORKS,
} from 'app-constants'
import ERC20Abi from 'Assets/Abi/ERC20.json'
import tokenPriceApi from './tokenPriceApi'
// import Web3Helpers from './Web3Helpers'

const url = 'https://bsc-dataseed.binance.org'

// export const getValueForTesting = async ({ amount, fromToken, toToken, router, factoryContract}: {
//   amount: string, fromToken: string, toToken: string, router?: string, factoryContract?: string
// }) => {
//   const web3Instance = new Web3(new Web3.providers.HttpProvider(url))
//   const web3Helpers = new Web3Helpers(web3Instance)

//   const amt = await web3Helpers.getAmountOut({
//     fromToken,
//     toToken,
//     web3Instance: web3Instance.eth,
//     isBsc: true,
//     router,
//     factoryContract,
//     amount: +amount,
//   })
//   return amt
// }

// export const getPair = async (from: string, to: string, factory: string) => {
//   try {
//     const web3Instance = new Web3(new Web3.providers.HttpProvider(url)).eth

//     const factoryInstance = new web3Instance.Contract(BSwapfactoryAbi as AbiItem[], factory)
//     const pair = await factoryInstance.methods.getPair(from, to).call()
//     return pair
//   } catch (e) {
//     return ZERO_ADDRESS
//   }
// }

// export const getImpactForTesting = async (from: string, to: string, fromAmt: number | string, toAmt: number | string, factory: string) => {
//   if (!fromAmt || !toAmt) return 0
//   let reserveRatio
//   fromAmt = Web3Utils.toWei(String(fromAmt))
//   toAmt = Web3Utils.toWei(String(toAmt))
//   const swapFee = factory === BSwapfactoryContractAddress[SupportedNetworks.bsctestnetwork] ? 0.25 : 0.3 // TODO
//   const pairAddress = await getPair(from, to, factory)
//   // const r = await getReservesRatio(from, pairAddress);
//   if (pairAddress !== ZERO_ADDRESS) reserveRatio = await getReservesRatio(from, pairAddress)
//   else {
//     const mediator = factory === BSwapfactoryContractAddress[SupportedNetworks.bsctestnetwork] ? WBNB : WETH // TODO
//     const pairAB = await getPair(from, mediator, factory)
//     const pairBC = await getPair(mediator, to, factory)
//     const ratioAB = await getReservesRatio(from, pairAB)
//     const ratioBC = await getReservesRatio(mediator, pairBC)
//     reserveRatio = ratioAB * ratioBC
//   }
//   const amtRatio = +fromAmt / +toAmt
//   const impact = (1 - reserveRatio / amtRatio) * 100 - swapFee
//   return impact < 0 ? 0.00001 : impact
// }

/**
 * @description return dmc price in USD
 * @description only works in bsc
 * @description DMC only available in BSC for now. 2022-06
 * @returns
 */
export const getDMCPrice = async () => {
  const web3 = new Web3(url) // bsc url
  const dmcBnbPoolContract = new web3.eth.Contract(BSwapPairAbi as AbiItem[], DMC_POOL_ADDRESS_BSC)
  // 0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c dont change it. it is BNB token address
  const WBNBContract = new web3.eth.Contract(ERC20Abi as AbiItem[], '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c')
  const DMCContract = new web3.eth.Contract(ERC20Abi as AbiItem[], DMC_TOKEN_ADDRESS)
  const [bnbDecimals, dmcDecimals, reserves, token0Address] = await Promise.all([
    WBNBContract.methods.decimals().call(),
    DMCContract.methods.decimals().call(),
    dmcBnbPoolContract.methods.getReserves().call(),
    dmcBnbPoolContract.methods.token0().call()
  ])

  let inputAmount = 1 // 1 bnb
  let liquidityAmount = 0

  if (WBNB.toLowerCase() === token0Address.toLowerCase()) {
    inputAmount = inputAmount * 10 ** bnbDecimals
    const amountB = (inputAmount * reserves[1]) / reserves[0]
    liquidityAmount = amountB / 10 ** dmcDecimals
  } else {
    inputAmount = inputAmount * 10 ** dmcDecimals
    const amountB = (inputAmount * reserves[0]) / reserves[1]
    liquidityAmount = amountB / 10 ** bnbDecimals
  }
  const bnbPriceInUsd = await tokenPriceApi.calcBNBPrice()
  return (1 / liquidityAmount) * +bnbPriceInUsd
}

export const chooseTokens = (newToken: any, token: any, defaultToken: any): [any, any | null] => {
  if (newToken.symbol === token.symbol || newToken.address === token.address) {
    return [newToken, defaultToken]
  } else {
    return [newToken, null]
  }
}

/**
 *
 * @param {Number | String} _num
 * @returns {String}
 */
export const withZero = (_num: number | string) => (+_num > 9 ? String(_num) : '0' + _num)

export const smartPath = ([tokenA, tokenB]: string[], W_token: string) => {
  if ([ZERO_ADDRESS, MAIN_ZERO_ADDRESS].includes(tokenA)) {
    tokenA = W_token
  }
  if ([ZERO_ADDRESS, MAIN_ZERO_ADDRESS].includes(tokenB)) {
    tokenB = W_token
  }
  return [tokenA, tokenB]
}

/**
 * @description a less than b
 */
export const ltToken = (a: string, b: string) => {
  const [aBN, bBN] = [Web3Utils.toBN(a), Web3Utils.toBN(b)]
  return aBN.lt(bBN);
}

export const smartTokens = (_tokenA: string, _tokenB: string) => {
  return ltToken(_tokenA, _tokenB) ? [_tokenA, _tokenB, false] : [_tokenB, _tokenA, true]
}

export const checkNot = (subject: string, text = 'Select Token', replacement = '-') => (subject !== text ? subject : replacement)

export const zeroIncludes = (adr: string) => [ZERO_ADDRESS, MAIN_ZERO_ADDRESS].includes(adr)

export const isEqAddr = (a: string, ...others: string[]) => {
  let isEq = true
  const bnA = toBN(a)
  for (let i = 0; i < others.length; i++) {
    const addr = others[i]
    const bnAddr = toBN(addr)
    isEq = bnA.eq(bnAddr)
    if (!isEq) {
      return isEq
    }
  }
  return isEq
}

export const isEqSymbol = (a: string, b: string) => {
  return a.toLowerCase() === b.toLowerCase()
}

export const smartSymbol = (_symbol: string) => {
  const key = _symbol.toLowerCase() as keyof ConvertableTokenSymbolConfig
  const s = CONVERTABLE_TOKEN_SYMBOLS[key]
  return s || _symbol
}

type D = {
  reserve0: string,
  reserve1: string,
}
export const calcAnalyticData = (d1: D, d2: D, revert = false) => {
  if (revert) {
    d1 = {
      reserve0: d1.reserve1,
      reserve1: d1.reserve0,
    }
    d2 = {
      reserve0: d2.reserve1,
      reserve1: d2.reserve0,
    }
  }
  const endValue = +d1.reserve0 / +d1.reserve1
  const smallValue = +d2.reserve0 / +d2.reserve1
  const changed = endValue - smallValue
  const percent = (Math.abs(changed) / smallValue) * 100

  return {
    endValue,
    smallValue,
    changed,
    percent,
  }
}

export const removeEmptyFieldsInObject = (_o: any = {}) => {
  const keys = Object.keys(_o)
  keys.forEach((key) => {
    const value = _o[key]
    if (value === null || value === undefined) {
      delete _o[key]
    }
  })
  return _o
}

export const getPairToken = (token: string, token0: BaseToken | any, token1: BaseToken | any): null | BaseToken | any => {
  if(isEqAddr(token0.address, token)) {
    return token1;
  } else if(isEqAddr(token1.address, token)) {
    return token0;
  }
  return null
}

export const getExplorerUrl = (appChainId: SupportedNetworks): string => NETWORKS[appChainId].ExplorerUrl;
