import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react'
import Select from 'react-select'
import { ThemeContext } from 'styled-components'
import { Pagination } from '@mui/material'
import { PaginationComponentProps, TableStyles } from 'react-data-table-component'
import * as S from '../../ReimbursementTable/styled'

type Label = {
  value: number,
  label: string,
}

interface ICustomReactTablePaginationProps extends React.HTMLAttributes<HTMLDivElement>, PaginationComponentProps { }

function CustomReactTablePagination({ rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage, currentPage }: ICustomReactTablePaginationProps) {
  const appTheme = useContext(ThemeContext)

  const values = useMemo<Label[]>(() => {
    return [
      {
        value: 10,
        label: '10',
      },
      {
        value: 25,
        label: '25',
      },
      {
        value: 50,
        label: '50',
      },
      {
        value: 100,
        label: '100',
      },
    ]
  }, [])
  const [perPage, setPerPage] = useState<Label>(values[0])

  const onChangeRowsPerPageHandler = useCallback(
    (newValue: { value: string }) => {
      onChangeRowsPerPage(parseInt(newValue.value), currentPage)
    },
    [currentPage, onChangeRowsPerPage]
  )

  useEffect(() => {
    const _perPage = values.find((value) => +value.value === +rowsPerPage)
    if (_perPage) {
      setPerPage(_perPage)
    }
  }, [rowsPerPage, values])

  const rowsFrom = useMemo<string | number>(() => {
    return (currentPage - 1) * rowsPerPage + 1;
  }, [currentPage, rowsPerPage])

  return (
    <S.PaginationContent>
      <div className='d-flex align-items-center'>
        <S.PaginationActions className='z-index-100'>
          <S.PaginationPageLabel className='label text-white text-lato'>Page</S.PaginationPageLabel>
          <Select
            id='page-select'
            styles={{
              control: (styles) => ({
                ...styles,
                border: 0,
                minHeight: 35,
                backgroundColor: appTheme.bodybg03,
                '&:hover': {
                  backgroundColor: appTheme.bodybg03,
                  color: 'white',
                },
                '&:hover font': {
                  color: 'white',
                },
                '&:hover svg': {
                  color: 'white',
                },
              }),
              option: (styles) => ({
                ...styles,
                color: 'black',
              }),
              indicatorsContainer: (styles) => ({
                ...styles,
                color: 'black',
                '&:hover': {
                  color: 'white',
                },
              }),
              singleValue: (styles) => ({
                ...styles,
                color: 'white',
              }),
              indicatorSeparator: () => ({
                display: 'none'
              })
            }}
            options={values}
            placeholder='Select Page'
            value={perPage}
            // @ts-ignore
            onChange={onChangeRowsPerPageHandler}
          ></Select>
        </S.PaginationActions>
        <div className='px-10'>
          <span className='text-muted fs-sm fw-bold'>result {rowsFrom}-{currentPage * rowsPerPage} of {rowCount}</span>
        </div>
      </div>
      <Pagination
        count={Math.ceil(rowCount / +rowsPerPage)}
        // count={5}
        shape='rounded'
        onChange={(e, changedPage) => onChangePage(changedPage, rowCount)}
        sx={{
          '& .MuiButtonBase-root': {
            fontSize: '1.2rem',
            fontWeight: 'bold',
            color: appTheme.fontColors.muted,
            '&:hover': {
              backgroundColor: appTheme.bodybg03,
            },
            '&.Mui-selected': {
              color: 'white',
              backgroundColor: appTheme.bodybg03,
            }
          },
          '& .MuiSvgIcon-root': {
            fontSize: '25px',
          },
        }}
      />
    </S.PaginationContent>
  )
}

export default CustomReactTablePagination

export const TableCustomStyles: TableStyles = {
  expanderRow: {
    style: {
      backgroundColor: '#343740'
    }
  },
  table: {
    style: {
      backgroundColor: 'transparent',
    }
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      color: '#8e9196',
      background: 'transparent',
      borderTopWidth: '2px',
      borderBottomWidth: '2px',
      borderColor: '#8e9196',
      height: '50px',
      fontFamily: 'var(--dx-ff-primary)'
    },
  },
  headCells: {
    style: {
      fontSize: '15px',
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        color: '#8e9196',
        borderRightWidth: '1px',
        borderColor: '#8e9196',
      },
      '.rdt_TableCol_Sortable': {
        justifyContent: 'space-between',
        '& > span:last-child': {
          display: 'none'
        }
      }
    },
  },
  cells: {
    style: {
      background: 'transparent',
      borderRightWidth: '1px',
      '& div:last-child': {
        width: '100%'
      },
      '& div:first-child': {
        overflow: 'inherit !important'
      },
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderColor: '#8e9196',
      },
    },
  },
  rows: {
    style: {
      borderRightWidth: '0px',
      height: '50px',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderColor: '#8e9196',
      borderBottomColor: '#8e9196 !important',
      color: '#8e9196',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: '#272a31'
      }
    },
  }
}

export const DoubleTableCustomStyles: TableStyles = {
  expanderRow: {
    style: {
      backgroundColor: '#343740'
    }
  },
  table: {
    style: {
      backgroundColor: 'transparent',
    }
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      color: '#8e9196',
      background: 'transparent',
      borderTopWidth: '2px',
      borderBottomWidth: '2px',
      borderColor: '#8e9196',
      height: '50px',
      fontFamily: 'var(--dx-ff-primary)'
    },
  },
  headCells: {
    style: {
      fontSize: '15px',
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        color: '#8e9196',
        borderRightWidth: '1px',
        borderColor: '#8e9196',
      },
      '.rdt_TableCol_Sortable': {
        justifyContent: 'space-between',
        '& > span:last-child': {
          display: 'none'
        }
      }
    },
  },
  cells: {
    style: {
      background: 'transparent',
      borderRightWidth: '1px',
      '& div:last-child': {
        width: '100%'
      },
      '& div:first-child': {
        overflow: 'inherit !important'
      },
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderColor: '#8e9196',
      },
    },
  },
  rows: {
    style: {
      borderRightWidth: '0px',
      height: '50px',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderColor: '#8e9196',
      borderBottomColor: '#8e9196 !important',
      color: '#8e9196',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: '#272a31'
      }
    },
  }
}