import TokenIco01 from 'Assets/images/icon-3.png'
import TokenIco02 from 'Assets/images/selectTkn-02.png'
import degen_token from 'Assets/images/Degen_token.png'
import SSIcon06 from 'Assets/images/ethRedioICON.png'
import bnb from 'Assets/images/bnb-icon.png'
import {
  DEGEN_TOKEN_ADDRESS_BSC,
  DMC_TOKEN_ADDRESS,
} from 'app-constants'
import { tBNB } from './addresses'
import { AppBuyNowTokenConfig, AppSupportedChain, SupportedNetworks } from 'types'

export const BuyNowTokens: AppBuyNowTokenConfig = {
  1: [
    {
      symbol: 'ETH',
      name: 'ETH',
      chain: 'Ethereum',
      image: SSIcon06,
      decimals: '18',
      value: '0x0000000000000000000000000000000000000000',
      address: '0x0000000000000000000000000000000000000000',
      balance: 0,
    },
    {
      symbol: 'BVBV',
      name: 'BVBV',
      chain: 'Ethereum',
      image: TokenIco02,
      decimals: '18',
      value: '0x422c9EeE99b40775c258bF1AEED4528F0A8b380E',
      address: '0x422c9EeE99b40775c258bF1AEED4528F0A8b380E',
      balance: 0,
    },
    {
      symbol: 'AVAV',
      name: 'AVAV',
      chain: 'Ethereum',
      image: TokenIco02,
      decimals: '18',
      value: '0x116699E5E9aE620Ad11CaAcb3dc5982ecfB49152',
      address: '0x116699E5E9aE620Ad11CaAcb3dc5982ecfB49152',
      balance: 0,
    },
    {
      symbol: 'TK',
      name: 'TK',
      chain: 'Ethereum',
      image: TokenIco02,
      decimals: '18',
      value: '0x47A530f3Fa882502344DC491549cA9c058dbC7Da',
      address: '0x47A530f3Fa882502344DC491549cA9c058dbC7Da',
      balance: 0,
    },
  ],
  56: [
    {
      symbol: 'BNB',
      name: 'BNB',
      chain: 'bsc',
      decimals: '18',
      address: '0x0000000000000000000000000000000000000000',
      value: '0x0000000000000000000000000000000000000000',
      image: bnb,
      balance: 0,
    },
    {
      symbol: 'DEGEN',
      name: 'DEGEN' /** Degen Swap Token */,
      decimals: '18',
      chain: 'bsc',
      address: DEGEN_TOKEN_ADDRESS_BSC,
      value: DEGEN_TOKEN_ADDRESS_BSC,
      image: degen_token,
      balance: 0,
    },
    {
      address: DMC_TOKEN_ADDRESS,
      value: DMC_TOKEN_ADDRESS,
      decimals: '18',
      image: '/images/dmc-token.png',
      chain: 'bsc',
      // label: 'DMC',
      name: 'DMC',
      symbol: 'DMC',
      balance: 0,
    },
  ],
  97: [
    {
      symbol: 'tBNB',
      name: 'tBNB',
      decimals: '18',
      chain: 'bsc',
      address: '0x0000000000000000000000000000000000000000',
      value: '0x0000000000000000000000000000000000000000',
      image: bnb,
      balance: 0,
    },
    {
      symbol: 'tDMC',
      name: 'DMC',
      chain: 'bsc',
      decimals: '18',
      image: '/images/dmc-token.png',
      // label: 'DMC',
      address: DMC_TOKEN_ADDRESS,
      value: DMC_TOKEN_ADDRESS,
      balance: 0,
    },
  ],
}

export const SupportedChains: AppSupportedChain[] = [
  {
    name: 'ETH',
    label: 'ETH',
    chain: 'Ethereum',
    symbol: 'eth',
    decimals: '18',
    chainId: SupportedNetworks.mainnet,
    image: SSIcon06,
    value: '0x0E11862a0Da0C38F15568A8FA2C992Afc1f0b278',
    address: '0x0E11862a0Da0C38F15568A8FA2C992Afc1f0b278',
  },
  {
    name: 'BSC',
    label: 'BSC',
    chain: 'BSC',
    symbol: 'bnb',
    chainId: SupportedNetworks.bsc,
    decimals: '18',
    image: TokenIco01,
    value: '0x078F9018a2fF0D7a0FE919Ff6e4d2485a0d78d8c',
    address: '0x078F9018a2fF0D7a0FE919Ff6e4d2485a0d78d8c',
  },
  {
    name: 'TBSC',
    label: 'TBSC',
    chain: 'TBSC',
    decimals: '18',
    symbol: 'tBNB',
    chainId: SupportedNetworks.bsctestnetwork,
    image: TokenIco01,
    value: tBNB,
    address: tBNB,
  },
]
