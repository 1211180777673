import { useMemo } from 'react'
import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars'
import type { ScrollbarProps } from 'react-custom-scrollbars'

const TrackVertical = styled.div`
  width: 18px !important;
  height: 100%;
  display: block;
  position: absolute;
  right: 0;

  &.black {
    background-color: black;
  }
`

const ThumbVertical = styled.div`
  width: 9px !important;
  margin: 3px 5px;
  background-color: ${({ theme }) => theme.thumbColor};
  z-index: 5;
`

const SScrollbars = styled(Scrollbars)`
  & ${TrackVertical} {
    display: none !important;
  }
  &:hover {
    ${TrackVertical} {
      display: block !important;
    }
  }
  &.scrollbar-show {
    & ${TrackVertical} {
      display: block !important;
    }
  }
  &.dark {
    ${TrackVertical} {
      background-color: ${({ theme }) => theme.bodybg01};
    }
    ${ThumbVertical} {
      background-color: ${({ theme }) => theme.bodybg03};
    }
  }
  &.dark-1 {
    ${TrackVertical} {
      width: 15px !important;
      border-radius: 3px;
      background-color: ${({ theme }) => theme.bodybg010};
    }
    ${ThumbVertical} {
      margin: 3px 3px;
      background-color: ${({ theme }) => theme.bodybg03};
    }
  }
  &.dark-2 {
    ${TrackVertical} {
      width: 13px !important;
      background-color: ${({ theme }) => theme.bodybg01};
    }
    ${ThumbVertical} {
      margin: 1px 2px;
      background-color: ${({ theme }) => theme.bodybg03};
    }
  }
`

interface ICustomScrollbarsProps extends ScrollbarProps{
  trackClass?: string
  scrollDirection?: 'right' | 'left',
  children: JSX.Element | JSX.Element[] | any
}

function CustomScrollbars({ style, children, height = '200px', width = '100%', trackClass = '', scrollDirection = 'right', ...props }: ICustomScrollbarsProps) {
  const styleProps = useMemo(() => ({ height, width, ...style }), [height, style, width])

  return (
    // @ts-ignore
    <SScrollbars
      renderTrackVertical={(props) => <TrackVertical {...props} className={`${trackClass} ${scrollDirection === 'left' ? 'left-0' : ''}`} />}
      renderThumbVertical={(props) => <ThumbVertical {...props} />}
      renderView={(props) => <div {...props} className='view scrollbar-none' />}
      style={styleProps}
      {...props}
    >
      {children}
    </SScrollbars>
  )
}

export default CustomScrollbars
