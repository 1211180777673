import {
    ContractCallContext,
} from 'ethereum-multicall';
import BSwapPairABI from 'Assets/Abi/BSwapPairAbi.json';
import { SupportedNetworks } from 'types';
import { hexToDecimals } from '../math';
import { multicall } from './multicall';


type PendingRewards = {
    // [lp address]: value;
    [key in string]: string;
}

export async function getPendingRewards(chainId: SupportedNetworks, lpAddresses: string[], user: string): Promise<PendingRewards> {
    const contractCallContext: ContractCallContext[] = lpAddresses.map((lpAddress) => (
        {
            reference: lpAddress.toLowerCase(),
            contractAddress: lpAddress,
            abi: BSwapPairABI,
            calls: [{ reference: 'pendingRewardCall_' + lpAddress, methodName: 'pendingReward', methodParameters: [user] }]
        }
    ))

    const result: PendingRewards = {};
    const values = await multicall(chainId, contractCallContext);

    for (const key in values) {
        const element = values[key];
        result[key] = hexToDecimals(element.callsReturnContext[0]?.returnValues[0]?.hex)
    }
    return result;
}
