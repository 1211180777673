import { useCallback, useState } from 'react'
import styled from 'styled-components';
import Media from 'Theme/media-breackpoint';
import { useAppChain } from 'hooks';
import { ModalBox } from 'Component/core/elements/others/styled';
import { PPClosBTN01 } from 'Component/core/elements/Buttons';
import CustomScrollbars from 'Component/core/elements/CustomScrollbars'
import Modal, { PPsBx01, PPstitle01, PPstitle02, PopInput01 } from '../Modal'
import TokenIcon from 'Component/TokenIcon';
import { LoadingSpinner } from 'Component/AppLoadingSpinner';
import useAppDebounceEffect from 'hooks/useAppDebounceEffect';
import LPInsureSubgraph from 'helper/subgraphs/LPInsureSubgraph';
import { GetInsureOffersWithLPBalanceType } from 'helper/graphql';
import { getInsureViewId } from 'helper/insure-utils';
export type { GetInsureOffersWithLPBalanceType as OfferType } from 'helper/graphql';

interface ISelectInsureOfferProps {
  isOpen: boolean;
  dismiss: () => void;
  onSelect: (offer: GetInsureOffersWithLPBalanceType) => void;
}

function SelectInsureOffer({ isOpen, dismiss, onSelect }: ISelectInsureOfferProps) {
  const { appChainId } = useAppChain()

  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [insureOffers, setInsureOffers] = useState<GetInsureOffersWithLPBalanceType[]>([])
  const [errMsg, setErrMsg] = useState('')

  const fetchInsureOffers = useCallback(async() => {
    const insureClient = new LPInsureSubgraph(appChainId);
    try {
      setIsLoading(true);
      setErrMsg('')
      const where = insureClient.buildLPBondsWhere(search);
      const { data } = await insureClient.getLPBonds(search ? where : undefined);
      setInsureOffers(data.insurePools)
    } catch (err: unknown) {
      // @ts-ignore
      setErrMsg(`Failed to get Insure Offers: ${err.message || err}`)
    } finally {
      setIsLoading(false);
    }
  }, [appChainId, search]);

  useAppDebounceEffect(() => {
    fetchInsureOffers()
  }, 400, [fetchInsureOffers])

  const handleOnChangeBondSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const handleOnClickBond = (_bond: GetInsureOffersWithLPBalanceType) => {
    onSelect(_bond);
  }

  return (
    <Modal isOpen={isOpen} dismiss={dismiss}>
      <PPMainBx>
        <PPsBx01 className='position-relative'>
          <PPClosBTN01 className='position-absolute right-10 top-10' onClick={dismiss} />
          <PPstitle01 className='d-flex justify-content-between align-items-center'>
            <h4>Select Insure Bond</h4>
          </PPstitle01>
          <PopInput01 placeholder='Search by project name/token address...' value={search} onChange={handleOnChangeBondSearch}/>
        </PPsBx01>
        <PPstitle02 className='pl-30'>
          <span>Bonds</span>
        </PPstitle02>
        <CustomScrollbars
          height='320px'
          width='100%'
          style={{ position: 'relative' }}
          trackClass='black'
          renderView={(props: any) => <div {...props} className='view pl-30' />}
        >
          <div className='d-flex flex-column pb-20 pt-5'>
            <div className='text-danger'>{errMsg}</div>
            {
              isLoading ? (
                <div className='d-flex justify-content-center mt-40'>
                  <LoadingSpinner />
                </div>
              ) : (
                insureOffers.map((bond) => {
                  return (
                    <SBondCard key={bond.id} onClick={() => handleOnClickBond(bond)}>
                      <TokenIcon
                        className='rounded-circle bg-white'
                        byAddress={bond.token.address}
                        alt={`Logo-${bond.token.address}`}
                        style={{ width: 30, height: 30 }}
                      />
                      <span className='pl-10'>{bond.token.symbol}-{bond.pairToken.symbol}</span>
                      <span className='pl-10 text-muted'>(#{getInsureViewId(bond.id)})</span>
                    </SBondCard>
                  )
                })
              )
            }
          </div>
        </CustomScrollbars>
      </PPMainBx>
    </Modal>
  )
}

const PPMainBx = styled(ModalBox)`
  max-width: 450px;
  min-width: 450px;
  margin: 0 auto;
  ${Media.lg} {
    min-width: 400px;
  }
  ${Media.md} {
    min-width: auto;
  }
  ${Media.xs} {
    width: auto;
    margin: 0px 15px;
    max-width: 100%;
  }
`
const SBondCard = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colorPrimary};
  }
`

export default SelectInsureOffer
