import React from 'react'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import Xbtn from 'Assets/images/closeBTN.png'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'

const ButtonStyles = css`
  font-family: var(--dx-ff-secondary);
  font-weight: 700;
  font-size: 21px;
  padding: 12px 12px;
  border-radius: 10px;
`
const ButtonNowrapStyles = css`
  overflow: hidden;
  display: block;
  text-align: center;
  text-overflow: ellipsis;
`

export const AppButton = styled.button`
  ${ButtonStyles}
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colorWhite};
  border: none;
  background-color: ${(props) => props.theme.colorGrey};
  width: 100%;

  &.text-nowrap {
    ${ButtonNowrapStyles}
  }

  :hover {
    background-color: ${(props) => props.theme.colorGrey6};
  }
  &.no-fill {
    &-primary {
      background-color: unset;
      color: ${(props) => props.theme.colorPrimary};
      &:hover {
        color: ${(props) => props.theme.colorPrimary01};
      }
    }
    &-muted {
      background-color: unset;
      color: ${(props) => props.theme.colorLightGrey};
      &:hover {
        color: ${(props) => props.theme.colorPrimary01};
      }
    }
    & {
      background-color: unset;
      color: unset;
      &:hover {
        color: unset;
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
    background-color: ${(props) => props.theme.bodybg013} !important;
    :hover {
      background-color: ${(props) => props.theme.colorGrey} !important;
    }
  }
  &.primary {
    background-color: ${(props) => props.theme.colorPrimary};
    :hover {
      background-color: ${(props) => props.theme.colorPrimary01};
    }
  }
  &.black {
    background-color: ${(props) => props.theme.bodybg03};
    :hover {
      background-color: ${(props) => props.theme.bodybg04};
    }
  }
  &.darkGray {
    // background-color: ${(props) => props.theme.bodybg013};
    border: 2px solid ${(props) => props.theme.bodybg013};
    box-shadow: ${(props) => props.theme.bodybg013} 0px 0px 10px 1px;

    :hover {
      background-color: #ababab;
    }
  }
  &.darkRed {
    background-color: red;
    :hover {
      background-color: red;
    }
  }
  &.orangBack {
    background-color: #8be05a;
    :hover {
      background-color: #a0ef72;
    }
  }
  &.hover\\:outline {
    &-primary:hover {
      border: 2px solid ${(props) => props.theme.colorPrimary} !important;
      box-shadow: ${(props) => props.theme.colorPrimary} 0px 0px 10px 1px !important;
    }
  }
  &.outline {
    &-primary {
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.colorPrimary};
      box-shadow: ${(props) => props.theme.colorPrimary} 0px 0px 10px 1px;

      &:hover {
        background-color: ${(props) => props.theme.colorPrimary};
        color: white;
      }
    }
    &-gray {
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.bodybg013};
      box-shadow: ${(props) => props.theme.bodybg013} 0px 0px 10px 1px;

      &:hover {
        background-color: ${(props) => props.theme.bodybg013};
      }
    }
    &-danger {
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.colorRed};
      box-shadow: ${(props) => props.theme.colorRed} 0px 0px 10px 1px;
      color: ${(props) => props.theme.colorRed};

      &:hover {
        background-color: ${(props) => props.theme.colorRed};
        color: white;
      }
    }
    &-disabled {
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.bodybg013};
      box-shadow: ${(props) => props.theme.bodybg013} 0px 0px 10px 1px;

      &:hover {
        background-color: ${(props) => props.theme.bodybg013};
      }
    }
    &-black {
      background-color: transparent;
      border: 2px solid black;
      box-shadow: black 0px 0px 10px 1px;
    }
  }
`
export const SwapBTN01 = styled(AppButton)``

export const OutlineButton = styled.button`
  ${ButtonStyles}
  &.outline {
    background-color: transparent;

    &-gray {
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.bodybg013};
    }
    &-danger {
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.colorRed};
    }
  }
`

export const PPClosBTN01 = styled.button`
  width: 20px;
  height: 20px;
  border: 0px;
  outline: none;
  color: #fff;
  background: url(${Xbtn}) left top no-repeat;
  position: absolute;
  right: -12px;
  top: -3px;
  transition: 0.5s ease all;
  :hover {
    opacity: 0.7;
    transform: rotate(180deg);
  }
`

const SButton = styled.button`
  color: ${(props) => props.theme.colorLightGrey};
  display: flex;
  align-items: center;

  & .label {
    margin-left: 5px;
    margin-right: 5px;
    text-transform: capitalize;
  }
`

export const SSelectDexButton = styled(OutlineButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
  }
  img {
    width: 30px;
    height: 30px;
  }
  span {
    padding-left: 10px;
    color: ${({ theme }) => theme.colorWhite};
    font-weight: normal;
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`

interface IButtonWithIconProps extends React.HTMLAttributes<HTMLButtonElement> {
  startIcon?: JSX.Element
  endIcon?: JSX.Element
  children?: any
}

export function ButtonWithIcon({ startIcon, endIcon, children, ...props }: IButtonWithIconProps) {
  return (
    <SButton {...props}>
      {startIcon}
      <div className='label'>{children}</div>
      {endIcon}
    </SButton>
  )
}

interface IBackButtonProps extends IButtonWithIconProps {
  label?: string
  iconSize?: SizeProp
}
export function BackButton({ label = 'back', iconSize = 'sm', ...props }: IBackButtonProps) {
  return (
    <ButtonWithIcon {...props} startIcon={<FontAwesomeIcon icon={faAngleLeft} size={iconSize} />}>
      {label}
    </ButtonWithIcon>
  )
}
