// import { SupportedChains } from 'app-constants/conToken'
import QueryString from 'query-string'
import { SupportedNetworks } from 'types'

export * from './coinGecko'
export * from './date-time'
export * from './bonds'
export * from './others'

export const isValidNumber = (num: string): boolean => {
  const regx = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)
  if (isNaN(+num)) {
    return false
  }
  if (num === '' || num === '.' || regx.test(num)) {
    return true
  } else {
    return false
  }
}

/**
 *
 * @param {String} _value @description Number in String
 * @description Remove zero in the front of String
 */
export const numberFormat = (_value = ''): string => {
  const checkAndRemoveZero = (v: string): string => {
    if (v.length > 1 && v.startsWith('0') && v[1] !== '.') {
      v = v.substring(1)
      return checkAndRemoveZero(v)
    }
    return v
  }
  let value = _value
  if (isNaN(+value)) {
    value = ''
  }
  value = checkAndRemoveZero(value)
  return value
}

// export const shortenChain = (name: string): AppSupportedChain | undefined => {
//   return SupportedChains.find((s) => s.chain.toLowerCase() === name.toLowerCase())
// }

export const generateSecondary = (link: string): string => `https://wispy-bird-88a7.uniswap.workers.dev/?url=http://${link}.link`

export const fetchLink = async (link: string, setter: (...params: any) => void, errorSetter: (state: boolean) => void ): Promise<void> => {
  const second = generateSecondary(link)
  try {
    const response = await fetch(link.includes('http') ? link : second)
    const data = await response.json()
    if (Array.isArray(data.tokens)) errorSetter(false)
    setter({ data, url: link })
  } catch (err) {
    errorSetter(true)
    setter(null)
  }
}

export const usdFormater = (maximumFractionDigits = 2, minimumFractionDigits = 2): Intl.NumberFormat => {
  const nf = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits
    // roundingIncrement: 5,
  })

  return nf
}

export const numberFormater = (maximumFractionDigits = 2): Intl.NumberFormat => {
  const nf = new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: maximumFractionDigits })

  return nf
}

export const numberWithZero = (value: number | string, minNumCount = 6, fractionDigits = 2): string => {
  value = +value
  value = new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: fractionDigits, minimumFractionDigits: 2 }).format(value)
  let [whole, fraction] = value.split('.')
  if (!fraction) {
    fraction = ''
  }
  do {
    whole = '0' + whole
  } while (whole.length < minNumCount)

  let result = whole
  if (fractionDigits > 0) {
    result = `${result}.${fraction}`
  }
  return result
}

/**
 *
 * @description Used to detect chain from _search and change the network.
 */
export const smartChainQuery = (_search: string, _newChain: SupportedNetworks = SupportedNetworks.mainnet): string => {
  const parsed: any = QueryString.parse(_search || '')
  parsed.chain = _newChain
  return QueryString.stringify(parsed)
}

export function getSiteName(domain: string): string {
  return domain.replace(/https|http|:|\//gi, '');
}