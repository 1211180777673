import styled from 'styled-components'

export const DragorInput = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;

  &.danger {
    .input-range__slider {
      background: ${({ theme }) => theme.colorRed};
    }
    .input-range__track--active {
      background: ${({ theme }) => theme.colorRed};
    }
  }
  &.primary {
    .input-range__slider {
      background: ${({ theme }) => theme.colorPrimary};
    }
    .input-range__track--active {
      background: ${({ theme }) => theme.colorPrimary};
    }
  }

  .input-range__slider {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    display: block;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    color: #fff !important;
    font-weight: 700;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-weight: 400;
    transform: translateY(-22px);
    border: 4px solid #fff;
    transform: translateY(-26px) translateX(-14px);
  }

  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range__slider-container {
    transition: left 0.3s ease-out;
    z-index: 100;
  }
  .input-range__label {
    color: #aaaaaa;
    font-size: 16px;
    transform: translateZ(0);
    white-space: nowrap;
  }
  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
  }
  .input-range__label--min {
    left: 12px;
    top: 7px;
    z-index: 2;
  }
  .input-range__label--max {
    right: 23px;
    top: 7px;
  }
  .input-range__label--value {
    position: absolute;
    top: -23px;
    z-index: 2;
    color: #fff;
    left: 3px;
    pointer-events: none;
  }
  .input-range__label-container {
    left: -50%;
    position: relative;
    color: #fff;
    font: 500 16px/20px 'IBM Plex Mono', arial;
    pointer-events: none;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }
  .input-range__track {
    background: ${({ theme }) => theme.placeHolderColor};
    cursor: pointer;
    display: block;
    height: 26px;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out;
    border-radius: 15px;
  }
  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }
  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }
  .input-range__label--value .input-range__label-container {
    color: white;
    font-weight: 700;
    font-size: 14px;
  }
`

export const RangeInput = styled(DragorInput)`
  .input-range__slider {
    width: 30px;
    height: 30px;
    transform: translateY(calc(-50% + 1px)) translateX(-26%);
    border: unset;
  }
  .input-range__label-container {
    padding: 2px 12px;
    background-color: ${({theme}) => theme.bodybg03};
  }
  .input-range__label--value {
    top: 25px;
  }
  .input-range__label--max,
  .input-range__label--min {
    top: 38px;
  }

  .input-range__track {
    height: 10px;
  }
  &.danger {
    .input-range__track--active {
      background-color: ${({ theme }) => theme.placeHolderColor};
    }
  }
`
