import styled from 'styled-components'

const defaultSize = 10
interface IStatusIcon {
  size?: string
}

export const StatusIcon = styled.div<IStatusIcon>`
  width: ${(props) => props.size || defaultSize}px;
  height: ${(props) => props.size || defaultSize}px;
  border-radius: 50%;
  background-color: #76797e;
  &.active {
    background-color: #8db610;
  }
  &.ended {
    background-color: #9b1c1e;
  }
`
