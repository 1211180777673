import { RootState } from 'redux/store'
import { AppThunkAction } from 'types'
import { ActionTypes, ActionTour } from './types'

type TourThunkAction<
  R, // Return type of the thunk function
> = AppThunkAction<R, RootState, null, ActionTour>

export const setDerexNeverShowTour = (state: boolean): TourThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.SetDEREX_NeverShow,
    payload: state
  })
}

export const setOpenDEREXTour = (state: boolean): TourThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.SetOpenDEREXTour,
    payload: state
  })
}

export const setIBONeverShowTour = (state: boolean): TourThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.SetIBO_NeverShow,
    payload: state
  })
}

export const setOpenIBOTour = (state: boolean): TourThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.SetOpenIBOTour,
    payload: state
  })
}

export const setLPInsureNeverShowTour = (state: boolean): TourThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.SetLPInsure_NeverShow,
    payload: state
  })
}

export const setOpenLPInsureTour = (state: boolean): TourThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.SetOpenLPInsureTour,
    payload: state
  })
}
