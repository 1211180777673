import axios from 'axios'
import { BOND_SERVER_URL } from 'config/pathConfig'
import { SupportedNetworks } from 'types'

export const createBond = ({ bondId, logo, website, whitepaper, networkId }: {
  bondId: string
  logo: Blob
  website?: string
  whitepaper?: string
  networkId: SupportedNetworks
}) => {
  const form = new FormData()
  form.append('bondId', bondId)
  form.append('logo', logo)
  website && form.append('website', website)
  whitepaper && form.append('whitepaper', whitepaper)
  return axios.post(`${BOND_SERVER_URL}/api/bonds?networkId=${networkId}`, form)
}

export const voteToBond = ({ bondId, networkId, yea = true }: { bondId: string, yea?: boolean, networkId: SupportedNetworks }) => {
  return axios.post(`${BOND_SERVER_URL}/api/bonds/${bondId}/vote?networkId=${networkId}`, {
    yea,
  })
}

export const getBond = ({ bondId, networkId }: { bondId: string, networkId: SupportedNetworks }) => {
  return axios.get(`${BOND_SERVER_URL}/api/bonds/${bondId}?networkId=${networkId}`)
}

export const getBonds = ({ ids, networkId }: { ids: string[], networkId: SupportedNetworks }) => {
  return axios.get(`${BOND_SERVER_URL}/api/bonds?bondIds=${ids}&networkId=${networkId}`)
}

export const getInvestmentAndProfit = ({ userWallet, networkId }: { userWallet: string, networkId: SupportedNetworks }) => {
  return axios.get(`${BOND_SERVER_URL}/api/bonds/investment-and-profit?networkId=${networkId}&userWallet=${userWallet}`)
}

export const getTotalRaisedUSD = ({ networkId }: { networkId: SupportedNetworks }) => {
  return axios.get(`${BOND_SERVER_URL}/api/bonds/totalRaisedUSD?networkId=${networkId}`)
}

type InsureTotalRaisedUSDRes  = {
  totalRaisedUSD?: number | string,
  currency: string,
}
export const getInsureTotalRaisedUSD = ({ networkId }: { networkId: SupportedNetworks }) => {
  return axios.get<InsureTotalRaisedUSDRes>(`${BOND_SERVER_URL}/api/insure/totalraised-amount/${networkId}`)
}

export type TokenPrices = {
  usd: number | string;
  address: string;
  symbol: string;
  priceChange: {
    h24: number | string
  }
}

export type GetTokenPriceRes = {
  tokenPrices: TokenPrices[]
}

export const getTokenPrices = ({ networkId, tokenIds }: { networkId: SupportedNetworks, tokenIds: string[] }) => {
  return axios.get<GetTokenPriceRes>(`${BOND_SERVER_URL}/api/token-prices/${networkId}/${tokenIds.join(',').toLowerCase()}`)
}
