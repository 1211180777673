import { defaultDex, VESTING_PERIOD, BOND_TYPE, TimeOptions } from 'app-constants'
import { InvestmentLimit, LPInsureReducerState } from 'types'
import { ActionLPInsure, ActionTypes } from './types'

export const initialState: LPInsureReducerState = {
  tab: 0, // 0 - 4,
  // tab states
  // - tab 1
  // step 1
  token: '', // address  //  project token address
  withNewPool: true,
  dex: defaultDex,
  startIBODate: new Date().getTime(),
  endIBODate: new Date().getTime(),
  // - tab 2
  bondType: BOND_TYPE.DISCOUNT,
  vestingPeriod: {
    type: VESTING_PERIOD.CLIFF,
    afterValue: '0', // number in string
    afterOption: TimeOptions[1],
    portionValue: '0', // number in string
    everyOption: TimeOptions[1],
    everyValue: '0', // number in string
  },
  prepaymentPenalty: false,
  dynamicPenalty: '0', // number in string
  // tab 3
  amountOfSupply: '0', // number in string
  investmentLimit: {
    isSet: false,
    minimum: '0', // number in string
    maximum: '0', // number in string
  },
  // other states
  isAddingProjectInfo: false,
  pairToken: {
    name: '',
    symbol: '',
    address: '',
    decimals: '',
    balance: '',
  },
  tokenInfo: {
    name: '',
    symbol: '',
    address: '',
    decimals: '',
    balance: '',
  },
  tokenRatio: 0,
}

export default function Reducer(state = initialState, action: ActionLPInsure): LPInsureReducerState {
  let prevInvestmentLimit: InvestmentLimit
  switch (action.type) {
    case ActionTypes.SET_PARAMETERS:
      return { ...state, ...action.payload }

    case ActionTypes.SET_TOKEN:
      return {
        ...state, token: action.payload,
        pairToken: {
          name: '',
          symbol: '',
          address: '',
          decimals: '',
          balance: '',
        }
      }

    case ActionTypes.SET_DEX:
      return { ...state, dex: action.payload }

    case ActionTypes.SET_PAIR_TOKEN:
      return { ...state, pairToken: { ...state.pairToken, ...action.payload } }

    case ActionTypes.SET_PREPAYMENT_PENALTY:
      return { ...state, prepaymentPenalty: action.payload, dynamicPenalty: '0' }

    case ActionTypes.SET_TOKEN_INFO:
      return { ...state, tokenInfo: action.payload }

    case ActionTypes.SET_TOKEN_RATIO:
      return { ...state, tokenRatio: action.payload }

    case ActionTypes.SET_VESTING_PERIOD:
      return { ...state, vestingPeriod: { ...state.vestingPeriod, ...action.payload } }

    case ActionTypes.SET_INVESTMENT_LIMIT_ENABLE:
      prevInvestmentLimit = state.investmentLimit
      prevInvestmentLimit.isSet = action.payload
      prevInvestmentLimit.maximum = ''
      prevInvestmentLimit.minimum = ''
      return { ...state, investmentLimit: prevInvestmentLimit }
    case ActionTypes.SET_INVESTMENT_LIMIT:
      return { ...state, investmentLimit: { ...state.investmentLimit, ...action.payload } }

    case ActionTypes.SET_TAB:
      return { ...state, tab: action.payload }

    case ActionTypes.IS_ADDING_PROJECT_INFO:
      return { ...state, isAddingProjectInfo: action.payload }

    case ActionTypes.RESET:
      return initialState

    default:
      return state
  }
}
