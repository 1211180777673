import styled from 'styled-components'
import TokenIcon from 'Component/TokenIcon'

//////// Blog  start
export const BlogItem = styled.div`
`
export const BlogItemHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`
export const BlogItemTitle = styled.h6`
    font-weight: bold;
    font-size: ${({theme}) => theme.fontSize.md};
    margin-bottom: 0;
`
export const BlogItemContent = styled.p`
    margin-top: 10px;
    color: ${({theme}) => theme.colorLightGrey};
    font-size: ${({theme}) => theme.fontSize.sm};
    font-family: var(--dx-ff-primary);
`
export const BlogItemLink = styled.a`
    font-size: ${({theme}) => theme.fontSize.sm};
    color: ${({theme}) => theme.colorLightGrey};
`
export const BlogItemTimestamp = styled.span`
    color: ${({theme}) => theme.colorLightGrey};
`
export const BlogIcon = styled(TokenIcon)`
    width: 45px;
    background-color: white;
    border-radius: 5px;
`
export const BlogExpandButton = styled.button`
    font-size: ${({theme}) => theme.fontSize.sm};
    color: ${({theme}) => theme.colorLightGrey};
`
//////// Blog end