import { SBondsContent, SBondsImg, SBondsWrap, SExampleList } from 'Component/core/elements/IBO-SharedComponents'
import { AppButton } from 'Component/core/elements/Buttons'
import { Divider } from 'Component/core/elements/Divider'
import { PageTitleSection } from 'Component/core/elements/others/styled'
import SupportingAllDexs from 'Component/Supports/SupportingAllDexs'
import SupportingBlockchains from 'Component/Supports/SupportingBlockchains'
import { MainTitle } from 'Component/core/elements/Text'
import { BsCircleFill } from 'react-icons/bs'
import Gs from 'Theme/globalStyles'
import styled from 'styled-components'
import AppLink from 'Component/AppLink'
import { AppRouters } from 'app-constants/app-routers'

const StepGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 470px;
`

function LPInsureAbout() {
  return (
    <Gs.Container className='pb-30'>
      <div>
        <div>
          <PageTitleSection>
            <MainTitle className='text-start fs-4xl mb-0 text-center'>
              LAUNCH LP BOND INSURANCE
            </MainTitle>
          </PageTitleSection>
        </div>
        <div className='row mt-30'>
          <div className='col-5'>
            <h2>How to launch a bond pool offering</h2>
            <StepGroup>
              <div className='d-flex flex-column gap-4 mt-30 lh-base'>
                <div className='d-flex'>
                  <div className='bg-bodybg02 px-8 py-3 h-fit'>1</div>
                  <div className='pl-10 mt-2 h-min-50'>Set up your bond terms</div>
                </div>
                <div className='d-flex'>
                  <div className='bg-bodybg02 px-8 py-3 h-fit'>2</div>
                  <div className='pl-10 mt-2 h-min-50'>Deposit your project tokens</div>
                </div>
                <div className='d-flex'>
                  <div className='bg-bodybg02 px-8 py-3 h-fit'>3</div>
                  <div className='pl-10 mt-2 h-min-50'>Drive your community to invest in your liquidity pool utilizing the bond protection</div>
                </div>
                <div className='d-flex'>
                  <div className='bg-bodybg02 px-8 py-3 h-fit'>4</div>
                  <div className='pl-10 mt-2 h-min-50'>The bonds creates and hold LP tokens by utilizing 50% of the investment funds to buy token from the project, and the other 50% to pair with</div>
                </div>
                <div className='d-flex'>
                  <div className='bg-bodybg02 px-8 py-3 h-fit'>5</div>
                  <div className='pl-10 mt-2 h-min-50'>The funds that been used to buy the tokens from the projects kept in a vault and will be used as an insurance reserve to recover LP losses, leftovers will be used to buy to the project token and burn it</div>
                </div>
              </div>
              <div className='mt-20'>
                <AppButton as={AppLink} to={AppRouters.LPInsureLaunchPools} className='outline-primary text-primary hover:text-colorWhite text-uppercase'>launch your bond lp insurance</AppButton>
              </div>
            </StepGroup>
          </div>
          <div className='col-2 position-relative'>
            <Divider className='horizontal mt-0 mb-10 highlighted-border-right position-absolute left-50p z-index-5' />
            <div className='d-flex flex-column align-items-center justify-content-center h-full z-index-10 position-relative'>
              <SBondsWrap className='bg-bodybg015 py-5'>
                <SBondsImg src='/images/lp-insure/LP-launch-icon.png' alt='' />
                <SBondsContent className='text-primary fw-bold ff-secondary mt-10'>Dumping<br/>protection</SBondsContent>
              </SBondsWrap>
            </div>
          </div>
          <div className='col-5'>
            <h2>How to invest in bond LP insurance</h2>
            <StepGroup>
              <div className='d-flex flex-column gap-4 mt-30 lh-base'>
                <div className='d-flex'>
                  <div className='bg-bodybg02 px-8 py-3 h-fit'>1</div>
                  <div className='pl-10 mt-2 h-min-50'>Select a bond to invest in</div>
                </div>
                <div className='d-flex'>
                  <div className='bg-bodybg02 px-8 py-3 h-fit'>2</div>
                  <div className='pl-10 mt-2 h-min-50'>Deposit the amount and type of tokens that the bond requires</div>
                </div>
                <div className='d-flex'>
                  <div className='bg-bodybg02 px-8 py-3 h-fit'>3</div>
                  <div className='pl-10 mt-2 h-min-50'>The bonds creates and hold LP tokens by utilizing 50% of the investment funds to buy token from the project, and the other 50% to pair with</div>
                </div>
                <div className='d-flex'>
                  <div className='bg-bodybg02 px-8 py-3 h-fit'>4</div>
                  <div className='pl-10 mt-2 h-min-50'>Sell your bond at any time via OTC or cash out when the bond vesting expired</div>
                </div>
                <div className='d-flex'>
                  <div className='bg-bodybg02 px-8 py-3 h-fit'>5</div>
                  <div className='pl-10 mt-2 h-min-50'>If you cash out and lost due to dumping the bond will use the reserve to recover 100% of your losses</div>
                </div>
              </div>
              <div className='mt-20'>
                <AppButton as={AppLink} to={AppRouters.LPInsurePools} className='outline-primary text-primary hover:text-colorWhite text-uppercase'>select bond insurance to invest in</AppButton>
              </div>
            </StepGroup>
          </div>
        </div>
        <div className='lh-base my-50'>
          <p>
            The LP insurance offered by Qwantum finance labs operates through the use of bond tokens, which facilitate the prompt settlement of claims between the insurer and the insured. To start
            the process, the insurer must first generate a bond by depositing project tokens into a smart contract. Once the bond is generated, the insured can then deploy funds into the bond.
          </p>
          <p className='mt-30'>
            Half of the deployed funds are used to directly purchase project tokens without incurring slippage, while the other half is used to create a token pair using cryptocurrencies such as ETH, USDC, or any other token. The proceeds from the project token purchase are not distributed to the project, but rather added to the bond as an insurance reserve. These funds remain in the bond until the insured decides to cash out.
          </p>
          <p className='mt-30'>
            If the value of the token pair is lower than the amount when the bond was created, the bond will automatically trigger the insurance and fully recover the insured&apos;s loss. Any remaining funds in the insurance reserve that are not used to recover the insured&apos;s loss will be used to buy back project tokens from the pool and destroy them in a deadend address.
          </p>
        </div>
        <div className='mt-30 lh-base'>
          <h3 className='fw-bold'>Current Method Example:</h3>
          <SExampleList>
            <div>
              <BsCircleFill size={10} />
              <p className='pl-20'>User wants to hold $10,000 worth of XYZ{'<>'}ETH LP tokens.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p className='pl-20'>User swap $5,000 of ETH for $5,000 worth of XYZ on a DEX, incurring slippage.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p className='pl-20'>User pair the $5,000 worth of XYZ with another $5,000 of ETH to create the LP tokens.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p className='pl-20'>Every time the XYZ token decreases in value, the LP tokens lose ETH.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p className='pl-20'>The LP tokens may become worthless if the XYZ token becomes worthless and no ETH will be left.</p>
            </div>
          </SExampleList>
        </div>

        <div className='mt-30 lh-base'>
          <h3 className='fw-bold'>New LP.Insure Method Example:</h3>
          <SExampleList>
            <div>
              <BsCircleFill size={10} />
              <p className='pl-20'>User wants to hold $10,000 worth of XYZ{'<>'}ETH LP tokens.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p className='pl-20'>User send $10,000 of ETH to the bond, using $5,000 to purchase $5,000 worth of XYZ from the bond with zero slippage and the remaining $5,000 to pair with the XYZ token.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p className='pl-20'>The bond send to the pool both the ETH and XYZ tokens and receive back the LP tokens.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p className='pl-20'>Every time the XYZ token decreases in value, the LP tokens lose ETH.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p className='pl-20'>The LP tokens may become worthless if the XYZ token becomes worthless and no ETH will</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p className='pl-20'>be left. BUT when the the user orders the bond to sell the LP tokens, the insurance kicks in and recovers the user&apos;s lost ETH up to the original value of $5,000.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p className='pl-20'>Any remaining funds from the insurance (if any) will be used by the bond to purchase.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p className='pl-20'>additional XYZ tokens from the pool, increasing its value for the community, and burned into a zero address.</p>
            </div>
          </SExampleList>
        </div>

        <div className='mt-30 lh-base'>
          <h3 className='text-primary fw-bold'>How it works</h3>
          <p>Projects contribute their tokens to the launchpool while LP investors contribute a pairing token like USDT. The launchpool contract creates a pool based on pre-selected terms and receives LP tokens from the pool. When users come to the pool to buy the project token, this increases the amount of USDT in the pool thus benefiting the LP investors.</p>
          <p>Every pool automatically has a floor, which creates the baseline ratio of the tokens in the pool.  The majority LP can vote to turn the floor on or off. If the pool has tokens that pair with native tokens (such as ETH, BNB), the Secure Floor can be voted to be reduced.</p>
        </div>
        <div className='mt-30 lh-base'>
          <h3 className='text-primary fw-bold'>Protection</h3>
          <p>In order to protect LP investors from abusing the secure floor, the contract only allows LP investors to cash out the principal investment plus any potential profits generated through the pool. The leftover project tokens return back to the project or get burned meaning no LP from the launchpool receives tokens, removing the dumping risk.</p>
        </div>
        <div className='mt-30 lh-base'>
          <h3 className='text-primary fw-bold'>New project benefits</h3>
          <p>The Secure Floor solution allows brand new projects to welcome LP investors in any market conditions. Regardless of a bear market or a bull market, with a great whitepaper or with no whitepaper, the need for investors to guess success is completely removed.</p>
        </div>
        <div className='mt-30 lh-base'>
          <h3 className='text-primary fw-bold'>LP Benefits</h3>
          <p>LPs can spread liquidity in all new projects on DynamicSwap while enjoying the potential of picking up a blue chip project at the ground floor. The upside is accompanied without the strong risk of failure or bad actors. Even LPs arriving after the Secure Floor creation benefit from the protection of the same baseline floor as early LPs.</p>
        </div>
        <div className='mt-30 lh-base'>
          <h3 className='text-primary fw-bold'>Secure Floor plus Circuit Breakers</h3>
          <p>Combining the Secure Floor with DynamicSwap’s circuit breakers means that LP investors are able to benefit from slippage circuit breakers to protect against dumping. Further, the Secure Floor plus Circuit Breakers protects against a run on the bank scenario and extreme volatility.</p>
        </div>
        <div className='mt-30 lh-base'>
          <h3 className='text-primary fw-bold'>Set redemption timelines</h3>
          <p>Projects that open pools can set the contract to lock LP principal funds or profits for a certain timeframe. This helps protect consistency in the pool even when a token is booming and LPs profits soar. Projects do not have to set the redemption timelines.</p>
        </div>
        <div className='mt-30'>
          <h2 className='text-primary fw-bold'>Example</h2>
          <SExampleList>
            <div>
              <BsCircleFill size={10} />
              <p>Project ABC opens a pool with a ratio of 1 ABC tokens to 0.01 USDT. The ABC / USDT pool forms a Secure Floor of $0.01.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p>The project provides 10,000,000 ABC tokens to launchpool contract while the LP investors provide $100,000 USDT.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p>Once the 10,000,000 ABC token and $100,000 USDT are pushed to the pool, the launchpool contract receives LP tokens worth $200,000.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p>When users utilize the pool and send an extra $100,000 of USDT to buy the ABC token, the amount of tokens in the pool reduces by 10% and the amount of USDT increases by 200%.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p>Assuming LPs own 10% of the pool and those LPs want out, the LPs can trigger a redemption call at any time in the launchpool contract.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p>If called, $20,000 of USDT (equal to 10%) is paid directly to the LP.</p>
            </div>
            <div>
              <BsCircleFill size={10} />
              <p>990,000 ABC tokens (equal to 10%) return to the project or are burned.</p>
            </div>
          </SExampleList>
        </div>
        <div className='mt-20 fs-sm text-muted'>
          <p>NB. If the market crashes and token value drops to the secure floor ration, the 0% slippage triggers restricting the ability to dump and allowing only buy orders.</p>
        </div>
      </div>
      <SupportingAllDexs />
      <SupportingBlockchains
        onClickTitle={() => {
          console.log('onClickTitle')
        }}
      />
    </Gs.Container>
  )
}

export default LPInsureAbout
