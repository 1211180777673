import { TokenListItem, AppTokenListData, AlterTokenListItem, AppCustomToken, SupportedNetworks } from "types"

export enum ActionTypes {
  set = 'TOKENS/set',
  setItem = 'TOKENS/setItem',
  add = 'TOKENS/add',
  init = 'TOKENS/init',
  remove = 'TOKENS/remove',
  // itemData end
  setItems = 'TOKENS/ITEMS/setItems',
  updateItem = 'TOKENS/ITEMS/updateItem',
  ADD_CUSTOM_TOKEN = 'TOKENS/CUSTOM_TOKEN/ADD_CUSTOM_TOKEN',
  REMOVE_CUSTOM_TOKEN = 'TOKENS/CUSTOM_TOKEN/REMOVE_CUSTOM_TOKEN',
  CLEAR_ALL_CUSTOM_TOKEN = 'TOKENS/CUSTOM_TOKEN/CLEAR_ALL_CUSTOM_TOKEN',
}

export interface ActionSetToken {
  type: ActionTypes.set
  payload: Array<AppTokenListData>
}
export interface ActionSetItem {
  type: ActionTypes.setItem
  payload: {
    index: number
    tokens: AppTokenListData
  }
}

export interface ActionAdd {
  type: ActionTypes.add
  payload: AppTokenListData
}

export interface ActionInit {
  type: ActionTypes.init
}
export interface ActionSetItems {
  type: ActionTypes.setItems
  payload: Array<TokenListItem>
}
export interface ActionRemove {
  type: ActionTypes.remove
  payload: {
    index: number
  }
}
export interface ActionUpdateItem {
  type: ActionTypes.updateItem
  payload: {
    index: number
    item: AlterTokenListItem
  }
}

export interface ActionAddCustomToken {
  type: ActionTypes.ADD_CUSTOM_TOKEN
  payload: AppCustomToken
}

export interface ActionRemoveCustomToken {
  type: ActionTypes.REMOVE_CUSTOM_TOKEN
  payload: {
    address: string
    chainId: SupportedNetworks
  }
}
export interface ActionClearCustomToken {
  type: ActionTypes.CLEAR_ALL_CUSTOM_TOKEN
}

export type ActionTokenList = ActionSetToken | ActionSetItem | ActionAdd | ActionInit | ActionRemove | ActionUpdateItem | ActionSetItems
  | ActionAddCustomToken | ActionRemoveCustomToken | ActionClearCustomToken
