import { ApiObjectTokens, APITokenObject, AppBuyNowToken, AppToken, BaseToken, TokenObject } from "./tokens";

declare global {
    interface Window { ethereum?: any; trustwallet?: any; BinanceChain?: any }
}

export enum SupportedNetworks {
    mainnet = 1,
    bsc = 56,
    bsctestnetwork = 97
}

export enum EnvType {
    development,
    production
}

export enum WalletConnectorTypes {
    BINANCE_CHAIN = 1,
    METAMASK = 2,
    TRUST_WALLET = 3,
    MATH_WALLET = 4,
}

export type KeyValuePair<T> = {
    [key in string]: T
}

export type WalletInstallStatus = {
    [key in WalletConnectorTypes]: boolean
}

export type WalletOfficialSitesType = {
    [key in WalletConnectorTypes]: string
}

export type ToolTipText = KeyValuePair<string>

export type TokenResponse = {
    keywords: string[]
    logoURI: string
    name: string
    timestamp: string
    tokens: Array<APITokenObject> | ApiObjectTokens
    version: { major: number, minor: number, patch: number }
}

export type AppTokenListData = {
    keywords: string[]
    logoURI: string
    name: string
    timestamp: string
    tokens: Array<AppToken>
    version: { major: number, minor: number, patch: number }
}

export type AppNetwork = {
    HTTP_PROVIDER_URL: string,
    W_TOKEN_ADDRESS: string,
    CHAIN: SupportedNetworks,
    NAME: string,
    FACTORY: string, // bswap factory address
    ROUTER: string, // bswap router address
    ExplorerUrl: string,
}

export type AppNetworkConfig<T> = {
    [key in SupportedNetworks]: T
}

export type Networks = AppNetworkConfig<AppNetwork>
export type NetworkUrls<T = string> = AppNetworkConfig<T>
export type SmartContractConfig = AppNetworkConfig<string>
export type AppOtherConfigByNetwork = AppNetworkConfig<string>

export type AppBuyNowTokenConfig = AppNetworkConfig<Array<AppBuyNowToken>>

export type GraphData = {
    timestamp: number
    name: string
    real: number
    value: number
}

export type PairHourDatas = {
    id: string
    hourStartUnix: number
    pair: {
        id: string
        token0: {
            address: string
            name: string
            symbol: string
        }
        token1: {
            address: string
            name: string
            symbol: string
        }
    }
    reserve0: string
    reserve1: string
    reserveUSD: number
}


export type PairDayDatas = {
    id: string
    date: number
    token0: {
        address: string
        name: string
        symbol: string
    }
    token1: {
        address: string
        name: string
        symbol: string
    }
    reserve0: string
    reserve1: string
    reserveUSD: number
}


export type ApiToken = {
    [key in string]: APITokenObject
}

export type TokenListItem = {
    tokenListUrl: string
    alterName?: string
    alterImage?: string
    enabled: boolean
    locked: boolean
    tokens?: Array<TokenObject>
}

export type AlterTokenListItem = {
    data?: string
    alterName?: string
    alterImage?: string
    enabled?: boolean
    tokens?: Array<TokenObject>
}

export type AppTokenUrlConfig = AppNetworkConfig<Array<TokenListItem>>

export type CoinPrice = {
    id: string;
    symbol: string;
    name: string;
    price?: {
        [key in string]: number
    };
}

export type VoteVar = {
    index: number,
    decimals: number,
}

export type VoteVars = {
    timeFrame: VoteVar
    maxDump0: VoteVar
    maxDump1: VoteVar
    maxTxDump0: VoteVar
    maxTxDump1: VoteVar
    coefficient: VoteVar
    minimalFee: VoteVar
    periodMA: VoteVar
}

export type ProposalItem = {
    index: number
    title: string
    ruleId: number
    getVoteDescrition: (...params: any) => string
    about?: JSX.Element
    order?: number
    poolVar?: VoteVar
    className?: string
}

export type TokenImages = {
    [key in string]: string
}

export type DexInfo = {
    title: string
    router: string
    img: string
    theGraphUri: string
}

export interface ApiDexInfo extends DexInfo {
    factory: string
    type: string
    chainId: number
    id: number
    pairUrl: string
}

export type BondLabelInfo = {
    label: string,
    value: string,
}

export type TimeOption = {
    label: string,
    value: number,
}

export interface BallotTimeOption extends TimeOption {
    decimalValue: string | number
}

export type BondType = {
    DISCOUNT: BondLabelInfo
    IMPERMANENT: BondLabelInfo
}

export type BondAlterNativeTokens = {
    [key in string]: string
}

export type FeeStructureLabelInfo = {
    label: string,
    tooltipText?: string,
    value: number,
    fee: number | string,
}

export type FeeStructure = {
    UP_WITH_FREE_FEE: FeeStructureLabelInfo
    ABOVE_WITH_TOKEN_FEE_INTO_PDO_AND_Dumper: FeeStructureLabelInfo
}
export enum VestingOptions {
    Cliff = 'CLIFF',
    Graded = 'GRADED',
}

export type VestingOption = {
    CLIFF: VestingOptions,
    GRADED: VestingOptions,
}

export enum RedemptionOptions {
    Burn = 'BURN',
    Refund = 'REFUND',
}

export type RedemptionOption = {
    BURN: RedemptionOptions
    REFUND: RedemptionOptions
}

export type AppCookiesConfig = {
    isChannelMember: 'isChannelMember'
}

export type VoteRule = {
    ABI: string
    types: Array<string>
}

export enum TransactionSpeed {
    STANDARD = 1,
    FAST = 1.5,
    INSTANT = 2,
}

export type TransactionSpeedConfig = {
    STANDARD: TransactionSpeed
    FAST: TransactionSpeed
    INSTANT: TransactionSpeed
}

export type Ballot = {
    id: string
    ruleId: number
    ruleIndex: number | string | null
    args: string
    status: number
    closeVote: number
    totalVotes: number
    totalVotesLP: number
    yea: number
    yeaLP: number
    timestamp: number | string
    pair: {
        address: string
        token0: {
            address: string
            name: string
            symbol: string
            decimals: string
        }
        token1: {
            address: string
            name: string
            symbol: string
            decimals: string
        }
    }
}

export type BondInfo = {
    bondId: string
    logo: string
    website?: string
    whitepaper?: string
    yeaVote: number
}

export type FeeParam = {
    id: string
    dsReleaseTime: string
    feeType: number
    router: string
    stakingAPY: number
    stakingPeriod: string
    licensee: string;
}

export type VestingParam = {
    id: string
    cliffDate: string
    cliffProfitDate: string
    // gradedPeriod: string
    // gradedPortionPercent: number
    // gradedProfitPeriod: string
    // gradedProfitPortionPercent: number
    prepaymentPenalty: number
}

export type BondParameters = {
    id: string
    pairToken: BaseToken
    token: BaseToken
    maxInvestment: number
    minInvestment: number
    endDate: number
    startDate: number
    supplyAmount: number
    targetAmount: number
    feeParam: FeeParam
    vestingParam: VestingParam
}

export type PoolParam = {
    poolPair: {
        lpAddress: string
        reserve0: string
        reserve1: string
        token1: {
            address: string
            name: string
            symbol: string
            decimals: string
        }
        token0: {
            address: string
            name: string
            symbol: string
            decimals: string
        }
        reserveETH: string
        totalSupply: string
    }
    projectWallet: string
    // spentTokens: string
    // fee: string | number
    // totalRaisedPairTokens: string
}

export type InitialPosition = {
    id: string
    bond: BondData
    user: string
    tokenAmount: string | number
    pairAmount: string | number
}

export type BondData = {
    id: string
    viewId: number
    nonceId: string
    creator: string
    classId: string
    transactionHash: string
    paramerters: BondParameters
    poolParam: PoolParam
    initialPositions: InitialPosition[]
    info?: BondInfo | null
}

export type ConvertableTokenSymbolConfig = {
    wbnb: string,
    weth: string,
}

export type GraphDataType = {
    id: string
    label: string
    days: number // used for subgraph
    periodLabel: string
    periodValue: string
    labelFormat: string
}

export type GraphTypes = {
    days: GraphDataType
    week: GraphDataType
    month: GraphDataType
    year: GraphDataType
}

export type TokenDayData = {
    id: string
    date: number
    priceUSD: string
    token: {
        id: string
        symbol: string
        name: string
        decimals: string
    }
}

export type Nav = {
    text: string
    to: string
    reg?: RegExp
    externalLink: string
    dataTip?: string
}

export enum CreatePoolType {
    New,
    Fork
}

export type AppVaildate = {
    isInvalid: boolean
    message: string
}

export type Blockchain = {
    chainId: SupportedNetworks
    icon: string
    name: string
}

export enum RoiBoosterTabs {
    BondHolders     = '0',
    LPHolders       = '1',
    Project         = '2',
    Traders         = '3'
}

export type PageLoadState = {
    isLoading: boolean;
    error: string;
}
