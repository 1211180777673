import { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'
import DataTable from 'react-data-table-component'
import { fontSizes } from 'Theme/theme'

const buildCustomStyles = ({ BGColor, Color, HeaderColor, BorderColor }: {
  BGColor: string, Color: string, HeaderColor: string, BorderColor: string
}) => ({
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      color: HeaderColor,
      background: BGColor,
      borderTopWidth: '2px',
      borderBottomWidth: '2px',
      borderColor: BorderColor,
      height: '50px',
    },
  },
  headCells: {
    style: {
      '& div': {
        fontSize: fontSizes.sm,
        whiteSpace: 'normal',
        fontWeight: 'bold',
        lineHeight: '20px',
      },
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        color: HeaderColor,
        background: BGColor,
        borderRightWidth: '1px',
        borderColor: BorderColor,
      },
    },
  },
  cells: {
    style: {
      background: BGColor,
      borderRightWidth: '1px',
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        color: Color,
        background: BGColor,
        borderRightWidth: '1px',
        borderColor: BorderColor,
      },
    },
  },
  rows: {
    style: {
      borderRightWidth: '0px',
      height: '50px',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderColor: BorderColor,
      borderBottomColor: `${BorderColor} !important`,
      color: Color,
    },
  },
  pagination: {
    style: {
      background: BGColor,
      color: 'white',
    },
  },
})

export const useTableStyles = () => {
  const theme = useContext(ThemeContext)

  return useMemo(
    () => buildCustomStyles({ BGColor: theme.bodybg03, Color: 'white', BorderColor: theme.greyBorder, HeaderColor: theme.colorGrey6 }),
    [theme.bodybg03, theme.colorGrey6, theme.greyBorder]
  )
}

export const SDataTable = styled(DataTable)``
