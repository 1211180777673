import styled, { css } from 'styled-components'
import Media from 'Theme/media-breackpoint'

type AppScrollbarCSSProps = {
  scrollWidth?: string
  trackColor?: string
  handleColor?: string
  handleHoverColor?: string
}

export const AppScrollbarCSS = css<AppScrollbarCSSProps>`
  /* width */
  &::-webkit-scrollbar {
    width: ${({ scrollWidth }) => scrollWidth || '8px'};
    border-radius: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${({ trackColor }) => trackColor || '#f1f1f1'};
    border-radius: 6px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${({ handleColor }) => handleColor || '#888'};
    border-radius: 6px;
    border: 2px solid ${({ trackColor }) => trackColor || '#f1f1f1'};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ handleHoverColor }) => handleHoverColor || '#555'};
  }
  /* horizontal scrollbar */
  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  /* scrollbar button */
  &::-webkit-scrollbar-corner {
    display: none;
  }
`

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

export const ImgBx = styled(FlexDiv)`
  width: 23px;
  height: 23px;
  text-align: center;
  margin-right: 5px;
  border-radius: 15px;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }
`

export const Box = styled.div`
  position: relative;
  border-radius: 10px;
  border: 2px solid ${(props) => props.theme.bodybg02};
  background-color: ${(props) => props.theme.bodybg03};
  &.ver2 {
    background: none;
    border: none;
    padding: 0px;
    :after {
      display: none;
    }
  }
  &.desktop-div {
    ${Media.md2} {
      display: none;
    }
  }
  &.mobile-div {
    display: none;
    ${Media.md2} {
      display: block;
    }
  }
  &.main {
    ${Media.md2} {
      max-height: auto;
    }
  }

  ${Media.md2} {
    width: 100%;
    margin-bottom: 20px;
  }
  ${Media.xs} {
    padding: 10px;
  }
`

export const RoundedDiv = styled.div`
  border: 2px solid ${({ theme }) => theme.greyBorder};
  border-radius: 10px;
  padding: 10px;
`
interface ISWrongNetworkBtnProps {
  size?: string
}
export const SWrongNetworkBtn = styled.button<ISWrongNetworkBtnProps>`
  align-items: center;
  border-color: ${(props) => props.theme.colorRed};
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  display: flex;
  justify-content: center;
  left: 0px;
  width: ${(props) => props.size || '40px'};
  height: ${(props) => props.size || '40px'};
  z-index: 102;

  & > svg {
    color: ${(props) => props.theme.colorRed};
  }
`

export const ModalBox = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.bodybg03};
  border: 2px solid #000;
  display: block;
  border-radius: 10px;
`

export const TabActionWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 25px;
  padding: 10px;
  left: 0;
  margin-right: 5px;
`

export const ViewAddressWrap = styled.div``
export const PageTitleSection = styled.div``
export const JoinCommunityLockWrapper = styled(FlexDiv)`
  width: 45px;
  height: 45px;
  box-shadow: 0 0px 10px -1px ${(props) => props.theme.colorRed};
`
export const SSelfImg = styled.img`
  display: inline;
  max-width: 58px;
  min-width: 58px;
  min-height: 52px;
  max-height: 52px;
  margin-top: -15px;
  margin-left: 2px;
  margin-right: 2px;
`
