import React, { useState, useCallback, useContext, useEffect, useRef, useMemo } from 'react'
import { ThemeContext } from 'styled-components'
import { BsQuestionCircleFill } from 'react-icons/bs'
import ReactHtmlParser from 'react-html-parser'
import { DebounceInput } from 'react-debounce-input'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import { useTour } from '@reactour/tour'
import { IoIosArrowDown } from 'react-icons/io'
import { FaLock } from 'react-icons/fa'

import appSocket from 'socket/socket'
import { LoadingSpinner } from 'Component/AppLoadingSpinner'
import Gs from 'Theme/globalStyles'
import tooltip from 'app-constants/tooltip-texts'
import { AppFlipNumbers, TotalRaiseWrap } from 'Component/core/elements/AppFlipNumbers'
import { JoinCommunityLockWrapper, PageTitleSection } from 'Component/core/elements/others/styled'
import { MainDesc, MainTitle } from 'Component/core/elements/Text'
import { LPPageBannerInnovation } from 'Component/PageBanner/PageBanner'
import { LaunchButton, PopInput, SearchBox, SearchInputWrapper } from 'Component/core/elements/SearchBox'
import AppLink from 'Component/AppLink'
import LPInsureBondItem from 'Component/BondCards/LPInsureBondCard'
import { AppOverlay } from 'Component/core/elements/Overlay'
import BotSignInButtton from 'Component/Telegram/BotSignInButtton'
import { SEARCH_PARAMS } from 'app-constants/search-params'
import { useAppChain, useIsMemeberCookie, useSearchParam } from 'hooks'
import SupportingBlockchains from 'Component/Supports/SupportingBlockchains'
import SupportingAllDexs from 'Component/Supports/SupportingAllDexs'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { GlobalsSelector, LPInsureListSelector, TourSelector } from 'redux/selectors'
import { fetchInsureList, setSearch } from 'redux/reducers/lp-insure-list/actions'
import { LPInsureData } from 'types'
import CurvedArrow from 'Component/core/elements/Curved-Arrow'
import { TourPopup } from 'Component/tours'
import { setLPInsureNeverShowTour, setOpenLPInsureTour } from 'redux/reducers/tour/Actions'
import { AppRouters } from 'app-constants/app-routers'
import { FireNotification } from 'Component/core/elements/Notifications'
import { SOffcanvas } from 'Component/core/elements/OffCanvas'
import { PPClosBTN01 } from 'Component/core/elements/Buttons'
import YourSecureFloorLaunchPools from './components/YourSecureFloorLaunchPools'
import { getInsureTotalRaisedUSD } from 'helper/apis/bond-server-api'
import { numberWithZero } from 'helper'
import useAppSnackbar, { AppWaringOptions } from 'hooks/useAppSnackbar'
import useAppDebounceEffect from 'hooks/useAppDebounceEffect'
import { withConnectWalletModal } from 'redux/actions'


interface ILPInsurePoolsProps {
  connectWallet: () => void; // from withConnectWallet;
}
function LPInsurePools({ connectWallet }: ILPInsurePoolsProps) {
  const { currentStep, isOpen: isOpenTour, setIsOpen: setIsOpenTour, steps: tourSteps } = useTour();
  const { isLPInsureNeverShow, isOpenLPInsureTour } = useAppSelector(TourSelector)
  const { account } = useAppSelector(GlobalsSelector)
  const theme = useContext(ThemeContext)
  const { appChainId } = useAppChain();
  const [openWarningSnack] = useAppSnackbar(AppWaringOptions);

  const { list: lpInsureList, isLoading, investLengthIn24hrs, errMsg } = useAppSelector(LPInsureListSelector);
  const dispatch = useAppDispatch();
  const [isChannelMember] = useIsMemeberCookie()

  const showConnectToChannel = !isChannelMember
  const tgError = useSearchParam(SEARCH_PARAMS.tgError)
  const yourInvestmentRef = useRef<HTMLButtonElement>(null)
  const [hideNotification, setHideNotification] = useState(false)
  const [autoRefresh, setAutoRefresh] = useState(false)
  const [totalRaisedUSD, setTotalRaisedUSD] = useState(numberWithZero(0, 9))

  const inactivedBonds = useMemo<LPInsureData[]>(() => {
    return lpInsureList.filter((b) => {
      return +b.parameters.endDate < Date.now()
    })
  }, [lpInsureList])

  const commingBonds = useMemo<LPInsureData[]>(() => {
    return lpInsureList.filter((b) => {
      return +b.parameters.startDate > Date.now() && +b.parameters.endDate > Date.now()
    }).slice()
  }, [lpInsureList, autoRefresh])

  const activedBonds = useMemo<LPInsureData[]>(() => {
    return lpInsureList.filter((b) => {
      return +b.parameters.endDate > Date.now() && +b.parameters.startDate < Date.now()
    }).slice()
  }, [lpInsureList, autoRefresh])

  const handleOnClickTourClose = useCallback(
    (state: boolean) => {
      if (state === false) {
        dispatch(setOpenLPInsureTour(false))
      }
      dispatch(setLPInsureNeverShowTour(state))
    },
    []
  )

  const handleOnChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(e.target.value))
    dispatch(fetchInsureList(appChainId, account))
  }, [account])

  const handleOnClickConnectWallet = () => {
    if(yourInvestmentRef.current) {
      yourInvestmentRef.current.click();
      connectWallet();
    }
  }

  useEffect(() => {
    dispatch(fetchInsureList(appChainId, account))
  }, [appChainId, account])

  useEffect(() => {
    setIsOpenTour(isLPInsureNeverShow === true ? false : isOpenLPInsureTour)
  }, [isLPInsureNeverShow, isOpenLPInsureTour])

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if(commingBonds.length > 0) {
      let nearestComingDate: number = parseInt(String(commingBonds[0].parameters.startDate), 10);

      for (let i = 0; i < commingBonds.length; i++) {
        const bond = commingBonds[i];
        if(+nearestComingDate > +bond.parameters.startDate) {
          nearestComingDate = +bond.parameters.startDate
        }
      }

      if(nearestComingDate > 0) {
        timer = setTimeout(() => {
          setAutoRefresh(p => !p)
        }, nearestComingDate + 1000)
      }
    }
    return () => {
      timer && clearTimeout(timer)
    }
  }, [commingBonds])

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getInsureTotalRaisedUSD({ networkId: appChainId })
        const value = data.totalRaisedUSD || 0
        setTotalRaisedUSD(numberWithZero(value, 9))
      } catch (error) {
        // @ts-ignore
        openWarningSnack(`Can't get total raised amount. \n reason: ${error.message || error}`)
        setTotalRaisedUSD(numberWithZero(0, 9))
      }
    })()

    {
      const onChangeTotalRaised = (usd: number) => {
        console.log('onChangeInsureTotalRaised: ', usd);
        setTotalRaisedUSD(numberWithZero(usd, 9))
      }
      const eventName = `Insure/TotalRaised/${appChainId}`;
      appSocket.on(eventName, onChangeTotalRaised)
      return () => {
        appSocket.off(eventName, onChangeTotalRaised)
      }
    }
  }, [appChainId])

  useAppDebounceEffect(() => {
    errMsg && openWarningSnack(errMsg)
  }, 200, [errMsg])

  return (
    <Gs.Container className='pb-30'>
      <div className='d-flex justify-content-between'>
        <div>
          <PageTitleSection>
            <MainTitle className='text-start fs-6xl text-primary mb-0 text-shadow-primary'>INVEST IN LIQUIDITY POOLS</MainTitle>
            <MainTitle className='text-start fs-2xl p-0'>WITH 100% DECENTRALIZED DUMPING PROTECTION INSURANCE</MainTitle>
            <MainDesc className='text-start p-0'>
              3 in 1 bond: buying tokens at market price with no slippage, earning high APY through self-custodied farming, and protecting against price dumping through decentralized insurance without the need for voting or claiming.
              <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('BOND_PAGE_OFFER_TOOLTIP')} />
            </MainDesc>
          </PageTitleSection>
          <div className='d-flex'>
            <TotalRaiseWrap>
              <p className='ml-10 text-dark-1'>Total Raised</p>
              {/* @ts-ignore */}
              <AppFlipNumbers
                border={2}
                className='mb-50 text-primary fw-bolder'
                background={theme.bodybg014}
                // color={theme.colorPrimary01}
                numbers={`$${totalRaisedUSD}`}
                play
              />
            </TotalRaiseWrap>
          </div>
        </div>
        <LPPageBannerInnovation className='mr-40' />
      </div>
      <div className='mb-20'>
        <SearchBox className='w-full py-10 flex-row flex-nowrap fs-lg mt-20'>
          <SearchInputWrapper className='pl-20 w-full d-flex'>
            <div className='text-white d-flex align-items-center text-nowrap'>
              <i className='fas fa-search'></i>
              <span className='pl-10'>Search by</span>
            </div>
            {/* @ts-ignore TODO */}
            <PopInput as={DebounceInput} element='input' debounceTimeout={400} className='w-full pl-10' placeholder='Project name, token address...'
              onChange={handleOnChangeSearch}
            />
          </SearchInputWrapper>
          <LaunchButton
            className='black w-auto flex-column py-20' as={AppLink} to={`${AppRouters.LPInsureAboutPage}`}
            data-tour="lpInsure-tour-deploy-lp-insurance"
          >
            <p className='text-uppercase px-50 text-nowrap mb-0'>
              <span>Deploy</span>
              &nbsp;
              <span className='text-primary'>LP Insurance</span>
            </p>
          </LaunchButton>
        </SearchBox>
        <div className='position-absolute right-0 mt-90 z-index-20'>
          <FireNotification className='fs-sm' hidden={hideNotification || +investLengthIn24hrs === 0} onClickClose={() => setHideNotification(true)}>
            Hurry, {investLengthIn24hrs} investrs already invest in the last 24 hours
          </FireNotification>
        </div>
        {isLoading ? (
          <div className='d-flex justify-content-center mt-40 h-40p'>
            <LoadingSpinner />
          </div>
        ) : (
          <React.Fragment>
            <div className='row mt-40'>
              {activedBonds.slice(0, 3).map((item, index, self) => (
                <div key={index} className='col-sm-12 col-md-6 col-lg-6 col-xl-4 gy-3'>
                  <LPInsureBondItem bondData={item} tourEnable={self.length === 1 || index === 1} />
                </div>
              ))}
            </div>
            <div className='position-relative py-5'>
              {activedBonds.length > 3 ? (
                <>
                  {showConnectToChannel && (
                    <AppOverlay className='w-full h-full d-flex flex-column align-items-center position-absolute z-index-10 justify-content-center' blur={3}>
                      <JoinCommunityLockWrapper className='rounded-circle mb-3 border border-danger'>
                        <FaLock size={20} />
                      </JoinCommunityLockWrapper>
                      <p className='py-10'>Join the community to unlock all the opportunities</p>
                      {/* <AppButton className='w-auto px-40 fs-md py-10 bg-colorBlue02' onClick={connectToTelegramChannel}>
                      Connect to Telegram
                    </AppButton> */}
                      {tgError && <p className='text-danger'>{ReactHtmlParser(tgError)}</p>}
                      <BotSignInButtton size='large' radius={5} dataAuthUrl={`${window.location.origin}/telegram/callback${location.search}&redirectUrl=${`${AppRouters.LPInsureLaunchPools}`}`} />
                    </AppOverlay>
                  )}
                  <div className='row'>
                    {activedBonds.slice(3, Infinity).map((item, index) => (
                      <div key={index} className='col-sm-12 col-md-6 col-lg-6 col-xl-4 gy-3'>
                        <LPInsureBondItem bondData={item} />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div></div>
              )}
            </div>
            <Accordion allowZeroExpanded className='mt-30' preExpanded={[1]}>
              {/* Coming soon */}
              <AccordionItem uuid={1}>
                <AccordionItemHeading>
                  <AccordionItemButton className='fs-md'>
                    <div className='d-flex justify-content-center'>
                      <h4 className='mr-5'>Coming soon</h4>
                      <IoIosArrowDown />
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='mt-20 fs-12 text-muted row mt-20'>
                  {commingBonds.length > 0 &&
                    commingBonds.map((item, index) => (
                      <div key={index} className='col-sm-12 col-md-6 col-lg-6 col-xl-4 gy-3 position-relative'>
                        <AppOverlay className='w-full h-full d-flex flex-column align-items-center position-absolute z-index-10 justify-content-center'>
                          <h3 className='text-primary'>Coming soon</h3>
                        </AppOverlay>
                        <LPInsureBondItem bondData={item} />
                      </div>
                    ))}
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
            <Accordion allowZeroExpanded className='mt-30' preExpanded={[2]}>
              {/* Inactive reserve */}
              <AccordionItem uuid={2}>
                <AccordionItemHeading>
                  <AccordionItemButton className='fs-md'>
                    <div className='d-flex justify-content-center'>
                      <h4 className='mr-5'>Inactive</h4>
                      <IoIosArrowDown />
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='fs-12 text-muted row mt-20'>
                  <div className='row mt-20'>
                    {inactivedBonds.length > 0 &&
                      inactivedBonds.map((item, index) => (
                        <div key={index} className='col-sm-12 col-md-6 col-lg-6 col-xl-4 gy-3 position-relative'>
                          <LPInsureBondItem bondData={item} active={false} />
                        </div>
                      ))}
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </React.Fragment>
        )}
      </div>
      <SOffcanvas className='offcanvas w-full offcanvas-end bg-primary-1' tabIndex={-1} id='lpYourInvestmentCanvas'>
        <div className='offcanvas-header'>
          <PPClosBTN01 ref={yourInvestmentRef} className='position-absolute right-10 top-10' data-bs-dismiss='offcanvas' aria-label='Close' />
        </div>
        <div className='offcanvas-body'>
          <YourSecureFloorLaunchPools onClickConnectWallet={handleOnClickConnectWallet} />
        </div>
      </SOffcanvas>
      <SupportingAllDexs />
      <SupportingBlockchains />
      <CurvedArrow show={isOpenTour} dynamicUpdate width={3} toOffsetX={-5} color='white' fromSelector='.tour-content' toSelector={tourSteps[currentStep].selector} zIndex={100000} />
      <TourPopup show={isOpenTour as boolean} onClickClose={handleOnClickTourClose} />
    </Gs.Container>
  )
}

export default withConnectWalletModal(LPInsurePools)
