import { BondData, BondListReducerState } from 'types'
import { ActionBondList, ActionTypes } from './types'

export const initialState: BondListReducerState = {
  isLoading: false,
  search: '',
  list: [],
  investLengthIn24hrs: 0,
}

export default function Reducer(state = initialState, action: ActionBondList): BondListReducerState {
  let classId: string, nonceId: string, bondIndex: number, list: Array<BondData>, item: BondData
  switch (action.type) {
    case ActionTypes.FETCH_BOND_LIST:
      return { ...state, list: action.payload }

    case ActionTypes.IS_LOADING_BOND_LIST:
      return { ...state, isLoading: action.payload }

    case ActionTypes.SET_SEARCH_VALUE:
      return { ...state, search: action.payload }

    case ActionTypes.SET_INVEST_LENGTH_IN_24HRS:
      return { ...state, investLengthIn24hrs: action.payload }

    case ActionTypes.VOTE_TO_BOND:
      classId = action.payload.classId
      nonceId = action.payload.nonceId
      bondIndex = state.list.findIndex((b) => b.classId === classId && b.nonceId === nonceId)
      list = state.list.slice()
      item = list[bondIndex]
      if(!item.info) {
        item.info = {
          bondId: `${classId}-${nonceId}`,
          logo: '',
          yeaVote: 0,
        }
      }
      if(!item.info.yeaVote) {
        item.info.yeaVote = 0
      }
      item.info.yeaVote += 1
      return { ...state, list: list.slice() }
    default:
      return state
  }
}
