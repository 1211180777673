import { isAddress } from 'web3-utils'
import Subgraph from './base'
import { BondSubgraphs } from 'app-constants'
import { SupportedNetworks } from 'types'
import { createClient, GetInsureFarms, GetInsureFarmsQL, GetInsureInvestors, GetInsureInvestorsType, GetInsureOffersWithLPBalanceQL, GetInsureOffersWithLPBalanceRes, GetUserInsureBonds, GetUserInsureBondsType } from '../graphql/index'

// DEREX subgraph helper
class LPInsureSubgraph  extends Subgraph{
  SubGraphClient

  constructor(_chainId: SupportedNetworks) {
    super();
    this.SubGraphClient = createClient(BondSubgraphs[_chainId])
  }

  buildLPBondsWhere = (search: string) => {
    if(isAddress(search)) {
      return {
        token: search.toLowerCase()
      }
    } else {
      return {
        token_: {
          symbol_starts_with_nocase: search
        }
      }
    }
  }

  getLPBonds = async (where: undefined | any = undefined, first = 1000, skip = 0, user: string | undefined = undefined ) => {
    if(user) user = user.toLowerCase();
    return await this.SubGraphClient.query<GetInsureOffersWithLPBalanceRes>({
      query: GetInsureOffersWithLPBalanceQL,
      variables: {
        first,
        skip,
        where: where,
        user: user
      },
    })
  }

  getInsureInvestors = async (where: undefined | any = undefined) => {
    return await this.SubGraphClient.query<GetInsureInvestorsType>({
      query: GetInsureInvestors,
      variables: {
        where: where,
      },
    })
  }
  getInsureBondsByUser = async (account: string) => {
    return await this.SubGraphClient.query<GetUserInsureBondsType>({
      query: GetUserInsureBonds,
      variables: {
        account: `${account}`.toLowerCase(),
      },
    })
  }
  getInsureFarms = async(where: any = undefined) => {
    return await this.SubGraphClient.query<GetInsureFarms>({
      query: GetInsureFarmsQL,
      variables: {
        where: where
      },      
    })
  }
}

export default LPInsureSubgraph
