import { NETWORKS } from 'app-constants'
import { SEARCH_PARAMS } from 'app-constants/search-params'
import { smartChainQuery } from 'helper'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Web3 from 'web3'
import useWallet from './useWallet'
import { SupportedNetworks } from 'types'
export * from './others'
export * from './usePairs'
export * from './usePools'
export * from './useModal'
export * from './useTokenListStorage'
export * from './coingecko'

type UseOnScreenReturnType = [boolean, React.Dispatch<any>, any]
// hook that binds intersection observer
export const useOnScreen = (options?: IntersectionObserverInit): UseOnScreenReturnType => {
  const [ref, setRef] = useState<any>(null)
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options)

    if (ref) {
      observer.observe(ref)
    }

    return () => {
      if (ref) {
        observer.unobserve(ref)
      }
    }
  }, [options, ref])

  return [visible, setRef, ref]
}

// export const useAppNetwork = (): AppNetwork => {
//   const { appChainId } = useAppChain()
//   if (!appChainId) {
//     throw new Error('Something Went Wrong, Network does not exists.')
//   }

//   const network = useMemo(() => {
//     return NETWORKS[appChainId]
//   }, [appChainId])

//   return network
// }

export const useWeb3 = (): Web3 => {
  const { library } = useWallet() // if wallet is connected
  const { appChainId } = useAppChain()

  // console.log('HTTP_PROVIDER_URL: ', HTTP_PROVIDER_URL)
  // console.log('library?.provider: ', library?.provider)
  // console.log('Web3.givenProvider: ', Web3.givenProvider)
  const web3 = useMemo(() => {
    return new Web3(library?.provider || NETWORKS[appChainId].HTTP_PROVIDER_URL)
  }, [library?.provider, appChainId])
  return web3
}

export const useSearchParam = (name: string): string | null => {
  const { search } = useLocation()
  const value = new URLSearchParams(search).get(name)
  return value
}
type UseAppChainReturnType = {
  appChainId: SupportedNetworks
  switchAppNetwork: (network: SupportedNetworks) => void
}
export const useAppChain = (): UseAppChainReturnType => {
  const chainId: SupportedNetworks = (useSearchParam(SEARCH_PARAMS.chainId) || SupportedNetworks.bsctestnetwork) as SupportedNetworks
  const id = +chainId as SupportedNetworks
  const history = useHistory()

  const switchAppNetwork = useCallback((_chainId: SupportedNetworks) => {
    const search = smartChainQuery(history.location.search, _chainId)
    history.push({
      pathname: history.location.pathname,
      search: search,
    })
  }, [history])

  return {
    appChainId: id,
    switchAppNetwork
  } as UseAppChainReturnType
}
