import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import type { TransactionReceipt } from 'web3-core'
import moment from 'moment'
import { adjustLPInsureParams } from 'helper'
import { RootState } from 'redux/store'
import { AppThunkAction, LPInsureInfo, SupportedNetworks } from 'types'
import { ActionLPInsureList, ActionTypes } from './types'
import { LPInsureData } from 'types'
import { getMaxAmount } from 'helper/multi-calls/lp-bond-call-helpers'
import { getIsureOffers, voteToInsure } from 'helper/apis/insure-apis'
import { getErrorMessageHTTP } from 'helper/error-utils'
import LPInsureSubgraph from 'helper/subgraphs/LPInsureSubgraph'
import { LP_INSURE_MAIN_CONTRACT, LP_INSURE_PROXY_FACTORY } from 'app-constants/lp-insure'
import InsureOfferABI from 'Assets/Abi/lp-insure-proxy-abi.json'
import InsureBondABI from 'Assets/Abi/lp-insure-bond-abi.json'
import { allowance, approve } from 'helper/sc-utils/insure-sc-utils'

type LPInsureListAppThunkAction<
  R, // Return type of the thunk function
> = AppThunkAction<R, RootState, null, ActionLPInsureList>

export const setSearch = (query: string): LPInsureListAppThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SEARCH_VALUE,
    payload: query,
  })
}

export const fetchInsureList = (appChainId: SupportedNetworks, account: string | undefined = undefined): LPInsureListAppThunkAction<Promise<void>> => async (dispatch, getState) => {
  const search = getState().lpInsureList.search
  let insurePools: LPInsureData[] = []
  dispatch({
    type: ActionTypes.IS_LOADING_INSURE_LIST,
    payload: true,
  })
  // get bonds
  try {
    const bondSubgraph = new LPInsureSubgraph(appChainId)
    let where: any = undefined
    if (search) {
      where = bondSubgraph.buildWhereByToken(search)
    }
    const res = await bondSubgraph.getLPBonds(where, undefined, undefined, account)
    if (res.data === null || !Array.isArray(res.data.insurePools)) {
      throw new Error('Something went wrong. Bond Data is null or Bond list is not array.')
    }
    insurePools = res.data.insurePools.map((insurePool) => ({
      ...insurePool,
      maxAmounts: {
        maxPayAmount: '0',
        maxTokenAmount: '0',
      },
      info: undefined,
    }))
    insurePools = insurePools.map((insurePool) => adjustLPInsureParams(insurePool))
  } catch (error) {
    console.error(error)
    dispatch({
      type: ActionTypes.SET_ERROR_MESSAGE,
      // @ts-ignore
      payload: `${error?.message || error}`,
    })
  }
  const offerIds = insurePools.map(lpPool => lpPool.id);
  // getMaxAmount
  const maxAmounts = await getMaxAmount(appChainId, offerIds)
  insurePools = insurePools.map(pool => {
    const maxAmount = maxAmounts[pool.id];
    pool.maxAmounts = {
      maxPayAmount: maxAmount?.maxPayAmount || '0',
      maxTokenAmount: maxAmount?.maxPayAmount || '0',
    }
    return pool;
  })
  try {
    // get insure pool informations
    const offerIDs = insurePools.map((item) => item.id)
    const res = await getIsureOffers({ offerIds: offerIDs, networkId: appChainId });
    const infos = res.data.offers as LPInsureInfo[]

    for (let i = 0; i < insurePools.length; i++) {
      const bond = insurePools[i]
      const _info = infos.find((info) => +info.offerID === +bond.id)
      bond.info = _info
    }
  } catch (error) {
    console.error(error);
    dispatch({
      type: ActionTypes.SET_ERROR_MESSAGE,
      // @ts-ignore
      payload: `${error?.message || error}`,
    })
  }
  dispatch({
    type: ActionTypes.FETCH_INSURE_LIST,
    payload: insurePools.slice(),
  })
  dispatch({
    type: ActionTypes.IS_LOADING_INSURE_LIST,
    payload: false,
  })
}

export const reFetchInsureList = (): LPInsureListAppThunkAction<Promise<void>> => async (dispatch, getState) => {
  const appChainId = getState().globals.appChainId;
  const account = getState().globals.account;
  dispatch(fetchInsureList(appChainId, account))
}

export const voteToOffer = (offerId: number): LPInsureListAppThunkAction<Promise<void>> => async (dispatch, getState) => {
  const appChainId = getState().globals.appChainId
  try {
    const res = await voteToInsure({ offerID: +offerId, networkId: appChainId })
    if (res.data.success) {
      dispatch({
        type: ActionTypes.SET_VOTE_TO_OFFER,
        payload: offerId
      })
    } else {
      throw new Error('Something Went Wrong!')
    }
  } catch (error: any) {
    const er = getErrorMessageHTTP(error)
    console.error(er)
    throw er
  }
}

export const fetchBondBuyersInLast24hrs = (appChainId: SupportedNetworks): LPInsureListAppThunkAction<Promise<void>> => async (dispatch) => {
  const subgraph = new LPInsureSubgraph(appChainId)
  const yesterday = moment().subtract(1, 'days').toDate().getTime().toString().slice(0, -3) // change mili timestamp to secs timestamp
  const where: any = {
    timestamp_gte: yesterday,
  } 

  const res = await subgraph.getInsureInvestors(where)

  dispatch({
    type: ActionTypes.SET_INVEST_LENGTH_IN_24HRS,
    payload: res.data?.lpinvestmentHistories?.length || 0,
  })
}

export const redeemInsure = (classId: string, nonceId: string, amount: string | number): LPInsureListAppThunkAction<Promise<any>> => async (dispatch, getState) => {
  const { appChainId, account } = getState().globals;
  const insureBondContract = LP_INSURE_MAIN_CONTRACT[appChainId]
  const w3 = new Web3(Web3.givenProvider)
  const insureBondInstance = new w3.eth.Contract(InsureBondABI as AbiItem[], insureBondContract);
  const transactions = [
    {
      classId: classId,
      nonceId: nonceId,
      amount: amount,
    },
  ];
  const method = insureBondInstance.methods.redeem(account, transactions);
  try {
    const allowedAmount = await allowance(appChainId, classId, nonceId, insureBondContract, account);
    if(+allowedAmount < +amount) {
      await approve(appChainId, classId, nonceId, insureBondContract, amount as string, account)
    }
    // to check params
    // await method.estimateGas({ from: account })
    const result = await method.send({ from: account })
    return result
  } catch (error) {
    // @ts-ignore
    const errorMessage: string = error.message || error.toString();
    if(errorMessage.search(/onlyBond/gi) > -1) {
      throw new Error('Only Bond can redeem!')
    }
    throw error;
  }
}

export const harvest = (classIds: string[]): LPInsureListAppThunkAction<Promise<TransactionReceipt>> => async (dispatch, getState) => {
  const { account, appChainId } = getState().globals;
  const insureOfferContract = LP_INSURE_PROXY_FACTORY[appChainId];
  const w3 = new Web3(Web3.givenProvider)
  const offerInstance = new w3.eth.Contract(InsureOfferABI as AbiItem[], insureOfferContract)
  const method = offerInstance.methods.harvest(classIds);
  try {
    await method.estimateGas({ from: account })
    return (await method.send({ from: account })) as TransactionReceipt
  } catch (error: unknown) {
    console.log(error);
    throw error;
  }
}
