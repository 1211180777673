import axios from 'axios'
import { BOND_SERVER_URL } from 'config/pathConfig'
import { LPInsureInfo, SupportedNetworks } from 'types'

type OffersRes = {
    offers: LPInsureInfo[]
}
export const getIsureOffers = ({ offerIds, networkId }: { offerIds: string[] | number[], networkId: SupportedNetworks }) => {
    return axios.get<OffersRes>(`${BOND_SERVER_URL}/api/insure/offers/${networkId}?ids=${offerIds.join(',')}`)
}

export const voteToInsure = ({ offerID, networkId }: { offerID: string | number, networkId: SupportedNetworks }) => {
    return axios.post(`${BOND_SERVER_URL}/api/insure/vote/${networkId}/${offerID}`)
}
