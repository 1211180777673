import { CoinGeckoClient } from 'coingecko-api-v3'
import type { CoinListResponseItem } from 'coingecko-api-v3'
import CoinsListJSON from 'Assets/jsons/coins/list.json'
import SupportedVSCurrenciesJSON from 'Assets/jsons/coins/supported_vs_currencies.json'

export const coinGeckoApis = new CoinGeckoClient({
  timeout: 10000,
  autoRetry: true,
})

export const CoinsList = CoinsListJSON
export const SupportedVSCurrencies = SupportedVSCurrenciesJSON
export const getCoinBySymbol = (symbol: string): CoinListResponseItem | undefined => {
  symbol = symbol.toLowerCase()
  const Coin: CoinListResponseItem | undefined = CoinsList.coins_list.find((c) => symbol == c.symbol)
  return Coin
}

// export const getCoinsBySymbols = (symbols: string[]): CoinListResponseItem[] => {
//   symbols = symbols.map((s) => String(s).toLowerCase())
//   const filteredCoins: CoinListResponseItem[] = CoinsList.coins_list.filter((c) => symbols.includes(c.symbol))
//   return filteredCoins
// }

export const getCoin = (address: string, symbol: string): CoinListResponseItem | null | undefined => {
  for(const c of CoinsList.coins_list) {
    const addresses: string[] = Object.values(c.platforms || {}) as string[];
    if(addresses.length > 0) {
      const token = addresses.find((adr) => adr !== null && adr.toLowerCase() === address.toLowerCase())
      if(token) return c;
    }
  }
  for(const c of CoinsList.coins_list) {
    if(c.symbol.toLowerCase() == symbol.toLowerCase()) {
      return c;
    }
  }
  return null;
}
