import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

const SWrap = styled.div`
  position: relative;
  overflow: hidden;
  transition: all 0.2s;
`
const SChildrenDiv = styled.div`
  display: flex;
  overflow-x: scroll;
  padding-left: 10px;
  padding-right: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
`

const ControlButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: grey;
  z-index: 1;

  &:hover {
    color: ${({ theme }) => theme.colorPrimary};
  }
`

const SPrevButton = styled(ControlButton)`
  left: 0px;
`
const SNextButton = styled(ControlButton)`
  right: 0px;
`

interface IHorizontalScrollProps {
  children: JSX.Element | JSX.Element[]
  prevButton?: JSX.Element
  nextButton?: JSX.Element
}

function HorizontalScroll({ children, nextButton, prevButton }: IHorizontalScrollProps) {
  const childRef = useRef<HTMLDivElement>(null)
  const [scrollLeft, setScrollLeft] = useState(0)

  const handleOnClickPrev = () => {
    if(childRef.current) {
      childRef.current.scrollLeft -= 100
      setScrollLeft(childRef.current.scrollLeft)
    }
  }
  const handleOnClickNext = () => {
    if(childRef.current) {
      childRef.current.scrollLeft += 100
      setScrollLeft(childRef.current.scrollLeft)
    }
  }

  return (
    <SWrap>
      <SPrevButton onClick={handleOnClickPrev} hidden={!(scrollLeft > 0)}>{prevButton || <FaChevronLeft />}</SPrevButton>
      <SChildrenDiv ref={childRef}>
        {children}
      </SChildrenDiv>
      <SNextButton 
        onClick={handleOnClickNext}
        hidden={(scrollLeft + ((childRef.current?.clientWidth || childRef.current?.offsetWidth) || 0)) >= (childRef.current?.scrollWidth || 0)}
      >
        {nextButton || <FaChevronRight />}
      </SNextButton>
    </SWrap>
  )
}

export default HorizontalScroll
