import styled from 'styled-components'
import { FlexDiv } from '../others/global-components'

export const MTSearchBX = styled(FlexDiv)`
  width: 100%;
  padding: 15px 28px 20px 28px;
  border-bottom: 1px solid #3c3f46;

  input {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: 2px solid #545861;
    background-color: transparent;
    padding: 10px;
    font-size: 14px;
    color: #fff;
  }
`
