import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { BsQuestionCircleFill } from 'react-icons/bs'
// import { HiExternalLink } from 'react-icons/hi'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { AiFillWarning } from 'react-icons/ai'
// import { IoMdArrowDropdown } from 'react-icons/io'
import { loadTokenRatio, nextStep, prevStep, setFeeStructure, setPairToken, setParams, setParamsInvestmentLimit,
  // setParamsStakingPeriod 
} from 'redux/reducers/bond/actions'
import { useAppChain } from 'hooks'

import { AppButton, BackButton } from 'Component/core/elements/Buttons'
// import { SFeeStructureTable } from 'Pages/IBO/components/styled'
import { InputGroup, InputGroupText } from 'Component/core/elements/InputGroup'
import { Divider } from 'Component/core/elements/Divider'
// import PeriodPicker from 'Component/core/elements/PeriodPicker'
import { FEE_STRUCTURE, NETWORKS, ZERO_ADDRESS, MainTokenSymbols } from 'app-constants'
import { RadioButton } from 'Component/core/elements/RadioButton'
import TokenIcon from 'Component/TokenIcon'
// import AppDatePicker from 'Component/core/elements/DatePicker/DatePicker'
import SelectDexes from 'Component/popup/SelectDexes'
import tooltip from 'app-constants/tooltip-texts'
import AppTooltip from 'Component/AppTooltip'
import { BondSelector } from 'redux/selectors'
import { TokenBalanceSpan } from 'Component/core/elements/others/global-components'
import useAppSnackbar, { AppWaringOptions } from 'hooks/useAppSnackbar'
import { isEqAddr, numberFormater } from 'helper'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { SForms1 } from 'Component/core/elements/IBO-SharedComponents'
import SelectTokenPopup from 'Component/popup/SelectTokenPopup'
import { AppVaildate, FeeStructureLabelInfo } from 'types'

function FundraisingTerms() {
  const { appChainId } = useAppChain()
  const dispatch = useAppDispatch()
  const bondState = useAppSelector(BondSelector)
  const [openWaringSnackbar] = useAppSnackbar(AppWaringOptions)
  const network = useMemo(() => {
    return NETWORKS[appChainId]
  }, [appChainId])

  const pairToken = bondState.pairToken
  const tokenInfo = bondState.tokenInfo
  const tokenRatio = bondState.tokenRatio
  const targetPriceUSD = useMemo(() => +bondState.pairTokenPriceUSD * tokenRatio, [bondState.pairTokenPriceUSD, tokenRatio])

  const [isOpenSelectDex, setIsOpenSelectDex] = useState(false)
  const [isOpenSelectPairToken, setIsOpenSelectPairToken] = useState(false)
  // const [lpAddress, setLPAddress] = useState<string>(ZERO_ADDRESS)
  const [feeStructureState, setFeeStructureState] = useState({
    upto: false,
    intoDumperShield: false,
    intoPDO: false,
  })

  const isNativePairToken = useMemo(() => {
    return isEqAddr(pairToken.address, network.W_TOKEN_ADDRESS) || isEqAddr(pairToken.address, ZERO_ADDRESS)
  }, [pairToken.address, network])

  const withBelow100NativeToken = useMemo(() => {
    return isNativePairToken && +bondState.raisingTarget <= 100
  }, [bondState.raisingTarget, isNativePairToken])

  const requiredTargetAmount = useMemo<number>(() => {
    return (+bondState.raisingTarget * 1) / +bondState.amountOfSupply;
  }, [bondState.raisingTarget, bondState.amountOfSupply])

  const isInvalidTargetRaise = useMemo<AppVaildate>(() => {
    if (!+bondState.raisingTarget) return {
      isInvalid: false,
      message: ''
    };
    if (+bondState.pairToken.balance < requiredTargetAmount) {
      const n = numberFormater(6).format(requiredTargetAmount)
      return {
        isInvalid: true,
        message: `To create a pool with a ${bondState.amountOfSupply}:${bondState.raisingTarget} (1:${n}) ratio, you must have in your wallet at least ${n} ${pairToken.symbol}. we use the tokens to set initial pool ratio`
      };
    }
    return {
      isInvalid: false,
      message: ''
    }
  }, [bondState.raisingTarget, pairToken.balance, requiredTargetAmount, bondState.amountOfSupply])

  const isInvalidAmountOfSupply = useMemo<boolean>(() => {
    if (+bondState.amountOfSupply === 1) return true;
    if (!+bondState.amountOfSupply) return true;
    if (+bondState.amountOfSupply <= +tokenInfo.balance) return false;
    return true;
  }, [bondState.amountOfSupply, tokenInfo.balance])

  const onChangeValues = useCallback((_name: string, _value: any) => {
    dispatch(
      setParams({
        [_name]: _value,
      })
    )
  }, [])

  const onChangeInvestmentLimit = (_name: string, _value: any) => {
    dispatch(
      setParamsInvestmentLimit({
        [_name]: _value,
      })
    )
  }
  // const onChangeStakingPeriod = (_name: string, _value: any) => {
  //   dispatch(
  //     setParamsStakingPeriod({
  //       [_name]: _value,
  //     })
  //   )
  // }

  const handleOnDismissSelectDexes = useCallback(() => {
    setIsOpenSelectDex(false)
  }, [])
  const handleDismissSelectToken = useCallback(() => {
    setIsOpenSelectPairToken(false)
  }, [])

  const handleOnSelectToken = useCallback(
    async ({ name, symbol, address, decimals }: {
      name: string, symbol: string, address: string, decimals: string
    }) => {
      setIsOpenSelectPairToken(false)
      try {
        await dispatch(
          setPairToken({
            name,
            symbol,
            address,
            decimals,
          })
        )
        // @ts-ignore
      } catch (error: Error) {
        console.error(error)
        return openWaringSnackbar(error?.message)
      }
    },
    [bondState.token, openWaringSnackbar]
  )

  const handleOnSelectDex = useCallback(
    // @ts-ignore others
    ({ router, img, title, theGraphUri }: any): void => {
      setIsOpenSelectDex(false)
      dispatch(
        setParams({
          dexWithPool: {
            title,
            router,
            img: img,
            theGraphUri: theGraphUri,
          },
        })
      )
    },
    []
  )

  const handleOnClickNextStep = () => {
    // validate fields
    if (!+bondState.amountOfSupply) {
      return openWaringSnackbar("Please enter the 'Choose amount of supply'")
    }
    if (+bondState.amountOfSupply <= 1) {
      return openWaringSnackbar("'Choose amount of supply' must be greater than or equal to 2")
    }
    if (!+bondState.raisingTarget) {
      return openWaringSnackbar("Please enter the 'Target Raise'")
    }
    if (isInvalidTargetRaise.isInvalid) {
      return openWaringSnackbar(isInvalidTargetRaise.message)
    }
    if (isInvalidAmountOfSupply) {
      return openWaringSnackbar("'Supply Amount' must be less than Balance")
    }
    dispatch(nextStep())
  }
  const handleOnClickback = () => {
    dispatch(prevStep())
  }

  const setFeeStructureValue = useCallback((value: FeeStructureLabelInfo) => {
    dispatch(setFeeStructure(value))
  }, [])

  useEffect(() => {
    dispatch(loadTokenRatio())
  }, [bondState.amountOfSupply, bondState.raisingTarget])

  useEffect(() => {
    if (withBelow100NativeToken) {
      setFeeStructureValue(FEE_STRUCTURE.UP_WITH_FREE_FEE)
      setFeeStructureState({
        upto: false,
        intoDumperShield: true,
        intoPDO: true,
      })
    } else {
      setFeeStructureValue(FEE_STRUCTURE.ABOVE_WITH_TOKEN_FEE_INTO_PDO_AND_Dumper)
      setFeeStructureState({
        upto: true,
        intoDumperShield: false,
        intoPDO: false,
      })
    }
  }, [withBelow100NativeToken, onChangeValues])

  // useEffect(() => {
  //   if (isEqAddr(bondState.dexWithPool.router, ZERO_ADDRESS)) {
  //     setLPAddress(ZERO_ADDRESS)
  //   }
  //   (async () => {
  //     const w3H = new Web3Helpers(w3)
  //     const generalRouter = new GeneralRouterContract(w3)
  //     const factory = await generalRouter.getFactory(bondState.dexWithPool.router);
  //     const _lpAddr = await w3H.getPair(bondState.token, bondState.pairToken.address, factory);
  //     setLPAddress(_lpAddr)
  //   })()
  // }, [bondState.dexWithPool.router, w3])

  return (
    <React.Fragment>
      <div className='py-20'>
        <BackButton onClick={handleOnClickback} />
      </div>
      <SForms1 as='table'>
        <tbody>
          <tr>
            <td className='pt-15'>
              <label className='fw-bold'>Supply Amount*</label>
              <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Supply Amount')} />
            </td>
            <td className='position-relative'
              data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Must be more than 1'}
            >
              <InputGroup className={isInvalidAmountOfSupply ? 'danger' : ''}>
                <NumberFormat displayType={'input'} value={bondState.amountOfSupply} onValueChange={({ value }) => onChangeValues('amountOfSupply', value)} />
                <InputGroupText className='text-uppercase fw-bold'>{tokenInfo.symbol}</InputGroupText>
              </InputGroup>
              <div className='position-absolute right-30'>
                <button className='text-muted fs-sm' onClick={() => onChangeValues('amountOfSupply', tokenInfo.balance)}>
                  Balance: <TokenBalanceSpan>{tokenInfo.balance}</TokenBalanceSpan>
                </button>
              </div>
            </td>
            <td className='pt-15'>
              <label className='fw-bold'>Target Raise*</label>
              <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Target Raise')} />
            </td>
            <td className='position-relative'>
              <InputGroup
                className={isInvalidTargetRaise.isInvalid ? 'danger' : ''}
              >
                <InputGroupText className='py-7'>
                  <TokenIcon className='md-2 rounded-circle' byAddress={pairToken.address} onClick={() => setIsOpenSelectPairToken(true)} />
                </InputGroupText>
                <NumberFormat
                  displayType={'input'}
                  value={bondState.raisingTarget}
                  onValueChange={({ value }) => onChangeValues('raisingTarget', value)}
                  isAllowed={(values) => {
                    return (+values.value) > -1
                  }}
                />
                <InputGroupText className='text-uppercase fw-bold'>{pairToken.symbol}</InputGroupText>
              </InputGroup>
              <div className='position-absolute w-full mt-5'>
                <div className='text-muted fs-sm float-start'>
                  1 {tokenInfo.symbol} {'<>'} <TokenBalanceSpan>{tokenRatio || 0}</TokenBalanceSpan>&nbsp;{pairToken.symbol}
                </div>
                <button className='fs-sm float-end text-danger' hidden={!isInvalidTargetRaise.isInvalid}>
                  [
                    Minimum needed <TokenBalanceSpan prefix='$'>{targetPriceUSD || 0}</TokenBalanceSpan>
                  ]
                  <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={isInvalidTargetRaise.message} />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </SForms1>
      <Divider className='vertical mt-50 highlighted-border-bottom' />
      <div className='text-danger text-center pt-20 pb-10'>
        <p><AiFillWarning className='mb-2' size={20} />&nbsp;If your token is subject to reflection taxes on transfer, note that it can affect the net token amount that we&apos;ll received from you</p>
        <p>We will share your smart contractIBO address at the end of the setup, enabling you to shitelist them to help users from taxes when they purchase your tokens through the IBO.</p>
      </div>
      <SForms1 className='mt-30'>
        <tbody>
          <tr>
            <td>
              <label className='pt-15 fw-bold'>Fee structure</label>
            </td>
            <td>
              <RadioButton
                id='fees-structure-1'
                name='fees-structure'
                className='d-flex py-5'
                radioClassName='mt-8'
                label={
                  <label htmlFor='fees-structure-1' className='ml-10 pt-5 lh-base text-nowrap'>
                    {FEE_STRUCTURE.UP_WITH_FREE_FEE.label.replace(/%%%/gi, MainTokenSymbols[appChainId])}
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={FEE_STRUCTURE.UP_WITH_FREE_FEE.tooltipText} />
                  </label>
                }
                disabled={feeStructureState.upto}
                checked={bondState.feeStructure.value === FEE_STRUCTURE.UP_WITH_FREE_FEE.value}
                onChange={() => setFeeStructureValue(FEE_STRUCTURE.UP_WITH_FREE_FEE)}
              />
              <RadioButton
                id='fees-structure-3'
                name='fees-structure'
                className='d-flex py-5'
                radioClassName='mt-8'
                label={
                  <label htmlFor='fees-structure-3' className='ml-10 pt-5 lh-base'>
                    {FEE_STRUCTURE.ABOVE_WITH_TOKEN_FEE_INTO_PDO_AND_Dumper.label.replace(/%%%/gi, MainTokenSymbols[appChainId])}
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={FEE_STRUCTURE.ABOVE_WITH_TOKEN_FEE_INTO_PDO_AND_Dumper.tooltipText} />
                  </label>
                }
                disabled={feeStructureState.intoPDO}
                checked={bondState.feeStructure.value === FEE_STRUCTURE.ABOVE_WITH_TOKEN_FEE_INTO_PDO_AND_Dumper.value}
                onChange={() => setFeeStructureValue(FEE_STRUCTURE.ABOVE_WITH_TOKEN_FEE_INTO_PDO_AND_Dumper)}
              />
            </td>
            {bondState.feeStructure.value === FEE_STRUCTURE.UP_WITH_FREE_FEE.value && <td colSpan={2} className='w-auto'></td>}
            {/* {bondState.feeStructure.value === FEE_STRUCTURE.ABOVE_WITH_TOKEN_FEE_INTO_DUMPER_SHIELD.value && (
              <td colSpan={2} className='w-auto'>
                <SFeeStructureTable className='mt-10'>
                  <tbody>
                    <tr>
                      <td className='py-0' colSpan={2}>
                        <label className='fw-bold text-decoration-underline cursor-auto'>Create a Dumper Shield</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className='fw-bold'>Select Dumper Shield period</label>
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Select_Dumper_Shield_Period')} />
                      </td>
                      <td>
                        <InputGroup className='pl-10'>
                          <AppDatePicker selected={bondState.shieldPeriod} onChange={(date) => onChangeValues('shieldPeriod', date.getTime())} />
                        </InputGroup>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className='fw-bold'>
                          Select existing DEX with {tokenInfo.symbol}/{pairToken.symbol} pool
                          <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Select_Existing_Dex')} />
                        </label>
                      </td>
                      <td className='position-relative'>
                        <SSelectDexButton
                          className='w-max-320 w-min-320 outline-gray py-5 text-end'
                          onClick={() => setIsOpenSelectDex(true)}
                          disabled
                        >
                          {bondState.dexWithPool.title ? (
                            <p>
                              <img src={bondState.dexWithPool.img} alt='' />
                              <span>{bondState.dexWithPool.title}</span>
                            </p>
                          ) : (
                            <> &nbsp;</>
                          )}
                          <IoMdArrowDropdown className='text-muted' />
                        </SSelectDexButton>
                        <div className='position-absolute w-full text-end'>
                          <a href={`${getExplorerUrl(appChainId)}/address/${lpAddress}`} target='_blank' className='text-danger' rel='noreferrer'>
                            <span className='fs-sm'>Verify that is the right pool contract</span>
                            <HiExternalLink />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </SFeeStructureTable>
              </td>
            )} */}
            {bondState.feeStructure.value === FEE_STRUCTURE.ABOVE_WITH_TOKEN_FEE_INTO_PDO_AND_Dumper.value && <td colSpan={2} className='w-auto'></td>}
            {/* {bondState.feeStructure.value === FEE_STRUCTURE.ABOVE_WITH_TOKEN_FEE_INTO_PDO_AND_Dumper.value && (
              <td colSpan={2} className='w-auto'>
                <SFeeStructureTable className='mt-10'>
                  <tbody>
                    <tr>
                      <td className='py-0' colSpan={2}>
                        <label className='fw-bold text-decoration-underline cursor-auto'>Create a PDO</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className='fw-bold'>
                          Select existing DEX with {tokenInfo.symbol}/{pairToken.symbol} pool
                          <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Select_Existing_Dex')} />
                        </label>
                      </td>
                      <td className='position-relative'>
                        <SSelectDexButton
                          className='w-max-320 w-min-320 outline-gray py-5 text-end'
                          onClick={() => setIsOpenSelectDex(true)}
                          disabled
                        >
                          {bondState.dexWithPool.title ? (
                            <p>
                              <img src={bondState.dexWithPool.img} alt='' />
                              <span>{bondState.dexWithPool.title}</span>
                            </p>
                          ) : (
                            <> &nbsp;</>
                          )}
                          <IoMdArrowDropdown className='text-muted' />
                        </SSelectDexButton>
                        <div className='position-absolute w-full text-end'>
                          <a href='https://todo.com' target='_blank' className='text-danger' rel='noreferrer'>
                            <span className='fs-sm'>Verify that is the right pool contract</span>
                            <HiExternalLink />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className='fw-bold'>Staking period for users</label>
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Staking period for users')} />
                      </td>
                      <td>
                        <InputGroup>
                          <NumberFormat
                            displayType='input'
                            isAllowed={({ value }) => (+value) > 0}
                            value={bondState.stakingPeriod.value}
                            onValueChange={({ value }) => {
                              onChangeStakingPeriod('value', value)
                            }}
                          />
                          <InputGroupText className='p-7'>
                            <PeriodPicker
                              label={bondState.stakingPeriod.timeOption.label}
                              options={TimeOptions}
                              onClickOption={(e, selectedOption) => {
                                onChangeStakingPeriod('timeOption', selectedOption)
                              }}
                            />
                          </InputGroupText>
                        </InputGroup>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className='fw-bold'>APY for users</label>
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Bond_Pool_APY_FOR_USERS')} />
                      </td>
                      <td>
                        <InputGroup>
                          <NumberFormat
                            displayType='input'
                            isAllowed={({ value }) => (+value) > -1}
                            value={bondState.apyForUsers}
                            onValueChange={({ value }) => {
                              onChangeValues('apyForUsers', value)
                            }}
                          />
                          <InputGroupText>% </InputGroupText>
                        </InputGroup>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className='fw-bold'>Dumper Shield for users</label>
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Dumper Shield for users')} />
                      </td>
                      <td className='d-flex align-items-center'>
                        <RadioButton
                          id='dumper-shield-1'
                          name='dumper-shield'
                          className='d-flex align-items-center pr-70'
                          label={
                            <label htmlFor='dumper-shield-1' className='ml-10 pt-5 text-capitalize'>
                              Yes
                            </label>
                          }
                          checked={bondState.isDumperShieldForUsers}
                          onChange={() => onChangeValues('isDumperShieldForUsers', true)}
                        />
                        <RadioButton
                          id='dumper-shield-2'
                          name='dumper-shield'
                          className='d-flex align-items-center'
                          label={
                            <label htmlFor='dumper-shield-2' className='ml-10 pt-5 text-capitalize'>
                              No
                            </label>
                          }
                          checked={!bondState.isDumperShieldForUsers}
                          onChange={() => onChangeValues('isDumperShieldForUsers', false)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='py-0' colSpan={2}>
                        <label className='fw-bold text-decoration-underline cursor-auto'>Create a Dumper Shield</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className='fw-bold'>Select Dumper Shield period</label>
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Select_Dumper_Shield_Period')} />
                      </td>
                      <td>
                        <InputGroup className='pl-10'>
                          <AppDatePicker selected={bondState.shieldPeriod} onChange={(date) => onChangeValues('shieldPeriod', date.getTime())} />
                        </InputGroup>
                      </td>
                    </tr>
                  </tbody>
                </SFeeStructureTable>
              </td>
            )} */}
          </tr>
        </tbody>
      </SForms1>
      <Divider className='vertical mt-20 mb-10 highlighted-border-bottom' />
      <SForms1 className='mt-30'>
        <tbody>
          <tr>
            <td>
              <label className='fw-bold mt-15'>
                Investment limit per wallet
                <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Invest_Limit_Per_Wallet')} />
              </label>
            </td>
            <td></td>
            <td colSpan={2} className='w-auto'>
              <div className='d-flex justify-content-between w-full w-max-200'>
                <RadioButton
                  id='investment-limit-Yes'
                  name='investmentlimit'
                  className='d-flex align-items-center py-10'
                  label={
                    <label htmlFor='investment-limit-Yes' className='ml-10 pt-5 text-capitalize'>
                      Yes
                    </label>
                  }
                  checked={bondState.investmentLimit.isSet === true}
                  onChange={() => onChangeInvestmentLimit('isSet', true)}
                />
                <RadioButton
                  id='investment-limit-no'
                  name='investmentlimit'
                  className='d-flex align-items-center py-10'
                  label={
                    <label htmlFor='investment-limit-no' className='ml-10 pt-5 text-capitalize'>
                      No
                    </label>
                  }
                  checked={bondState.investmentLimit.isSet === false}
                  onChange={() => onChangeInvestmentLimit('isSet', false)}
                />
              </div>
              <div className={`${bondState.investmentLimit.isSet ? 'd-flex' : 'd-none'} mt-20 pl-15`}>
                <div className='row px-10 w-full'>
                  <div className='col-6 position-relative'>
                    <label className='py-10 fw-bold'>Minimum</label>
                    <InputGroup>
                      <InputGroupText className='px-15'>$</InputGroupText>
                      <NumberFormat displayType='input' value={bondState.investmentLimit.minimum} onValueChange={({ value }) => onChangeInvestmentLimit('minimum', value)}
                        isAllowed={({ value }: NumberFormatValues) => {
                          return +value >= 0
                        }}
                      />
                    </InputGroup>
                  </div>
                  <div className='col-6'>
                    <label className='py-10 fw-bold'>Maximum</label>
                    <InputGroup>
                      <InputGroupText className='px-15'>$</InputGroupText>
                      <NumberFormat displayType='input' value={bondState.investmentLimit.maximum} onValueChange={({ value }) => onChangeInvestmentLimit('maximum', value)}
                        isAllowed={({ value }: NumberFormatValues) => {
                          return +value >= 0
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </SForms1>
      <Divider className='vertical mt-20 mb-10 highlighted-border-bottom' />
      <div className='d-flex justify-content-center mt-50 mb-50 position-relative'>
        <BackButton className='position-absolute left-0 top-22' onClick={handleOnClickback} />
        <AppButton className='text-capitalize outline-primary w-max-500' onClick={handleOnClickNextStep}>
          next step
        </AppButton>
      </div>
      <SelectDexes isOpen={isOpenSelectDex} dismiss={handleOnDismissSelectDexes} onSelect={handleOnSelectDex} />
      <AppTooltip />
      <SelectTokenPopup
        isOpen={isOpenSelectPairToken}
        onlyTokensWithBalance
        dismiss={handleDismissSelectToken}
        onSelectToken={handleOnSelectToken}
      />
    </React.Fragment>
  )
}

export default FundraisingTerms
