import styled from 'styled-components'
import Modal from '../Modal'
import Media from '../../Theme/media-breackpoint'
import { PPClosBTN01 } from 'Component/core/elements/Buttons'
import { FlexDiv, ModalBox } from 'Component/core/elements/others/global-components'
import { SupportedNetworks } from 'types'
import AppTooltip from 'Component/AppTooltip'
import { BlockChains } from 'app-constants'

interface IChooseBlockchainProps {
  isOpen: boolean
  dismiss: () => void
  onSelect: (targetChain: SupportedNetworks) => void
}

export default function ChooseBlockchainPopup({ isOpen, dismiss, onSelect }: IChooseBlockchainProps) {
  return (
    <Modal isOpen={isOpen} dismiss={dismiss}>
      <PPMainBx>
        <PPsBx01 className='position-relative'>
          <PPstitle01 className='d-flex justify-content-center align-items-center'>
            <h3 className='fw-bold'>Choose blockchain</h3>
          </PPstitle01>
          <CloseButton onClick={dismiss} />
        </PPsBx01>
        <div className='d-flex flex-column gap-3'>
          {BlockChains.map((blockchain, index) => (
            <DexCard key={`blockchain-${index}`} as='button' onClick={() => onSelect(blockchain.chainId)}>
              <span className='title'>{blockchain.name}</span>
              <img src={blockchain.icon} alt={blockchain.name} />
            </DexCard>
          ))}
        </div>
      </PPMainBx>
      <AppTooltip />
    </Modal>
  )
}

const PPMainBx = styled(ModalBox)`
  max-width: 300px;
  min-width: 300px;
  margin: 0 auto;
  padding: 25px;
  ${Media.lg} {
    min-width: 300px;
  }
  ${Media.md} {
    min-width: auto;
  }
  ${Media.xs} {
    width: auto;
    margin: 0px 15px;
    max-width: 100%;
  }
`

const PPsBx01 = styled(FlexDiv)`
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
`

const PPstitle01 = styled(FlexDiv)`
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  color: white;
`

const DexCard = styled(FlexDiv)`
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.greyBorder};
  border-radius: 10px;
  color: ${({ theme }) => theme.colorWhite};
  &:hover {
    color: ${({ theme }) => theme.colorPrimary};
  }
  img {
    padding: 5px;
    width: 45px;
    height: 45px;
  }

  span.title {
    padding-left: 10px;
  }
`

const CloseButton = styled(PPClosBTN01)`
  width: 15px;
  height: 15px;
  position: relative;
  background-size: 100%;
  right: 0;
  top: -3px;
`
