import { FlexDiv } from 'Component/core/elements/others/global-components'
import styled from 'styled-components'

export const OuterLink = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  a {
    color: ${(props) => props.theme.colorLightGrey};
  }
`

export const Box1BottomPart = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colorLightGrey};
  margin-bottom: 30px;
  span {
    color: ${(props) => props.theme.colorPrimary03};
  }
`

export const Box1TopPart = styled(FlexDiv)`
  justify-content: flex-start;
  margin-bottom: 10px;
  .img-outer {
    width: 21px;
    height: 21px;
    padding: 2px;
    border-radius: 50%;
    overflow: hidden;
    // background-color: ${(props) => props.theme.colorWhite};
    margin-right: 1px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &.ver2 {
      width: 27px;
      height: 27px;
    }
  }
  &.mb-0 {
    margin-bottom: 0px;
  }
`

export const Box1Title = styled.div`
  margin: 0px 10px 0px 15px;
  line-height: normal;
  font-size: ${({theme}) => theme.fontSize['md']};
  font-weight: bold;
  color: ${(props) => props.theme.colorWhite};
  text-transform: uppercase;
`

export const Box1MiddlePart = styled(FlexDiv)`
  justify-content: space-between;
`

export const BMRight = styled(FlexDiv)`
  justify-content: flex-start;
`

export const GraphTypeSelect = styled.select`
  background: transparent;
  color: white;
  border: 0px;
  padding-right: 10px;
`

export const GraphTypeOption = styled.option`
  background: #16191e;
  border: 0px;
`

export const BMTitle = styled.div`
  font-size: 24px;
  font-family: var(--dx-ff-secondary);
  line-height: 36px;
  font-weight: 700;
  color: ${(props) => props.theme.colorWhite};
`
