/* eslint-disable */
// @ts-nocheck TODO
import React, { useState, useEffect } from 'react'
import { AppButton, BackButton } from 'Component/core/elements/Buttons'
import { Divider } from 'Component/core/elements/Divider'
import NumberFormat from 'react-number-format'
import { BsQuestionCircleFill } from 'react-icons/bs'
import { InputGroup, InputGroupControl, InputGroupText } from 'Component/core/elements/InputGroup'
import AppTooltip from 'Component/AppTooltip'
import { RadioButton } from 'Component/core/elements/RadioButton'
import AppCheckbox from 'Component/core/elements/AppCheckbox'
import PeriodPicker from 'Component/core/elements/PeriodPicker'
import { nextStep, prevStep, setHasPrincipalVesting, setHasProfitVesting, setParams, setParamsVestingForPrincipals, setParamsVestingForProfit, setParamsVestingPeriod, setPrepaymentPenalty } from 'redux/reducers/bond/actions'
import { VESTING_PERIOD, TimeOptions, BOND_TYPE, VESTING_FOR_PRINCIPAL, VESTING_FOR_PROFIT, REDEMPTION_LEFTOVER } from 'app-constants'
import tooltip from 'app-constants/tooltip-texts'
import { BondSelector } from 'redux/selectors'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { SForms } from 'Component/core/elements/IBO-SharedComponents'

function BondTerms() {
  const dispatch = useAppDispatch()
  const bondState = useAppSelector(BondSelector)
  const { hasPrincipalVesting, hasProfitVesting } = bondState

  const handleOnChangeHasPrincipal = (e: any) => {
    dispatch(setHasPrincipalVesting(e.target.value === '1'))
  }
  const handleOnChangeHasProfit = (e: any) => {
    dispatch(setHasProfitVesting(e.target.value === '1'))
  }

  const handleChangeParams = (_name: string, _value: any) => {
    dispatch(
      setParams({
        [_name]: _value,
      })
    )
  }

  const handlePrepaymentPenalty = (enabled: boolean) => {
    dispatch(setPrepaymentPenalty(enabled))
  }

  // dont remove! we will use in future
  // const handleChangeBondType = (_value) => {
  //   handleChangeParams('bondType', _value)
  // }

  const handleOnChangeRedemptionValue = (e: any) => {
    dispatch(
      setParams({
        leftoverBurn: e.target.value,
      })
    )
  }

  const handleChangeVestingForPrincipal = (_name: string, _value: any) => {
    dispatch(
      setParamsVestingForPrincipals({
        [_name]: _value,
      })
    )
  }

  const handleChangeVestingForProfit = (_name: string, _value: any) => {
    dispatch(
      setParamsVestingForProfit({
        [_name]: _value,
      })
    )
  }

  const handleChangeVestingPeriod = (_name: string, _value: any) => {
    dispatch(
      setParamsVestingPeriod({
        [_name]: _value,
      })
    )
  }

  const handleOnClickNextStep = () => {
    dispatch(nextStep())
  }
  const handleOnClickback = () => {
    dispatch(prevStep())
  }

  useEffect(() => {
    if(hasPrincipalVesting === false && hasProfitVesting === false) {
      dispatch(setPrepaymentPenalty(false))
    }
  }, [hasPrincipalVesting, hasProfitVesting])

  return (
    <React.Fragment>
      <div className='py-20'>
        <BackButton onClick={handleOnClickback} />
      </div>
      <SForms>
        <tbody>
          {/* <tr>
            <td>
              <label className='fw-bold'>
                Bond type
                <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
              </label>
            </td>
            <td>
              <RadioButton
                id='_50-discount'
                name='bond-type'
                className='mr-5 d-flex align-items-center'
                label={
                  <label htmlFor='_50-discount' className='ml-10 pt-5'>
                    50% discount
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                  </label>
                }
                checked={bondState.bondType.value === BOND_TYPE.DISCOUNT.value}
                value={BOND_TYPE.DISCOUNT.value}
                onClick={() => handleChangeBondType(BOND_TYPE.DISCOUNT)}
              />
            </td>
            <td>
              <RadioButton
                id='bond-type-loss'
                name='bond-type'
                className='d-flex align-items-center'
                label={
                  <label htmlFor='bond-type-loss' className='ml-10 pt-5 text-capitalize'>
                    impermanent loss protection
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                  </label>
                }
                checked={bondState.bondType.value === BOND_TYPE.IMPERMANENT.value}
                value={BOND_TYPE.IMPERMANENT.value}
                onClick={() => handleChangeBondType(BOND_TYPE.IMPERMANENT)}
              />
            </td>
          </tr> */}
          {bondState.bondType.value === BOND_TYPE.DISCOUNT.value && (
            <tr>
              <td>
                <label className='fw-bold'>
                  Leftover tokens
                  <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                </label>
              </td>
              <td>
                <RadioButton
                  id='Redemption-Leftover-Burn'
                  name='leftoverBurn'
                  className='mr-5 d-flex align-items-center'
                  label={
                    <label htmlFor='Redemption-Leftover-Burn' className='ml-10 pt-5'>
                      Burn
                      <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Sends tokens to a zero address'} />
                    </label>
                  }
                  checked={bondState.leftoverBurn === REDEMPTION_LEFTOVER.BURN}
                  value={REDEMPTION_LEFTOVER.BURN}
                  onChange={handleOnChangeRedemptionValue}
                />
              </td>
              <td>
                <RadioButton
                  id='Redemption-Leftover-Refund'
                  name='leftoverBurn'
                  className='d-flex align-items-center'
                  label={
                    <label htmlFor='Redemption-Leftover-Refund' className='ml-10 pt-5 text-capitalize'>
                      Refund to project
                      <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Refund_To_Project')} />
                    </label>
                  }
                  checked={bondState.leftoverBurn === REDEMPTION_LEFTOVER.REFUND}
                  value={REDEMPTION_LEFTOVER.REFUND}
                  onChange={handleOnChangeRedemptionValue}
                />
              </td>
            </tr>
          )}
        </tbody>
      </SForms>

      <Divider className='vertical mt-20 mb-10 highlighted-border-bottom' />
      {bondState.bondType.value === BOND_TYPE.DISCOUNT.value ? (
        <div>
          <SForms>
            <tbody>
              <tr>
                <td>
                  <label className='fw-bold'>Principal vesting </label>
                </td>
                <td>
                  <RadioButton
                    id='has-vesting-principal-yes'
                    className='mr-5 d-flex align-items-center'
                    name='has-vesting-principal'
                    value={'1'}
                    checked={hasPrincipalVesting === true}
                    onChange={handleOnChangeHasPrincipal}
                    label={
                      <label className='ml-10 pt-5' htmlFor='has-vesting-principal-yes'>
                        Yes
                      </label>
                    }
                  />
                </td>
                <td>
                  <RadioButton
                    id='has-vesting-principal-no'
                    className='d-flex align-items-center'
                    name='has-vesting-principal'
                    value={'0'}
                    checked={hasPrincipalVesting === false}
                    onChange={handleOnChangeHasPrincipal}
                    label={
                      <label className='ml-10 pt-5 text-capitalize' htmlFor='has-vesting-principal-no'>
                        No
                      </label>
                    }
                  />
                </td>
              </tr>
              <tr hidden={!hasPrincipalVesting}>
                <td></td>
                <td>
                  <RadioButton
                    id='Vesting-Cliff'
                    name='vesting-principal'
                    className='mr-5 d-flex align-items-center'
                    label={
                      <label htmlFor='Vesting-Cliff' className='ml-10 pt-5'>
                        Cliff
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Principal Cliff')} />
                      </label>
                    }
                    checked={bondState.vestingForPrincipal.type === VESTING_FOR_PRINCIPAL.CLIFF}
                    value={VESTING_FOR_PRINCIPAL.CLIFF}
                    onChange={(e) => handleChangeVestingForPrincipal('type', e.target.value)}
                  />
                </td>
                <td>
                  {/* <RadioButton
                    id='Vesting-Graded'
                    name='vesting-principal'
                    className='d-flex align-items-center'
                    label={
                      <label htmlFor='Vesting-Graded' className='ml-10 pt-5 text-capitalize'>
                        Graded
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                      </label>  
                    }
                    checked={bondState.vestingForPrincipal.type === VESTING_FOR_PRINCIPAL.GRADED}
                    value={VESTING_FOR_PRINCIPAL.GRADED}
                    onChange={(e) => handleChangeVestingForPrincipal('type', e.target.value)}
                  /> */}
                </td>
                {bondState.vestingForPrincipal.type === VESTING_FOR_PRINCIPAL.CLIFF ? (
                  <>
                    <td>
                      <label className='fw-bold'>
                        After
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                      </label>
                    </td>
                    <td>
                      <InputGroup>
                        <InputGroupControl
                          as={NumberFormat}
                          displayType='input'
                          value={bondState.vestingForPrincipal.afterValue}
                          onValueChange={({ value }) => handleChangeVestingForPrincipal('afterValue', value)}
                          isAllowed={({ value }) => {
                            return value > -1
                          }}
                        />
                        <InputGroupText className='py-6'>
                          <PeriodPicker
                            label={bondState.vestingForPrincipal.afterOption.label}
                            options={TimeOptions}
                            onClickOption={(e, option) => handleChangeVestingForPrincipal('afterOption', option)}
                          />
                        </InputGroupText>
                      </InputGroup>
                    </td>
                  </>
                ) : (
                  <>
                    <td>
                      <label className='fw-bold'>
                        Portion
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Bond_Token_Potion')} />
                      </label>
                    </td>
                    <td>
                      <InputGroup>
                        <NumberFormat
                          displayType='input'
                          value={bondState.vestingForPrincipal.portionValue}
                          onValueChange={({ value }) => handleChangeVestingForPrincipal('portionValue', value)}
                          isAllowed={({ value }) => {
                            return value > -1 && value < 100
                          }}
                        />
                        <InputGroupText>%</InputGroupText>
                      </InputGroup>
                    </td>
                  </>
                )}
              </tr>
              <tr hidden={bondState.vestingForPrincipal.type === VESTING_FOR_PRINCIPAL.CLIFF}>
                <td colSpan={3}></td>
                <td>
                  <label className='fw-bold'>
                    Every
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Bond_Token_Every')} />
                  </label>
                </td>
                <td>
                  <InputGroup>
                    <InputGroupControl
                      as={NumberFormat}
                      displayType='input'
                      type='number'
                      value={bondState.vestingForPrincipal.everyValue}
                      onValueChange={({ value }) => handleChangeVestingForPrincipal('everyValue', value)}
                      isAllowed={({ value }) => {
                        return value > -1
                      }}
                    />
                    <InputGroupText className='py-6'>
                      <PeriodPicker
                        label={bondState.vestingForPrincipal.everyOption.label}
                        options={TimeOptions}
                        onClickOption={(e, option) => handleChangeVestingForPrincipal('everyOption', option)}
                      />
                    </InputGroupText>
                  </InputGroup>
                </td>
              </tr>
              <tr>
                <td className='pt-30'>
                  <label className='fw-bold'>Profit vesting</label>
                </td>
                <td>
                  <RadioButton
                    id='has-vesting-profit-yes'
                    className='mr-5 d-flex align-items-center'
                    name='has-vesting-profit'
                    value='1'
                    checked={hasProfitVesting === true}
                    onChange={handleOnChangeHasProfit}
                    label={
                      <label className='ml-10 pt-5' htmlFor='has-vesting-profit-yes'>
                        Yes
                      </label>
                    }
                />
                </td>
                <td>
                  <RadioButton
                    id='has-vesting-profit-no'
                    className='d-flex align-items-center'
                    name='has-vesting-profit'
                    value='0'
                    checked={hasProfitVesting === false}
                    onChange={handleOnChangeHasProfit}
                    label={
                      <label className='ml-10 pt-5 text-capitalize' htmlFor='has-vesting-profit-no'>
                        No
                      </label>
                    }
                  />
                </td>
              </tr>
              <tr className='vertical-align-top' hidden={!hasProfitVesting}>
                <td></td>
                <td className='pt-25'>
                  <RadioButton
                    id='Vesting-For-Profits-Cliff'
                    name='Vesting-For-Profits'
                    className='mr-5 d-flex align-items-center'
                    label={
                      <label htmlFor='Vesting-For-Profits-Cliff' className='ml-10 pt-5'>
                        Cliff
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Cliff')} />
                      </label>
                    }
                    checked={bondState.vestingForProfit.type === VESTING_FOR_PROFIT.CLIFF}
                    value={VESTING_FOR_PROFIT.CLIFF}
                    onChange={(e) => handleChangeVestingForProfit('type', e.target.value)}
                  />
                </td>
                <td className='pt-25'>
                  {/* <RadioButton
                    id='Vesting-For-Profits-Graded'
                    name='Vesting-For-Profits'
                    className='d-flex align-items-center'
                    label={
                      <label htmlFor='Vesting-For-Profits-Graded' className='ml-10 pt-5 text-capitalize'>
                        Graded
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                      </label>
                    }
                    checked={bondState.vestingForProfit.type === VESTING_FOR_PROFIT.GRADED}
                    value={VESTING_FOR_PROFIT.GRADED}
                    onChange={(e) => handleChangeVestingForProfit('type', e.target.value)}
                  /> */}
                </td>
                {bondState.vestingForProfit.type === VESTING_FOR_PROFIT.CLIFF ? (
                  <>
                    <td className='pt-30'>
                      <label className='fw-bold'>
                        After
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                      </label>
                    </td>
                    <td>
                      <InputGroup>
                        <NumberFormat
                          displayType='input'
                          value={bondState.vestingForProfit.afterValue}
                          onValueChange={({ value }) => handleChangeVestingForProfit('afterValue', value)}
                          isAllowed={({ value }) => {
                            return value > -1 && value < 100
                          }}
                        />
                        <InputGroupText className='py-6'>
                          <PeriodPicker label={bondState.vestingForProfit.afterOption.label} options={TimeOptions} onClickOption={(e, option) => handleChangeVestingForProfit('afterOption', option)} />
                        </InputGroupText>
                      </InputGroup>
                      <div className='mt-10'>
                        <AppCheckbox
                          label={
                            <span className='fs-sm text-muted'>
                              Start profits redemption after principal
                              <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                            </span>
                          }
                          readOnly
                          checked={bondState.vestingForProfit.isStartProfit}
                          value={1}
                          onChange={(e) => handleChangeVestingForProfit('isStartProfit', e.target.checked)}
                        />
                      </div>
                    </td>
                  </>
                ) : (
                  <>
                    <td className='pt-30'>
                      <label className='fw-bold'>
                        Portion
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Bond_Token_Potion')} />
                      </label>
                    </td>
                    <td>
                      <InputGroup>
                        <NumberFormat
                          displayType='input'
                          value={bondState.vestingForProfit.portionValue}
                          onValueChange={({ value }) => handleChangeVestingForProfit('portionValue', value)}
                          isAllowed={({ value }) => {
                            return value > -1 && value < 100
                          }}
                        />
                        <InputGroupText>%</InputGroupText>
                      </InputGroup>
                    </td>
                  </>
                )}
              </tr>
              <tr hidden={bondState.vestingForProfit.type === VESTING_FOR_PRINCIPAL.CLIFF} className='vertical-align-top'>
                <td colSpan={3}></td>
                <td>
                  <label className='fw-bold mt-15'>
                    Every
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Bond_Token_Every')} />
                  </label>
                </td>
                <td>
                  <InputGroup>
                    <NumberFormat
                      displayType='input'
                      value={bondState.vestingForProfit.everyValue}
                      onValueChange={({ value }) => handleChangeVestingForProfit('everyValue', value)}
                      isAllowed={({ value }) => {
                        return value > -1 && value < 100
                      }}
                    />
                    <InputGroupText className='py-6'>
                      <PeriodPicker label={bondState.vestingForProfit.afterOption.label} options={TimeOptions} onClickOption={(e, option) => handleChangeVestingForProfit('afterOption', option)} />
                    </InputGroupText>
                  </InputGroup>
                  <div className='mt-10'>
                    <AppCheckbox
                      label={
                        <span className='fs-sm text-muted'>
                          Start profits redemption after principal
                          <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                        </span>
                      }
                      checked={bondState.vestingForProfit.isStartProfit}
                      value={1}
                      onChange={(e) => handleChangeVestingForProfit('isStartProfit', e.target.checked)}
                    />
                  </div>
                </td>
              </tr>
              <tr hidden={!hasProfitVesting || !hasPrincipalVesting}>
                <td>
                  <label className='fw-bold'>
                    Prepayment penalty
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('PREPAYMENT_PENALTY')} />
                  </label>
                </td>
                <td>
                  <RadioButton
                    id='Prepayment-Penalty-Yes'
                    name='Prepayment-Penalty'
                    className='mr-5 d-flex align-items-center'
                    label={
                      <label htmlFor='Prepayment-Penalty-Yes' className='ml-10 pt-5'>
                        Yes
                      </label>
                    }
                    checked={bondState.prepaymentPenalty === true}
                    onChange={() => handlePrepaymentPenalty(true)}
                  />
                </td>
                <td>
                  <RadioButton
                    id='Prepayment-Penalty-No'
                    name='Prepayment-Penalty'
                    className='d-flex align-items-center'
                    label={
                      <label htmlFor='Prepayment-Penalty-No' className='ml-10 pt-5 text-capitalize'>
                        No
                      </label>
                    }
                    checked={bondState.prepaymentPenalty === false}
                    onChange={() => handlePrepaymentPenalty(false)}
                  />
                </td>
                <td hidden={!bondState.prepaymentPenalty}>
                  <label className='fw-bold'>
                    Dynamic penalty
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Dynamic_Penalty')} />
                  </label>
                </td>
                <td hidden={!bondState.prepaymentPenalty}>
                  <InputGroup>
                    <NumberFormat
                      displayType='input'
                      value={bondState.dynamicPenalty}
                      onValueChange={({ value }) => handleChangeParams('dynamicPenalty', value)}
                      isAllowed={({ value }) => {
                        return value > -1 && value < 100
                      }}
                    />
                    <InputGroupText>%</InputGroupText>
                  </InputGroup>
                </td>
              </tr>
            </tbody>
          </SForms>
        </div>
      ) : (
        <div>
          <SForms>
            <tbody>
              <tr>
                <td>
                  <label className='fw-bold'>Vesting period</label>
                </td>
                <td>
                  <RadioButton
                    id='Vesting-period-Cliff'
                    name='isAdd'
                    className='mr-5 d-flex align-items-center'
                    label={
                      <label htmlFor='Vesting-period-Cliff' className='ml-10 pt-5'>
                        Cliff
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                      </label>
                    }
                    checked={bondState.vestingPeriod.type === VESTING_PERIOD.CLIFF}
                    value={VESTING_PERIOD.CLIFF}
                    onChange={(e) => handleChangeVestingPeriod('type', e.target.value)}
                  />
                </td>
                <td>
                  <RadioButton
                    id='Vesting-period-Graded'
                    name='isAdd'
                    className='d-flex align-items-center'
                    label={
                      <label htmlFor='Vesting-period-Graded' className='ml-10 pt-5 text-capitalize'>
                        Graded
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                      </label>
                    }
                    checked={bondState.vestingPeriod.type === VESTING_PERIOD.GRADED}
                    value={VESTING_PERIOD.GRADED}
                    onChange={(e) => handleChangeVestingPeriod('type', e.target.value)}
                  />
                </td>
                {bondState.vestingPeriod.type === VESTING_PERIOD.CLIFF ? (
                  <>
                    <td>
                      <label className='fw-bold'>
                        After
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                      </label>
                    </td>
                    <td>
                      <InputGroup>
                        <InputGroupControl
                          as={NumberFormat}
                          displayType='input'
                          type='number'
                          value={bondState.vestingPeriod.afterValue}
                          onValueChange={({ value }) => handleChangeVestingPeriod('afterValue', value)}
                          isAllowed={({ value }) => {
                            return value > -1
                          }}
                        />
                        <InputGroupText className='py-7'>
                          <PeriodPicker label={bondState.vestingPeriod.afterOption.label} options={TimeOptions} onClickOption={(e, option) => handleChangeVestingPeriod('afterOption', option)} />
                        </InputGroupText>
                      </InputGroup>
                    </td>
                  </>
                ) : (
                  <>
                    <td className='pt-30'>
                      <label className='fw-bold'>
                        Portion
                        <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Bond_Token_Potion')} />
                      </label>
                    </td>
                    <td>
                      <InputGroup>
                        <NumberFormat
                          displayType='input'
                          value={bondState.vestingPeriod.portionValue}
                          onValueChange={({ value }) => handleChangeVestingPeriod('portionValue', value)}
                          isAllowed={({ value }) => {
                            return value > -1 && value < 100
                          }}
                        />
                        <InputGroupText>%</InputGroupText>
                      </InputGroup>
                    </td>
                  </>
                )}
              </tr>
              <tr hidden={bondState.vestingPeriod.type === VESTING_PERIOD.CLIFF}>
                <td colSpan={3}></td>
                <td>
                  <label className='fw-bold'>
                    Every
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Bond_Token_Every')} />
                  </label>
                </td>
                <td>
                  <InputGroup>
                    <NumberFormat
                      displayType='input'
                      value={bondState.vestingPeriod.everyValue}
                      onValueChange={({ value }) => handleChangeVestingPeriod('everyValue', value)}
                      isAllowed={({ value }) => {
                        return value > -1
                      }}
                    />
                    <InputGroupText className='py-7'>
                      <PeriodPicker label={bondState.vestingPeriod.everyOption.label} options={TimeOptions} onClickOption={(e, option) => handleChangeVestingPeriod('everyOption', option)} />
                    </InputGroupText>
                  </InputGroup>
                </td>
              </tr>
              <tr hidden={!hasProfitVesting || !hasPrincipalVesting}>
                <td>
                  <label className='fw-bold'>Prepayment penalty</label>
                </td>
                <td>
                  <RadioButton
                    id='Prepayment-Penalty-Yes'
                    name='Prepayment-Penalty'
                    className='mr-5 d-flex align-items-center'
                    label={
                      <label htmlFor='Prepayment-Penalty-Yes' className='ml-10 pt-5'>
                        Yes
                      </label>
                    }
                    checked={bondState.prepaymentPenalty === true}
                    onChange={() => handlePrepaymentPenalty(true)}
                  />
                </td>
                <td>
                  <RadioButton
                    id='Prepayment-Penalty-No'
                    name='Prepayment-Penalty'
                    className='d-flex align-items-center'
                    label={
                      <label htmlFor='Prepayment-Penalty-No' className='ml-10 pt-5 text-capitalize'>
                        No
                      </label>
                    }
                    checked={bondState.prepaymentPenalty === false}
                    onChange={() => handlePrepaymentPenalty(false)}
                  />
                </td>
                <td>
                  <label className='fw-bold'>
                    Dynamic penalty
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                  </label>
                </td>
                <td>
                  <InputGroup>
                    <NumberFormat
                      displayType='input'
                      value={bondState.dynamicPenalty}
                      onValueChange={({ value }) => handleChangeParams('dynamicPenalty', value)}
                      isAllowed={({ value }) => {
                        return value > -1 && value < 100
                      }}
                    />
                    <InputGroupText>%</InputGroupText>
                  </InputGroup>
                </td>
              </tr>
            </tbody>
          </SForms>
        </div>
      )}
      <Divider className='vertical mt-20 mb-10 highlighted-border-bottom' />
      <div className='d-flex justify-content-center mt-50 position-relative'>
        <BackButton className='position-absolute left-0 top-22' onClick={handleOnClickback} />
        <AppButton className='text-capitalize outline-primary w-max-500' onClick={handleOnClickNextStep}>
          next step
        </AppButton>
      </div>
      <AppTooltip />
    </React.Fragment>
  )
}

export default BondTerms
