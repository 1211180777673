import styled from 'styled-components'
import defaultIcon from 'Assets/images/defaultIcon.svg'
import { useEffect, useState } from 'react'
import { TokenImagesSelector } from 'redux/selectors'
import { useAppSelector } from 'redux/hooks'
import { TokenAlterImages } from 'app-constants/images'

const TokenImage = styled.img`
  &.md {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    padding: 2px;
    margin-right: 7.5px;
  }
  &.md-1,
  &.md-1-border {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    margin: 1px;
  }
  &.md-1-border {
    border: 1px solid white;
    border-radius: 50px;
  }
  &.md-2 {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
  }
  &.bg-white {
    background-color: white;
  }
`

interface ITokenIconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  as?: any
  byAddress?: string
}

function TokenIcon({ alt, byAddress = '', src = '', ...props }: ITokenIconProps) {
  const [imageSrc, setImageSrc] = useState(defaultIcon)
  const tokenImages = useAppSelector(TokenImagesSelector)

  useEffect(() => {
    const adr = byAddress?.toLowerCase() || '';
    const uri = TokenAlterImages[adr] || tokenImages[adr];

    if (src) {
      return setImageSrc(src)
    }
    if (byAddress && uri) {
      return setImageSrc(uri)
    }
    // default icon
    return setImageSrc(defaultIcon)
  }, [byAddress, src])

  return (
    <TokenImage
      src={imageSrc}
      onError={(e: any) => {
        e.target.onerror = null
        e.target.src = defaultIcon
      }}
      alt={alt || ''}
      {...props}
    />
  )
}

export default TokenIcon
