import { immediateToast } from 'izitoast-react'
import { EventEmitter } from 'events'

type Message = string | undefined
enum ActionType {
}
type Action = {
  type: ActionType
}

class NotificationConfig extends EventEmitter {
  info(message: Message) {
    immediateToast('info', {
      message: message,
      timeout: 5000,
      position: 'topRight',
    })
  }

  error(message: Message) {
    immediateToast('error', {
      message: message,
      timeout: 5000,
      position: 'topRight',
    })
  }

  warning(message: Message) {
    immediateToast('warning', {
      message: message,
      timeout: 5000,
      position: 'topRight',
    })
  }

  success(message: Message) {
    immediateToast('success', {
      message: message,
      timeout: 5000,
      position: 'topRight',
    })
  }

  handleActions(action: Action) {
    switch (action.type) {
      default:
        break
    }
  }
}

const notificationConfig = new NotificationConfig()
export default notificationConfig
