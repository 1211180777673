import { LOCAL_STORAGE_ITEM_NAMES } from 'app-constants'
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'helper/localStorage'
import { TourReducerState } from 'types'
import { ActionTour, ActionTypes } from './types'

const initialState: TourReducerState = {
  isDEREXNeverShow  : loadStateFromLocalStorage(LOCAL_STORAGE_ITEM_NAMES.TOUR_DEREX_STATE) == 1 ? true : false,
  isOpenDerexTour   : true,
  isIBONeverShow    : loadStateFromLocalStorage(LOCAL_STORAGE_ITEM_NAMES.TOUR_IBO_STATE) == 1 ? true : false,
  isOpenIBOTour     : true,
  isLPInsureNeverShow    : loadStateFromLocalStorage(LOCAL_STORAGE_ITEM_NAMES.TOUR_LP_INSURE_STATE) == 1 ? true : false,
  isOpenLPInsureTour     : true,
}

export default function settingsReducer(state = initialState, action: ActionTour): TourReducerState {
  switch (action.type) {

    case ActionTypes.SetIBO_NeverShow:
      saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.TOUR_IBO_STATE, action.payload === true ? 1 : 0)
      return { ...state, isIBONeverShow: action.payload }

    case ActionTypes.SetDEREX_NeverShow:
      saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.TOUR_DEREX_STATE, action.payload === true ? 1 : 0)
      return { ...state, isDEREXNeverShow: action.payload }

    case ActionTypes.SetLPInsure_NeverShow:
      saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.TOUR_LP_INSURE_STATE, action.payload === true ? 1 : 0)
      return { ...state, isLPInsureNeverShow: action.payload }

    case ActionTypes.SetOpenIBOTour:
      return { ...state, isOpenIBOTour: action.payload }

    case ActionTypes.SetOpenDEREXTour:
      return { ...state, isOpenDerexTour: action.payload }

    case ActionTypes.SetOpenLPInsureTour:
      return { ...state, isOpenLPInsureTour: action.payload }

    default:
      return state
  }
}
