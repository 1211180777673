import styled from 'styled-components'
import { AppButton } from './Buttons'
import { FlexDiv } from './others/styled'

export const SearchBox = styled(FlexDiv)`
  justify-content: space-between;
  border-top: 2px solid ${(props) => props.theme.grayBorder3};
  border-bottom: 2px solid ${(props) => props.theme.grayBorder3};
`
export const SearchInputWrapper = styled.div`
  position: relative;
`

export const PopInput = styled.input`
  font-weight: 700;
  color: ${(props) => props.theme.colorWhite};
  line-height: normal;
  background-color: transparent;
  border: none;
  padding-left: 50px;
`

export const LaunchButton = styled(AppButton)`
  box-shadow: 0px 0px 6px ${({ theme }) => theme.colorPrimary};
`