import gql from 'graphql-tag'
import ApolloClient from 'apollo-boost'

export * from './lpinsure'

export const SubgraphPairDataQL = gql`
  query GetSubgraphData($pairAddress: String!) {
    pairHourDatas(where: { pair: $pairAddress }) {
      id
      hourStartUnix
      pair {
        id
        token0 {
          address: id
          name
          symbol
        }
        token1 {
          address: id
          name
          symbol
        }
      }
      reserve0
      reserve1
      reserveUSD
    }
    pairDayDatas(where: { pairAddress: $pairAddress }) {
      id
      date
      token0 {
        address: id
        decimals
        name
        symbol
      }
      token1 {
        address: id
        decimals
        name
        symbol
      }
      reserve0
      reserve1
      reserveUSD
    }
  }
`

export const SubgraphPairDataByParamQL = gql`
  query GetSubgraphData($pairAddress: String!) {
    pairHourDatas(first: 24, orderBy: hourStartUnix, where: { pair: $pairAddress }, orderDirection: desc) {
      id
      hourStartUnix
      pair {
        id
        token0 {
          address: id
          decimals
          name
          symbol
        }
        token1 {
          address: id
          decimals
          name
          symbol
        }
      }
      reserve0
      reserve1
      reserveUSD
    }
    pairDayDatas(first: 30, orderBy: date, orderDirection: desc, where: { pairAddress: $pairAddress }) {
      id
      date
      token0 {
        address: id
        decimals
        name
        symbol
      }
      token1 {
        address: id
        decimals
        name
        symbol
      }
      reserve0
      reserve1
      reserveUSD
    }
  }
`

export const getTokensQL = gql`
  query GetTokens($where: Token_filter) {
    tokens(where: $where) {
      address: id
      name
      decimals
      symbol
      totalSupply
    }
  }
`

export const GetBallots = gql`
  query GetBallots($first: Int, $skip: Int, $order: OrderDirection, $orderBy: Ballot_orderBy, $where: Ballot_filter) {
    ballots(first: $first, skip: $skip, orderDirection: $order, orderBy: $orderBy, where: $where) {
      id
      ruleId
      ruleIndex
      args
      status
      closeVote
      totalVotes
      totalVotesLP
      yea
      yeaLP
      timestamp
      pair {
        address: id
        token0 {
          address: id
          decimals
          name
          symbol
        }
        token1 {
          address: id
          decimals
          name
          symbol
        }
      }
    }
  }
`

export const GetPairs = gql`
  query GetPairs($first: Int, $skip: Int, $where: Pair_filter) {
    pairs(first: $first, skip: $skip, where: $where) {
      address: id
      token0 {
        address: id
        name
        symbol
        decimals
      }
      token1 {
        address: id
        name
        symbol
        decimals
      }
    }
  }
`
export const GetPair = gql`
  query GetPair($lpAddress: ID!) {
    pair(id: $lpAddress) {
      lpAddress: id
      reserve0
      reserve1
      token0 {
        address: id
        name
        symbol
        decimals
      }
      token1 {
        address: id
        name
        symbol
        decimals
      }
    }
  }
`

export const GetTokenDayDatas = gql`
  query GetTokenDayDatas($where: TokenDayData_filter, $first: Int) {
    tokenDayDatas(where: $where, first: $first, orderBy: date, orderDirection: desc) {
      id
      date
      priceUSD
      token {
        address: id
        name
        symbol
        decimals
      }
    }
  }
`

/**
 * Bond subgraph queries
 */
export const GetBonds = gql`
  query GetBonds($first: Int, $skip: Int, $where: Bond_filter, $initialPositionUser: Bytes) {
    bonds(first: $first, skip: $skip, where: $where, orderDirection: desc, orderBy: viewId) {
      id
      viewId
      nonceId
      creator
      classId
      transactionHash
      paramerters {
        id
        pairToken {
          address: id
          name
          symbol
          decimals
        }
        token {
          address: id
          name
          symbol
          decimals
        }
        maxInvestment
        minInvestment
        endDate
        startDate
        supplyAmount
        targetAmount
        feeParam {
          id
          dsReleaseTime
          feeType
          router
          stakingAPY
          stakingPeriod
          licensee
        }
        vestingParam {
          id
          cliffDate
          cliffProfitDate
          prepaymentPenalty
        }
      }
      poolParam {
        poolPair {
          lpAddress: id
          reserve0
          reserve1
          token1 {
            address: id
            name
            symbol
            decimals
          }
          token0 {
            address: id
            name
            symbol
            decimals
          }
          reserveETH
          totalSupply
        }
        projectWallet
        spentTokens
        totalRaisedPairTokens
        fee
        bondContract
        isClosed
        isCoin
      }
      initialPositions (first: 1, where: { user: $initialPositionUser}) {
        id
        tokenAmount
        pairAmount
        user
      }
    }
  }
`

export const GetBondsForFarms = gql`
  query GetBondsForFarms($first: Int, $skip: Int, $where: Bond_filter) {
    bonds(first: $first, skip: $skip, where: $where, orderDirection: desc, orderBy: viewId) {
      transactionHash
      viewId
      classId
      nonceId
      creator
      token {
        symbol
      }
    }
  }
`

export const GetFarms = gql`
  query GetFarms($first: Int, $skip: Int, $where: Farm_filter) {
    farms(first: $first, skip: $skip, where: $where) {
      id
      classId
      farmId
      rewardToken {
        address: id
        name
        symbol
        decimals
      }
      period
      toDumperShield
      timestamp
    }
  }
`

export type GetInsureOffersWithLPBalanceRes = {
  insurePools: GetInsureOffersWithLPBalanceType[]
}
export type GetInsureOffersWithLPBalanceType = {
  id: string
  transactionHash: string
  totalInsurance: string
  poolParams: {
    id: string
    bondContract: string
    spentTokens: string
    totalRaisedPairTokens: string
    projectWallet: string

    poolPair: {
      lpAddress: string
      reserve0: string
      reserve1: string
      token1: {
        address: string
        name: string
        symbol: string
        decimals: string
      }
      token0: {
        address: string
        name: string
        symbol: string
        decimals: string
      }
      reserveETH: string
      totalSupply: string
      liquidityPositions: {
        user: {
          id: string
        }
        liquidityTokenBalance: string
      }[]
    }
  }
  token: {
    address: string
    name: string
    symbol: string
    decimals: string
  }
  pairToken: {
    address: string
    name: string
    symbol: string
    decimals: string
  }
  parameters: {
    id: string
    startDate: string
    router: string
    minInvestment: string
    maxInvestment: string
    isCoin: boolean
    endDate: string
    supplyAmount: string
    vestingParam: {
      cliffDays: string
      prepaymentPenalty: string
    }
  }
  insureEntity: {
    insureFarms: {
      id: string
      classId: string
      farmId: string
      rewardToken: {
        address: string
        name: string
        symbol: string
        decimals: string
      }
      rewardPerSecond: string
      toDumperShield: boolean
      period: string
      timestamp: string
    }[]
  }
  initialInvestments: {
    id: string
    tokenAmount: string
    pairAmount: string
  }[]
  investmentPositions: {
    id: string
    tokenAmount: string
    pairAmount: string
  }[]
}

export const GetInsureOffersWithLPBalanceQL = gql`
  query GetInsurePools($first: Int, $skip: Int, $where: InsurePool_filter, $user: ID) {
    insurePools(first: $first, skip: $skip, where: $where)  {
      id
      transactionHash
      totalInsurance
      poolParams {
        id
        bondContract
        spentTokens
        totalRaisedPairTokens
        projectWallet

        poolPair {
          lpAddress: id
          reserve0
          reserve1
          token1 {
            address: id
            name
            symbol
            decimals
          }
          token0 {
            address: id
            name
            symbol
            decimals
          }
          reserveETH
          totalSupply
          liquidityPositions( where: { user: $user } ) {
            user {
              id
            }
            liquidityTokenBalance
          }
        }
      }
      token {
        address: id
        name
        symbol
        decimals
      }
      pairToken {
        address: id
        name
        symbol
        decimals
      }
      parameters {
        id
        startDate
        router
        minInvestment
        maxInvestment
        isCoin
        endDate
        supplyAmount
        vestingParam {
          cliffDays
          prepaymentPenalty
        }
      }
      insureEntity {
        insureFarms {
          id
          classId
          farmId
          rewardToken {
            address: id
            name
            symbol
            decimals
          }
          rewardPerSecond
          toDumperShield
          period
          timestamp
        }
      }
      initialInvestments(where: { investor: $user }) {
        id
        tokenAmount
        pairAmount
      }
      investmentPositions(where: {user: $user}) {
        id
        tokenAmount
        pairAmount
      }
    }
  }`

export const GetUserWithLpPositionsByAccount = gql`
  query GetUserWithLpPositions($account: ID!) {
    users(where: { id: $account }) {
      account: id
      usdSwapped
      liquidityPositions {
        id
        lpBalance: liquidityTokenBalance
        pair {
          lpAddress: id
          token0 {
            address: id
            name
            symbol
            decimals
          }
          token1 {
            address: id
            name
            symbol
            decimals
          }
          totalSupply
          reserve0
          reserve1
        }
      }
    }
  }
`

export const GetBondBuyers = gql`
  query GetBondBuyers($where: BondBuyer_filter) {
    bondBuyers(where: $where) {
      id
      buyer
      bondAmounts
      timestamp
    }
  }
`

// GetPairswith Liquidity position id type: pairId-account
export const GetPairsWithLiquidityPositionsByIds = gql`
  query GetPairsWithLP_Id($id_in: [ID!]) {
    pairs(where: { liquidityPositions_: { id_in: $id_in } }) {
      liquidityPositions {
        id
        lpBalance: liquidityTokenBalance
        pair {
          lpAddress: id
          token0 {
            address: id
            name
            symbol
            decimals
          }
          token1 {
            address: id
            name
            symbol
            decimals
          }
          totalSupply
          reserve0
          reserve1
        }
        user {
          account: id
          usdSwapped
        }
      }
    }
  }
`
export const GetRewardTokens = gql`
  query GetRewardTokens($first: Int, $skip: Int, $where: RewardToken_filter) {
    rewardTokens(first: $first, skip: $skip, where: $where) {
      address: id
      name
      symbol
      decimals
    }
  }
`
export const createClient = (uri: string) =>
  new ApolloClient({
    uri: uri,
  })
