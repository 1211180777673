import styled from 'styled-components'

export const ButtonGroupButton = styled.button`
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  color: ${(props) => props.theme.colorLightGrey};
  &.primary {
    background-color: ${(props) => props.theme.colorPrimary};
    color: white;
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  align-items: stretch;
  border: 3px solid ${(props) => props.theme.greyBorder};
  border-radius: 10px;
  padding: 5px;
`
