import React, { useCallback, useState, useMemo } from 'react'
import { IoMdArrowDropdown } from 'react-icons/io'
import { DebounceInput } from 'react-debounce-input'
import { isAddress } from 'web3-utils'
import { HiExternalLink } from 'react-icons/hi'
import { BsQuestionCircleFill } from 'react-icons/bs'

import { LaunchPoolInputGroup, SPairTokenButton } from 'Component/core/elements/IBO-SharedComponents'
import { InputGroup, InputGroupControl } from 'Component/core/elements/InputGroup'
import TokenIcon from 'Component/TokenIcon'
import useAppSnackbar, { AppWaringOptions } from 'hooks/useAppSnackbar'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { LPInsureSelector } from 'redux/selectors'
import { nextStep, setLPInsureDex, setParams, setToken, setPairToken } from 'redux/reducers/lp-insure/actions'
import { AppButton, SSelectDexButton } from 'Component/core/elements/Buttons'
import { Divider } from 'Component/core/elements/Divider'
import AppDatePicker from 'Component/core/elements/DatePicker/DatePicker'
import SelectDexes from 'Component/popup/SelectDexes'
import { ApiDexInfo, BaseToken, LPInsureReducerParams, PairToken } from 'types'
import SelectDexPairToken from 'Component/popup/SelectDexPairToken'
import { getExplorerUrl, getPairToken, isEqAddr } from 'helper'
import AppTooltip from 'Component/AppTooltip'
import { ZERO_ADDRESS } from 'app-constants'
import { useAppChain } from 'hooks'
import SelectTokenPopup from 'Component/popup/SelectTokenPopup'

import { TokenText } from './styled'

function ProjectInformation() {
  const [openWaringSnackbar] = useAppSnackbar(AppWaringOptions)
  const dispatch = useAppDispatch()
  const lpInsure = useAppSelector(LPInsureSelector)
  const { appChainId } = useAppChain()

  const [isOpenSelectPairToken, setIsOpenSelectPairToken] = useState(false)
  const [isOpenSelectDex, setIsOpenSelectDex] = useState(false)
  const [isOpenSelectToken, setIsOpenSelectToken] = useState(false)

  const [lpAddress, setLPAddress] = useState<string>(ZERO_ADDRESS)
  
  const isInvalidEndSale = useMemo(() => {
    if(Date.now() >= lpInsure.endIBODate) {
      return {
        state: true,
        errorMessage: 'End date should be bigger than today',
      }
    }
    return {
      state: false,
      errorMessage: '',
    }
}, [lpInsure.endIBODate])

const isInvalidDex = useMemo(() => {
  if(lpInsure.dex.router === ZERO_ADDRESS) {
    return {
      state: true,
      errorMessage: 'Dex is required',
    }
  }
  return {
    state: false,
    errorMessage: '',
  }
}, [lpInsure.dex])

  const isDisableSelectLP = useMemo<boolean>((): boolean => {
    return !isAddress(lpInsure.token) && isAddress(lpInsure.dex.router) && !isEqAddr(lpInsure.dex.router, ZERO_ADDRESS)
  }, [lpInsure.token])

  const handleOnChangeToken = useCallback(
    (e: any) => {
      const token = e.target.value
      if (!isAddress(token)) {
        openWaringSnackbar('Please enter correct address')
      }
      dispatch(setToken(token))
      setLPAddress(ZERO_ADDRESS)
    },
    [openWaringSnackbar]
  )
  const setLPInsureReducerParams = useCallback((key: keyof LPInsureReducerParams, value: any) => {
    dispatch(
      setParams({
        [key]: value
      })
    )
  }, [])

  const handleOnChangeIBOStartDate = (_date: Date) => {
    setLPInsureReducerParams('startIBODate', _date.getTime())
  }
  const handleOnChangeIBOEndDate = (_date: Date) => {
    setLPInsureReducerParams('endIBODate', _date.getTime())
  }

  const handleOnSelectToken = useCallback(({ address }: BaseToken) => {
      dispatch(setToken(address))
      setIsOpenSelectToken(false)
  }, [])

  const onSelectPairToken = (pairToken: PairToken) => {
    const pToken: string = lpInsure.token
    const poolPairToken = getPairToken(pToken, pairToken.token0, pairToken.token1)

    if (!lpInsure.token) {
      openWaringSnackbar('Please add token first!')
    } else if (poolPairToken) {
      setLPAddress(pairToken.address)
      dispatch(setPairToken({
        address: poolPairToken.address,
        decimals: poolPairToken.decimals,
        name: poolPairToken.name,
        symbol: poolPairToken.symbol,
      }))
    } else {
      openWaringSnackbar('Please select right pool!')
    }
    setIsOpenSelectPairToken(false)
  }
  const handleOnDismissSelectDexes = () => {
    setIsOpenSelectDex(false)
  }
  const onCloseSelectPairToken = () => {
    setIsOpenSelectPairToken(false)
  }
  const handleOnSelectDex = (dex: ApiDexInfo) => {
    setIsOpenSelectDex(false)
    dispatch(setLPInsureDex(dex))
  }

  const onClickNextStep = () => {
    // check data validate
    if (isInvalidEndSale.state) {
      return openWaringSnackbar(isInvalidEndSale.errorMessage)
    }
    if (isInvalidDex.state) {
      return openWaringSnackbar(isInvalidDex.errorMessage)
    }
    if (!lpInsure.pairToken.address || lpInsure.pairToken.address === '') {
      return openWaringSnackbar('You should select right pool!')
    }
    dispatch(nextStep())
  }

  return (
    <React.Fragment>
      <div className='py-40'>
        <LaunchPoolInputGroup className='mt-30'>
          <div className='my-10 label-section'>
            <label className='text-nowrap fw-bold'>Place your token contract*</label>
          </div>
          <div className='my-10 input-section'>
            <InputGroup className='w-full'>
              <InputGroupControl as={DebounceInput} element='input' value={lpInsure.token} onChange={handleOnChangeToken} debounceTimeout={400} />
              <TokenText
                className='text-uppercase'
                onClick={() => setIsOpenSelectToken(true)}
              >[{lpInsure.tokenInfo.symbol || 'token'}]</TokenText>
            </InputGroup>
          </div>
        </LaunchPoolInputGroup>
        <Divider className='vertical mt-15 mb-10 highlighted-border-bottom' />
        <div className='row py-30'>
          <div className='col-6'>
            <LaunchPoolInputGroup>
              <div className='label-section'>
                <label className='fw-bold'>
                  Select  a DEX
                  <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                </label>
              </div>
              <div className='input-section'>
                <SSelectDexButton className={`w-full w-min-320 py-7 text-end ${isInvalidDex.state ? 'outline-danger' : 'outline-gray'}`} onClick={() => setIsOpenSelectDex(true)}>
                  {lpInsure.dex.title ? (
                    <p>
                      <img src={lpInsure.dex.img} alt='' />
                      <span>{lpInsure.dex.title}</span>
                    </p>
                  ) : (
                    <> &nbsp;</>
                  )}
                  <IoMdArrowDropdown className='text-muted' />
                </SSelectDexButton>
              </div>
            </LaunchPoolInputGroup>
          </div>
          <div className='col-6'>
            <LaunchPoolInputGroup className='pl-20'>
              <div className='label-section'>
                <label className='fw-bold'>Select a liquidity pool*</label>
              </div>
              <div className='input-section position-relative'>
                <SPairTokenButton
                  className={`w-min-320 w-full outline-gray py-7 text-end ${lpInsure.pairToken.address ? '' : 'danger'}`}
                  onClick={() => setIsOpenSelectPairToken(true)}
                  disabled={isDisableSelectLP}
                >
                  {lpInsure.pairToken.address ? (
                    <p>
                      <TokenIcon className='md-2 rounded-circle' byAddress={lpInsure.pairToken.address} alt='' />
                      <span>{lpInsure.pairToken.symbol}</span>
                      <span>{'<>'}</span>
                      <TokenIcon className='md-2 ml-10 rounded-circle' byAddress={lpInsure.tokenInfo.address} alt='' />
                      <span>{lpInsure.tokenInfo.symbol}</span>
                    </p>
                  ) : (
                    <> &nbsp;</>
                  )}
                  <IoMdArrowDropdown className='text-muted' />
                </SPairTokenButton>
                <div className='position-absolute right-0 -bottom-20'>
                  <a href={`${getExplorerUrl(appChainId)}/address/${lpAddress}`} target='_blank' className='text-danger' rel='noreferrer'>
                    <span className='fs-sm'>Verify that is the right pool contract</span>
                    <HiExternalLink />
                  </a>
                </div>
              </div>
            </LaunchPoolInputGroup>
          </div>
        </div>
        <Divider className='vertical mt-15 mb-10 highlighted-border-bottom' />
        <div className='row py-30'>
          <div className='col-6'>
            <LaunchPoolInputGroup>
              <div className='label-section'>
                <label className='mt-5 fw-bold'>Start sale bond*</label>
              </div>
              <div className='input-section'>
                <InputGroup className='w-min-320 w-full py-2 pl-15'>
                  <AppDatePicker dateFormat='MMMM d, yyyy' selected={lpInsure.startIBODate} onChange={handleOnChangeIBOStartDate} />
                </InputGroup>
              </div>
            </LaunchPoolInputGroup>
          </div>
          <div className='col-6'>
            <LaunchPoolInputGroup className='pl-20'>
              <div className='label-section'>
                <label className='mt-5 fw-bold'>End sale bond*</label>
              </div>
              <div className='input-section'>
                <InputGroup className={`w-min-320 w-full py-2 pl-15 ${isInvalidEndSale.state ? 'danger' : ''}`}>
                  <AppDatePicker dateFormat='MMMM d, yyyy' selected={lpInsure.endIBODate} onChange={handleOnChangeIBOEndDate} />
                </InputGroup>
              </div>
            </LaunchPoolInputGroup>
          </div>
        </div>
        <Divider className='vertical mt-15 mb-10 highlighted-border-bottom' />
        <div className='d-flex justify-content-center mt-50'>
          <AppButton className='text-capitalize outline-primary w-max-500' onClick={onClickNextStep}>
            next step
          </AppButton>
        </div>
      </div>
      <SelectTokenPopup
        onlyTokensWithBalance
        isOpen={isOpenSelectToken}
        dismiss={() => setIsOpenSelectToken(false)}
        onSelectToken={handleOnSelectToken}
      />
      <SelectDexes isOpen={isOpenSelectDex} dismiss={handleOnDismissSelectDexes} onSelect={handleOnSelectDex} />
      <SelectDexPairToken
        isOpen={isOpenSelectPairToken}
        dismiss={onCloseSelectPairToken}
        onSelect={onSelectPairToken}
        options={{
          dex: lpInsure.dex,
          pairsWith: lpInsure.tokenInfo.symbol
        }}
      />
      <AppTooltip />
    </React.Fragment>
  )
}

export default ProjectInformation
