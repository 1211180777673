import { useCallback, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { addCustomToken, clearAllCustomToken, removeCustomToken } from "redux/reducers/tokenList/tokenActions"
import { TokenListSelector } from "redux/selectors"
import { AppCustomToken, SupportedNetworks } from "types"

type UseCustomTokensReturnType = {
    tokens: AppCustomToken[]
    allTokens: AppCustomToken[]
    addToken: (newCustomToken: AppCustomToken) => void
    removeToken: (tokenAddress: string, chainId: SupportedNetworks) => void
    clearAll: () => void
}

export const useCustomTokens = (chainId: SupportedNetworks): UseCustomTokensReturnType => {
    const { customTokens } = useAppSelector(TokenListSelector);
    const tokens = useMemo(() => {
        return customTokens.filter((ct) => ct.chain === chainId);
    }, [customTokens, chainId]);
    const dispatch = useAppDispatch();

    const addToken = useCallback((_v: AppCustomToken): void => {
        dispatch(addCustomToken(_v));
    }, [])

    const removeToken = useCallback((address: string, c: SupportedNetworks): void => {
        dispatch(removeCustomToken(address, c));
    }, [])

    const clearAll = useCallback((): void => {
        dispatch(clearAllCustomToken());
    }, [])

    return { allTokens: customTokens, tokens: tokens, addToken, removeToken, clearAll }
}
