import { BondDetailReducerState } from 'types'
import { Actions, ActionTypes } from './types'

export const initialState: BondDetailReducerState = {
  isLoading: true,
  bond: null
}

export default function Reducer(state = initialState, action: Actions): BondDetailReducerState {
  switch (action.type) {
    case ActionTypes.IS_LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.FETCH_BOND_DETAIL:
      return { ...state, bond: action.payload };
    default:
      return state
  }
}
