import { LPInsureData, LPInsureListReducerState } from 'types'
import { ActionLPInsureList, ActionTypes } from './types'

export const initialState: LPInsureListReducerState = {
  isLoading: false,
  errMsg: '',
  search: '',
  list: [],
  investLengthIn24hrs: 0,
}

export default function Reducer(state = initialState, action: ActionLPInsureList): LPInsureListReducerState {
  let offerID: number | string, index: number, list: LPInsureData[], item: LPInsureData;
  switch (action.type) {
    case ActionTypes.FETCH_INSURE_LIST:
      return { ...state, list: action.payload }

    case ActionTypes.IS_LOADING_INSURE_LIST:
      return { ...state, isLoading: action.payload, errMsg: action.payload ? '' : state.errMsg }

    case ActionTypes.SET_SEARCH_VALUE:
      return { ...state, search: action.payload }

    case ActionTypes.SET_ERROR_MESSAGE:
      return { ...state, errMsg: action.payload }

    case ActionTypes.SET_INVEST_LENGTH_IN_24HRS:
      return { ...state, investLengthIn24hrs: action.payload }

    case ActionTypes.SET_VOTE_TO_OFFER:
      offerID = action.payload
      index = state.list.findIndex((b) => +b.id === +offerID)
      list = state.list.slice()
      item = list[index]
      if(!item.info) {
        item.info = {
          offerID: offerID,
          yeaVote: 0,
        }
      }
      if(!item.info.yeaVote) {
        item.info.yeaVote = 0
      }
      item.info.yeaVote += 1
      return { ...state, list: list.slice() }
    default:
      return state
  }
}
