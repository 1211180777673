import { TimeOptions, ZERO_ADDRESS } from 'app-constants'
import { CreatePoolType, LiquidityReducerState } from 'types'
import { ActionLiquidity, ActionTypes } from './types'

export const initialState: LiquidityReducerState = {
  /// start vote params
  isPrivate: false,
  maxTxDump0: 1, // token0 limitation per order in percent but use 2 decimal in contract
  maxTxDump1: 0, // token1 limitation per order in percent but use 2 decimal in contract
  timeFrame: 1, // token1 & token0 limitation per time period in seconds
  maxDump0: 5, // token0 limitation per time period
  maxDump1: 0, // token1 limitation per time period
  periodMA: 45, //
  minimalFee: 0.03, // minimal fee in percent
  coefficient: 100, // coefficient in percent
  voteDutation: 24, // voting duration // coming soon
  minVotingPower: 2, /// minimal voting power to create  a proposal // coming soon
  isAddSecureFloor: true, //
  tokenToSecure: ZERO_ADDRESS, // protected token address
  limitation: TimeOptions[1],
  wmaOption: TimeOptions[3],
  voteOption: TimeOptions[2],
  /// ends vote params

  userInfo: {
    usdSwapped: 0,
    liquidityPositions: [],
  },

  // select tokens
  createPoolWith: CreatePoolType.New,
  activedLPAddress: ZERO_ADDRESS,
  lpAddress: {
    isLoaded: false,
    address: ZERO_ADDRESS
  },
  lpInfo: {
    token0: {
      name: '',
      address: ZERO_ADDRESS,
      decimals: '',
      symbol: '',
    },
    token1: {
      name: '',
      address: ZERO_ADDRESS,
      decimals: '',
      symbol: '',
    },
    reserve0: '0',
    reserve1: '0',
  },
  tokenA: {
    name: '',
    symbol: '',
    address: '',
    decimals: '',
  },
  tokenB: {
    name: '',
    symbol: '',
    address: '',
    decimals: '',
  },
  tokenAPriceUSD: '',
  tokenBPriceUSD: '',
}

export default function liquidityReducer(state = initialState, action: ActionLiquidity): LiquidityReducerState {
  let tokenToSecure: string;
  switch (action.type) {
    case ActionTypes.SET:
      return { ...state, ...action.payload }

    case ActionTypes.SET_TOKEN_A:
      return { ...state, tokenA: action.payload, lpAddress: { isLoaded: false, address: ZERO_ADDRESS }, tokenToSecure: action.payload.address }

    case ActionTypes.SET_TOKEN_B:
      return { ...state, tokenB: action.payload, lpAddress: { isLoaded: false, address: ZERO_ADDRESS } }

    case ActionTypes.SET_USER_INFO:
      return { ...state, userInfo: action.payload }

    case ActionTypes.SET_TOKEN_PRICES:
      return { ...state, tokenAPriceUSD: action.payload[0], tokenBPriceUSD: action.payload[1] }

    case ActionTypes.SET_LP_ADDRESS:
      return { ...state, lpAddress: action.payload }

    case ActionTypes.SET_ACTIVED_LP_ADDRESS:
      return { ...state, activedLPAddress: action.payload }

    case ActionTypes.SET_CREATE_POOL_TYPE_WITH:
      return { ...state, createPoolWith: action.payload }

    case ActionTypes.SET_POOL_INFO:
      return { ...state, lpInfo: action.payload }

    case ActionTypes.SET_IS_ADD_SECURE_FLOOR:
      tokenToSecure = state.tokenToSecure;
      if (action.payload) { // is no secure floor
        tokenToSecure = state.tokenA.address;
      } else {
        tokenToSecure = ZERO_ADDRESS;
      }
      return { ...state, isAddSecureFloor: action.payload, tokenToSecure: tokenToSecure }
    case ActionTypes.RESET:
      return initialState

    default:
      return state
  }
}
