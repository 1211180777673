import { useState, useContext, useCallback, useEffect } from 'react'
import Collapse from '@kunukn/react-collapse'
import type { CollapseProps } from '@kunukn/react-collapse'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemState, AccordionItemPanel } from 'react-accessible-accordion'

import AppLink from 'Component/AppLink'
import { Scrollbars } from 'react-custom-scrollbars'
import bnbImage from 'Assets/images/icon-3.png'
import ethImage from 'Assets/images/b-icon.png'
import { BsDiscord, BsFillCaretUpFill, BsFillCaretDownFill } from 'react-icons/bs'
import { FaTelegramPlane, FaTwitter, FaRedditAlien, FaMediumM, FaGithub, FaMoon, FaSun } from 'react-icons/fa'
import LogoImg from 'Assets/images/logo(new).png'
import AtomImg from 'Assets/images/atom.png'
import useWallet from 'hooks/useWallet'
import { numberFormater } from 'helper'
import { ViewAddress } from 'Component/core/elements/others/global-components'
import { AppButton, PPClosBTN01 } from 'Component/core/elements/Buttons'
import { AppContext } from 'Contexts'
import S from './styled'

interface IAppSidebarProps {
  isOpen: boolean
  toggle: (state: boolean | undefined) => void
  onInit: (props: CollapseProps) => void
}

function AppSidebar({ isOpen, toggle, onInit }: IAppSidebarProps) {
  const [isOpenReimStakingContract, setIsOpenReimStakingContract] = useState(false)
  const { active, account, chainId, balance } = useWallet()
  const { isWrongNetwork } = useContext(AppContext)

  const menuEventLister = useCallback((e: any) => {
    const headerMenu = document.querySelector('#header__menu')
    if (!headerMenu?.contains(e.target)) {
      toggle(false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('click', menuEventLister)
    return () => {
      window.removeEventListener('click', menuEventLister)
    }
  }, [menuEventLister])

  return (
    <S.MenuRight>
      {/* @ts-ignore ignored for id */}
      <Collapse onInit={onInit} isOpen={isOpen} id='header__menu'>
        <S.MenuBg>
          <Scrollbars
            renderTrackVertical={(props) => <div {...props} className='track-vertical' />}
            renderThumbVertical={(props) => <div {...props} className='thumb-vertical' />}
            renderView={(props) => <div {...props} className='view' />}
            autoHide
            autoHideTimeout={1000}
          >
            <S.BallanceText className='mobile-div'>{active ? <div style={{ marginRight: '25px' }}>${numberFormater(3).format(+balance)}</div> : <span></span>}</S.BallanceText>
            <S.MenuTop>
              <div className='m-inner'>
                <div className='img-outer'>{active || chainId === 1 || chainId === 56 ? <img src={chainId === 56 ? bnbImage : ethImage} alt='Currency' /> : <span></span>}</div>
                <S.WalletAddress className='m-0'>{active && !isWrongNetwork ? <ViewAddress address={account || ''} /> : <AppButton className='darkRed'>Wrong Network</AppButton>}</S.WalletAddress>
              </div>
              {/* <MenuIcon
            className="m-0 no-margin"
            onClick={() => this.setIsOpen(this.state.isOpen)}
          >
            <TopDot className="topdot"></TopDot>
            <MidDot className="middot"></MidDot>
            <BottomDot className="bottomdot"></BottomDot>
          </MenuIcon> */}
              <PPClosBTN01 className='menuCloseBtn right-20 top-10' onClick={() => toggle(false)} />
            </S.MenuTop>
            <S.MenuLinks>
              <AppLink to='#' className='active'>
                Buy DMC
              </AppLink>
              {/* <AppLink to="#">Dynamic AMM</AppLink> */}
              <AppLink to='https://multiswap.derex.exchange'>Multi Swap</AppLink>
              <AppLink to='#'>Multi Trade</AppLink>
              <AppLink to='#'>Multi Staking</AppLink>
            </S.MenuLinks>
            <S.CustomAccordion>
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton className='menuToggleBtn'>Launchpool</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <S.MenuLinks2>
                      <AppLink to='#'>Reimbursement</AppLink>
                      <AppLink to='#'>HODL</AppLink>
                      <AppLink to='#'>Forge</AppLink>
                      <AppLink to='#'>Burn</AppLink>
                      <AppLink to='#'>Farm</AppLink>
                      <AppLink to='#'>Launchpool</AppLink>
                    </S.MenuLinks2>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </S.CustomAccordion>
            <S.MenuLinks>
              <AppLink to='#'>Reserve Presale</AppLink>
              <AppLink to='#'>PDO</AppLink>
              <AppLink to='#'>Secure Future</AppLink>
              <AppLink
                to='#'
                onClick={() => {
                  setIsOpenReimStakingContract((v) => !v)
                }}
              >
                Reimbursement Staking Contract
              </AppLink>
            </S.MenuLinks>
            <S.CustomAccordion2>
              <Accordion allowZeroExpanded>
                <AccordionItem
                  dangerouslySetExpanded={isOpenReimStakingContract}
                  onClick={() => {
                    setIsOpenReimStakingContract((v) => !v)
                  }}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      v1.5
                      <AccordionItemState>{({ expanded }) => (expanded ? <BsFillCaretUpFill /> : <BsFillCaretDownFill />)}</AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <S.VersionLink>
                      <AppLink to='#' className='active'>
                        v1
                      </AppLink>
                      <AppLink to='#'>v2 - </AppLink>
                    </S.VersionLink>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </S.CustomAccordion2>
            <S.CustomAccordion3>
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Library
                      <AccordionItemState>{({ expanded }) => (expanded ? <BsFillCaretUpFill /> : <BsFillCaretDownFill />)}</AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <S.LibLinks>
                      <AppLink to='#'>GitHub</AppLink>
                      <AppLink to='#'>Whitepaper</AppLink>
                      <AppLink to='#'>Light Paper</AppLink>
                      <AppLink to='#'>Technical Documentation</AppLink>
                      <AppLink to='#'>Pitch Deck</AppLink>
                      <AppLink to='#'>One Page</AppLink>
                    </S.LibLinks>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Community
                      <AccordionItemState>{({ expanded }) => (expanded ? <BsFillCaretUpFill /> : <BsFillCaretDownFill />)}</AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <S.LibLinks>
                      <AppLink to='#'>
                        <FaTelegramPlane />
                        Telegram
                      </AppLink>
                      <AppLink to='#'>
                        <FaTwitter />
                        Twitter
                      </AppLink>
                      <AppLink to='#'>
                        <FaRedditAlien />
                        Reddit
                      </AppLink>
                      <AppLink to='#'>
                        <BsDiscord />
                        Discord
                      </AppLink>
                      <AppLink to='#'>
                        <FaMediumM />
                        Medium
                      </AppLink>
                      <AppLink to='#'>
                        <FaGithub />
                        Github
                      </AppLink>
                    </S.LibLinks>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      About
                      <AccordionItemState>{({ expanded }) => (expanded ? <BsFillCaretUpFill /> : <BsFillCaretDownFill />)}</AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <S.LibLinks>
                      <AppLink to='#'>About Us</AppLink>
                      <AppLink to='#'>Careers</AppLink>
                      <AppLink to='#'>Partner with Us</AppLink>
                      <AppLink to='#'>Privacy Policy</AppLink>
                      <AppLink to='#'>Terms of Use</AppLink>
                      <AppLink to='#'>Cookie Policy</AppLink>
                    </S.LibLinks>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </S.CustomAccordion3>
            <S.ThemeSection>
              <FaMoon className='active' />
              <FaSun />
            </S.ThemeSection>
            <hr />
            <S.Logo>
              <img src={LogoImg} alt='' className='logo' />
            </S.Logo>
            <S.CopyRText>
              &copy; 2022 Dynamic Swap. All rights
              <br /> reserved
            </S.CopyRText>
            <S.PoweredBy>
              <p>Powered by</p>
              <img src={AtomImg} alt='' />
            </S.PoweredBy>
            <S.CopyRText className='mt-15'>
              Atom Foundation is a Decentralized Financial (DeFi) liquidity ecosystem powered by proprietary protocols which underlines zero volatility platforms, a NFT (Non-fungible token) and
              altcoin marketplace with downside protection, post dex offerings (PDO), a dynamic AMM with multiple swap exchanges, cross network aggregation, as well as a CBDC alternative and
              price support game theoretic model.
            </S.CopyRText>
          </Scrollbars>
        </S.MenuBg>
      </Collapse>
    </S.MenuRight>
  )
}

export default AppSidebar
