import { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Connectors, BinanceWalletNetworkIds } from 'Connectors'
import Web3Utils from 'web3-utils'
import useAppSnackbar from './useAppSnackbar'
import { LOCAL_STORAGE_ITEM_NAMES } from 'app-constants'
import { SupportedNetworks, WalletConnectorTypes } from 'types'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'

interface UseWalletReturnType extends Web3ReactContextInterface {
  balance: string | number
  connectWallet: (_type: WalletConnectorTypes) => void
  switchNetwork: (targetChain: SupportedNetworks) => void
  disconnectWallet: () => void
}

const useWallet = (): UseWalletReturnType => {
  const { active, activate, chainId, error, account, library, deactivate, ...web3React } = useWeb3React()
  const [openSnackbar] = useAppSnackbar()
  const [balance, setBalance] = useState<string | number>(0)

  const connectWallet = useCallback(
    async (type: WalletConnectorTypes) => {
      const connector = Connectors[type]
      try {
        // TODO need to check reconnect wallet after reopen our page.
        // if (typeof connector?.isAuthorized === 'function') {
        //   await connector.isAuthorized()
        // }
        await activate(connector)
        localStorage.setItem(LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE, type.toString())
      } catch (error) {
        console.error(error)
      }
    },
    [activate]
  )

  const switchBinanceWalletNetwork = useCallback(
    async (chainId: string) => {
      const iChainId = parseInt(chainId) as SupportedNetworks
      await window.BinanceChain?.switchNetwork(BinanceWalletNetworkIds[iChainId])
    },
    []
  )
  const switchMetaMaskWalletNetwork = useCallback(
    async (chain: SupportedNetworks) => {
      await library.provider?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3Utils.toHex(chain) }],
      })
    },
    [library?.provider]
  )

  const switchNetwork = useCallback(
    async (chain: SupportedNetworks) => {
      const savedType = localStorage.getItem(LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE)
      let connectedType: WalletConnectorTypes
      if (savedType) {
        connectedType = parseInt(savedType) as WalletConnectorTypes
      } else {
        connectedType = WalletConnectorTypes.METAMASK
      }

      const chainId = Web3Utils.toHex(chain)

      switch (connectedType) {
        case WalletConnectorTypes.BINANCE_CHAIN: // works for binance wallet
          await switchBinanceWalletNetwork(chainId)
          break

        default: // works for metamask and math walelt
          await switchMetaMaskWalletNetwork(chain)
          break
      }
    },
    [switchBinanceWalletNetwork, switchMetaMaskWalletNetwork]
  )

  const disconnectWallet = useCallback(() => {
    try {
      deactivate()
      localStorage.removeItem(LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE)
    } catch (error: any) {
      console.error(error.message)
    }
  }, [deactivate])

  // // refresh balance
  const refreshBaseTokenBalance = useCallback(() => {
    library
      ?.getBalance(account)
      .then((_balance: any) => {
        const balanceFromWei = Web3Utils.hexToNumberString(_balance._hex)
        setBalance(Web3Utils.fromWei(balanceFromWei))
      })
      .catch((err: any) => {
        console.error(err)
        setBalance(0)
      })
  }, [library, account])

  useEffect(() => {
    if (error) {
      openSnackbar(error.message)
    }
    // disabled for  openSnackbar
    // eslint-disable-next-line
  }, [error])

  useEffect(() => {
    if (account) {
      localStorage.setItem('account', account) // saving it for old functions
    } else {
      localStorage.removeItem('account') // removing it for old functions
    }
  }, [account])

  useEffect(() => {
    refreshBaseTokenBalance()
    library?.on('block', refreshBaseTokenBalance)
    return () => {
      library?.off('block', refreshBaseTokenBalance)
    }
  }, [library, refreshBaseTokenBalance])

  return {
    active,
    error,
    account,
    library,
    chainId,
    balance,
    deactivate,
    ...web3React,
    connectWallet,
    switchNetwork,
    activate,
    disconnectWallet,
  }
}

export default useWallet
