import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import Modal, { PPstitle02, PopInput01 } from '../Modal'
import Media from '../../Theme/media-breackpoint'

import CustomScrollbars from 'Component/core/elements/CustomScrollbars'
import TokenIcon from 'Component/TokenIcon'
import { PPClosBTN01 } from 'Component/core/elements/Buttons'
import { FlexDiv, ModalBox } from 'Component/core/elements/others/global-components'
import { useAppChain, useOnScreen, usePairs } from 'hooks'
import { DEREX_SUBGRAPH } from 'app-constants'
import { DebounceInput } from 'react-debounce-input'
import { DexInfo, PairToken } from 'types'
import AppTooltip from 'Component/AppTooltip'

interface ISelectDexPairTokenProps {
  isOpen: boolean
  dismiss: () => void
  onSelect: (pairToken: PairToken) => void
  options?: {
    dex?: DexInfo
    pairsWith?: string
  }
}

function SelectDexPairToken({ isOpen, dismiss, onSelect, options = {} }: ISelectDexPairTokenProps) {
  const { appChainId } = useAppChain()
  const theGraphUri = options.dex?.theGraphUri || DEREX_SUBGRAPH[appChainId]
  const pairsWith = useMemo(() => options.pairsWith, [options.pairsWith])
  const [pairs, fetchMorePairs, search, setSearch] = usePairs(theGraphUri)

  const [loadMoreRequired, lastRef] = useOnScreen({ threshold: 0.1 })

  const onClickPairItem = useCallback(
    (pairToken: PairToken) => {
      onSelect(pairToken)
    },
    [onSelect]
  )

  useEffect(() => {
    if (loadMoreRequired) {
      fetchMorePairs()
    }
    // disabled for fetchMorePairs
    // eslint-disable-next-line
  }, [loadMoreRequired])

  useEffect(() => {
    if (pairsWith) {
      setSearch(pairsWith)
    } else {
      setSearch('')
    }
  }, [pairsWith, setSearch])

  return (
    <Modal isOpen={isOpen} dismiss={dismiss}>
      <PPMainBx>
        <PPsBx01 className='position-relative'>
          <PPClosBTN01 className='position-absolute right-10 top-10' onClick={dismiss} />
          <PPstitle01 className='d-flex justify-content-between align-items-center'>
            <h4>Choose liquidity pool</h4>
          </PPstitle01>
          {/* @ts-ignore */}
          <PopInput01 as={DebounceInput} element='input' debounceTimeout={400} placeholder='Search name(exam: WETH-USDT) or past address' value={search} onChange={(e) => setSearch(e.target.value)} />
        </PPsBx01>
        <PPstitle02 className='pl-30'>
          <span>Pool name</span>
        </PPstitle02>
        <CustomScrollbars
          height='320px'
          width='100%'
          style={{ position: 'relative' }}
          trackClass='black'
          renderView={({ style, ...props }: any) => <div style={{ ...style, marginRight: 0, marginBottom: 0 }} {...props} className='view scrollbar-none' />}
        >
          <div className='d-flex flex-column pb-20'>
            {pairs.map((pair, index) => (
              <PairTokenItem key={index} className='py-10 pl-30' onClick={() => onClickPairItem(pair)}>
                <div className='d-flex align-items-center'>
                  <TokenIcon className='md-1-border' byAddress={pair.token0.address} />
                  <span className='ml-5 fs-md'>{pair.token0.symbol}</span>
                  &nbsp;{' <> '}&nbsp;
                  <TokenIcon className='md-1-border' byAddress={pair.token1.address} />
                  <span className='ml-5 fs-md'>{pair.token1.symbol}</span>
                </div>
              </PairTokenItem>
            ))}
            <div ref={lastRef} />
          </div>
        </CustomScrollbars>
      </PPMainBx>
      <AppTooltip />
    </Modal>
  )
}

export default SelectDexPairToken

const PPMainBx = styled(ModalBox)`
  max-width: 450px;
  min-width: 450px;
  margin: 0 auto;
  ${Media.lg} {
    min-width: 400px;
  }
  ${Media.md} {
    min-width: auto;
  }
  ${Media.xs} {
    width: auto;
    margin: 0px 15px;
    max-width: 100%;
  }
`

const PPsBx01 = styled(FlexDiv)`
  width: 100%;
  padding: 30px 30px 10px 30px;
`

const PPstitle01 = styled(FlexDiv)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0 12px 0;
  position: relative;
  color: white;
`

const PairTokenItem = styled.button`
  border-bottom: 1px solid ${({ theme }) => theme.bodybg04};
  color: white;
  &:hover {
    color: ${(props) => props.theme.colorPrimary};
  }
`
