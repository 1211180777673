import { useEffect, useRef } from 'react'

const { REACT_APP_BOT_ID } = process.env;

interface IBotSignInButttonProps {
  radius: number;
  dataAuthUrl: string;
  size?: 'small' | 'medium' | 'large'
}

function BotSignInButtton({ radius, dataAuthUrl, size = 'small' }: IBotSignInButttonProps) {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (ref.current) {
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://telegram.org/js/telegram-widget.js?19'
      script.setAttribute('data-telegram-login', REACT_APP_BOT_ID as string)
      script.setAttribute('data-size', size)
      if (typeof radius !== 'undefined' && typeof radius === 'number') {
        script.setAttribute('data-radius', String(radius))
      }
      script.setAttribute('data-auth-url', dataAuthUrl)
      script.setAttribute('data-request-access', 'write')

      ref.current.appendChild(script)
    }
  }, [ref, size, radius])

  return <div ref={ref}></div>
}

export default BotSignInButtton
