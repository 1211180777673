import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { BsQuestionCircleFill } from 'react-icons/bs'
import NumberFormat from 'react-number-format'
import { isAddress } from 'web3-utils'

import Modal from 'Component/Modal'
import { ModalBox } from 'Component/core/elements/others/styled'
import Media from 'Theme/media-breackpoint'
import { AppButton, PPClosBTN01 } from 'Component/core/elements/Buttons'
import { InputGroup, InputGroupControl, InputGroupText } from 'Component/core/elements/InputGroup'
import PeriodPicker from 'Component/core/elements/PeriodPicker'
import { NETWORKS, TimeOptions } from 'app-constants'
import { RadioButton } from 'Component/core/elements/RadioButton'
import tooltip from 'app-constants/tooltip-texts'
import { AppVaildate, BaseToken, TimeOption } from 'types'
import { useAppChain } from 'hooks'
import Web3 from 'web3'
import Web3Helpers from 'helper/Web3Helpers'
import useWallet from 'hooks/useWallet'
import { decimalAmountToExactAmount } from 'helper/math'
import { TokenBalanceSpan } from 'Component/core/elements/others/global-components'
import AppTooltip from 'Component/AppTooltip'
import AppDatePicker from 'Component/core/elements/DatePicker/DatePicker'
import SelectTokenPopup from './SelectTokenPopup'

interface IAddNewRewardPopupProps {
  isOpen: boolean
  onSubmit: (values: RewardsFormDataType) => void
  dismiss: () => void
}

enum Positions {
  none = '0',
  one = '1',
  two = '2',
  three = '3'
}

export type RewardsFormDataType = {
  rewardsTokenInfo: {
    name: string;
    symbol: string;
    address: string;
    decimals: string;
    balance: number;
  }
  supplyOfRewards: number;
  rewardsPeriod: number;
  rewardsPeriodOption: TimeOption;
  hasDumperShield: boolean;
  protectionPeriod: EpochTimeStamp; // timestamp
  position: Positions;
}

function AddNewRewardPopup({ isOpen, dismiss, onSubmit }: IAddNewRewardPopupProps) {
  const { appChainId } = useAppChain()
  const { account } = useWallet()
  const network = useMemo(() => {
    return NETWORKS[appChainId]
  }, [appChainId])

  const [isOpenSelectTokenPopup, setIsOpenSelectTokenPopup] = useState(false);

  const [invalidRewardsToken, setInvalidRewardsToken] = useState<AppVaildate>({
    isInvalid: true,
    message: '',
  })
  const [invalidSupply, setInvalidSupply] = useState<AppVaildate>({
    isInvalid: true,
    message: '',
  })
  const [invalidRewardsPeriod, setInvalidRewardsPeriod] = useState<AppVaildate>({
    isInvalid: true,
    message: '',
  })
  
  const [rewardsTokenAddress, setRewardsTokenAddress] = useState<string>('')
  const [supplyOfRewards, setSupplyOfRewards] = useState<string | number>(''); // bigdecimals
  const [rewardsPeriod, setRewardsPeriod] = useState<string | number>('');
  const [rewardsPeriodOption, setRewardsPeriodOption] = useState<TimeOption>(TimeOptions[1]);
  const [rewardsTokenBalance, setRewardsTokenBalance] = useState<string | number>('');
  const [hasDumperShield, setHasDumperShield] = useState<boolean>(false);
  const [position, setPosition] = useState<Positions>(Positions.one);
  const [rewardsToken, setRewardsToken] = useState({
    name: '',
    symbol: '',
    address: '',
    decimals: '',
  })
  const [protectionPeriod, setProtectionPeriod] = useState<EpochTimeStamp>(Date.now());

  const handleRewardTokenChange = async(_token: string) => {
    setRewardsTokenAddress(_token);
    setInvalidRewardsToken({
      isInvalid: false,
      message: '',
    })
    if(isAddress(_token)) {
      const web3 = new Web3(network.HTTP_PROVIDER_URL);
      const web3Helpers = new Web3Helpers(web3, account);
      const _tokenInfo = await web3Helpers.getTokenInfo(_token, account as string);
      const _tokenDecimals = _tokenInfo.decimals || '18';
      setRewardsToken({
        name: _tokenInfo.name,
        symbol: _tokenInfo.symbol || 'Unknown',
        address: _token,
        decimals: _tokenDecimals,
      })
      setRewardsTokenBalance(decimalAmountToExactAmount(_tokenInfo.balance, _tokenDecimals))
    } else {
      setRewardsToken({
        name: '',
        symbol: '',
        address: '',
        decimals: '',
      })
      setRewardsTokenBalance('')
    }
  }

  const handleOnChangeRewardsTokenAddress = async(e: React.ChangeEvent<HTMLInputElement>) => {
    const _tokenAddress = e.target.value;
    handleRewardTokenChange(_tokenAddress)
  }
  const handleOnChangeDumperShield = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _hasDumperShield = e.target.value === '1';
    setHasDumperShield(_hasDumperShield)
  }
  const handleOnChangePositions = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPosition(e.target.value as Positions)
  }
  const handleOnClickMax = () => {
    setSupplyOfRewards(rewardsTokenBalance)
  }
  const handleOnChangeProtectionPeriod = (_date: Date) => {
    setProtectionPeriod(_date.getTime())
  }
  const formValidate = (): boolean => {
    // data validation section start
    if(!rewardsToken.address) {
      setInvalidRewardsToken({
        isInvalid: true,
        message: 'Please input rewards token address!!!',
      })
      return false;
    }
    if(+supplyOfRewards > +rewardsTokenBalance) {
      setInvalidSupply({
        isInvalid: true,
        message: 'insufficient token balance!!!',
      })
      return false;
    }
    if(!rewardsPeriod) {
      setInvalidRewardsPeriod({
        isInvalid: true,
        message: 'Please input invalid period!!!'
      })
      return false;
    }
    return true;
    // data validation section end
  }
  const handleOnClickAddNew = () => {
    const isValid = formValidate();
    if(isValid) {
      onSubmit({
        rewardsTokenInfo: {
          name: rewardsToken.name,
          symbol: rewardsToken.symbol,
          address: rewardsToken.address,
          decimals: rewardsToken.decimals,
          balance: +rewardsTokenBalance,
        },
        supplyOfRewards: +supplyOfRewards,
        rewardsPeriod: +rewardsPeriod,
        rewardsPeriodOption: rewardsPeriodOption,
        hasDumperShield: hasDumperShield,
        protectionPeriod: protectionPeriod,
        position: position
      })
    }
  }

  const handleOnSelectToken = (token: BaseToken) => {
    handleRewardTokenChange(token.address)
    setIsOpenSelectTokenPopup(false)
  }

  return (
    <Modal isOpen={isOpen} dismiss={dismiss}>
      <PPMainBx className='position-relative'>
        <PPClosBTN01 className='position-absolute right-10 top-10' onClick={dismiss} />
        <div className='p-30'>
          <Title>Add New Rewards</Title>
          <p className='text-muted text-center'>
            Your tokens will be distributed among the bondholders
            <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
          </p>

          <div className='mt-20 d-flex flex-column gap-5 py-20'>
            <div className='row align-items-center'>
              <div className='col-4'>
                <span className='text-nowrap'>
                  Add rewards token <br /> address
                  <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                </span>
              </div>
              <div className='col-8'>
                <InputGroup className={invalidRewardsToken.isInvalid ? 'danger' : ''}>
                  <InputGroupControl type='text' value={rewardsTokenAddress} onChange={handleOnChangeRewardsTokenAddress} />
                  <RewardSymbolText onClick={() => setIsOpenSelectTokenPopup(true)} className='text-uppercase fw-bold'>{rewardsToken.symbol || '[Token]'}</RewardSymbolText>
                </InputGroup>
              </div>
            </div>
            <div className='row'>
              <div className='col-4'>
                <div className='mt-15'>
                  <span className='text-nowrap'>
                    Choose supply of<br /> rewards
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                  </span>
                </div>
              </div>
              <div className='col-8'>
                <InputGroup className={invalidSupply.isInvalid ? 'danger' : ''}>
                  <NumberFormat className='py-12' displayType={'input'}
                    value={supplyOfRewards}
                    onValueChange={({ value, floatValue }) => {
                      setInvalidSupply({
                        isInvalid: false,
                        message: '',
                      })
                      setSupplyOfRewards(floatValue || value)
                    }}
                    thousandSeparator
                    isAllowed={({ value }) => {
                      return value <= rewardsTokenBalance;
                    }}
                  />
                </InputGroup>
                <div className='d-flex w-full justify-content-end'>
                  <AppButton className='no-fill fs-sm fw-normal w-auto py-2'
                    onClick={handleOnClickMax}
                  >
                    <span className='text-muted'>Balance:&nbsp; </span>
                    <span className='text-muted'><TokenBalanceSpan digits={4}>{ rewardsTokenBalance }</TokenBalanceSpan></span>
                    &nbsp; <span className='text-primary'>MAX</span>
                  </AppButton>
                </div>
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-4'>
                <span className='text-nowrap'>
                  Choose reward period
                  <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                </span>
              </div>
              <div className='col-8'>
                <InputGroup className={invalidRewardsPeriod.isInvalid ? 'danger' : ''}>
                  <NumberFormat className='py-12' displayType={'input'}
                    value={rewardsPeriod}
                    onValueChange={({ value, floatValue }) => {
                      setInvalidRewardsPeriod({
                        isInvalid: false,
                        message: ''
                      })
                      setRewardsPeriod(floatValue || value)
                    }}
                  />
                  <InputGroupText className='py-4'>
                    <PeriodPicker label={rewardsPeriodOption.label} options={TimeOptions} onClickOption={(e, _selectedOption) => setRewardsPeriodOption(_selectedOption)} />
                  </InputGroupText>
                </InputGroup>
              </div>
            </div>
            <div className='row'>
              <div className='col-4'>
                <div className='mt-5'>
                  <span className='text-nowrap'>
                    Dumper shield
                    <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Dumper Shield for users')} />
                  </span>
                </div>
              </div>
              <div className='col-8 d-flex flex-column gap-5'>
                <div className='row'>
                  <div className='col'>
                    <RadioButton
                      id='dumper-sheild-Yes'
                      name='dumper-sheild'
                      className='mr-5 d-flex align-items-center'
                      label={
                        <label htmlFor='dumper-sheild-Yes' className='ml-10 pt-5'>Yes</label>
                      }
                      value='1'
                      checked={hasDumperShield === true}
                      onChange={handleOnChangeDumperShield}
                    />
                  </div>
                  <div className='col'>
                    <RadioButton
                      id='dumper-sheild-No'
                      name='dumper-sheild'
                      className='mr-5 d-flex align-items-center'
                      label={
                        <label htmlFor='dumper-sheild-No' className='ml-10 pt-5'>No</label>
                      }
                      value='0'
                      checked={hasDumperShield === false}
                      onChange={handleOnChangeDumperShield}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <span className='text-nowrap fw-bold'>
                      Protection period
                      <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                    </span>
                  </div>
                  <div className='col-6'>
                    <AppDatePicker className='text-white w-auto' dateFormat='MMMM d, yyyy' selected={protectionPeriod} onChange={handleOnChangeProtectionPeriod} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <span className='text-nowrap fw-bold'>
                      Positions
                      <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={'Coming Soon'} />
                    </span>
                  </div>
                  <div className='col row'>
                    <div className='col pl-0'>
                      <RadioButton
                        id='positions-1st'
                        name='positions'
                        className='mr-5 d-flex align-items-center'
                        label={
                          <label htmlFor='positions-1st' className='ml-10 pt-5'>1st</label>
                        }
                        value={Positions.one}
                        checked={position === Positions.one}
                        onChange={handleOnChangePositions}
                      />
                    </div>
                    <div className='col'>
                      <RadioButton
                        id='positions-2st'
                        name='positions'
                        className='mr-5 d-flex align-items-center'
                        label={
                          <label htmlFor='positions-2st' className='ml-10 pt-5'>2nd</label>
                        }
                        value={Positions.two}
                        checked={position === Positions.two}
                        onChange={handleOnChangePositions}
                      />
                    </div>
                    <div className='col'>
                      <RadioButton
                        id='positions-3st'
                        name='positions'
                        className='mr-5 d-flex align-items-center'
                        label={
                          <label htmlFor='positions-3st' className='ml-10 pt-5'>3rd</label>
                        }
                        value={Positions.three}
                        checked={position === Positions.three}
                        onChange={handleOnChangePositions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AppButton className='outline-primary mt-40' onClick={handleOnClickAddNew}>Add New Rewards</AppButton>
        </div>
      </PPMainBx>
      <AppTooltip />
      <SelectTokenPopup
        isOpen={isOpenSelectTokenPopup}
        dismiss={() => setIsOpenSelectTokenPopup(false)}
        onSelectToken={handleOnSelectToken}
        onlyTokensWithBalance
      />
    </Modal>
  )
}

const PPMainBx = styled(ModalBox)`
  max-width: 700px;
  min-width: 700px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 20px;
  ${Media.lg} {
    min-width: 700px;
  }
  ${Media.md} {
    min-width: auto;
  }
  ${Media.xs} {
    width: auto;
    margin: 0px 15px;
    max-width: 100%;
  }
`

const Title = styled.h1`
  font-weight: bold;
  font-family: var(--dx-ff-secondary);
  font-size: ${({ theme }) => theme.fontSize['2xl']};
  text-align: center;
`
const RewardSymbolText = styled(InputGroupText)`
  cursor: pointer;
  &:hover {
    color: ${({theme}) =>  theme.colorPrimary};
  }
`

export default AddNewRewardPopup
