import { RootState } from 'redux/store'
import { createSelector } from 'reselect'

const selectLiquidity = (state: RootState) => state.liquidity
const selectTokenList = (state: RootState) => state.tokenList
const selectImportedPools = (state: RootState) => state.importedPools
const selectGlobals = (state: RootState) => state.globals
const selectCoingecko = (state: RootState) => state.coingecko
const selectBond = (state:RootState) => state.bond
const selectBondDetail = (state:RootState) => state.bondDetail
const selectBondList = (state: RootState) => state.bondList
const selectLPInsure = (state:RootState) => state.lpInsure
const selectLPInsureList = (state:RootState) => state.lpInsureList
const selectVotes = (state: RootState) => state.votes
const selectTax = (state: RootState) => state.tax
const selectSettings = (state: RootState) => state.settings
const selectTour = (state: RootState) => state.tour

export const LiquiditySelector = createSelector(selectLiquidity, (state) => state)
export const LPUserInfoSelector = createSelector(selectLiquidity, (state) => state.userInfo)
export const LiquidityTokenASelector = createSelector(selectLiquidity, (state) => state.tokenA)
export const LiquidityTokenBSelector = createSelector(selectLiquidity, (state) => state.tokenB)
export const TokenImagesSelector = createSelector(selectTokenList, (state) => state.tokenImages)
export const TokenListSelector = createSelector(selectTokenList, (state) => state)
export const ImportedPoolsSelector = createSelector(selectImportedPools, (state) => state)
export const UserAddedLiquidityPositionsSelector = createSelector(selectImportedPools, (state) => state.userAddedLiquidityPositions)
export const GlobalsSelector = createSelector(selectGlobals, (state) => state)
export const AppThemeConfigSelector = createSelector(selectGlobals, (state) => state.themeConfig)
export const TransactionModalSelector = createSelector(selectGlobals, (globals) => globals.transactionModal)
export const AvailableTokensSelector = createSelector(selectGlobals, (globals) => globals.availableTokens)
export const FaildTransactionModalSelector = createSelector(selectGlobals, (globals) => globals.failedT_ModalStatus)
export const AppChainIdSelector = createSelector(selectGlobals, (globals) => globals.appChainId)
export const CoingeckoSelector = createSelector(selectCoingecko, (coingecko) => coingecko)
export const SimplePriceSelector = createSelector(selectCoingecko, (coingecko) => coingecko.simplePrices)
export const BondSelector = createSelector(selectBond, (state) => state)
export const BondDetailSelector = createSelector(selectBondDetail, (state) => state)
export const BondTabSelector = createSelector(selectBond, (state) => state.tab)
export const BondListSelector = createSelector(selectBondList, (state) => state)
export const BondList_ListSelector = createSelector(selectBondList, (state) => state.list)
export const BondList_InvestLengthIn24hrsSelector = createSelector(selectBondList, (state) => state.investLengthIn24hrs)
export const VotesSelector = createSelector(selectVotes, (state) => state)
export const SelectedBallotIdSelector = createSelector(selectVotes, (state) => state.selectedBallotId)
export const ActivedBallotsSelector = createSelector(selectVotes, (state) => state.activedBallots)
export const TaxSelector = createSelector(selectTax, (state) => state)
export const SettingsSelector = createSelector(selectSettings, (state) => state)
export const TourSelector = createSelector(selectTour, (state) => state)

export const LPInsureSelector = createSelector(selectLPInsure, (state) => state)
export const LPInsureListSelector = createSelector(selectLPInsureList, (state) => state)
