import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import Gs from '../Theme/globalStyles'
import { MainTitle, MainDesc, PageTitleGroup } from 'Component/core/elements/Text'
import ReimbursementTable from '../Component/ReimbursementTable/index'

import { App2Context } from '../App2ContextProvider'

function ReimbursementPage() {
  const app2 = useContext(App2Context)

  return (
    <>
      <Helmet
        style={[
          {
            cssText: `
                    .MainBox{background-image:none;}
                `,
          },
        ]}
      />
      <Gs.Container>
        <PageTitleGroup>
          <MainTitle>Reimbursement Staking Contract</MainTitle>
          <MainDesc>
            Stake tokens to claim reimbursement for gas and fees. You can unstake at anytime with no penalty and the remaining will stay pending for future claiming
            <i className='fas helpIco fa-question-circle' data-tip='Content Coming Soon'></i>
          </MainDesc>
        </PageTitleGroup>
        <ReimbursementTable
          balance={app2.balance}
          balances={app2.balances}
          // setting={app2.setting}
          unstakingAmounts={app2.unstakingAmounts}
          stakingTableDatas={app2.stakingTableDatas}
          // connectWallet={() => {
          //     app2.connectWallet();
          // }}
          getSetting={() => {
            app2.getSetting()
          }}
        />
      </Gs.Container>
    </>
  )
}
// Common Style Div

export default ReimbursementPage
