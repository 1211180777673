import { EventEmitter } from 'events'
import { ethers } from 'ethers'
import Web3Utils from 'web3-utils'
import reimbursementStakingAbi from '../abi/reimbursementStakingAbi.json'
import constantConfig from '../config/constantConfig'
import { ReceiptCb, SupportedNetworks, TxCb } from 'types'
import { TransactionRequest, Web3Provider } from '@ethersproject/providers'
import { Deferrable } from 'ethers/lib/utils'

class ReimbursementStakingContract extends EventEmitter {
  web3
  networkId
  reimbursementStakingContractAddress
  reimbursementStakingInstance
  constructor(web3: Web3Provider, networkId: SupportedNetworks) {
    super()
    this.web3 = web3
    this.networkId = networkId
    this.reimbursementStakingContractAddress = constantConfig[networkId].reimbursementStakingContract
    // TODO need to check type web3.getSigner
    // @ts-ignore
    this.reimbursementStakingInstance = new ethers.Contract(this.reimbursementStakingContractAddress, reimbursementStakingAbi, web3.getSigner(0))
  }

  async reimbursementStaking(vaultAddress: string, amount: number, txCb: TxCb, successCb: ReceiptCb) {
    const payload = await this.reimbursementStakingInstance.populateTransaction.stake(vaultAddress, amount)
    this.sendTransaction(payload.data as string, 0, '350000', this.reimbursementStakingContractAddress, txCb, successCb)
  }

  async reimbursementStakingWithdraw(vaultAddress: string, txCb: TxCb, successCb: ReceiptCb) {
    const payload = await this.reimbursementStakingInstance.populateTransaction.unstake(vaultAddress)
    this.sendTransaction(payload.data as string, 0, '350000', this.reimbursementStakingContractAddress, txCb, successCb)
  }

  async sendTransaction(payload: string, value: number, gasLimit: string, to: string, txCb: TxCb, receiptCb: ReceiptCb) {
    let gasPrice = '0'
    if (this.networkId === 56 || this.networkId === 97) gasPrice = '20'
    else {
      const response = await fetch('https://ethgasstation.info/json/ethgasAPI.json')
      const json = await response.json()
      gasPrice = (json.fast / 10).toString()
    }

    const tx: Deferrable<TransactionRequest> = {
      to: to,
      data: payload,
      gasPrice: Web3Utils.toHex(Web3Utils.toWei(gasPrice, 'gwei')),
      gasLimit: Web3Utils.toHex(gasLimit),
      value: Web3Utils.toHex(value),
    }

    this.web3
      .getSigner(0)
      .sendTransaction(tx)
      .then((result) => {
        txCb(result.hash)
        result.wait().then(async (receipt) => {
          receiptCb(receipt)
        })
      })
      .catch((error) => {
        console.log(error);
      })
  }

  handleActions(action: any) {
    switch (action.type) {
      default:
        break
    }
  }
}

export default ReimbursementStakingContract
