/* eslint-disable */
import ReactDatePicker from 'react-datepicker'
import { FaCalendarAlt } from 'react-icons/fa'
import * as S from './styled'
import 'react-datepicker/dist/react-datepicker.css'

function AppDatePicker({ dateFormat = 'MMMM d, yyyy', parentClassName = '', ...props }) {
  return (
    <S.AppDatePickerDiv className={parentClassName}>
      <FaCalendarAlt className='mr-5' />
      {/* @ts-ignore */}
      <ReactDatePicker dateFormat={dateFormat} {...props} />
    </S.AppDatePickerDiv>
  )
}

export default AppDatePicker
