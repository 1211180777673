import { DEFAULT_SLIPPAGE, LOCAL_STORAGE_ITEM_NAMES, TRANSACTION_SPEED } from 'app-constants'
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'helper/localStorage'
import { SettingReducerState } from 'types'
import { ActionSetting, ActionTypes } from './types'

const initialState: SettingReducerState = {
  multihops: false,
  slippage: DEFAULT_SLIPPAGE,
  speed: TRANSACTION_SPEED.STANDARD,
  deadline: 20, // mins// transaction deadline
}

export default function settingsReducer(state = initialState, action: ActionSetting): SettingReducerState {
  let newState, settings, _value
  switch (action.type) {
    case ActionTypes.TOGGLE_MULTIHOPS:
      newState = { ...state, multihops: !state.multihops }
      saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.SETTINGS, newState)
      return newState
    case ActionTypes.SET_SLIPPAGE:
      _value = +action.payload
      if (_value > 100) {
        _value = 100
      } else if (_value < 0 || _value === 0) {
        _value = DEFAULT_SLIPPAGE
      }
      newState = { ...state, slippage: _value }
      saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.SETTINGS, newState)
      return newState
    case ActionTypes.SET_DEADLINE:
      newState = { ...state, deadline: action.payload }
      saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.SETTINGS, newState)
      return newState
    case ActionTypes.SET_SPEED:
      newState = { ...state, speed: action.payload }
      saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.SETTINGS, newState)
      return newState
    default:
      settings = loadStateFromLocalStorage(LOCAL_STORAGE_ITEM_NAMES.SETTINGS)
      if (settings) {
        state = settings
      } else {
        saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.SETTINGS, state)
      }
      return state
  }
}
