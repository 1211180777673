import styled from 'styled-components'

export const Dropdown = styled.div`
  & > button {
    &:hover {
      color: ${({ theme }) => theme.colorPrimary};
    }
    font-size: ${({ theme }) => theme.fontSize.md};
    color: ${({ theme }) => theme.colorLightGrey};
  }
  .dropdown-item {
    font-size: ${({ theme }) => theme.fontSize.md};
    color: ${({ theme }) => theme.colorLightGrey};

    &.active {
      background-color: unset;
    }
    &:hover {
      color: ${({ theme }) => theme.colorPrimary};
    }
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: unset;
  }
  // theme
  &.dark {
    & > .dropdown-toggle {
      padding: 10px 15px;
      color: white;
      font-size: ${({theme}) => theme.fontSize.md };
      background-color: ${({theme}) => theme.bodybg03};
    }
    & > .dropdown-menu {
      padding: 10px 15px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      color: white;
      font-size: ${({theme}) => theme.fontSize.md };
      background-color: ${({theme}) => theme.bodybg03};
    }
  }
`

export const DropdownMenu = styled.ul`
  background-color: ${(props) => props.theme.greyBorder};

  & > .dropdown-item {
    color: white;
    font-size: 15px;
    padding: 5px;
    &:hover {
      color: ${(props) => props.theme.colorPrimary};
      background-color: unset;
      cursor: pointer;
    }
  }
`
