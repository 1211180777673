import React, { useMemo, useState, useCallback, useContext } from "react";
import { MdSubdirectoryArrowRight } from "react-icons/md";
import { FaSort } from "react-icons/fa";
import styled from "styled-components";
import DataTable, { ExpanderComponentProps, TableColumn, TableStyles } from "react-data-table-component";

import { AppButton } from "Component/core/elements/Buttons";
import { BondHolderRowType } from "../BondHoldersTabContent";
import { TokenBalanceSpan } from "Component/core/elements/others/global-components";
import AppLoadingSpinner from "Component/AppLoadingSpinner";
import { useAppChain, useFailedInfoModal, useProcessingModal } from "hooks";
import { useAppDispatch } from "redux/hooks";
import { harvestFarms } from "redux/reducers/bond/actions";
import { decimalAmountToExactAmount } from "helper/math";
import BondSubgraph from "helper/subgraphs/BondSubgraph";
import { numberFormater } from "helper";
import useAppSnackbar, { AppWaringOptions } from "hooks/useAppSnackbar";
import useAppDebounceEffect from "hooks/useAppDebounceEffect";
import { Farm } from "types/subgraphs/farms";
import { RoiPageContext } from "Pages/RoiBoosterPage/Contexts";

type BondHolderSubDataType = {
  name: string;
  farmId: string;
  rewardToClaim: string | number;
  tokenValueUSD: string | number;
  tokenRewardValueUSD: string | number;
  toDumperShield: boolean;
}

const customStyles: TableStyles = {
  table: {
    style: {
      backgroundColor: 'transparent',
      "& > div": {
        backgroundColor: 'transparent',
      }
    }
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      color: '#8e9196',
      background: 'transparent',
      borderTopWidth: '1px',
      borderBottomWidth: '1px',
      borderColor: '#8e9196',
      height: '50px',
      fontFamily: 'var(--dx-ff-primary)'
    },
  },
  headCells: {
    style: {
      fontSize: '15px',
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        color: '#8e9196',
        borderRightWidth: '1px',
        borderColor: '#8e9196',
      },
      '.rdt_TableCol_Sortable': {
        justifyContent: 'space-between',
        '& > span:last-child': {
          display: 'none'
        }
      }
    },
  },
  cells: {
    style: {
      background: 'transparent',
      borderRightWidth: '1px',
      '& div:first-child': {
        overflow: 'inherit !important'
      },
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderColor: '#8e9196',
      },
    },
  },
  rows: {
    style: {
      borderRightWidth: '0px',
      height: '50px',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderColor: '#8e9196',
      borderBottomColor: '#8e9196 !important',
      color: '#8e9196',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: '#272a31'
      }
    },
  }
}

interface EProps extends ExpanderComponentProps<any> {
  data: BondHolderRowType;
}

const BondHoldersExpandableRowComponent: React.FC<EProps> = ({ data }) => {
  const { appChainId } = useAppChain()
  const dispatch = useAppDispatch()
  const [openLoadingModal, closeLoadingModal] = useProcessingModal()
  const [openFailedModal] = useFailedInfoModal()
  const [openWSnackbar] = useAppSnackbar(AppWaringOptions)
  const values = useContext(RoiPageContext)

  const [isLoading, setIsLoading] = useState(true)
  const [farms, setFarms] = useState<Farm[]>([])

  const handleOnClickClaim = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
    const farmid: string = e.currentTarget.dataset.farmid as string;
    const rewardtoclaim: string = e.currentTarget.dataset.rewardtoclaim as string;
    if (+rewardtoclaim === 0) {
      return openWSnackbar('You have 0 token to claim.')
    }
    try {
      openLoadingModal('Claim reward tokens....')
      await dispatch(harvestFarms(data.classId, [farmid]))
    } catch (error: unknown) {
      console.log(error)
      // @ts-ignore
      const errorMessage = error.message || error.toString();
      openFailedModal(errorMessage)
    } finally {
      closeLoadingModal()
    }
  }, [data.classId, openWSnackbar])

  const columns = useMemo<TableColumn<BondHolderSubDataType>[]>(
    () => [
      {
        name: <>Rewarded token <FaSort /></>,
        style: {
          color: 'white'
        },
        sortable: true,
        cell: (row) => {
          return <span className="token-name">{row.name}</span>
        },
      },
      {
        name: <>Rewarded to claim <FaSort /></>,
        sortable: true,
        style: {
          color: 'white'
        },
        cell: (row) => {
          return <span>{numberFormater(4).format(+row.rewardToClaim)}</span>
        },
      },
      {
        name: <>Token Value <FaSort /></>,
        sortable: true,
        style: {
          color: 'white'
        },
        cell: (row) => {
          return <TokenBalanceSpan prefix="$" digits={8}>{row.tokenValueUSD}</TokenBalanceSpan>
        },
      },
      {
        name: <>Token Reward Value <FaSort /></>,
        sortable: true,
        style: {
          color: 'white'
        },
        cell: (row) => {
          return <TokenBalanceSpan prefix="$" digits={8}>{row.tokenRewardValueUSD}</TokenBalanceSpan>
        },
      },
      {
        name: <>Dumper Shield restriction <FaSort /></>,
        sortable: true,
        style: {
          color: 'white'
        },
        cell: (row) => {
          return <span>{row.toDumperShield ? 'Yes' : 'No'}</span>
        },
      },
      {
        name: 'Claim rewards',
        center: true,
        cell: (row) => {
          return (
            <AppButton
              className='outline-black hover:outline-primary fs-md text-primary fw-normal py-2 px-60 radius-3 text-nowrap'
              onClick={handleOnClickClaim}
              data-farmid={row.farmId}
              data-rewardtoclaim={row.rewardToClaim}
              title="Claim"
            >Claim</AppButton>
          )
        },
      },
    ],
    [handleOnClickClaim]
  )

  const tableData = useMemo(() => {
    const rows = farms.map(farm => {
      const reward = data.rewards.find(reward => reward.rewardToken.toLowerCase() === farm.rewardToken.address.toLowerCase())
      const rewardToClaim = reward ? decimalAmountToExactAmount(reward.amount, farm.rewardToken.decimals || '18') : '0'
      // todo update token price by usd
      const rewardTokenValueUSD: string | number = reward ? (values.rewardTokenPrices[reward.rewardToken.toLocaleLowerCase()]?.usd || 0) : '0'
      return {
        name: farm.rewardToken.symbol || 'unknown',
        farmId: farm.farmId,
        toDumperShield: farm.toDumperShield,
        rewardToClaim: rewardToClaim,
        tokenValueUSD: rewardTokenValueUSD,
        tokenRewardValueUSD:  +rewardTokenValueUSD * +rewardToClaim,
      }
    });
    return rows;
  }, [farms, data, values.rewardTokenPrices])

  useAppDebounceEffect(() => {
    (async () => {
      const subgraph = new BondSubgraph(appChainId);
      try {
        setIsLoading(true)
        const res = await subgraph.getFarms({ classId: data.classId })
        setFarms(res.data.farms)
      } catch (error) {
        console.log('error: ', error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, 300, [appChainId, data])

  return (
    <>
      <DataTable
        columns={columns}
        data={tableData}
        customStyles={customStyles}
        expandableRows
        dense
        expandableIcon={{ collapsed: <MdSubdirectoryArrowRight size={22} color="white" />, expanded: null }}
        expandableRowDisabled={() => true}
        noDataComponent={<>
          {isLoading ? <AppLoadingSpinner /> : <SNoFarmsText>No farms</SNoFarmsText>}
        </>}
      />
    </>
  );
};

export default BondHoldersExpandableRowComponent;

const SNoFarmsText = styled.div`
  padding: 15px 0;
  color: white;
`
