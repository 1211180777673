export const getNowInSecs = () => Math.floor(Date.now() / 1e3)
export const sleep = async (millisecs: number) => {
  return new Promise((resolve) => {
    return setTimeout(() => resolve(null), millisecs)
  })
}
/**
 * 
 * @param deadLine 
 * @description return deadline in seconds
 * @returns 
 */
export const getDeadLine = (deadLine: number): number => {
  return Math.floor(Date.now() / 1000 + (deadLine * 60))
}

// change milisecs to secs
export const getTimeInSecs = (time: Date | number) => {
  return new Date(time).getTime().toString().slice(0, -3);
}

export const getOfferTime = (date: Date | number, delayInMins = 1) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = d.getMonth();
  const days = d.getDate();
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes() + delayInMins;
  return new Date(year, month, days, hours, minutes);
}