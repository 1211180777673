import React, { useMemo, useState } from "react";
import { MdSubdirectoryArrowRight } from "react-icons/md";
import { FaSort } from "react-icons/fa";
import DataTable, { ExpanderComponentProps, SortFunction, TableColumn, TableStyles } from "react-data-table-component";

import { AppButton } from "Component/core/elements/Buttons";
import { numberFormater } from "helper";
import { RowType } from "../local-types";

type TestType = {
  defaultExpanded: boolean;
  name: string
}

const customStyles: TableStyles = {
  table: {
    style: {
      backgroundColor: 'transparent',
    }
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      color: '#8e9196',
      background: 'transparent',
      borderTopWidth: '1px',
      borderBottomWidth: '1px',
      borderColor: '#8e9196',
      height: '50px',
      fontFamily: 'var(--dx-ff-primary)'
    },
  },
  headCells: {
    style: {
      fontSize: '15px',
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        color: '#8e9196',
        borderRightWidth: '1px',
        borderColor: '#8e9196',
      },
      '.rdt_TableCol_Sortable': {
        justifyContent: 'space-between',
        '& > span:last-child': {
          display: 'none'
        }
      }
    },
  },
  cells: {
    style: {
      background: 'transparent',
      borderRightWidth: '1px',
      '& div:first-child': {
        overflow: 'inherit !important'
      },
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderColor: '#8e9196',
      },
    },
  },
  rows: {
    style: {
      borderRightWidth: '0px',
      height: '50px',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderColor: '#8e9196',
      borderBottomColor: '#8e9196 !important',
      color: '#8e9196',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: '#272a31'
      }
    },
  }
}

interface EProps extends ExpanderComponentProps<RowType> {
  someTitleProp?: string;
}

const ExpandableRowComponent: React.FC<EProps> = (data) => {
  console.log('data: ', data)

  const columns = useMemo<TableColumn<TestType>[]>(
    () => [
      {
        name: <>Rewarded token <FaSort /></>,
        style: {
          color: 'white'
        },
        sortable: true,
        cell: () => {
          return <span className="token-name">DRX</span>
        },
      },
      {
        name: <>Rewarded to claim <FaSort /></>,
        sortable: true,
        style: {
          color: 'white'
        },
        cell: () => {
          return <span>{numberFormater(4).format(Math.random() * 100000)}</span>
        },
      },
      {
        name: <>Token Value <FaSort /></>,
        sortable: true,
        style: {
          color: 'white'
        },
        cell: () => {
          return <span>{numberFormater(4).format(Math.random() * 100000)}</span>
        },
      },
      {
        name: <>Token Value <FaSort /></>,
        sortable: true,
        style: {
          color: 'white'
        },
        cell: () => {
          return <span>{numberFormater(4).format(Math.random() * 100000)}</span>
        },
      },
      {
        name: <>Dumper Shield restriction <FaSort /></>,
        sortable: true,
        style: {
          color: 'white'
        },
        cell: () => {
          return <span>Yes</span>
        },
      },
      {
        name: 'Claim rewards',
        center: true,
        cell: () => {
          return <AppButton className='outline-black hover:outline-primary fs-md text-primary fw-normal py-2 px-60 radius-3'>Claim</AppButton>
        },
      },
    ],
    []
  )
  const [tableData] = useState<TestType[]>(new Array(10).fill(0).map(() => ({ name: '1' } as TestType)))

  // const handleTableSort: SortFunction<TestType> = (rows, selector, direction) => {
  const handleTableSort: SortFunction<TestType> = (rows) => {
    return rows;
  }

  return (
    <>
      <DataTable
        columns={columns}
        data={tableData}
        customStyles={customStyles}
        expandableRows
        dense
        expandableIcon={{ collapsed: <MdSubdirectoryArrowRight size={22} color="white" />, expanded: null }}
        expandableRowDisabled={() => true}
        sortFunction={handleTableSort}
      />
    </>
  );
};

export default ExpandableRowComponent;
