import React, { useState, useEffect, useMemo } from 'react'
import { FaExternalLinkAlt, FaThumbsUp } from 'react-icons/fa'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion'
import Countdown from 'react-countdown'
import fromExponential from 'from-exponential'

import { AppButton } from 'Component/core/elements/Buttons'
import { AmountLabel, Box, Completionist, TokenBalanceSpan, ViewAddress } from 'Component/core/elements/others/global-components'
import AppSlider from 'Component/core/elements/Slider'
import { StatusIcon } from 'Component/core/elements/StatusIcon'
import { Divider } from 'Component/core/elements/Divider'
import { DetailItem, InvestInput } from './elements'
import { getTokenRatio, isEqAddr, withZero } from 'helper'
import AppTimeView from 'Component/core/elements/AppTimeView'
import tooltip from 'app-constants/tooltip-texts'
import { NETWORKS, ZERO_ADDRESS } from 'app-constants'
import { useAppChain, useFailedInfoModal, usePriceBySymbol, useProcessingModal, useWeb3 } from 'hooks'
import AppTooltip from 'Component/AppTooltip'
import * as S from './styled'
import useAppSnackbar, { AppWaringOptions } from 'hooks/useAppSnackbar'
import useWallet from 'hooks/useWallet'
import TokenIcon from 'Component/TokenIcon'
import Web3Helpers from 'helper/Web3Helpers'
import { decimalAmountToExactAmount } from 'helper/math'
import { useAppDispatch } from 'redux/hooks'
import { BondInfoItem } from 'Component/core/elements/IBO-SharedComponents'
import AppLink from 'Component/AppLink'
import { LPInsureData } from 'types'
import { buyLpInsureOffer, getLpInsureParameters, getPoolParams, withdrawProjectTokens } from 'redux/reducers/lp-insure/actions'
import { AppRouters } from 'app-constants/app-routers'
import { reFetchInsureList, voteToOffer } from 'redux/reducers/lp-insure-list/actions'
import { getInsureViewId } from 'helper/insure-utils'
import { GlobalDexes } from 'config/app-config'

interface ILPInsureBondItemProps extends React.HTMLAttributes<HTMLDivElement> {
  bondData: LPInsureData
  active?: boolean
  tourEnable?: boolean
}

function LPInsureBondCard({ bondData, active = true, tourEnable = false, ...props }: ILPInsureBondItemProps) {
  const { appChainId } = useAppChain()
  const w3 = useWeb3()
  const [openWarningSnackbar] = useAppSnackbar(AppWaringOptions)
  const dispatch = useAppDispatch()
  const network = useMemo(() => NETWORKS[appChainId], [appChainId])

  const exchange = useMemo(() => Object.values(GlobalDexes).find(dex => isEqAddr(dex.router, bondData.parameters.router)), [bondData.parameters.router])

  const [investValue, setInvestValue] = useState<string | number>('')

  const [myBondBalance, setMyBondBalance] = useState(0);
  const [parameters, setParameters] = useState<{ supplyAmount: string | number }>({
    supplyAmount: 0,
  })
  const [poolParams, setPoolParams] = useState<{ spentTokens: string | number, totalRaisedPairTokens: string | number }>({
    spentTokens: 0,
    totalRaisedPairTokens: 0
  })

  const {
    id,
    token: { symbol, name, address, decimals },
    pairToken,
    parameters: {
      vestingParam: { prepaymentPenalty },
    },
    poolParams: {
      poolPair,
    },
    initialInvestments
  } = bondData;
  const { yeaVote = 0 } = bondData.info || {}
  const [initialTokenAmount, initialPairAmount] = useMemo<[string | number, string | number]>(() => {
    let _tokenAmt = initialInvestments[0]?.tokenAmount || 0;
    let _pairAmt  = initialInvestments[0]?.pairAmount || 0;
    _tokenAmt     = decimalAmountToExactAmount(_tokenAmt, decimals)
    _pairAmt      = decimalAmountToExactAmount(_pairAmt, pairToken.decimals)

    return [_tokenAmt, _pairAmt]
  }, [initialInvestments])
  const [farmsInfo, totalApy] = useMemo(() => {
    let _totalApy = 0;
    const _farmsInfo = bondData.insureEntity.insureFarms.map((_farm) => {
      const tokenAPY = decimalAmountToExactAmount(+(_farm.rewardPerSecond) * 60 * 24 * 365, _farm.rewardToken.decimals);
      const apy = tokenAPY / +poolPair.totalSupply;
      _totalApy += apy;
      return {
        tokenName: _farm.rewardToken.name,
        apy: apy,
      }
    })
    _totalApy = _farmsInfo.length > 0 ? _totalApy / _farmsInfo.length : 0;
    return [_farmsInfo, _totalApy]
  }, [bondData.insureEntity.insureFarms, poolPair.totalSupply])

  const totalSupply = useMemo(() => {
    return decimalAmountToExactAmount(parameters.supplyAmount, decimals);
  }, [parameters.supplyAmount, decimals])
  const vauleInUsd = usePriceBySymbol(pairToken.symbol)
  const { account } = useWallet()
  const isCreator = useMemo(() => bondData.poolParams.projectWallet && account && isEqAddr(bondData.poolParams.projectWallet, account), [bondData.poolParams.projectWallet, account])
  const supplyAmountWithOutDecimals = useMemo<string>(() => {
    return fromExponential(decimalAmountToExactAmount(+parameters.supplyAmount - +poolParams.spentTokens, decimals))
  },[ parameters.supplyAmount, poolParams.spentTokens, decimals ])

  const totalSold = useMemo(() => {
    return fromExponential(decimalAmountToExactAmount(poolParams.spentTokens, pairToken.decimals))
  }, [ pairToken.decimals, poolParams.spentTokens ])
  
  const totalSoldInPercent = useMemo(() => {
    let _percent: string | number = 0;
    if(totalSupply > 0) {
      _percent = +totalSold / totalSupply * 100;
    }
    _percent = _percent.toFixed(2);
    return fromExponential(_percent)
  }, [ totalSupply, totalSold ])

  const tokenRatio = useMemo(() => {
    return getTokenRatio(address, [poolPair.token0, poolPair.token1], [poolPair.reserve0, poolPair.reserve1])
  }, [address, poolPair.reserve0, poolPair.reserve1, poolPair.token0, poolPair.token1])

  const availableInvestmentAmount = useMemo<number>(() => {
    const maxPayAmount = decimalAmountToExactAmount(bondData.maxAmounts.maxPayAmount, pairToken.decimals);
    const payLimit = Math.min(maxPayAmount, +bondData.parameters.maxInvestment);
    return Math.max(payLimit, maxPayAmount)
  }, [bondData.parameters.maxInvestment, bondData.maxAmounts.maxPayAmount])

  const availableInvestmentAmtUSD = useMemo(() => fromExponential(vauleInUsd * availableInvestmentAmount), [availableInvestmentAmount, vauleInUsd])

  const bondFaceValue = vauleInUsd * tokenRatio

  const remainingAbleAllowInPercent = useMemo<number>(() => {
    const value = (+parameters.supplyAmount - +poolParams.spentTokens) / +parameters.supplyAmount
    return value * 100 || 0
  }, [parameters.supplyAmount, pairToken.decimals, poolParams.spentTokens])

  const myBalanceInDecimals = useMemo(() => {
    return myBondBalance / 10 ** +pairToken.decimals
  }, [myBondBalance, pairToken.decimals])

  const [openFTModal] = useFailedInfoModal()
  const [openLoadingModal, closeLoadingModal] = useProcessingModal()

  const onClickVote = async () => {
    try {
      await dispatch(voteToOffer(+id))
    } catch (error: any) {
      openWarningSnackbar(error.message || String(error))
    }
  }

  const handleOnChangeInvestInput = (value: string | number) => {
    setInvestValue(value)
  }

  const invest = async () => {
    if(!investValue) return openWarningSnackbar('Input amount to invest!');
    try {
      openLoadingModal()
      await dispatch(buyLpInsureOffer(pairToken.address, pairToken.decimals, id, investValue, network.W_TOKEN_ADDRESS))
      await dispatch(reFetchInsureList())
    } catch (error: any) {
      console.error(error)
      openFTModal(error.response?.data || error.message || error)
    } finally {
      closeLoadingModal()
    }
  }

  const handleOnClickWithdraw = async () => {
    try {
      openLoadingModal()
      const availableAmt: number = +parameters.supplyAmount - (+poolParams.spentTokens);
      if (availableAmt > 0) {
        await dispatch(withdrawProjectTokens(id, fromExponential(availableAmt)))
        await dispatch(reFetchInsureList())
      } else {
        openWarningSnackbar('There is no supply!')
      }
    } catch (error: any) {
      console.error(error)
      openFTModal(error.response?.data || error.message || error)
    } finally {
      closeLoadingModal()
    }
  }

  const handleOnClickMaxButton = async () => {
    let tokenAddr = pairToken.address
    if (isEqAddr(network.W_TOKEN_ADDRESS, pairToken.address)) tokenAddr = ZERO_ADDRESS
    const w3Helper = new Web3Helpers(w3, account)

    const bal = await w3Helper.getTokenBalance(tokenAddr)
    const nBal = decimalAmountToExactAmount(bal, pairToken.decimals)
    if (!nBal) {
      openWarningSnackbar('Insufficient token balance')
    } else if (!bondData.parameters.maxInvestment) {
      setInvestValue(nBal)
    } else if (bondData.parameters.maxInvestment && +bondData.parameters.maxInvestment < nBal) {
      setInvestValue(bondData.parameters.maxInvestment)
    } else if (bondData.parameters.maxInvestment && +bondData.parameters.maxInvestment > nBal) {
      setInvestValue(nBal)
    }
  }

  useEffect(() => {
    (async () => {
      setMyBondBalance(0)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      const result = await getPoolParams(id, appChainId);
      setPoolParams(result)
    })();
    (async () => {
      const result = await getLpInsureParameters(id, appChainId);
      setParameters(result)
    })();
  }, [id, appChainId])

  const countdownComponent = useMemo(() => {
    return (
      <Countdown
        date={new Date(bondData.parameters.endDate)}
        intervalDelay={0}
        precision={3}
        renderer={({ days, hours, minutes, seconds, completed, milliseconds }) => {
          // const milli = Math.round(milliseconds > 10 ? milliseconds / 10 : milliseconds)
          const milli = milliseconds % 100
          const _days = withZero(days)
          const value = `${_days}:${withZero(hours)}:${withZero(minutes)}:${withZero(seconds)}:${withZero(milli)}`
          return completed ? <Completionist /> : <AppTimeView numclassName='p-5 px-7 fs-2md' value={value} />
        }}
      />
    )
  }, [bondData.parameters.endDate])

  const tokenIconElt = useMemo(() => {
    return (
      <TokenIcon
        className='rounded-circle bg-white'
        byAddress={address}
        alt={`Logo-${address}`}
        style={{ width: 80, height: 80 }}
        data-bs-toggle='offcanvas'
        data-bs-target='#lpYourInvestmentCanvas'
        aria-controls='lpYourInvestmentCanvas'
      />
    )
  }, [address])

  return (
    <Box className='hover:border-highlight-colorPrimary border-0 p-30 position-relative' {...props}>
      <AppLink to={`${AppRouters.IBO_BONDS_PAGE}/${id}`} className='py-8 px-10 text-muted position-absolute left-2 -top-2' target='_blank'>
        <FaExternalLinkAlt className='' size={12} />
      </AppLink>
      {active && (
        <S.SVote onClick={onClickVote}>
          <FaThumbsUp />
          {/* @ts-ignore */}
          <span className='pl-5'>{parseInt(yeaVote || 0)}</span>
        </S.SVote>
      )}
      <S.ProjectDetailBox detailWidth={70}>
        <div className='ProjectDetailBox-Info'>
          {tokenIconElt}
          <div className='ProjectDetailBox-Info-Details'>
            <S.ProjectSymbol>{symbol}</S.ProjectSymbol>
            <S.ProjectName href="#">
              {name}
            </S.ProjectName>
          </div>
        </div>
        <Divider className='horizontal px-0 highlighted-border-right' />
        <div className='fs-12 pl-30 ProjectDetailBox-Detail'>
          <BondInfoItem label='Farming APY' nobg />
          <span className='text-primary fs-2md fw-bold'>{totalApy.toFixed(2)}%</span>
        </div>
      </S.ProjectDetailBox>
      <div className='fs-sm mt-5'>
        {/* @ts-ignore */}
        <AppSlider className='primary' readOnly height={15} value={remainingAbleAllowInPercent} />
        <p className='text-primary text-center fs-sm mt-7'>
          Remaining available allocation&nbsp;
          <TokenBalanceSpan suffix='%' digits={4}>
            {remainingAbleAllowInPercent}
          </TokenBalanceSpan>
        </p>
      </div>
      {active ? (
        <div className='mt-15'>
          <h5 className='text-center mb-15 text-muted'>Current bond pool offering ends in</h5>
          <div className='text-center fw-bold'>{countdownComponent}</div>
          <h5 className='text-center text-muted my-20'>
            1 {symbol} = <AmountLabel digits={4}>{tokenRatio * vauleInUsd}</AmountLabel>, 1 {pairToken.symbol} = <AmountLabel digits={4}>{vauleInUsd}</AmountLabel>
          </h5>
        </div>
      ) : (
        <div className='my-20'>
          <p className='text-colorRedHover fw-bolder fs-lg text-center'>{totalSoldInPercent}% Sold Out</p>
        </div>
      )}

      {active ? (
        <>
          <InvestInput
            tokenAddress={pairToken.address}
            valueInUsd={+availableInvestmentAmtUSD}
            isInvalid={false}
            isLoading={false}
            max={{
              symbol: pairToken.symbol,
              value: availableInvestmentAmount,
            }}
            value={investValue}
            hideWithdraw={!isCreator}
            onChangeInvestInput={handleOnChangeInvestInput}
            onClickWithdraw={handleOnClickWithdraw}
            onClickMax={handleOnClickMaxButton}
            onClickInvest={invest}
          />
        </>
      ) : (
        <>
          <AppButton className={`text-primary fs-lg outline-gray ${isCreator ? 'visible' : 'invisible'}`}>Launch The Pool</AppButton>
          <div className='text-end my-5'>
            <button className={`text-danger fs-12 mb-15 fw-bold ${isCreator ? 'visible' : 'invisible'}`}
              onClick={handleOnClickWithdraw}
            >Claim your token</button>
          </div>
        </>
      )}
      <Divider className='vertical highlighted-border-bottom' />
      <Accordion allowZeroExpanded className='mt-20'>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton className='d-flex justify-content-between align-items-center fs-md text-white'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center m-0'>
                  <StatusIcon className={`${active ? 'active' : 'ended'}`} />
                  <span className='ml-10'>
                    {active ? 'LIVE' : 'ENDED'} #{getInsureViewId(id)}
                  </span>
                </div>
                <Divider className='horizontal h-initial' />
                {active && (
                  <>
                    <span className='ml-10'>Exchange:</span>
                    <TokenIcon src={exchange?.icon} width={30} height={30}/>
                  </>
                )}
              </div>
              <div>
                <AccordionItemState>
                  {({ expanded }) =>
                    expanded ? (
                      <>
                        Hide <IoIosArrowUp className='-mt-5' size={18} />
                      </>
                    ) : (
                      <span data-tour={tourEnable && "lpInsure-tour-insure-bond-details"}>
                        Details <IoIosArrowDown size={18} />
                      </span>
                    )
                  }
                </AccordionItemState>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className='mt-20 fs-12 text-muted'>
            <S.BondDetailBox minHeight={210}>
              {/* dont remove following line. we will use in future */}
              {/* <DetailItem label='Bond type' value='50% discount' /> */}
              {/* Funds distributions is always 100% LP */}
              {/* <DetailItem label='Funds distributions' value='100% LP' helpText={tooltip('Funds distributions')} /> */}
              <DetailItem label='Pairs' value={`${symbol} <> ${pairToken.symbol}`} />
              <DetailItem label='Exchange' value='DEREX' />
              <DetailItem label='Bond face value'
                value={<TokenBalanceSpan prefix='$' digits={6}>{bondFaceValue}</TokenBalanceSpan>}
                helpText={tooltip('Bond face value')}
              />
              {/* TODO */}
              <DetailItem label='Principal vesting' value={'Clip 180 days'} />
              {+prepaymentPenalty > 0 && <DetailItem label='Prepayment penalty' value={prepaymentPenalty} helpText={tooltip('PREPAYMENT_PENALTY')} />}
              {/* <DetailItem label='Profits  vesting period' value={secondsToLabel(gradedProfitPeriod)} /> */}
              <DetailItem label='Accepting payment' value={pairToken.symbol} />
              <DetailItem label='Bonds supply available' value={supplyAmountWithOutDecimals} />
              <DetailItem label='Investment limit per wallet' value={`${+bondData.parameters.maxInvestment > 0 ? `${bondData.parameters.maxInvestment} ${pairToken.symbol}` : 'No limit'}`} />
              <DetailItem
                label='Total sold'
                value={
                  <>
                    <span className='text-primary'>
                      <TokenBalanceSpan>{totalSold}</TokenBalanceSpan>
                    </span>
                  </>
                }
              />
              <DetailItem
                label='Total sold (%)'
                value={
                 <TokenBalanceSpan suffix='%' digits={2}>{totalSoldInPercent}</TokenBalanceSpan>
                }
              />
              <DetailItem
                label='Remaining available allocation'
                value={
                  <TokenBalanceSpan suffix='%' digits={2}>{remainingAbleAllowInPercent}</TokenBalanceSpan>
                }
              />
            </S.BondDetailBox>

            <Divider className='vertical mb-15 mt-10 highlighted-border-bottom' />
            <Accordion allowZeroExpanded className='mt-20'>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <DetailItem label={
                        <span>Farming APY&nbsp;&nbsp;
                          <AccordionItemState>
                            {({ expanded }) =>
                              expanded ? (
                                <>
                                  <BiChevronUp size={22} />
                                </>
                              ) : (
                                <span>
                                  <BiChevronDown size={22} />
                                </span>
                              )
                            }
                          </AccordionItemState>
                        </span>
                      } 
                      value={<div className='pt-5'>{totalApy.toFixed(2)}%</div>}
                    />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='text-disabled'>
                  {
                    farmsInfo.length > 0 ? (
                      farmsInfo.map((farm, index) => (
                        <DetailItem key={index} label={farm.tokenName} value={`${farm.apy.toFixed(2)}%`} />
                      )) 
                    ) : (
                      <>No Farms</>
                    )
                  }
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
            <Divider className='vertical mb-15 mt-10 highlighted-border-bottom' />

            <DetailItem label='Tokens supply' value={<TokenBalanceSpan>{totalSupply}</TokenBalanceSpan>} helpText={tooltip('Tokens supply')} />
            <div className='mt-10'>
              <DetailItem label='FDV' value={<TokenBalanceSpan digits={6} prefix='$'>{totalSupply * vauleInUsd}</TokenBalanceSpan>} helpText={tooltip('FDV')} />
            </div>

            <Divider className='vertical my-15 highlighted-border-bottom' />

            <DetailItem
              label='Your investment'
              value={
                <span>
                  <TokenBalanceSpan prefix='$'>{vauleInUsd * myBalanceInDecimals}</TokenBalanceSpan>&nbsp;(<TokenBalanceSpan>{myBalanceInDecimals}</TokenBalanceSpan> {pairToken.symbol})
                </span>
              }
            />
            <DetailItem
              label='Your initial LP assets'
              value={
                <>
                  <TokenBalanceSpan suffix={` ${symbol}`}>{initialTokenAmount}</TokenBalanceSpan>
                  &nbsp;+&nbsp;
                  <TokenBalanceSpan suffix={` ${pairToken.symbol}`}>{initialPairAmount}</TokenBalanceSpan>
                </>
              }
            />
            <DetailItem
              label='Proof'
              value={
                <a href={`${network.ExplorerUrl}/tx/${bondData.transactionHash}`} target='_blank' rel='noreferrer' className='text-secondary'>
                  <ViewAddress address={bondData.transactionHash} />
                </a>
              }
            />

            <div className='d-flex justify-content-between mt-25'>
              <a href={`${network.ExplorerUrl}/address/${address}`} className='text-primary' rel='noreferrer' target='_blank'>
                View Project info
                <FaExternalLinkAlt className='ml-5' />
              </a>
              <a href={`${network.ExplorerUrl}/address/${poolPair.lpAddress}`} className='text-primary' rel='noreferrer' target='_blank'>
                View Project liquidity
                <FaExternalLinkAlt className='ml-5' />
              </a>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      <AppTooltip />
    </Box>
  )
}

export default LPInsureBondCard
