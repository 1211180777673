import styled from 'styled-components'
import Media from 'Theme/media-breackpoint'
import { FlexDiv } from './others/styled'

export const MainTitle = styled.div`
  font-size: 42px;
  color: ${(props) => props.theme.colorWhite};
  font-family: var(--dx-ff-secondary);
  text-align: center;
  margin: 0px 15px 10px;
  padding-top: 30px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  ${Media.sm} {
    font-size: 30px;
    margin-bottom: 20px;
  }
`

export const MainDesc = styled.div`
  font-size: 17px;
  line-height: 25px;
  color: ${(props) => props.theme.colorLightGrey};
  text-align: center;
  margin: 0px 15px 40px;
  padding: 0px 20px;
  font-family: var(--dx-ff-primary);
  max-width: 850px;
  ${Media.xs} {
    margin-bottom: 40px;
  }
`



export const PageTitleGroup = styled(FlexDiv)`
  width: 100%;
  font: 300 32px/40px 'Montserrat', arial;
  color: #fff;
  span {
    font: 300 18px/32px 'Lato', monospace;
    display: block;
    color: #8e9195;
    width: 100%;
    text-align: center;
    padding-top: 10px;
  }

  .fas.helpIco {
    font-size: 14px;
  }
`