import styled from 'styled-components'
import { AppButton } from '../Buttons'
import { FlexDiv } from '../others/global-components'

export const InputGroupTextArea = styled.textarea`
  padding: 10px;
  color: white;
  width: 100%;
  min-width: 30px;
  border-radius: 5px;
  background: transparent;
`

export const InputGroupControl = styled.input`
  padding: 10px;
  color: white;
  width: 100%;
  min-width: 30px;
  &.outline {
    &-gray {
      border: 2px solid ${(props) => props.theme.bodybg013};
    }
  }
`

export const InputGroupText = styled(FlexDiv)`
  background-color: ${(props) => props.theme.greyBorder};
  padding: 12px;
`

export const InputGroup = styled.div`
  display: flex;
  align-items: stretch;
  border: 2px solid ${(props) => props.theme.greyBorder};
  border-radius: 10px;
  & > ${InputGroupText}:first-child {
    border-radius: 5px 0 0 5px;
  }
  & > ${InputGroupText}:last-child {
    border-radius: 0 5px 5px 0;
  }
  & input {
    padding: 10px;
    color: white;
    width: 100%;
    min-width: 30px;
    &.outline {
      &-gray {
        border: 1px solid ${(props) => props.theme.bodybg013};
      }
    }
  }
  &.danger {
    border-color: ${({ theme }) => theme.colorRed};
    box-shadow: 0 0 7px 0px ${({ theme }) => theme.colorRed};

    ${InputGroupText} {
      border-left: 2px solid ${({ theme }) => theme.colorRed};
    }
  }
`

export const SButtonGroupElt = styled(AppButton)`
  padding: 0 15px;
  color: white;
  line-height: inherit;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize['2xl']};
`
export const SButtonGroup = styled.div`
  display: flex;
  white-space: nowrap;
  ${SButtonGroupElt}:first-child {
    border-radius: 10px 0 0 10px;
  }
  ${SButtonGroupElt}:last-child {
    border-radius: 0 10px 10px 0;
  }
`

interface IAppNumberInputProps extends React.HTMLAttributes<HTMLInputElement> {
  onClickPlus?: React.MouseEventHandler | any
  onCLickMinus?: React.MouseEventHandler | any
}

export function AppNumberInput({ onClickPlus, onCLickMinus, ...props }: IAppNumberInputProps) {
  return (
    <InputGroup>
      <SButtonGroup className='my-5 ml-5 white-nowrap'>
        <SButtonGroupElt className='bg-primary' onClick={onClickPlus}>
          +
        </SButtonGroupElt>
        <SButtonGroupElt className='bg-secondary' onClick={onCLickMinus}>
          -
        </SButtonGroupElt>
      </SButtonGroup>
      <InputGroupControl {...props} />
      <InputGroupText className='px-15'>%</InputGroupText>
    </InputGroup>
  )
}
