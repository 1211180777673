export type MediaConfig = {
  xl: string,
  lg: string
  lg2: string
  md: string
  md2: string
  sm: string
  xs: string
}

export enum MediaSize {
  xl = '1600px',
  lg = '1440px',
  lg2 = '1200px',
  md = '1024px',
  md2 = '800px',
  sm = '767px',
  xs = '500px',
}

const Media: MediaConfig = {
  xl: `@media(max-width:${MediaSize.xl})`,
  lg: `@media(max-width:${MediaSize.lg})`,
  lg2: `@media(max-width:${MediaSize.lg2})`,
  md: `@media(max-width:${MediaSize.md})`,
  md2: `@media(max-width:${MediaSize.md2})`,
  sm: `@media(max-width:${MediaSize.sm})`,
  xs: `@media(max-width:${MediaSize.xs})`,
}

export default Media
