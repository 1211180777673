import { Action, ThunkDispatch } from "@reduxjs/toolkit"
import { ThemeConfigType } from "./app-theme"
import { LPInsureData } from "./lp-insure"
import { Ballot, BondData, BondLabelInfo, CreatePoolType, DexInfo, FeeStructureLabelInfo, SupportedNetworks, TimeOption, TransactionSpeed } from "./others"
import { BaseToken, TokenBalance } from "./tokens"

export type AppThunkAction<
    R, // Return type of the thunk function
    S, // state type used by getState
    E, // any "extra argument" injected into the thunk
    A extends Action // known types of actions that can be dispatched
> = (dispatch: ThunkDispatch<S, E, A>, getState: () => S, extraArgument: E) => R

export enum BondTabs {
    ProjectInformation,
    BondTerms,
    FundraisingTerms,
    DeployBondPool,
}
export enum LPInsureTabs {
    ProjectInformation,
    BondTerms,
    FundraisingTerms,
    DeployBondPool,
    BuyBackOffer,
}

export type VestingForProfit = {
    type: string,
    afterOption: TimeOption,
    afterValue: string // number in string
    portionValue: string, // number in string
    everyOption: TimeOption,
    everyValue: string, // number in string
    isStartProfit: boolean, // start profits redemption after principal
}
export type VestingForProfitParams = {
    type?: string,
    afterOption?: TimeOption,
    afterValue?: string // number in string
    portionValue?: string, // number in string
    everyOption?: TimeOption,
    everyValue?: string, // number in string
    isStartProfit?: boolean, // start profits redemption after principal
}

type PeriodOptionParams = {
    type?: string,
    afterOption?: TimeOption,
    afterValue?: string, // number in string
    portionValue?: string, // number in string
    everyOption?: TimeOption
    everyValue?: string, // number in string
}

export type VestingForPrincipal = {
    type: string,
    afterOption: TimeOption,
    afterValue: string, // number in string
    portionValue: string, // number in string
    everyOption: TimeOption
    everyValue: string, // number in string
}

export type VestingPeriod = {
    type: string
    afterValue: string // number in string
    afterOption: TimeOption
    portionValue: string // number in string
    everyOption: TimeOption
    everyValue: string, // number in string
}

export type VestingForPrincipalParams = PeriodOptionParams
export type VestingPeriodParams = PeriodOptionParams

export type InvestmentLimit = {
    isSet: boolean,
    minimum: string, // number in string
    maximum: string, // number in string
}
export type InvestmentLimitParams = {
    isSet?: boolean,
    minimum?: string, // number in string
    maximum?: string, // number in string
}

export type TokenParamsWithBalance = {
    name: string
    symbol: string
    address: string
    decimals: string
    balance: string
}

export type StakingPeriod = {
    timeOption: TimeOption
    value: string, // number in string
}
export type StakingPeriodParams = {
    timeOption?: TimeOption
    value?: string, // number in string
}
export type BondReducerState = {
    tab: BondTabs
    token: string
    withNewPool: boolean
    website: string
    whitepaper: string
    logo: {
        preview: string
        file: null | Blob
    }
    dex: DexInfo
    startIBODate: number
    endIBODate: number
    // - tab 2
    bondType: BondLabelInfo
    vestingForPrincipal: VestingForPrincipal
    vestingForProfit: VestingForProfit
    vestingPeriod: VestingPeriod
    prepaymentPenalty: boolean
    dynamicPenalty: string // number in string
    leftoverBurn: string
    // tab 3
    amountOfSupply: string // number in string
    raisingTarget: string // number in string
    investmentLimit: InvestmentLimit
    feeStructure: FeeStructureLabelInfo
    shieldPeriod: number // timestamp in number
    dexWithPool: DexInfo
    // staking period for users
    stakingPeriod: StakingPeriod
    apyForUsers: string // number in string
    isDumperShieldForUsers: boolean
    // other states
    isAddingProjectInfo: boolean
    pairToken: TokenBalance
    pairTokenPriceUSD: string | number
    tokenInfo: TokenBalance
    tokenRatio: number
    hasPrincipalVesting: boolean
    hasProfitVesting: boolean
}
export type BondReducerParams = {
    tab?: BondTabs
    token?: string
    withNewPool?: boolean
    website?: string
    whitepaper?: string
    logo?: {
        preview: string
        file: null | Blob
    }
    dex?: DexInfo
    startIBODate?: number
    endIBODate?: number
    // - tab 2
    bondType?: BondLabelInfo
    vestingForPrincipal?: VestingForPrincipal
    vestingForProfit?: VestingForProfit
    vestingPeriod?: VestingPeriod
    prepaymentPenalty?: boolean
    dynamicPenalty?: string // number in string
    leftoverBurn?: string
    // tab 3
    amountOfSupply?: string // number in string
    raisingTarget?: string // number in string
    investmentLimit?: InvestmentLimit
    feeStructure?: FeeStructureLabelInfo
    shieldPeriod?: number // timestamp in number
    dexWithPool?: DexInfo
    // staking period for users
    stakingPeriod?: {
        timeOption: TimeOption
        value: string // number in string
    }
    apyForUsers?: string // number in string
    isDumperShieldForUsers?: boolean
    // other states
    isAddingProjectInfo?: boolean
    pairToken?: TokenBalance
    tokenInfo?: TokenBalance
    tokenRatio?: number
}

export type LPInsureReducerState = {
    tab: LPInsureTabs
    token: string
    withNewPool: boolean
    dex: DexInfo
    startIBODate: number
    endIBODate: number
    // - tab 2
    bondType: BondLabelInfo
    vestingPeriod: VestingPeriod
    prepaymentPenalty: boolean
    dynamicPenalty: string // number in string
    // tab 3
    amountOfSupply: string // number in string
    investmentLimit: InvestmentLimit
    // other states
    isAddingProjectInfo: boolean
    pairToken: TokenBalance
    tokenInfo: TokenBalance
    tokenRatio: number
}

export type LPInsureReducerParams = {
    tab?: LPInsureTabs
    token?: string
    withNewPool?: boolean
    dex?: DexInfo,
    startIBODate?: number,
    endIBODate?: number
    // - tab 2
    bondType?: BondLabelInfo,
    vestingPeriod?: VestingPeriod
    prepaymentPenalty?: boolean,
    dynamicPenalty?: string, // number in string
    // tab 3
    amountOfSupply?: string, // number in string
    investmentLimit?: InvestmentLimit
    // other states
    isAddingProjectInfo?: boolean,
    pairToken?: TokenBalance
    tokenInfo?: TokenBalance
    tokenRatio?: number,
}

export type GlobalReducerState = {
    showConnectWalletModal: boolean
    processingModal: {
        isOpen: boolean,
        message: string,
    },
    availableTokens: Array<BaseToken>,
    failedT_ModalStatus: {
        isOpen: boolean,
        message: string,
        header: string,
    },
    transactionModal: {
        isOpen: boolean
        hash: string
    },
    appErrorModal: {
        isOpen: boolean
        message: string
    },
    account: string,
    themeConfig: ThemeConfigType
    userTokensWithBalance: {
        isLoading: boolean,
        errorMessage: string,
        tokens: TokenBalance[],
    }
    appChainId: SupportedNetworks   
}

export type VotesReducerState = {
    isLoading: boolean
    selectedBallotId: string // type id-pairAddress
    isLoadMoreActivedBallots: boolean
    isLoadMoreCompletedBallots: boolean
    activedBallots: Array<Ballot>
    completedBallots: Array<Ballot>
}

export type TaxReducerState = {
    owner: string
    taxedToken: string
    pair: {
        address: string
        decimals: string
        token0: {
            name: string
            symbol: string
            address: string
            image: string
        },
        token1: {
            name: string
            symbol: string
            address: string
            image: string
        },
    },
    sellTax: number, // 0 ~ 99
    sellTaxReceiver: string
    buyTax: number
    buyTaxReceiver: string
    showPairTokenPopup: boolean
    showTaxFeeStatusPopup: boolean
}

export interface TaxSetValuesParams {
    owner?: string
    taxedToken?: string
    pair?: {
        address: string
        decimals: string
        token0: {
            name: string
            symbol: string
            address: string
            image: string
        },
        token1: {
            name: string
            symbol: string
            address: string
            image: string
        },
    },
    sellTax?: number, // 0 ~ 99
    sellTaxReceiver?: string
    buyTax?: number
    buyTaxReceiver?: string
    showPairTokenPopup?: boolean
    showTaxFeeStatusPopup?: boolean
}


export interface SettingReducerState {
    multihops: boolean,
    slippage: number,
    speed: TransactionSpeed,
    deadline: number, // mins// transaction deadline
}

export interface LiquidityReducerState {
    isPrivate: boolean
    maxTxDump0: number
    maxTxDump1: number
    timeFrame: number
    maxDump0: number
    maxDump1: number
    periodMA: number
    minimalFee: number
    coefficient: number
    voteDutation: number
    minVotingPower: number
    isAddSecureFloor: boolean
    tokenToSecure: string
    limitation: TimeOption
    wmaOption: TimeOption
    voteOption: TimeOption

    userInfo: LiquidityUserInfo
    // select tokens
    createPoolWith: CreatePoolType
    activedLPAddress: string
    lpAddress: {
        isLoaded: boolean;
        address: string;
    }
    lpInfo: {
        token0: BaseToken;
        token1: BaseToken;
        reserve0: string // in number
        reserve1: string // in number
    }
    tokenA: BaseToken
    tokenB: BaseToken

    // token price in USD
    tokenAPriceUSD: string | number 
    tokenBPriceUSD: string | number 

}

export type LPPairToken = {
    lpAddress: string
    token0: BaseToken
    token1: BaseToken
    totalSupply: string
    reserve0: string
    reserve1: string
}

export type LiquidityPosition = {
    id: string
    lpBalance: string
    pair: LPPairToken
}

export type LiquidityUserInfo = {
    usdSwapped: number
    liquidityPositions: LiquidityPosition[]
}

export interface LiquidityParams {
    isPrivate?: boolean
    maxTxDump0?: number
    maxTxDump1?: number
    timeFrame?: number
    maxDump0?: number
    maxDump1?: number
    periodMA?: number
    minimalFee?: number
    coefficient?: number
    voteDutation?: number
    minVotingPower?: number
    isAddSecureFloor?: boolean
    tokenToSecure?: string
    limitation?: TimeOption
    wmaOption?: TimeOption
    voteOption?: TimeOption
    userInfo?: LiquidityUserInfo,

    // select tokens
    tokenA?: BaseToken
    tokenB?: BaseToken
}

export type Pool = {
    lpAddress: string
    lpSymbol: string
    token0: BaseToken
    token1: BaseToken
}

export type UserAddedLPPosition = {
    id: string
    lpBalance: string
    pair: {
        lpAddress: string
        token0: BaseToken
        token1: BaseToken
        totalSupply: string // number in string
        reserve0: string // number in string
        reserve1: string // number in string
    }
    user: {
        account: string
        usdSwapped: string
    }
}

export type PairsWithLP = {
    liquidityPositions: Array<UserAddedLPPosition>
}

export type PoolInReducer = {
    lpAddress: null | string
    lpSymbol: null | string
    token0: null | BaseToken
    token1: null | BaseToken
}

export type ImportedPoolReducerState = {
    lpAddress: string
    pool: PoolInReducer
    list: Array<Pool>
    userAddedLiquidityPositions: Array<UserAddedLPPosition>
    errors: Array<any>
    isLoadingPool: boolean
}

export type ReducerTokenPrice = {
    name: string;
    address: string;
    symbol: string;
    priceUSD: string;
    usd_24h_change: string;
}
export type ReducerSimplePrices = {
    // [token address in string & lowercase]: ReducerTokenPrice
    [key in string]: ReducerTokenPrice
}


export type CoinGeckoReducerState = {
    simplePrices: ReducerSimplePrices
}

export type BondListReducerState = {
    isLoading: boolean,
    search: string,
    list: BondData[],
    investLengthIn24hrs: number,
}

export type LPInsureListReducerState = {
    isLoading: boolean,
    search: string,
    errMsg: string,
    list: LPInsureData[],
    investLengthIn24hrs: number,
}

export type BondDetailReducerState = {
    isLoading: boolean,
    bond: null | BondData
}

export type TourReducerState = {
    isIBONeverShow      : boolean;
    isDEREXNeverShow    : boolean;
    isLPInsureNeverShow : boolean;
    isOpenDerexTour     : boolean;
    isOpenIBOTour       : boolean;
    isOpenLPInsureTour  : boolean;
}
