import styled from 'styled-components'

export const AppDatePickerDiv = styled.div`
  display: flex;
  align-items: center;
  & .react-datepicker__month {
    padding: 10px;
  }
  & .react-datepicker__header {
    padding-top: 10px;
  }
  & .react-datepicker__day--outside-month {
    color: grey;
  }
  & .react-datepicker__day-names {
    padding: 5px 0 0 0;
  }
  & .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 1.2rem;
  }
  &.react-datepicker__header[padding: 10px;] & .react-datepicker__current-month {
    font-size: 1.2rem;
  }
  & .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    padding: 1px;
    font-size: 1.1rem;
  }
  & > svg {
    min-width: 15px;
    min-height: 15px;
  }
`
