import { BSC_ReimbursementContract, ETH_ReimbursementContract, JNTR_E_ContractAddress, JNTR_B_ContractAddress, JNTR_TB_ContractAddress, ETH_StakingContract, BNB_StakingContract } from 'app-constants'

const data = {
  1: {
    stakingContract: ETH_StakingContract,
    explorer: 'https://etherscan.io',
    jntrbToken: JNTR_E_ContractAddress,
    reimbursementStakingContract: ETH_ReimbursementContract,
  },
  56: {
    stakingContract: BNB_StakingContract,
    explorer: 'https://bscscan.com',
    jntrbToken: JNTR_B_ContractAddress,
    reimbursementStakingContract: BSC_ReimbursementContract,
  },
  97: {
    stakingContract: '0x1567B4fB760a3443c5e1430B3828806Db2f248FD', // mukesh updated
    // stakingContract: "0xe786b3b3a8def6c8855c312cc42c06d596347243"; // yuriy
    explorer: 'https://testnet.bscscan.com',
    jntrbToken: JNTR_TB_ContractAddress,
    reimbursementStakingContract: '0x668509132Ba24A5cD09D7909c71a2D3AF19016ff',
  },
  allowedNetwork: [1, 56, 97, 137],
  pureToken: [
    {
      name: 'Ether',
      networkId: 1,
      image: 'imgs/pureEth.png',
      id: 'ethereum',
      frozenToken: ETH_StakingContract,
    },
    {
      name: 'Bnb',
      networkId: 56,
      image: 'imgs/pureBnb.png',
      id: 'binancecoin',
      frozenToken: BNB_StakingContract,
    },
  ],
  tokenByAddress: {
    [JNTR_TB_ContractAddress]: {
      name: 'JNTRB',
      symbol: 'JNTR/b',
    },
  },
}

export default data
