import styled from 'styled-components'
import { IoMdArrowDropright } from 'react-icons/io'

const SFireNotification = styled.div`
  max-width: 220px;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 10px 0 0 10px;
  background-color: ${({ theme }) => theme.pageBgColor};
  box-shadow: 0px 0px 6px ${({ theme }) => theme.colorPrimary};

  & .icon {
    color: black;
  }

  & .fire {
    width: 40px;
    height: 40px;
    background-image: url('/images/imgpsh_fullsize_anim.gif');
    background-size: 60px;
    background-repeat: no-repeat;
    background-position: center;
  }
  & .content {
    padding-left: 5px;
  }
`

interface IFireNotificationProps extends React.HTMLAttributes<HTMLDivElement> {
  onClickClose?: any
}

export const FireNotification = ({ children, onClickClose = () => 0, ...props }: IFireNotificationProps) => {
  return (
    <SFireNotification {...props}>
      <button>
        <IoMdArrowDropright className='icon text-white' size={20} onClick={onClickClose} />
      </button>
      <div className='fire'></div>
      <div className='content'>{children}</div>
    </SFireNotification>
  )
}
