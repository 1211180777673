import { BOND_PROXY_CONTRACTS, BOND_MAIN_CONTRACTS } from 'app-constants'
import { connect } from 'react-redux'
import { SupportedNetworks } from 'types'
import { toggleConnectWalletModal } from './reducers/globals/globalActions'
import { AppThunkDispatch, RootState } from './store'

const mapDispatchToProps = {
  connectWallet: toggleConnectWalletModal,
}

export const withConnectWalletModal = connect(null, mapDispatchToProps)

export const getCurrentBondContract = () => (dispatch: AppThunkDispatch, getState: () => RootState ): string => {
  return getBondContract(getState)
}

export const getBondContract = (getState: () => RootState): string => {
  const appChainId: SupportedNetworks = getState().globals.appChainId
  return BOND_PROXY_CONTRACTS[appChainId]
}

export const getBondMainContract = (getState: () => RootState): string => {
  const appChainId: SupportedNetworks = getState().globals.appChainId
  return BOND_MAIN_CONTRACTS[appChainId]
}
