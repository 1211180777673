import { useMemo, useState } from 'react'
import { BsQuestionCircleFill } from 'react-icons/bs'
import { MainDesc, MainTitle, PageTitleGroup } from 'Component/core/elements/Text'
import tooltip from 'app-constants/tooltip-texts'
import { AppButton } from 'Component/core/elements/Buttons'
import { TableColumn, TableStyles } from 'react-data-table-component'
import CustomReactTablePagination from 'Component/core/elements/CustomReactDataTableElements'
import AppTooltip from 'Component/AppTooltip'
import { LiquidityPosition, PageLoadState } from 'types'
import { useAppSelector } from 'redux/hooks'
import { GlobalsSelector } from 'redux/selectors'
import useAppDebounceEffect from 'hooks/useAppDebounceEffect'
import BSwapSubgraph from 'helper/subgraphs/BSwapSubgraph'
import { decimalAmountToExactAmount } from 'helper/math'
import { TokenBalanceSpan } from 'Component/core/elements/others/global-components'
import { getPendingRewards } from 'helper/multi-calls/pool-multicall-utils'
import AppDataTable from 'Component/AppDataTable'
import useWallet from 'hooks/useWallet'
import { withConnectWalletModal } from 'redux/actions'
import AppLoadingSpinner from 'Component/AppLoadingSpinner'

type TableRowType = {
  name: string
  lpAddress: string
  availableToClaim: number
}
type RewardsType = {
  [key in string]: string;
}
const customStyles: TableStyles = {
  table: {
    style: {
      backgroundColor: 'transparent',
    }
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      color: '#8e9196',
      background: 'transparent',
      borderTopWidth: '2px',
      borderBottomWidth: '2px',
      borderColor: '#8e9196',
      height: '50px',
      fontFamily: 'var(--dx-ff-primary)'
    },
  },
  headCells: {
    style: {
      fontSize: '15px',
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        color: '#8e9196',
        borderRightWidth: '1px',
        borderColor: '#8e9196',
      },
    },
  },
  cells: {
    style: {
      background: 'transparent',
      borderRightWidth: '1px',

      '& div:first-child': {
        overflow: 'inherit !important'
      },
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderColor: '#8e9196',
      },
    },
  },
  rows: {
    style: {
      borderRightWidth: '0px',
      height: '50px',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderColor: '#8e9196',
      borderBottomColor: '#8e9196 !important',
      color: '#8e9196',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: '#272a31'
      }
    },
  }
}

const emptyTableData: TableRowType[] = [{
  name: '',
  lpAddress: '',
  availableToClaim: 0
}]

function TradersTabContent({ connectWallet }: { connectWallet: () => void }) {
  const { active } = useWallet()
  const { appChainId, account } = useAppSelector(GlobalsSelector)

  const [pageState, setPageState] = useState<PageLoadState>({ error: '', isLoading: true });

  const [tableData, setTableData] = useState<TableRowType[]>([])
  const [lpPositions, setLpPositions] = useState<LiquidityPosition[]>([])
  const [rewards, setRewards] = useState<RewardsType>({})

  const totalRewards: number = useMemo(() => {
    const values = Object.values(rewards).map((v) => +v);
    const sum = values.length > 0 ? values.reduce((accumulator, currectValue) => accumulator + currectValue) : 0
    return decimalAmountToExactAmount(sum, 18)
  }, [rewards])

  useAppDebounceEffect(() => {
    (async () => {
      try {
        setPageState({ isLoading: true, error: '' })
        const subgraph = new BSwapSubgraph(appChainId);
        const res = await subgraph.fetchUserWithLpPositions(account);
        if (res.data.users.length > 0) {
          setLpPositions(res.data.users[0].liquidityPositions);
        } else {
          setLpPositions([])
        }
        setPageState({ isLoading: false, error: '' })
      } catch (error) {
        setPageState({ isLoading: false, error: String(error) })
      }
    })()
  }, 300, [appChainId, account])

  useAppDebounceEffect(() => {
    (async () => {
      const pendingRewards = await getPendingRewards(appChainId, lpPositions.map((lp) => lp.pair.lpAddress.toLowerCase()), account);
      setRewards(pendingRewards)
    })()
  }, 300, [appChainId, account, lpPositions])

  useAppDebounceEffect(() => {
    setTableData(lpPositions.map((lp) => {
      const rewardValue = rewards[lp.pair.lpAddress.toLowerCase()];
      return {
        name: `${lp.pair.token0.symbol} <> ${lp.pair.token1.symbol}`,
        lpAddress: lp.pair.lpAddress,
        availableToClaim: decimalAmountToExactAmount(rewardValue || '0', 18) || 0, // 18 is standard decimals of derex pool
      }
    }))
  }, 200, [lpPositions, rewards])

  const columns = useMemo<TableColumn<TableRowType>[]>(
    () => [
      {
        name: 'Pools',
        cell: (row) => {
          return <a href='#' className='token-name'>{row.name}</a>
        },
      },
      {
        name: '$DRX available to claim',
        style: {
          color: 'white'
        },
        cell: (row) => {
          // TODO
          return <p><TokenBalanceSpan digits={2}>{row.availableToClaim}</TokenBalanceSpan></p>
        },
      },
      {
        name: 'Claim',
        maxWidth: '200px',
        center: true,
        cell: (row) => {
          return (
            <AppButton
              className='outline-black hover:outline-primary fs-md text-primary fw-normal py-2 px-60 radius-3'
              data-lpaddress={row.lpAddress}
            >Claim</AppButton>
          )
        },
      },
    ],
    []
  )

  return (
    <div className='mb-30'>
      <PageTitleGroup className='flex-column'>
        <MainDesc className='w-max-auto p-0 mx-0 text-justify'>
          <MainTitle as='div' className='text-start ml-0'>Airdrop super bonus</MainTitle>
          DEREX did not conduct any presale or early investment offers, and the team does not hold any tokens.
          Instead, the project rewards users and projects utilizing DEREX and IBO by giving away $DRX tokens as a bonus.
          These tokens not only have no selling pressure from existing holders, but also have a correlated buying presuure model that uses 80% of generated fees to buy back and burn $DRX tokens
        </MainDesc>
      </PageTitleGroup>
      <div className='mb-20'>
        <p className='text-danger text-title'>$DRX tokens are subject to Dumper Shield restriction
          <BsQuestionCircleFill className='fas helpIco' data-type='light' data-html='true' data-class='data-tooltip' data-tip={tooltip('Coming Soon')} />
        </p>
        {
          pageState.isLoading ? (
            <div className='h-min-15vh w-full d-flex align-items-center justify-content-center'>
              <AppLoadingSpinner />
            </div>
          ) : (
            <AppDataTable
              requireWalletConnect={!active}
              emptyTableData={emptyTableData}
              columns={columns}
              data={tableData}
              customStyles={customStyles}
              pagination
              dense
              paginationComponent={(props) =>
                <div className='d-flex justify-content-between mt-15'>
                  <CustomReactTablePagination {...props} />
                  <div className='d-flex align-items-center mr-25'>
                    <div className='px-10 mr-20'>
                      <span className='text-muted fs-md'>Total $DRX to claim: <TokenBalanceSpan digits={3}>{totalRewards}</TokenBalanceSpan></span>
                    </div>
                    <AppButton className='w-auto outline-black hover:outline-primary fs-md text-primary fw-normal py-2 px-49 radius-3'>Claim All</AppButton>
                  </div>
                </div>
              }
              onClickConnectWallet={connectWallet}
            />
          )
        }
      </div>
      <AppTooltip />
    </div>
  )
}

export default withConnectWalletModal(TradersTabContent)
