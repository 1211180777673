import { BaseToken, SupportedNetworks, ThemeConfigType, TokenBalance } from "types"

export enum ActionTypes {
  toggleConnectWalletModal = 'GLOBAL/toggleConnectWalletModal',
  setAvailableTokens = 'GLOBAL/setAvailableTokens',
  setOpenTransationFailedModal = 'GLOBAL/setOpenTransationFailedModal',
  setTransactionModal = 'GLOBAL/setTransactionModal',
  setAppErrorModal = 'GLOBAL/setAppErrorModal',
  SET_ACCOUNT = 'GLOBAL/SET_ACCOUNT',
  SET_CHAIN = 'GLOBAL/SET_CHAIN',
  SET_IS_OPEN_TRANSACTION_MODAL = 'GLOBAL/SET_IS_OPEN_TRANSACTION_MODAL',
  SET_APP_THEME = 'GLOBAL/SET_APP_THEME',
  SET_USER_TOKENS_WITH_BALANCE = 'GLOBAL/SET_USER_TOKENS_WITH_BALANCE',
}

interface ActionToggleConnectWallet {
  type: ActionTypes.toggleConnectWalletModal
  payload: undefined | boolean
}

interface ActionSetAvailableTokens {
  type: ActionTypes.setAvailableTokens
  payload: Array<BaseToken>
}

interface ActionSetOpenTFModal {
  type: ActionTypes.setOpenTransationFailedModal
  payload: {
    isOpen: boolean
    message: string
    header: string
  }
}

interface ActionSetTransactionModal {
  type: ActionTypes.setTransactionModal
  payload: {
    isOpen: boolean
    hash: string
  }
}

interface ActionSetAppError {
  type: ActionTypes.setAppErrorModal
  payload: {
    isOpen: boolean
    message: string
  }
}

interface ActionSetAccount {
  type: ActionTypes.SET_ACCOUNT
  payload: string
}

interface ActionSetChain {
  type: ActionTypes.SET_CHAIN
  payload: SupportedNetworks
}

interface ActionSetIsOpenTRModal {
  type: ActionTypes.SET_IS_OPEN_TRANSACTION_MODAL
  payload: {
    isOpen: boolean,
    message: string,
  }
}

interface ActionSetAppTheme {
  type: ActionTypes.SET_APP_THEME
  payload: ThemeConfigType
}

interface ActionSetUserTokensWithBalance {
  type: ActionTypes.SET_USER_TOKENS_WITH_BALANCE
  payload: {
    isLoading: boolean,
    errorMessage: string,
    tokens: TokenBalance[]
  }
}

export type ActionGlobals = ActionToggleConnectWallet | ActionSetAvailableTokens | ActionSetOpenTFModal | ActionSetTransactionModal | ActionSetAppError | ActionSetAccount | ActionSetChain | ActionSetIsOpenTRModal | ActionSetAppTheme
  | ActionSetUserTokensWithBalance
