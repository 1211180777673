import { NETWORKS } from 'app-constants';
import {
    Multicall,
    ContractCallResults,
    ContractCallContext,
    ContractCallReturnContext,
} from 'ethereum-multicall';
import { SupportedNetworks } from 'types';
import Web3 from 'web3';

type Values = {
    [key in string]: ContractCallReturnContext
}
const COMFORTABLE_LENGTH = 500 // 1000 is the best count

export async function multicall(chainId: SupportedNetworks, contractCallContext: ContractCallContext[]): Promise<Values> {
    const web3 = new Web3(NETWORKS[chainId].HTTP_PROVIDER_URL);
    const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

    const contractCallContexts: ContractCallContext[][] = [];
    let count = 0;
    do {
        contractCallContexts.push(contractCallContext.slice(count, count + COMFORTABLE_LENGTH));
        count += COMFORTABLE_LENGTH;
    } while (count < contractCallContext.length);
    const callResults: ContractCallResults[] = (await Promise.all(contractCallContexts.map((c) => multicall.call(c)))).flat(1);
    let values: Values = {}

    for (let i = 0; i < callResults.length; i++) {
        const re = callResults[i];
        values = {
            ...values,
            ...re.results,
        }
    }
    return values;
}
