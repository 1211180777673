export enum LOCAL_STORAGE_ITEM_NAMES {
  WALLET_TYPE           = 'WALLET_TYPE',
  SETTINGS              = 'settings',
  global                = 'app_global',
  APP_CUS_TOKENS        = 'customTokens', // to save the tokens added by user
  COIN_LIST             = 'coinList',
  COMMON_TOKENS         = 'common_tokens',
  CONNECTED_CHAIN       = 'CONNECTED_CHAIN', // used for ChainChecker to detect connected chain
  COIN_GECKO            = 'COIN_GECKO',
  IMPORTED_POOLS        = 'IMPORTED_POOLS',
  TOUR_IBO_STATE        = 'TOUR_IBO_STATE',
  TOUR_DEREX_STATE      = 'TOUR_DEREX_STATE',
  TOUR_LP_INSURE_STATE  = 'TOUR_LP_INSURE_STATE',
}
