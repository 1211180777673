import { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import type { TooltipProps } from 'react-tooltip'

function AppTooltip({ ...props }: TooltipProps): JSX.Element {
  useEffect(() => {
    ReactTooltip.rebuild()
  })

  return <ReactTooltip effect='solid' type='light' className='myTip' {...props} />
}

export const AppToolTipControl = ReactTooltip;

export default AppTooltip
