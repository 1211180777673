import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { BOND_PROXY_CONTRACTS, NETWORKS, ZERO_ADDRESS } from "app-constants";
import { SupportedNetworks } from "types";
import BondAbi from 'Assets/Abi/bond-abi.json'
import DumperShieldABI from 'Assets/Abi/DumperShieldABI.json'

export const createDumperShield = async(appChainId: SupportedNetworks, account: string, token: string, router: string, unlockDate: number, dao: string = ZERO_ADDRESS) => {
    const network = NETWORKS[appChainId];
    const web3 = new Web3(network.HTTP_PROVIDER_URL)
    const bondInstance = new web3.eth.Contract(BondAbi as AbiItem[], BOND_PROXY_CONTRACTS[appChainId]);
    const DumperShieldContract = await bondInstance.methods.dumperShield().call();

    const DSInstance = new web3.eth.Contract(DumperShieldABI as AbiItem[], DumperShieldContract);
    await DSInstance.methods.createDumperShield(token, router, unlockDate, dao).send({ from: account }) /// ZERO_ADDRESS is dao for this func
}
