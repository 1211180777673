import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { NETWORKS } from "app-constants";
import { SupportedNetworks } from "types";
import InsureBondABI from 'Assets/Abi/lp-insure-bond-abi.json'
import InsureOfferABI from 'Assets/Abi/lp-insure-proxy-abi.json'
import { LP_INSURE_MAIN_CONTRACT, LP_INSURE_PROXY_FACTORY } from "app-constants/lp-insure";

type UserAmounts = {
    principalAmount: string[] | number[]
    availableAmount: string[] | number[]
}

export const getUserAmounts = async(appChainId: SupportedNetworks, classIds: string[], nonceIds: string[], account: string): Promise<UserAmounts> =>{
    const currentBondContract = LP_INSURE_MAIN_CONTRACT[appChainId];
    const httpProviderUrl = NETWORKS[appChainId].HTTP_PROVIDER_URL
    const w3 = new Web3(httpProviderUrl)
    const bondInstance = new w3.eth.Contract(InsureBondABI as AbiItem[], currentBondContract)
    const result = await bondInstance.methods.getUserAmounts(classIds, nonceIds, account.toLowerCase()).call()
    return result
}

type Farm = {
    baseRewardPerToken: string;
    endDate: string | number
    endRewardPerToken: string | number
    owner: string
    rewardPerSecond: string | number
    rewardToken: string | number
    toDumperShield: boolean
}

export const getFarms = async(appChainId: SupportedNetworks, classId: string): Promise<Farm[]> =>{
    const currentBondContract = LP_INSURE_PROXY_FACTORY[appChainId];
    const httpProviderUrl = NETWORKS[appChainId].HTTP_PROVIDER_URL
    const w3 = new Web3(httpProviderUrl)
    const bondInstance = new w3.eth.Contract(InsureOfferABI as AbiItem[], currentBondContract)
    const result = await bondInstance.methods.getFarms(classId).call()
    return result
}

type Reward = {
    amount: string;
    classId: string
    farmId: string
    rewardToken: string
}
export const getRewards = async(appChainId: SupportedNetworks, classIds: string[], user: string): Promise<Reward[]> =>{
    const currentBondContract = LP_INSURE_PROXY_FACTORY[appChainId];
    const httpProviderUrl = NETWORKS[appChainId].HTTP_PROVIDER_URL
    const w3 = new Web3(httpProviderUrl)
    const bondInstance = new w3.eth.Contract(InsureOfferABI as AbiItem[], currentBondContract)
    const result = await bondInstance.methods.getRewards(classIds, user).call()
    return result
}

export async function balanceOf(appChainId: SupportedNetworks, account: string, classId: string, nonceId: string): Promise<string | number> {
    const currentBondContract = LP_INSURE_MAIN_CONTRACT[appChainId];
    const httpProviderUrl = NETWORKS[appChainId].HTTP_PROVIDER_URL
    const w3 = new Web3(httpProviderUrl)
    const bondInstance = new w3.eth.Contract(InsureBondABI as AbiItem[], currentBondContract)
    const result = await bondInstance.methods.balanceOf(account, classId, nonceId).call()
    return result
}

export const allowance = async(appChainId: SupportedNetworks, classId: string, nonceId: string, spender: string, account: string): Promise<string> => {
    const currentBondContract = LP_INSURE_MAIN_CONTRACT[appChainId]
    const httpProviderUrl = NETWORKS[appChainId].HTTP_PROVIDER_URL
    const w3 = new Web3(httpProviderUrl)
    const bondInstance = new w3.eth.Contract(InsureBondABI as AbiItem[], currentBondContract)
    const result = await bondInstance.methods.allowance(account, spender, classId, nonceId).call()
    return result as string
}

export const approve = async(appChainId: SupportedNetworks, classId: string, nonceId: string, spender: string, amount: string, account: string): Promise<string> => {
    const w3 = new Web3(Web3.givenProvider)
    const insureBondContract = LP_INSURE_MAIN_CONTRACT[appChainId];
    const bondInstance = new w3.eth.Contract(InsureBondABI as AbiItem[], insureBondContract);
    const transactions = [
        {
            classId: classId,
            nonceId: nonceId,
            amount: amount,
        }
    ]
    const result = await bondInstance.methods.approve(spender, transactions).send({ from: account })
    return result as string
}
