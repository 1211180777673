import styled from 'styled-components'

type AppOverlayCustom  = {
  blur?: number
}

export const AppOverlay = styled.div<AppOverlayCustom>`
  border: solid 2px rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(${(props) => props.blur || 5}px);
  background-color: rgba(255, 255, 255, 0.0001);
  border-radius: 10px;

  &:before {
    border: inherit;
    border-color: transparent;
    filter: blur(8px);
  }
`
