import { RootState } from 'redux/store'
import { AppThunkAction, ReducerSimplePrices } from 'types'
// import { RootState } from 'redux/store'
// import { AppThunkAction } from 'types'
import { ActionCoingecko, ActionTypes } from './types'

type CoingeckoThunkAction<R> = AppThunkAction<R, RootState, null, ActionCoingecko>


export const initSimplePrices = (values: ReducerSimplePrices): CoingeckoThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.INIT_TOKEN_PRICE,
    payload: values,
  })
}


export const setSimplePrices = (values: ReducerSimplePrices): CoingeckoThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_TOKEN_PRICE,
    payload: values,
  })
}
