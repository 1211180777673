import { InputGroup, InputGroupText } from 'Component/core/elements/InputGroup'
import styled from 'styled-components'

export const TokenText = styled(InputGroupText)`
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  text-transform: uppercase;
`
export const SInvestCodeWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin : 20px 0px;
`

export const SInvestCodeTitle = styled.div`
  display: flex;
  width: 50%;
`

export const SRadioButtonGroup = styled.div`
  display: flex;
  align-items: center;
  
  ${InputGroup} {
    min-width: 250px;
    max-width: 400px;
  }
`