import React, { useCallback } from 'react'
import styled from 'styled-components'
import DataTable, { TableProps, PaginationComponentProps } from 'react-data-table-component'
import { AppButton } from './core/elements/Buttons'

const SConnectWalletWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  margin-top: 40px;

  & ${AppButton} {
    padding-right: 150px;
    padding-left: 150px;
  }
`

interface IAppDataTableProps<T> extends TableProps<T> {
  requireWalletConnect: boolean
  emptyTableData: T[]
  onClickConnectWallet: () => void
}

function AppDataTable<T>({ requireWalletConnect, data, emptyTableData, onClickConnectWallet, className, paginationComponent, ...props }: IAppDataTableProps<T>) {

  const customPagination = useCallback(() => {
    return (
      requireWalletConnect || data.length === 0
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ? (props: PaginationComponentProps) => <></>
      : paginationComponent
    )
  }, [requireWalletConnect, data, paginationComponent])

  return (
    <div>
      <DataTable
        className={requireWalletConnect || data.length === 0 ? `rdt_Table-no-data ${className}` : className}
        data={(requireWalletConnect || data.length === 0) ? emptyTableData : data}
        paginationComponent={customPagination()}
        {...props}
      />
      {requireWalletConnect && (
        <SConnectWalletWrap>
          <AppButton className='outline-black fw-bold w-auto' onClick={onClickConnectWallet}>Connect Wallet</AppButton>
        </SConnectWalletWrap>
      )}
    </div>
  )
}

export default AppDataTable
