import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import styled from 'styled-components'

const SInfoCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 15px 50px;
  min-width: 350px;

  border-color: ${(props) => props.theme.colorPrimary};
  color: ${({ theme }) => theme.colorLightGrey};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.bodybg03};

  font-weight: bold;
  text-transform: uppercase;

  &.active {
    box-shadow: ${(props) => props.theme.colorPrimary} 0px 0px 10px 1px;
    color: ${({ theme }) => theme.colorPrimary};
  }
`

const SCircleNumber = styled.div`
  width: 40px;
  height: 40px;
  left: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorGrey8};
  color: ${({ theme }) => theme.colorPrimary};
`

const STitle = styled.h3`
  //   margin-left: 50px;
  max-width: 200px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSize['lg']};
`

interface IInfoCardProps extends React.HTMLAttributes<HTMLDivElement> {
  num: string | number
  isCompleted: boolean
  disabled?: boolean
}

function InfoCard({ num, title, isCompleted, className = '', ...props }: IInfoCardProps): JSX.Element {
  return (
    <SInfoCard className={`info-card ${className}`} {...props}>
      <SCircleNumber>{isCompleted ? <BsCheckLg /> : num}</SCircleNumber>
      <STitle>{title}</STitle>
    </SInfoCard>
  )
}

export default InfoCard
