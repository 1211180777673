import { useCallback, useEffect, useMemo, useState } from 'react'
import { isAddress } from 'web3-utils'
import { createClient, GetPairs } from 'helper/graphql'
import { PairToken } from 'types'

const COUNT = 20

const buildWhereCondition = (_search: string) => {
  if (isAddress(_search)) {
    return { id: _search }
  } else {
    return { symbol_contains_nocase: _search }
  }
}

type IUsePairsReturnType = [PairToken[], () => Promise<void>, string, React.Dispatch<React.SetStateAction<string>>]

export const usePairs = (subgraphUrl: string): IUsePairsReturnType => {
  const [query, setQuery] = useState('')
  const [pairs, setPairs] = useState<PairToken[]>([])

  const subgraphClient = useMemo(() => {
    return createClient(subgraphUrl)
  }, [subgraphUrl])

  const fetchPairs = useCallback(
    async (_skip = 0): Promise<PairToken[]> => {
        // search by address or name
        if (query) {
          const condition = buildWhereCondition(query)
          try {
            const res0 = await subgraphClient.query({
              query: GetPairs,
              variables: {
                first: COUNT,
                skip: _skip,
                where: { token0_: condition },
              },
            })

            const res1 = await subgraphClient.query({
              query: GetPairs,
              variables: {
                first: COUNT,
                skip: _skip,
                where: { token1_: condition },
              },
            })
            const pairs: PairToken[] = [...res0.data.pairs, ...res1.data.pairs]
            if (pairs.length > 0) {
              // find images
              return pairs
            } else {
              throw new Error('Pairs no exist')
            }
          } catch (error) {
            return []
          }
        } else {
          try {
            const res = await subgraphClient.query({
              query: GetPairs,
              variables: {
                first: COUNT,
                skip: _skip,
              },
            })
            const pairs = res.data.pairs
            if (pairs.length > 0) {
              // find images
              return pairs
            } else {
              throw new Error('Pairs no exist')
            }
          } catch (error) {
            return []
          }
        }
    },
    [subgraphClient, query]
  )

  const fetchMorePairs = useCallback(async (): Promise<void> => {
    fetchPairs(pairs.length)
      .then((_pairs) => {
        setPairs((p) => [...p, ..._pairs])
      })
      .catch((err) => {
        console.error(err)
      })
  }, [pairs.length, fetchPairs])

  useEffect(() => {
    const run = async () => {
      const _pairs = await fetchPairs()

      setPairs(_pairs)
    }
    run()
  }, [fetchPairs])

  return [pairs, fetchMorePairs, query, setQuery]
}
