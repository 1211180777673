import styled from 'styled-components'

interface IDividerProps {
  width?: string
}

export const Divider = styled.div<IDividerProps>`
  background-color: ${(props) => props.theme.headbg01};
  &.vertical {
    width: 100%;
    height: ${props => props.width || '1'}px;
  }
  &.horizontal {
    width: ${props => props.width || '1'}px;
    height: 100%;
  }
`
