import { useCallback, useEffect, useMemo, useState } from 'react'
import { LOCAL_STORAGE_ITEM_NAMES } from 'app-constants'
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'helper/localStorage'

type UseCommonTokensReturnType = {
  commonBases: string[]
  add: (_address: string | string[]) => void
  remove:(address: string) => void
}

export function useCommonTokens(): UseCommonTokensReturnType {
  const [commonBases, setCommonBases] = useState<string[]>(() => {
    let savedItems = loadStateFromLocalStorage(LOCAL_STORAGE_ITEM_NAMES.COMMON_TOKENS)

    if (savedItems) {
      try {
        savedItems = [...savedItems]
        return savedItems
      } catch (error) {
        localStorage.removeItem(LOCAL_STORAGE_ITEM_NAMES.COMMON_TOKENS)
        return []
      }
    } else {
      return []
    }
  }) // Array of Token Address String

  const removeDuplicate = useCallback(
    /**
     *
     * @param {Array} values
     */
    (values: any) => {
      return values.filter((v: any, index: number, self: any[]) => self.findIndex((sV) => sV === v) === index)
    },
    []
  )

  const add = useCallback(
    (_address: string | string[]) => {
      if (Array.isArray(_address)) {
        setCommonBases((p: string[]) => {
          const newValues = [...p, ..._address]
          return removeDuplicate(newValues)
        })
      } else if (typeof _address === 'string') {
        setCommonBases((p: string[]) => {
          const newValues = [...p, _address]
          return removeDuplicate(newValues)
        })
      }
    },
    [removeDuplicate]
  )

  const remove = useCallback((_address: string) => {
    setCommonBases((p) => p.filter((v) => String(v).toLowerCase() !== String(_address).toLowerCase()))
  }, [])

  useEffect(() => {
    saveStateToLocalStorage(LOCAL_STORAGE_ITEM_NAMES.COMMON_TOKENS, commonBases)
  }, [commonBases])

  return useMemo(() => ({ commonBases, add, remove }), [commonBases, add, remove])
}
