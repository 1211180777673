import moment from 'moment'
import { getBonds, voteToBond } from 'helper/apis/bond-server-api'
import BondSubgraph from 'helper/subgraphs/BondSubgraph'
import { getErrorMessageHTTP } from 'helper/error-utils'
import { adjustBondParams } from 'helper'
import { RootState } from 'redux/store'
import { AppThunkAction, BondData, BondInfo, SupportedNetworks } from 'types'
import { ActionBondList, ActionTypes } from './types'

type BondListAppThunkAction<
  R, // Return type of the thunk function
> = AppThunkAction<R, RootState, null, ActionBondList>

export const setSearch = (query: string): BondListAppThunkAction<void> => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SEARCH_VALUE,
    payload: query,
  })
}

export const fetchBonds = (appChainId: SupportedNetworks, account: string | undefined = undefined): BondListAppThunkAction<Promise<void>> => async (dispatch, getState) => {
  const search = getState().bondList.search
  let bonds: Array<BondData> = []
  dispatch({
    type: ActionTypes.IS_LOADING_BOND_LIST,
    payload: true,
  })
  // get bonds
  try {
    const bondSubgraph = new BondSubgraph(appChainId)
    let where: any = undefined
    if (search) {
      where = bondSubgraph.buildWhereByToken(search)
    }
    const res = await bondSubgraph.getBonds(where, undefined, undefined, account)
    if (res.data === null || !Array.isArray(res.data.bonds)) {
      throw new Error('Something went wrong. Bond Data is null or Bond list is not array.')
    }
    bonds = res.data?.bonds as BondData[]
    bonds = bonds.map((bond) => adjustBondParams(bond))
  } catch (error) {
    console.error(error)
  }
  try {
    // get project informations
    const bondIds = bonds.map((bond) => `${bond.classId}-${bond.nonceId}`)
    const res = await getBonds({ ids: bondIds, networkId: appChainId })
    const infos = res.data.bonds as Array<BondInfo>

    for (let i = 0; i < bonds.length; i++) {
      const bond = bonds[i]
      const _info = infos.find((info) => info.bondId === `${bond.classId}-${bond.nonceId}`)
      bond.info = _info || null
    }
  } catch (error) {
    console.error(error)
  }
  dispatch({
    type: ActionTypes.FETCH_BOND_LIST,
    payload: bonds,
  })
  dispatch({
    type: ActionTypes.IS_LOADING_BOND_LIST,
    payload: false,
  })
}

export const voteBond =
  (classId: string, nonceId: string, yea = true): BondListAppThunkAction<Promise<void>> =>
    async (dispatch, getState) => {
      const appChainId = getState().globals.appChainId
      try {
        const res = await voteToBond({ bondId: `${classId}-${nonceId}`, yea, networkId: appChainId })
        if (res.data.success) {
          dispatch({
            type: ActionTypes.VOTE_TO_BOND,
            payload: {
              classId,
              nonceId,
            },
          })
        } else {
          throw new Error('Something Went Wrong!')
        }
      } catch (error: any) {
        const er = getErrorMessageHTTP(error)
        console.error(er)
        throw er
      }
    }

export const fetchBondBuyersInLast24hrs = (appChainId: SupportedNetworks): BondListAppThunkAction<any> => async (dispatch) => {
  const bondSubgraph = new BondSubgraph(appChainId)
  const yesterday = moment().subtract(1, 'days').toDate().getTime().toString().slice(0, -3) // change mili timestamp to secs timestamp
  const where: any = {
    timestamp_gte: yesterday,
  } 

  const res = await bondSubgraph.getBondBuyers(where)

  dispatch({
    type: ActionTypes.SET_INVEST_LENGTH_IN_24HRS,
    payload: res.data?.bondBuyers?.length || 0,
  })
}

export const reFetchBonds = (): BondListAppThunkAction<Promise<void>> => async (dispatch, getState) => {
  const appChainId = getState().globals.appChainId;
  const account = getState().globals.account;

  return dispatch(fetchBonds(appChainId, account))
}
